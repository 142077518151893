<template>
  <b-form-row
    id="SVGEditor"
    class="svg-wrapper"
  >
    <b-col cols="12">
      <span>Show:</span>
      <b-form-checkbox-group
        v-model="visibleSections"
        :options="visibleSectionsOptions"
        class="d-inline-block ml-2 mb-2"
        @input="onVisibleSectionsChange"
      />
    </b-col>
    <b-col v-show="visibleSections.includes('svgSection')">
      <SVGSection
        ref="svgSection"
        @loaded="onLoaded"
        @load="onLoad"
      />
    </b-col>
    <b-col v-show="visibleSections.includes('productSection')">
      <ProductSection
        ref="productSection"
        @loaded="onLoaded"
        @load="onLoad"
      />
    </b-col>

    <div
      v-if="isSvgLoading"
      class="svg-wrapper__overlay"
    >
      <div class="svg-wrapper__overlay--background" />
      <div class="svg-wrapper__overlay--spinner">
        <b-spinner size="lg" />
      </div>
    </div>
  </b-form-row>
</template>

<script>
import SVGSection from './SVGSection.vue';
import ProductSection from './ProductSection.vue';
import {IMPORT_TARGET, TYPE_IMAGE} from "./constants";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SVGEditor',

  components: {
    SVGSection,
    ProductSection,
  },

  data: () => ({
    visibleSections: ['svgSection', 'productSection'],
    visibleSectionsOptions: [
      { text: 'SVG Section', value: 'svgSection' },
      { text: 'Product Section', value: 'productSection' },
    ],
  }),

  computed: {
    ...mapGetters('fileBrowser', [
      'isSvgLoading',
    ]),
  },
  methods: {
    ...mapActions('fileBrowser', [
      'setIsSvgLoading',
    ]),

    loadFromBlob(props) {
      const { blob, target } = props;
      let ref;
      let type = '';

      switch (target) {
        case IMPORT_TARGET.SVG:
        case IMPORT_TARGET.SVG_PDF:
          type = IMPORT_TARGET.SVG
          ref = this.$refs.svgSection;
          break;
        case IMPORT_TARGET.PRODUCT:
        case IMPORT_TARGET.PRODUCT_TIFF:
          type = TYPE_IMAGE
          ref = this.$refs.productSection;
          break;
        default:
          throw new Error('No section for provided target. Provided: ' + target)
      }

      ref.onFileSelected(blob, type, props);
    },
    onLoad() {
      this.setIsSvgLoading(true);
    },
    onLoaded() {
      this.setIsSvgLoading(false);
    },
    async onVisibleSectionsChange () {
      await this.$nextTick()
      let orientation = 'tall'
      if (this.visibleSections.length < 2) {
        orientation = 'landscape'
      }
      if (this.visibleSections.includes('svgSection')) {
        this.$refs.svgSection.SVGCanvas.setOrientation(orientation)
      }
      if (this.visibleSections.includes('productSection')) {
        this.$refs.productSection.ProductCanvas.setOrientation(orientation)
      }
    },
  },
};
</script>

<style scoped lang="scss">
.svg-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 30px 0;
  position: relative;

  &__overlay {
    z-index: 1;

    &--background, &--spinner {
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% + 40px);
      height: 100vh;
      margin: -20px;
    }
    &--background {
      background: black;
      opacity: 0.1;
    }
    &--spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
