import {SOURCE} from "../../../../constants/file-image-types";
import backgroundImageTranslator from "./background-image-translator";

const DATA_TRANSLATORS = {
    [SOURCE.BACKGROUND]: backgroundImageTranslator
}

export const resolveDataTranslator = source => {
    return DATA_TRANSLATORS[source]
}

export const addProcessingProp = data => ({
    ...data,
    percent: 0,
})