export function captureVideoFrame(file) {
    let video = document.createElement('video');
    let url = URL.createObjectURL(file);

    video.src = url;

    return new Promise(async (resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.onloadedmetadata = async function () {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.currentTime = 1;

            video.onseeked = async function () {
                context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
                context.drawImage(video, 0, 0, canvas.width, canvas.height);

                const imageDataURL = canvas.toDataURL('image/jpeg', 0.8);
                resolve(imageDataURL);
            };
        };
    });
}