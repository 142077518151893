import FileBrowser from "../../../services/api/file-browser";
import {FILE_TYPE, SOURCE, TYPE} from "../../../constants/file-image-types";
import {TAB_SOURCE_MAP, TABS} from "./constants";
import Requests from "../../../services/api/file-browser";

const resolvePoint = (route) => {
    const tab = route.params.tab;
    const path = route.params.pathMatch;
    const mainSource = TAB_SOURCE_MAP[tab];

    if (tab !== TABS.FILES || !path || tab === TABS.PRODUCTS) {
        return mainSource;
    }

    const lastFolder = path.split('/').pop()

    const folder = Number(lastFolder);

    if (isNaN(folder)) {
        return mainSource;
    }

    return `${SOURCE.FOLDER_CONTENT_RESOLVE_POINT}&folderId=${lastFolder}`
}
const pageNumberParam = (route) => {
    return `&pageNumber=${route.query.page}`
}
const typeParam = (route) => {
    return route.query.type ? `&type=${route.query.type}` : '';
}
const searchQueryParam = route => {
  return `&searchKey=${route.query.search || ''}`
}
const SOURCE_BUILDERS = [
    resolvePoint,
    searchQueryParam,
    pageNumberParam,
    typeParam
]
export const resolveSource = (route) => {
    return SOURCE_BUILDERS.reduce((url, resolve) => `${url}${resolve(route)}`, '')
}
export const resolveMultipleDeleteMethod = (route) => {
    const tab = route.params.tab

    switch (tab) {
        case TABS.PRODUCTS:
            return FileBrowser.deleteMultipleProductFile
        case TABS.FILES:
            return FileBrowser.deleteMultipleFolderFile
        default:
            throw new Error('no handler for this tab')
    }
}
export const resolveDownloadMethod = (route, items) => {
    const tab = route.params.tab

    switch (tab) {
        case TABS.PRODUCTS:
        case TABS.BACKGROUND:
            return FileBrowser.download;
        case TABS.FILES:
            const [firstItem] = items
            if (firstItem.type === FILE_TYPE.FOLDER) {
                return FileBrowser.downloadFolder;
            } else {
                return FileBrowser.downloadFolderFiles;
            }
        default:
            throw new Error('no handler for this case')
    }
}

export const resolveDeleteItemMethod = (route, item) => {
    const tab = route.params.tab
    switch (tab) {
        case TABS.PRODUCTS:
            switch (item.type) {
                case TYPE.FOLDER:
                    return Requests.deleteProductFolder;
                default:
                    return FileBrowser.deleteMultipleProductFile
        }
        case TABS.FILES:
            switch (item.type) {
                case TYPE.FOLDER:
                    return Requests.deleteFolder;
                default:
                    return Requests.deleteFolderFile;
            }
        case TABS.STOCK_IMAGES:
            return Requests.deleteStockFile;
        default:
            return () => Promise.reject();
    }
}
