var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "select-folder-modal",
        title: "Select Folder",
        size: "lg",
        "ok-only": "",
        "ok-disabled": !_vm.selectedItems.length,
      },
      on: {
        ok: function ($event) {
          return _vm.$emit("onSelectedItems", _vm.selectedItems)
        },
      },
    },
    [
      _c("FolderContentView", {
        attrs: { "is-only-folder": true },
        on: { onSelectedItems: (items) => (_vm.selectedItems = items) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }