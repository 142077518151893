<template>
  <div class="svg-messages-wrapper">
    <span>{{ displayMessage() }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SVGEditorMessages',

  computed: {
    ...mapGetters('svgEditor', [
      'isAltSelectionMode',
      'isSvgInstanceHasSelectedObjects',
      'isSelectionBoxMode',
    ]),
  },

  methods: {
    displayMessage() {
      if (this.isAltSelectionMode && this.isSvgInstanceHasSelectedObjects) {
        return 'Press Enter to finish selecting working area or Esc to cancel';
      }
      if (this.isSelectionBoxMode && this.isSvgInstanceHasSelectedObjects) {
        return 'Press Enter to paste result, F to full size selected area';
      }
    }
  },
};
</script>

<style scoped lang="scss">
.svg-messages-wrapper {
  position: absolute;
  left: 195px;
  top: 25px;
}
</style>