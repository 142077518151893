<template>
  <b-modal
    id="custom-template-size-modal"
    centered
    title="Add Custom Template Size"
    ok-title="Save"
    @ok="onSaved"
    @shown="shown"
  >
    <b-row>
      <b-col cols="12">
        <label>Name</label>
        <b-form-input
          v-model="inputForm.name"
          placeholder="Name"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-3"
      >
        <label>Customer</label>
        <b-form-select v-model="inputForm.customer">
          <b-form-select-option
            v-for="(customer, index) in customers"
            :key="'key_' + index + '_' + customer.id"
            :value="customer.id"
          >
            {{ customer.name }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col
        cols="12"
        class="mt-3"
      >
        <label>Theme</label>
        <b-form-select
          v-model="inputForm.theme"
          :disabled="!inputForm.customer"
        >
          <b-form-select-option
            v-for="(theme, index) in themes"
            :key="'key_' + index + '_' + theme.id"
            :value="theme.id"
          >
            {{ theme.name }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col
        cols="12"
        class="mt-3"
      >
        <div class="input-group">
          <b-form-input
            v-model.number="inputForm.width"
            type="number"
            min="0"
            placeholder="Width"
          />
          <span class="input-group-text">×</span>
          <b-form-input
            v-model.number="inputForm.height"
            type="number"
            min="0"
            placeholder="Height"
          />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer="{ cancel, ok }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <b-form-checkbox v-model="inputForm.saveAsNew">
          Save as new template
        </b-form-checkbox>
        <div>
          <b-button
            @click="cancel"
          >
            Cancel
          </b-button>

          <b-button
            variant="primary"
            :disabled="idDisabledSave"
            @click="ok"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { customTemplateSizeEventBroker, EVENTS } from '../../../group/modules/custom-template-size/event-broker';
import CustomerService from '@frontend/services/api/customer'
import { localStorageService } from "@frontend/lib/utils/local-storage";

const initInputForm = () => ({
  name: '',
  width: 0,
  height: 0,
  theme: '',
  customer: undefined,
  saveAsNew: true
})

export default {
  data: () => ({
    inputForm: initInputForm(),
    themes: [],
    customers: [],
    lastSavedSettings: null
  }),
  computed: {
    idDisabledSave() {
      return !this.inputForm.width
        || !this.inputForm.height
        || !this.inputForm.name
        || !this.inputForm.theme
        || !this.inputForm.customer;
    }
  },
  watch: {
    'inputForm.customer'() {
      this.loadThemes()
    },
    async lastSavedSettings() {
      if (!this.themes.length) {
        await this.loadThemes();
      }
      
      if (this.lastSavedSettings) {
        this.setLastSavedSettings();
      }
    }
  },
  mounted() {
    customTemplateSizeEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },
  methods: {
    init(dto) {
      this.inputForm.name = dto.name;
      this.$bvModal.show('custom-template-size-modal');
    },
    async shown() {
      this.lastSavedSettings = localStorageService.getCustomTemplateSettings();
      
      if (this.customers.length) {
        return;
      }

      this.customers = (await CustomerService.getCustomers()).data
      
      if (!this.lastSavedSettings) {
        const [match, customerId] = new RegExp(/banner\/(\d+)\/group/).exec(location.pathname);
        
        this.inputForm.customer = Number(customerId);
      }
    },
    async loadThemes() {
      if (this.inputForm.customer === undefined) {
        return;
      }
      this.themes = (await CustomerService.getThemes(this.inputForm.customer)).data;
    },
    clear() {
      this.inputForm = initInputForm();
    },
    onSaved() {
      localStorageService.setCustomTemplateSettings(this.inputForm);
      
      customTemplateSizeEventBroker.fire(EVENTS.SAVED, {
        ...this.inputForm
      });

      this.clear();
    },
    setLastSavedSettings() {
      this.inputForm.customer = Number(this.lastSavedSettings.customer);
      this.inputForm.theme = Number(this.lastSavedSettings.theme);
      this.inputForm.width = Number(this.lastSavedSettings.width);
      this.inputForm.height = Number(this.lastSavedSettings.height);
    }
  }
}
</script>
