var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-breadcrumb",
    { staticClass: "a-breadcrumb" },
    [
      _c(
        "b-breadcrumb-item",
        {
          attrs: { active: !_vm.breadcrumbs.length },
          on: {
            click: function ($event) {
              return _vm.$emit("goTo", {
                id: null,
                index: 0,
              })
            },
          },
        },
        [_c("i", { staticClass: "fa fa-home" })]
      ),
      _vm._v(" "),
      _vm._l(_vm.breadcrumbs, function (breadcrumb, index) {
        return _c(
          "b-breadcrumb-item",
          {
            key: breadcrumb.id,
            staticClass: "a-breadcrumb__item",
            attrs: { active: index === _vm.breadcrumbs.length - 1 },
            on: {
              click: function ($event) {
                return _vm.itemClickHandler(breadcrumb, index)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(breadcrumb.text) + "\n  ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }