import { generateKeyPhrasesRegex } from '@frontend/store/psd-layers/features/auto-map/utils/generate-keyphrases-regex';
import { matchKeyPhrase } from '@frontend/store/psd-layers/features/auto-map/utils/match-keyphrase';

export const match = ({ fieldType, sourceName, keyPhrases, options }) => {
  if (!fieldType || !sourceName) {
    return false;
  }

  const normalizedSourceName = sourceName.toLowerCase().trim();

  const sourceKeyPhrase = matchKeyPhrase(normalizedSourceName, keyPhrases);
  const sourceMatch = normalizedSourceName.match(generateKeyPhrasesRegex(sourceKeyPhrase, options));
  return sourceMatch ? fieldType : null;
}