<template>
  <div>
    <b-row class="mb-3">
      <b-col 
        cols="8" 
        class="pr-0"
      >
        <b-form-select
          v-model="selectedShadowGroup"
          :options="shadowGroups"
          text-field="name"
          value-field="id"
          @change="handleShadowGroupChange"
        >
          <template #first>
            <b-select-option value="">
              Create new group
            </b-select-option>
          </template>
        </b-form-select>
      </b-col>
      
      <b-col 
        cols="4"
        class="px-1"
      >
        <b-button
          v-b-tooltip.hover
          :title="!selectedPreset ? 'Save' : 'Update'"
          type="button"
          variant="outline-primary"
          @click="$bvModal.show('shadow-group-name-modal')"
        >
          <b-icon
            v-if="!selectedShadowGroup"
            icon="save-fill"
          />

          <b-icon
            v-else
            icon="pencil-fill"
          />
        </b-button>

        <b-button
          v-if="selectedShadowGroup"
          v-b-tooltip.hover
          title="Delete"
          type="button"
          variant="outline-danger"
          @click="deleteShadowGroup"
        >
          <b-icon icon="bucket-fill" />
        </b-button>
      </b-col>
      
      <ShadowGroupNameModal 
        :title="shadowGroupNameModal.title" 
        :value="shadowGroupNameModal.value"
        placeholder="Please enter a shadow group name"
        submit-btn-title="Continue"
        @submit="handleShadowGroupSubmit"
      />
    </b-row>
    
    <b-card no-body>
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          v-for="(shadow, key) in tabs"
          :key="'key' + key"
        >
          <template #title>
            {{ 'Shadow ' + (key + 1) }}
            <b-button
              class="float-right close-button"
              pill
              size="sm"
              variant="danger"
              @click="closeTab(key)"
            >
              X
            </b-button>
          </template>
          <b-form>
            <b-form-group>
              <template #label>
                Shadow Presets
              </template>
              <b-row>
                <b-col 
                  cols="6" 
                  class="pr-1"
                >
                  <b-form-select
                    v-model="selectedPreset"
                    :options="shadowPresets"
                    text-field="name"
                    value-field="id"
                    @change="handleSelectPresetChange"
                  >
                    <template #first>
                      <b-select-option value="">
                        create new
                      </b-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                
                <b-col 
                  cols="6"
                  class="p-0"
                >
                  <b-button
                    v-if="selectedPreset"
                    v-b-tooltip.hover
                    title="Apply"
                    type="button"
                    variant="primary"
                    @click="applyPreset"
                  >
                    <b-icon icon="check-lg" />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    :title="selectedPreset ? `Edit`: 'Save'"
                    type="button"
                    variant="outline-primary"
                    @click="savePresetOfOvalShadow"
                  >
                    <b-icon
                      v-if="!selectedPreset"
                      icon="save-fill"
                    />
                    <b-icon
                      v-else
                      icon="pencil-fill"
                    />
                  </b-button>
                  <b-button
                    v-if="selectedPreset"
                    v-b-tooltip.hover
                    title="Delete"
                    type="button"
                    variant="outline-danger"
                    @click="deletePreset"
                  >
                    <b-icon icon="bucket-fill" />
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form-group label="Blur">
            <b-input-group>
              <b-input
                v-model="ovalBlur"
                :max="100"
                :min="0"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalBlur"
                  :max="100"
                  :min="0"
                  type="number"
                  @change="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Width">
            <b-input-group>
              <b-input
                v-model="ovalWidth"
                :max="100"
                :min="0"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalWidth"
                  :max="100"
                  :min="0"
                  type="number"
                  @change="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Height">
            <b-input-group>
              <b-input
                v-model="ovalHeight"
                :max="100"
                :min="0"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalHeight"
                  :max="100"
                  :min="0"
                  type="number"
                  @change="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Offset X">
            <b-input-group>
              <b-input
                v-model="ovalOffsetX"
                :max="100"
                :min="-100"
                :step="0.01"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalOffsetX"
                  :max="100"
                  :min="-100"
                  type="number"
                  @input="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Offset Y">
            <b-input-group>
              <b-input
                v-model="ovalOffsetY"
                :max="100"
                :min="-100"
                :step="0.01"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalOffsetY"
                  :max="100"
                  :min="-100"
                  type="number"
                  @input="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Intensity">
            <b-input-group>
              <b-input
                v-model="ovalOpacity"
                :max="100"
                :min="0"
                type="range"
                @input="onOvalShadowChange"
              />
              <template #append>
                <b-input
                  v-model="ovalOpacity"
                  :max="100"
                  :min="0"
                  type="number"
                  @input="onOvalShadowChange"
                />
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Display mode">
            <b-form-radio-group
              :id="`display-mode-${key}`"
              v-model="displayModeSelected"
              :options="displayModeOptions"
              @change="onOvalShadowChange"
            />
          </b-form-group>
          <b-form-group 
            v-slot="{ ariaDescribedby }" 
            label="Fill types"
          >
            <b-form-radio-group
              v-model="selectedFillType"
              :aria-describedby="ariaDescribedby"
              :options="colorTypes"
              @change="onFillTypeChange"
            />
            
            <b-form-input
              v-if="selectedFillType === 'Color'"
              :id="`input-color-${key}`"
              v-model="ovalColor"
              :type="'color'"
              debounce="50"
              @update="onOvalShadowChange"
            />
            <b-form-select
              v-if="selectedFillType === 'Linear'"
              v-model="selectedLinearDirection" 
              :options="linearDirectionsOptions" 
              size="sm"
              @change="onOvalShadowChange"
            />
            <b-form-group
              v-if="['Linear', 'Radial'].includes(selectedFillType)"
            >
              <template 
                #label
              >
                <div
                  class="d-inline-flex justify-content-between align-items-center w-100"
                >
                  Color stops <b-button
                    size="sm"
                    variant="outline-success"
                    @click="addColorStop"
                  >
                    <b-icon
                      font-scale="1"
                      icon="plus"
                    />
                  </b-button>
                </div>
              </template>
              <div
                v-for="(colorStop, colorKey) in colorStops"
                :key="colorKey"
                class="color-stop-container"
              >
                <b-form-input
                  :id="`color-stop-${colorKey}`"
                  v-model="colorStop.color"
                  :type="'color'"
                  class="color-stop"
                  debounce="50"
                  @update="onColorStopChange($event, colorKey)"
                />
                <b-form-input
                  :id="`offset-${key}`"
                  v-model="colorStop.offset"
                  :type="'range'"
                  debounce="50"
                  max="1"
                  min="0"
                  size="sm"
                  step="0.01"
                  @update="onColorOffsetChange($event, key)"
                />
                <b-form-input
                  :id="`offset-${key}`"
                  v-model="colorStop.offset"
                  :type="'number'"
                  class="color-stop-numbers"
                  max="1"
                  min="0"
                  size="sm"
                  step="0.01"
                  @change="onColorOffsetChange($event, key)"
                />
                <b-button 
                  :disabled="colorStops.length <= 2"
                  size="sm"
                  variant="outline-danger" 
                  @click="removeColorStop(key)"
                >
                  <b-icon
                    font-scale="1"
                    icon="archive-fill"
                  />
                </b-button>
              </div>
            </b-form-group>
          </b-form-group>
        </b-tab>

        <!-- New Tab Button (Using tabs-end slot) -->
        <template #tabs-end>
          <b-nav-item
            href="#"
            role="presentation"
            @click.prevent="newTab"
          >
            <b>+</b>
          </b-nav-item>
        </template>

        <!-- Render this if no tabs -->
        <template #empty>
          <div class="text-center text-muted">
            There are no open tabs<br>
            Open a new tab using the <b>+</b> button above.
          </div>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>

import {
  COLOR_TYPE,
  BEHIND_DISPLAY_MODE,
  LINEAR_GRADIENT_COLOR_TYPE,
  IN_FRONT_DISPLAY_MODE,
  LEFT_LINEAR_GRADIENT_DIRECTION,
  RIGHT_LINEAR_GRADIENT_DIRECTION,
  TOP_LINEAR_GRADIENT_DIRECTION,
  BOTTOM_LINEAR_GRADIENT_DIRECTION, RADIAL_GRADIENT_COLOR_TYPE, DEFAULT_COLOR_STOPS, DEFAULT_COLOR
} from "@frontend/constants/oval-shadows";
import shadowPresetApi from '@frontend/services/api/shadow-preset'
import shadowGroupApi from '@frontend/services/api/shadow-group'
import ShadowGroupNameModal
  from "@frontend/components/modules/layouts-page/EditorControl/components/ShadowGroupNameModal.vue";

export default {
  name: 'OvalShadow',
  components: {ShadowGroupNameModal},
  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
    presetData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      tabIndex: 0,
      tabs: [],
      tabCounter: 0,
      ovalBlur: 0,
      ovalWidth: 0,
      ovalHeight: 0,
      ovalOffsetX: 0,
      ovalOffsetY: 0,
      ovalOpacity: 1,
      displayModeOptions: [
        BEHIND_DISPLAY_MODE,
        IN_FRONT_DISPLAY_MODE
      ],
      displayModeSelected: BEHIND_DISPLAY_MODE,
      ovalColor: DEFAULT_COLOR,
      colorTypes: [
        COLOR_TYPE,
        LINEAR_GRADIENT_COLOR_TYPE,
        RADIAL_GRADIENT_COLOR_TYPE,
      ],
      selectedFillType: COLOR_TYPE,
      linearDirectionsOptions: [
        LEFT_LINEAR_GRADIENT_DIRECTION,
        RIGHT_LINEAR_GRADIENT_DIRECTION,
        TOP_LINEAR_GRADIENT_DIRECTION,
        BOTTOM_LINEAR_GRADIENT_DIRECTION
      ],
      selectedLinearDirection: LEFT_LINEAR_GRADIENT_DIRECTION,
      colorStops: [...DEFAULT_COLOR_STOPS],
      selectedPreset: '',
      shadowPresets: [],
      shadowGroups: [],
      selectedShadowGroup: ''
    }
  },
  computed: {
    shadowGroupNameModal() {
      const selectedShadowGroup = this.shadowGroups.find(group => group.id === this.selectedShadowGroup);

      return {
        title: this.selectedShadowGroup ? 'Update Shadow Group' : 'Save Shadow Group',
        value: selectedShadowGroup ? selectedShadowGroup.name : ''
      }
    }
  },
  watch: {
    tabIndex() {
      this.initTab();
    },
    target(val) {
      this.selectedShadowGroup = this.target.ovalShadowGroupId || '';
      this.init();
    }
  },
  async mounted() {
    const { data } =  await shadowPresetApi.getAll({type: 'OvalShadow'});
    this.shadowPresets = data;
    this.init();
    await this.getShadowGroups();
    this.selectedShadowGroup = this.target.ovalShadowGroupId || '';
  },
  methods: {
    async deletePreset() {
      await shadowPresetApi.delete(this.selectedPreset);
      this.shadowPresets.splice(this.shadowPresets.findIndex(({id}) => id == this.selectedPreset ), 1);
      this.selectedPreset = '';
      await this.getShadowGroups();
      toastr.success("Shadow preset removed");
    },
    applyPreset() {
      const preset = this.shadowPresets.find(({id}) => id == this.selectedPreset );
      this.setOvalShadow(JSON.parse(preset.settings))
      this.onOvalShadowChange();
      toastr.success("Shadow preset applied");
    },
    async savePresetOfOvalShadow() {
      const selectedPreset = this.shadowPresets.find(preset => preset.id === this.selectedPreset);
      const swalData = await Swal.fire({
        title: selectedPreset ? 'Edit Oval Shadow Preset' : `Save Oval Shadow Preset`,
        html: `<div class="d-flex flex-column">
          <div>
            <input type="text" id="preset-name" class="form-control" placeholder="name" value="${selectedPreset ? selectedPreset.name : `Shadow ${this.tabIndex + 1}`}">
          </div>
          <div class="form-check ${!selectedPreset ? 'd-none' : ''}">
            <input type="checkbox" class="form-check-input" id="update-only-name">
            <label for="update-only-name" class="form-check-label">update name only</label>
          </div>
        </div>`,
        inputValue: selectedPreset ? selectedPreset.name : '',
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,
        
        preConfirm: () => {
          const name = document.getElementById('preset-name').value
          const updateOnlyName = document.getElementById('update-only-name').checked
          return { name, updateOnlyName };
        },
      });
      
      const { presetId, ...settings } = this.getCurrentShadowSettings();
      
      if (!swalData.isConfirmed) return;
      
      const data = {
        name: swalData.value.name,
        settings: JSON.stringify(settings),
        type: 'OvalShadow'
      };
      
      if (selectedPreset) {
        if (swalData.value.updateOnlyName) {
          delete data.settings;
        }
        await shadowPresetApi.update(this.selectedPreset, data);
        
        Object.assign(this.shadowPresets.find(({id}) => id == this.selectedPreset), data);
        toastr.success("Shadow preset updated");
      } else {
        const {data: preset} = await shadowPresetApi.create(data);
        this.shadowPresets.push(preset);
        this.selectedPreset = preset.id;
        toastr.success("Shadow preset saved");
        this.applyPreset();
      }
    },
    onFillTypeChange() {
      if (this.selectedFillType === LINEAR_GRADIENT_COLOR_TYPE && !this.target.ovalShadow[this.tabIndex].hasOwnProperty('colorStops')) {
        this.colorStops = [...DEFAULT_COLOR_STOPS];
        this.selectedLinearDirection = LEFT_LINEAR_GRADIENT_DIRECTION;
      }
     
      this.onOvalShadowChange()
    },
    addColorStop() {
      this.colorStops.push({ offset: 0, color: 'black' });
      this.onOvalShadowChange()
    },
    removeColorStop(key) {
      if (this.colorStops.length <= 2) return;
      this.colorStops.splice(key, 1);
      this.onOvalShadowChange()
    },
    onColorStopChange(event, key) {
      this.onOvalShadowChange()
    },
    onColorOffsetChange(event, key) {
      this.onOvalShadowChange()
    },
    closeTab(x) {
      this.tabs.splice(x, 1)
      const shadows = [...this.target.ovalShadow]
      shadows.splice(x, 1);
      this.tabIndex = this.tabIndex - 1;
      this.target.set('ovalShadow', shadows);
      this.target.canvas.renderAll();
      this.target.updateOvalShadowData();
      
    },
    newTab() {
      this.tabCounter += 1;
      this.tabs.push(this.tabCounter);
      
      this.target.set('ovalShadow', [
        ...(this.target.ovalShadow?.length ? this.target.ovalShadow : []),
        {
          width: this.presetData.width / 100,
          height: this.presetData.height / 100,
          blur: this.presetData.blur,
          offsetX: this.presetData.offsetX / 100,
          offsetY: this.presetData.offsetY / 100,
          opacity: this.presetData.opacity / 100,
          displayMode: BEHIND_DISPLAY_MODE,
          color: this.presetData.color,
          fillType: COLOR_TYPE,
          linearDirection: LEFT_LINEAR_GRADIENT_DIRECTION,
          colorStops: [...DEFAULT_COLOR_STOPS],
        }]);
      this.target.updateOvalShadowData();
      this.target.canvas.renderAll();

      setTimeout(() => {
        this.tabIndex = this.tabs.length - 1;
      }, 0);
    },
    getCurrentShadowSettings() {
      return {
        presetId: this.selectedPreset || null,
        width: this.ovalWidth / 100,
        height: this.ovalHeight / 100,
        blur: this.ovalBlur,
        offsetX: this.ovalOffsetX / 100,
        offsetY: this.ovalOffsetY / 100,
        opacity: this.ovalOpacity / 100,
        displayMode: this.displayModeSelected || BEHIND_DISPLAY_MODE,
        color: this.ovalColor || DEFAULT_COLOR,
        fillType: this.selectedFillType || COLOR_TYPE,
        linearDirection: this.selectedLinearDirection || LEFT_LINEAR_GRADIENT_DIRECTION,
        colorStops: this.colorStops && this.colorStops.length ? [...this.colorStops] : [...DEFAULT_COLOR_STOPS],
      }
    },
    onOvalShadowChange() {
      if (!this.target) return;
      const ovalShadow = this.getCurrentShadowSettings();
      if (!this.target.ovalShadow) {
        this.target.set('ovalShadow', [ovalShadow])
      } else {
        const shadows = [...this.target.ovalShadow];
        shadows[this.tabIndex] = ovalShadow;
        this.target.set('ovalShadow', shadows);
      }
      this.target.updateOvalShadowData();
      this.target.canvas.renderAll();
    },
    setOvalShadow(shadowSettings) {
      const {
        width,
        height,
        opacity,
        offsetX,
        offsetY,
        blur,
        color,
        displayMode,
        fillType,
        linearDirection,
        colorStops,
      } =  shadowSettings;
      this.ovalWidth = width * 100;
      this.ovalHeight = height * 100;
      this.ovalOffsetX = offsetX * 100;
      this.ovalOffsetY = offsetY * 100;
      this.ovalOpacity = opacity * 100;
      this.ovalBlur = blur;
      this.ovalColor = color;
      this.displayModeSelected = displayMode;
      this.selectedFillType = fillType;
      this.selectedLinearDirection = linearDirection;
      this.colorStops = colorStops;
    },
    initTab() {
      const targetHasOvalShadowSettings = 
        !!this.target && !!this.target?.ovalShadow && !!this.target?.ovalShadow.length 
        && this.tabIndex in this.target.ovalShadow
      
      if (!targetHasOvalShadowSettings) return;
      
      this.setOvalShadow(this.target.ovalShadow[this.tabIndex]);
      
      this.selectedPreset = this.currentPreset(this.target.ovalShadow[this.tabIndex])?.id || '';
    },
    init() {
      if (!this.target || this.target.type !== 'image') return;
      const targetHasOvalShadowSettings = !!this.target && !!this.target?.ovalShadow && !!this.target?.ovalShadow.length;
      if (targetHasOvalShadowSettings) {
        this.tabs = this.target.ovalShadow.map((shadow, key) => key);
        this.tabCounter = this.tabs.length - 1;
        this.tabIndex = this.tabs[0];
        this.initTab()
        return
      }
      this.tabs.push(0);
      const {width, height, opacity, offsetX, offsetY, blur, color} = this.presetData;
      this.ovalWidth = width;
      this.ovalHeight = height;
      this.ovalOffsetX = offsetX;
      this.ovalOffsetY = offsetY;
      this.ovalOpacity = opacity;
      this.ovalBlur = blur;
      this.ovalColor = color;
      this.onOvalShadowChange();
    },
    currentPreset(settings) {
      return this.shadowPresets?.find(preset => preset.id === settings.presetId);
    },
    handleShadowGroupSubmit(groupName) {
      if (this.selectedShadowGroup) {
        this.updateShadowGroup(groupName);
        return;
      }
      
      this.createShadowGroup(groupName);
    },
    async createShadowGroup(groupName) {
      try {
        const { data } = await shadowGroupApi.create({
          name: groupName,
          shadowSettings: this.target.ovalShadow,
        });

        this.shadowGroups.push(data);
        this.selectedShadowGroup = data.id;
        this.target.set('ovalShadowGroupId', data.id);

        toastr.success('The shadow group was created successfully.');
      } catch (error) {
        toastr.error('Something went wrong while creating the shadow group.');
      }
    },
    async updateShadowGroup(groupName) {
      try {
        const shadowGroup = this.shadowGroups.find(group => group.id === this.selectedShadowGroup);
        const targetSettings = [...this.target.ovalShadow];
        
        const shadow_presets = targetSettings.map(setting => {
          const { presetId, ...settings } = setting;
          
          return {
            id: presetId || null,
            settings
          }
        });
        
        await shadowGroupApi.updateShadowGroup(shadowGroup.id, {
          name: groupName,
          shadow_presets
        });
        
        await this.getShadowGroups();

        toastr.success('The shadow group was updated successfully.');
      } catch (error) {
        toastr.error('Something went wrong while updating the shadow group.');
      }
    },
    async deleteShadowGroup() {
      try {
        await shadowGroupApi.deleteShadowGroup(this.selectedShadowGroup);

        this.shadowGroups.splice(this.shadowGroups.findIndex(group => group.id === this.selectedShadowGroup), 1);
        
        this.selectedShadowGroup = '';
        
        toastr.success('The shadow group was deleted successfully.');
      } catch (error) {
        toastr.error('Something went wrong while deleting the shadow group.');
      }
    },
    async getShadowGroups() {
      try {
        const { data } = await shadowGroupApi.getShadowGroups();

        this.shadowGroups = data;
      } catch (error) {
        console.error('An error occurred while getting shadow groups', error);
      }
    },
    handleShadowGroupChange(value) {
      if (!value) {
        this.target.set('ovalShadow', null);
        this.target.set('ovalShadowGroupId', null);
        this.target.updateOvalShadowData();
        this.target.canvas.renderAll();
        this.tabs = [];
        this.selectedPreset = '';
        this.init();
        return;
      }
      
      const shadowGroup = this.shadowGroups.find(group => group.id === value);
      
      const shadowGroupPresets = shadowGroup.shadow_presets.map(preset => {
        return {
          ...JSON.parse(preset.settings),
          presetId: preset.id
        };
      });
      
      if (!shadowGroupPresets.length) {
        this.target.set('ovalShadow', null);
        this.target.updateOvalShadowData();
        this.target.canvas.renderAll();
        this.tabs = [];
        return;
      }
      
      this.target.set('ovalShadow', [...shadowGroupPresets]);
      this.target.set('ovalShadowGroupId', value);
      this.target.updateOvalShadowData();
      this.target.canvas.renderAll();
      
      this.init();
    },
    handleSelectPresetChange() {
      if (!this.selectedPreset) {
        this.onOvalShadowChange();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-button {
  padding: 0 5px 0 5px;
  font-size: 12px;
  margin-left: 5px;
}

.color-stop-container {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  .color-stop {
    width: 16%;
    height: 31px;
  }
  .color-stop-numbers {
    width: 30%;
  }
}



</style>
