import {
    FIELD_TYPE_BACKGROND_THEME_IMAGE,
    FIELD_TYPE_MULTI_LINE_TEXT,
    FIELD_TYPE_PRODUCT_IMAGE,
    FIELD_TYPE_TEXT
} from "../frontend/constants/type-fields-of-template";
import {EditTemplateContext} from "../frontend/contexts/edit-template-context";
import {FIELD_TYPES} from "../frontend/constants/field-types";


export const LAYER_FIELD_MAP = {
    [FIELD_TYPES.IMAGE]: [FIELD_TYPE_PRODUCT_IMAGE, FIELD_TYPE_BACKGROND_THEME_IMAGE],
    [FIELD_TYPES.TEXT]: [ FIELD_TYPE_MULTI_LINE_TEXT, FIELD_TYPE_TEXT ]
}


export const setTemplateFields = () => {
    const templateFieldsByType = {}
    const context = window.$context.get(EditTemplateContext)

    if (!context) {
        return templateFieldsByType;
    }
    Object.keys(LAYER_FIELD_MAP).forEach(typeKey => {
        templateFieldsByType[typeKey] = context.fieldsToFill.filter(({ type }) => LAYER_FIELD_MAP[typeKey].includes(type))
    })

    return templateFieldsByType
}
