import { isPSDFileType, initPsdPreviewer } from "../../../includes/psd-preview";
import { IMAGE_TYPE_PRODUCT, IMAGE_TYPE_STOCK } from "../../constants/file-upload-images-types";
import {TYPE} from "@frontend/constants/file-image-types";
import {TABS} from "@frontend/components/modules/file-browser/constants";

export const MUTATIONS = {
  SET_COMPANIES: 'SET_COMPANIES',
  SET_FIRST_COMPANY: 'SET_FIRST_COMPANY',

  SET_COMPANY: 'SET_COMPANY',
  SET_IMAGE_TYPE: 'SET_IMAGE_TYPE',
  SET_FOLDER: 'SET_FOLDER',
  SET_MAX_SIZE: 'SET_MAX_SIZE',
  SET_RESIZE: 'SET_RESIZE',
  SET_CONVERT: 'SET_CONVERT',
  SET_DIMENSIONS: 'SET_DIMENSIONS',
  SET_HAS_DIMENSION_ASPECT_RATIO: 'SET_HAS_DIMENSION_ASPECT_RATIO',
  SET_NAME: 'SET_NAME',
  SET_DPI: 'SET_DPI',
  SET_CONTENT_TYPE: 'SET_CONTENT_TYPE',
  SET_TAB: 'SET_TAB',

  SET_IS_REMOVE_BACKGROUND: 'SET_IS_REMOVE_BACKGROUND',
  SET_IS_CROP_TO_IMAGE: 'SET_IS_CROP_TO_IMAGE',
  SET_IS_COMPRESS: 'SET_IS_COMPRESS',
  SET_IS_RESIZE: 'SET_IS_RESIZE',
  SET_IS_CONVERT: 'SET_IS_CONVERT',
  SET_IS_RENAME: 'SET_IS_RENAME',
  SET_IS_DPI: 'SET_IS_DPI',

  SET_IMAGES: 'SET_IMAGES',
  SET_IMAGE_URLS: 'SET_IMAGE_URLS',
  SET_FEED_FILE: 'SET_FEED_FILE',
  SET_GENERATED_FROM_PSD: 'SET_GENERATED_FROM_PSD',
  SET_REPLACE_FILE_WITH_SAME_NAME: 'SET_REPLACE_FILE_WITH_SAME_NAME',

  SET_IS_READ_PER_ROW: 'SET_IS_READ_PER_ROW',
  SET_NEW_FILE_NAME: 'SET_NEW_FILE_NAME',
  SET_RUN_IN_BACKGROUND: 'SET_RUN_IN_BACKGROUND',
}

export default {
  namespaced: true,
  state: () => ({
    form: {
      images: [],
      imageUrls: '',
      feedFile: null,
      maxSize: 5000,
      resize: 99,
      convert: '',
      dimensions: {
        width: 0,
        height: 0
      },
      hasDimensionAspectRatio: false,
      name: '',
      dpi: 75,
      selectedCompany: null,
      selectedImageType: IMAGE_TYPE_PRODUCT,
      selectedFolder: {
        id: null,
        title: ''
      },
      isRemoveBackground: false,
      isCropToImage: false,
      isCompress: false,
      isResize: false,
      isConvert: false,
      isRename: false,
      isDPI: false,
      isReadPerRow: false,
      generatedFromPsd: '',
      replaceFileWithSameName: [],
      isRunInBackground: false,
    },
    contentType: TYPE.FOLDER,
    tab: TABS.FILES,
    companies: []
  }),
  getters: {
      getForm: (state) => state.form,
      getCompanies: (state) => {
        const companyAll = {
          id: 0,
          name: 'All companies',
        };

        return state.form.selectedImageType === IMAGE_TYPE_STOCK ? [companyAll, ...state.companies] : state.companies;
      },
      getSelectedCompany: (state) => state.form.selectedCompany,
      getSelectedImageType: (state) => state.form.selectedImageType,
      getMaxSize: (state) => state.form.maxSize,
      getResize: (state) => state.form.resize,
      getConvert: (state) => state.form.convert,
      getDimensions: (state) => state.form.dimensions,
      getIsRemoveBackground: (state) => state.form.isRemoveBackground,
      getIsCropToImage: (state) => state.form.isCropToImage,
      getIsCompress: (state) => state.form.isCompress,
      getIsResize: (state) => state.form.isResize,
      getIsConvert: (state) => state.form.isConvert,
      getIsRename: (state) => state.form.isRename,
      getIsDPI: (state) => state.form.isDPI,
      getReplaceFileWithSameName: (state) => state.form.replaceFileWithSameName,
      getContentType: state => state.contentType,
      getTab: state => state.tab,
      getIsReadPerRow: (state) => state.form.isReadPerRow,
      getIsRunInBackground: (state) => state.form.isRunInBackground,
  },
  mutations: {
    [MUTATIONS.SET_COMPANIES](state, payload) {
      state.companies = payload;
    },
    [MUTATIONS.SET_FIRST_COMPANY](state, companies) {
      const firstCompanyId = companies?.[0]?.id;

      state.form.selectedCompany = firstCompanyId !== undefined ? firstCompanyId : null;
    },

    // settings mutations
    [MUTATIONS.SET_COMPANY](state, payload) {
      state.form.selectedCompany = payload;
    },
    [MUTATIONS.SET_IMAGE_TYPE](state, payload) {
      state.form.selectedImageType = payload;
    },
    [MUTATIONS.SET_FOLDER](state, payload) {
      state.form.selectedFolder = payload;
    },
    [MUTATIONS.SET_MAX_SIZE](state, payload) {
      state.form.maxSize = payload;
    },
    [MUTATIONS.SET_DIMENSIONS](state, payload) {
      state.form.dimensions = payload;
    },
    [MUTATIONS.SET_HAS_DIMENSION_ASPECT_RATIO](state, payload) {
      state.form.hasDimensionAspectRatio = payload;
    },
    [MUTATIONS.SET_RESIZE](state, payload) {
      state.form.resize = payload;
    },
    [MUTATIONS.SET_CONVERT](state, payload) {
      state.form.convert = payload;
    },
    [MUTATIONS.SET_NAME](state, payload) {
      state.form.name = payload;
    },
    [MUTATIONS.SET_DPI](state, payload) {
      state.form.dpi = payload;
    },

    // action mutations
    [MUTATIONS.SET_IS_REMOVE_BACKGROUND](state, payload) {
      state.form.isRemoveBackground = payload;
    },
    [MUTATIONS.SET_IS_CROP_TO_IMAGE](state, payload) {
      state.form.isCropToImage = payload;
    },
    [MUTATIONS.SET_IS_COMPRESS](state, payload) {
      state.form.isCompress = payload;
    },
    [MUTATIONS.SET_IS_RESIZE](state, payload) {
      state.form.isResize = payload;
    },
    [MUTATIONS.SET_IS_CONVERT](state, payload) {
      state.form.isConvert = payload;
    },
    [MUTATIONS.SET_IS_RENAME](state, payload) {
      state.form.isRename = payload;
    },
    [MUTATIONS.SET_IS_DPI](state, payload) {
      state.form.isDPI = payload;
    },

    [MUTATIONS.SET_IMAGES](state, payload) {
      state.form.images = payload;
    },
    [MUTATIONS.SET_FEED_FILE](state, payload) {
      state.form.feedFile = payload;
    },
    [MUTATIONS.SET_IMAGE_URLS](state, payload) {
      state.form.imageUrls = payload;
    },
    [MUTATIONS.SET_GENERATED_FROM_PSD](state, payload) {
      state.form.generatedFromPsd = payload;
    },
    [MUTATIONS.SET_REPLACE_FILE_WITH_SAME_NAME](state, payload) {
      state.form.replaceFileWithSameName = payload;
    },
    [MUTATIONS.SET_CONTENT_TYPE](state, payload) {
      state.contentType = payload
    },
    [MUTATIONS.SET_TAB](state, payload) {
      state.tab = payload
    },

    [MUTATIONS.SET_IS_READ_PER_ROW](state, payload) {
      state.form.isReadPerRow = payload;
    },
    [MUTATIONS.SET_NEW_FILE_NAME](state, payload) {
      if (state.form.images[payload.index]) {
        const oldFile = state.form.images[payload.index];
        const newFile = new File([oldFile], payload.name, { type: oldFile.type });
        state.form.images.splice(payload.index, 1, newFile);
      }
    },

    [MUTATIONS.SET_RUN_IN_BACKGROUND](state, payload) {
        state.form.isRunInBackground = payload;
    },
  },
  actions: {
    async setImages({ commit }, files) {
      const file = files[0];

      if (!file) return;

      if (isPSDFileType(file)) {

        const generatedFromPsd = await new Promise((resolve) => {
          initPsdPreviewer(file, (isCancel, data) => {
              if (isCancel) {
                  resolve('');
              } else {
                const { filename } = data;
                resolve(filename);
              }
          });
        });

        commit(MUTATIONS.SET_GENERATED_FROM_PSD, generatedFromPsd);
        commit(MUTATIONS.SET_IMAGES, []);
      } else {
        commit(MUTATIONS.SET_IMAGES, files);
      }
    },
    setNewImagesNames({ commit }, data) {
      commit(MUTATIONS.SET_NEW_FILE_NAME, data);
    },
  }
}
