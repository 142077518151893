var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "simple-table-builder",
            title: "Table Builder",
            centered: "",
            size: "xl",
            "ok-title": "Save",
          },
          on: { ok: _vm.onSave },
        },
        [
          _vm.table && _vm.table.rows.length
            ? _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-button-group",
                    [
                      _c("b-button", { on: { click: _vm.addRow } }, [
                        _vm._v("\n          Add Row\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.addColumn } }, [
                        _vm._v("\n          Add Column\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.removeRow } }, [
                        _vm._v("\n          Remove Row\n        "),
                      ]),
                      _vm._v(" "),
                      _c("b-button", { on: { click: _vm.removeColumn } }, [
                        _vm._v("\n          Remove Column\n        "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "DropdownCounter",
                        {
                          attrs: {
                            value: _vm.getStyleValue("font-size", 0),
                            min: 1,
                            step: 1,
                          },
                          on: {
                            input: (value) =>
                              _vm.onChangeStyleOfTable(
                                "font-size",
                                value,
                                "px"
                              ),
                          },
                        },
                        [_c("i", { staticClass: "cil-text-size" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "DropdownCounter",
                        {
                          attrs: {
                            value: _vm.getStyleValue("border-width", 0),
                            min: 0,
                            step: 1,
                          },
                          on: {
                            input: (value) =>
                              _vm.onChangeStyleOfTable(
                                "border-width",
                                value,
                                "px",
                                true
                              ),
                          },
                        },
                        [_c("i", { staticClass: "cil-border-style" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        [
                          _c("ColorPicker", {
                            attrs: {
                              value: _vm.getStyleValue("color", "#000000"),
                            },
                            on: {
                              input: (value) =>
                                _vm.onChangeStyleOfTable("color", value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isSelectedCell
                    ? _c(
                        "b-form-row",
                        [
                          _c("b-col", { attrs: { cols: "3" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-0 mt-1",
                                    attrs: { for: "cell-value" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Value\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("b-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "cell-value",
                                    value: _vm.selectedCell.value,
                                    type: "text",
                                  },
                                  on: {
                                    input: (value) =>
                                      (_vm.selectedCell.value = value),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-col", { attrs: { cols: "1" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-0 mt-1",
                                    attrs: { for: "cell-value" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Colspan\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("b-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "cell-value",
                                    value: _vm.selectedCell.colspan,
                                    type: "number",
                                  },
                                  on: {
                                    input: (value) =>
                                      (_vm.selectedCell.colspan = value),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "0" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-secondary field-btn",
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("\n            Cancel\n          ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "0" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-success field-btn",
                                  on: { click: _vm.onApply },
                                },
                                [_vm._v("\n            Apply\n          ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.table && _vm.table.rows.length
            ? _c(
                "div",
                {
                  ref: "tableContainer",
                  staticClass: "table-builder-container",
                },
                [
                  _c("table", { ref: "table", style: _vm.getStyleOfTable }, [
                    _c("thead", [
                      _c(
                        "tr",
                        _vm._l(_vm.table.headers, function (column) {
                          return _c(
                            "th",
                            {
                              key: column.id,
                              class: {
                                "selected-cell":
                                  _vm.selectedCellId === column.id,
                              },
                              style: column.stringStyles,
                              attrs: { colspan: column.colspan },
                              on: {
                                click: function ($event) {
                                  return _vm.onSelectCell(column)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(column.value) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.table.rows, function (row) {
                        return _c(
                          "tr",
                          { key: row.id, style: row.stringStyles },
                          _vm._l(row.cells, function (cell) {
                            return _c(
                              "td",
                              {
                                key: cell.id,
                                class: {
                                  "selected-cell":
                                    _vm.selectedCellId === cell.id,
                                },
                                style: cell.stringStyles,
                                attrs: { colspan: cell.colspan },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectCell(cell)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cell.value) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c("b-dropdown", { attrs: { text: "Create Table" } }, [
                    _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", [_vm._v("Columns")]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "number", size: "sm" },
                                  model: {
                                    value: _vm.createTable.columns,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createTable,
                                        "columns",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "createTable.columns",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mx-2" }),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              [
                                _c("label", [_vm._v("Rows")]),
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "number", size: "sm" },
                                  model: {
                                    value: _vm.createTable.rows,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.createTable,
                                        "rows",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "createTable.rows",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "w-100",
                            attrs: { variant: "primary", size: "sm" },
                            on: { click: _vm.onCreateTable },
                          },
                          [_vm._v("\n            Create\n          ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }