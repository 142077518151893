var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass:
      "c-file-browser__item d-flex justify-content-center align-items-center w-100 h-100 content",
    domProps: { innerHTML: _vm._s(_vm.drawFileSvg(_vm.item)) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }