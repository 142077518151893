export const TYPE_SUBSCENE_BACKGROUND = 'Background'
export const TYPE_SUBSCENE_TEXT = 'Text'
export const TYPE_SUBSCENE_CAPTION = 'VTT'
export const TYPE_SUBSCENE_IMAGE = 'Image'
export const TYPE_SUBSCENE_VIDEO = 'Video'
export const TYPE_SUBSCENE_AUDIO = 'Audio'
export const TYPE_SUBSCENE_MUSIC = 'Music'
export const TYPE_SUBSCENE_GIF = 'Gif'
export const TYPE_SUBSCENE_LH = 'LH'
export const TYPE_SUBSCENE_RH = 'RH'
export const TYPE_SUBSCENE_FF = 'FF'
export const TYPE_SUBSCENE_TEXT_BOX = 'Text box'
