export default function fileMovSvg () {
  return `
  <svg
      id="file-mov-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 548.291 548.291">
      <g>
          <path d="M261.182 297.106c0 26.18 12.292 44.62 32.522 44.62 20.438 0 32.132-19.428 32.132-45.407 0-23.985-11.501-44.617-32.329-44.617-20.419 0-32.325 19.439-32.325 45.404z"
                fill="#727272" data-original="#000000" class=""></path>
          <path d="M486.201 196.121h-13.166v-63.525c0-.399-.062-.795-.115-1.2-.021-2.522-.825-5-2.552-6.96L364.657 3.675c-.033-.031-.064-.042-.085-.073-.63-.704-1.364-1.292-2.143-1.796a11.29 11.29 0 0 0-2.823-1.312c-.2-.052-.379-.134-.577-.188A10.793 10.793 0 0 0 356.562 0H96.757C84.894 0 75.256 9.649 75.256 21.502v174.613H62.092c-16.971 0-30.732 13.756-30.732 30.73v159.81c0 16.966 13.761 30.736 30.732 30.736h13.164V526.79c0 11.854 9.638 21.501 21.501 21.501h354.776c11.853 0 21.501-9.647 21.501-21.501V417.392H486.2c16.966 0 30.729-13.764 30.729-30.731v-159.81c.001-16.974-13.762-30.73-30.728-30.73zM96.757 21.507h249.054v110.006c0 5.94 4.817 10.751 10.751 10.751h94.972v53.861H96.757V21.507zm261 273.815c0 43.833-26.572 70.405-65.627 70.405-39.673 0-62.868-29.948-62.868-68.011 0-40.069 25.576-70.004 65.036-70.004 41.045-.005 63.459 30.739 63.459 67.61zM94.986 312.771l-2.378 50.771H64.652l8.53-133.646h40.245l13.095 44.606c4.16 15.475 8.328 32.137 11.299 47.802h.59c3.774-15.471 8.345-33.119 12.694-47.99l14.281-44.418h39.462l7.336 133.646H182.64l-1.987-51.159c-.593-16.064-1.194-35.503-1.194-54.934h-.591c-4.163 17.053-9.724 36.108-14.864 51.773l-16.259 52.146h-23.604l-14.278-51.77c-4.37-15.665-8.932-34.698-12.1-52.15h-.396c-.791 18.047-1.389 38.677-2.381 55.323zm356.548 208.197H96.757V417.392h354.776v103.576zm-8.484-157.426h-35.298l-42.834-133.646h33.113l16.257 56.513c4.562 15.862 8.724 31.14 11.9 47.8h.599c3.371-16.062 7.528-31.938 12.095-47.207l17.051-57.1h32.137l-45.02 133.64z"
                fill="#727272" data-original="#000000" class=""></path>
      </g>
  </svg>
`
};
