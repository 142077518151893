<template>
  <div>
    <div 
      class="tree-select-item pl-2 py-1"
      :class="{'tree-select-item--selected': selected?.id === option.id}"
      @click="handleFolderClick(option)"
    >
      <b-icon
        icon="triangle-fill"
        class="mr-2"
        :class="{'closed': !visible, 'opened': visible}"
        @click.stop="handleCollapseClick(option?.id)"
      />
      <p class="tree-select-item__label">
        {{ option?.name }}
      </p>
    </div>
    
    <b-collapse 
      class="ml-3" 
      :visible="visible"
    >
      <div 
        v-if="loading"
        class="pl-2"
      >
        <b-spinner 
          variant="primary"
          type="grow" 
          small
        />
        Loading...
      </div>
      <div 
        v-else-if="!option.children?.length" 
        class="pl-2"
      >
        <b-icon 
          icon="exclamation-circle" 
          variant="warning"
        />
        No subfolders
      </div>
      <template v-else>
        <div
          v-for="child in option.children"
          :key="child.id"
        >
          <FolderTreeSelectItem
            :item="child"
            :selected="selected"
            @change="handleFolderClick"
          />
        </div>
      </template>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'FolderTreeSelectItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Object,
      default: () => ({})
    }
  },
  
  data() {
    return {
      visible: false,
      option: this.normalizeOption(this.item),
      loading: false
    }
  },
  
  methods: {
    normalizeOption: (option) => ({
      id: option.id,
      name: option.name,
      children: null
    }),
    
    async handleCollapseClick(itemId) {
      this.visible = !this.visible;
      
      if (Array.isArray(this.option.children)) return;
      
      try {
        this.loading = true;
        
        const { data } = await axios.get(`/file/folder_content?folderId=${itemId}&pageNumber=1&pageSize=1000`);

        this.option = {
          ...this.option,
          children: data.items
            .filter(item => item.type === 'folder' && item.name !== '...')
        }
        
      } catch(error) {
        console.error('Something went wrong while fetching folders data');
      } finally {
        this.loading = false;
      }
    },

    handleFolderClick(option) {
      this.$emit('change', {
        id: option.id,
        name: option.name
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .tree-select-item {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    
    &__label {
      margin: 0;
    }
    
    &--selected {
      background-color: #209c86;
    }
    
    &:hover {
      background-color: #e9eceeed;
    }
  }

  .closed, .opened {
    font-size: 11px;
    transition: transform 0.3s;
  }

  .closed {
    transform: rotate(90deg);
  }

  .opened {
    transform: rotate(180deg);
  }
</style>
