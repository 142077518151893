<template>
  <b-modal
    id="outline-fiels-modal"
    title="Outline Fields"
    :ok-title="isLoading ? 'Saving...' : 'Save'"
    :ok-disabled="isLoading"
    @ok="onSave"
  >
    <b-row
      v-for="(field, index) in fields"
      :key="field.id"
      :class="{ 'mt-2': index > 0 }"
    >
      <b-col class="d-flex align-contents-center">
        <div class="mr-2">
          <label>{{ field.name }}</label>
          <b-form-input
            v-model="field.productId"
          />
        </div>
        <div>
          <label>{{ field.name }} Color</label>
          <div class="d-flex align-contents-center">
            <b-form-input
              v-model="field.color"
            />
            <ColorPicker
              v-model="field.color"
              class="ml-2"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { FIELD_TYPE_OUTLINE } from '../../../constants/type-fields-of-template';
import { outlineFieldHandlerEventBroker, EVENTS } from '../../../group/modules/outline-field/event-broker';
import ColorPicker from './EditorControl/components/ColorPicker.vue';

export default {
  components: { ColorPicker },
  data() {
    return {
      isLoading: false,
      instanceId: '',
      fields: [],
    }
  },
  mounted() {
    outlineFieldHandlerEventBroker.on(EVENTS.INIT, this.init.bind(this));
    outlineFieldHandlerEventBroker.on(EVENTS.CLOSE, this.close.bind(this));
  },
  methods: {
    init({ instance, preview }) {
      const data = preview.data;

      this.instanceId = preview.id;

      this.fields = instance.template.fields
        .filter(field => field.type === FIELD_TYPE_OUTLINE)
        .map(field => ({
          id: field.id,
          name: field.name,
          productId: data[`${field.element_id}_file_outline_id`] || '',
          color: data[`${field.element_id}_file_outline_color`] || '#000000',
        }));      

      this.$bvModal.show('outline-fiels-modal');
    },
    close() {
      this.isLoading = false;
      this.$bvModal.hide('outline-fiels-modal');
    },
    onSave(event) {
      event.preventDefault();

      this.isLoading = true;

      outlineFieldHandlerEventBroker.fire(EVENTS.SAVE, {
        instanceId: this.instanceId,
        fields: this.fields
      });
    }
  }
}
</script>
