export default class TemplateFieldOption {
    alignment = 'left'
    angle = 0
    cell = null
    colorSelector = null
    filename = null
    font = null
    fontColor = 'black'
    fontSelector = false
    fontSize = 0
    gridColumn = null
    groupName = ''
    x = 0
    y = 0
    height = 0
    width = 0
    kerning = 0
    leading = 0
    maxChars = 0
    moveable = 'No'
    placeholder = null
    scale = 1
    isShowAlignment = false
    isSizeToFit = false
    textTracking = 0
    link = ''
    customShadow = ''
    option1 = null
    option2 = null
    option3 = null
    option4 = null
    option5 = null
    objectFit = ''
    objectFill = ''

    constructor(options = {}) {
        this.alignment = options?.Alignment || this.alignment
        this.angle = +(options?.Angle || this.angle)
        this.cell = options?.Cell || this.cell
        this.colorSelector = options?.['Color Selector'] || this.colorSelector
        this.filename = options?.Filename || this.filename
        this.font = options?.Font || this.font
        this.fontColor = options?.['Font Color'] || this.fontColor
        this.fontSelector = options?.['Font Selector'] || this.fontSelector
        this.fontSize = +(options?.['Font Size'] || this.fontSize)
        this.gridColumn = options?.['Grid Column'] || this.gridColumn
        this.groupName = options?.['Group Name'] || this.groupName
        this.x = +(options?.['X'] || this.x)
        this.y = +(options?.['Y'] || this.y)
        this.height = +(options?.Height || this.height)
        this.width = +(options?.Width || this.width)
        this.kerning = options?.Kerning || this.kerning
        this.leading = +(options?.Leading || this.leading)
        this.maxChars = +(options?.['Max Chars'] || this.maxChars)
        this.moveable = options?.Moveable || this.moveable
        this.placeholder = options?.Placeholder || this.placeholder
        this.scale = +(options?.Scale || this.scale)
        this.isShowAlignment = options?.ShowAlignment || this.isShowAlignment
        this.isSizeToFit = options?.['Size To Fit'] || this.isSizeToFit
        this.textTracking = +(options?.['Text Tracking'] || this.textTracking)
        this.link = options?.['Link'] || this.link
        this.customShadow = options['Custom Shadow'] || this.customShadow
        this.objectFit = options['Object Fit'] || ''
        this.objectFill = options['Object Fill'] || ''

        this.option1 = options?.Option1 || this.option1
        this.option2 = options?.Option2 || this.option2
        this.option3 = options?.Option3 || this.option3
        this.option4 = options?.Option4 || this.option4
        this.option5 = options?.Option5 || this.option5
    }
}