var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          staticClass: "select-properties-dropdown",
          attrs: {
            size: "sm",
            variant: "primary",
            text: "Select properties",
            "menu-class": "p-0",
          },
        },
        [
          _c(
            "b-list-group",
            _vm._l(_vm.getProperties, function (property, index) {
              return _c(
                "b-list-group-item",
                { key: index },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { size: "sm" },
                      on: {
                        change: function ($event) {
                          return _vm.onChangeProperties(property)
                        },
                      },
                      model: {
                        value: property.value,
                        callback: function ($$v) {
                          _vm.$set(property, "value", $$v)
                        },
                        expression: "property.value",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(property.text) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-sm btn-outline-primary py-0 px-1 mx-1 pin-properties",
          on: { click: _vm.onPinProperties },
        },
        [
          _c("i", {
            staticClass: "bi",
            class: {
              "bi-pin-angle": !_vm.isPinProperties,
              "bi-pin-fill": _vm.isPinProperties,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }