<template>
  <b-modal
    id="save-as-template-modal"
    title="Save as Template"
    centered
    ok-title="Save"
    :ok-disabled="disabledSaveButton"
    @ok="onSave"
    @cancel="onClose"
  >
    <b-form-group label="Name">
      <b-form-input v-model="templateName" />
    </b-form-group>
    <b-form-group label="Customer">
      <Multiselect
        v-model="selectedCustomer"
        track-by="name"
        label="name"
        placeholder="Select Customer"
        :options="customers"
      />
    </b-form-group>
    <b-form-group label="Tags">
      <Multiselect
        v-model="selectedTags"
        multiple
        taggable
        :options="tags"
        :close-on-select="false"
        @tag="createTag"
      />
    </b-form-group>

    <div class="form-row">
      <div class="d-flex align-items-center">
        <div class="form-check mr-2">
          <input
            id="update_existing_template"
            v-model="isUpdateExistingTemplate"
            type="checkbox"
            class="form-check-input"
            name="update_existing_template"
          >
          <label
            class="form-check-label"
            for="update_existing_template"
          >
            Update existing template
          </label>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { saveAsTemplateEventBroker, EVENTS } from '@frontend/group/modules/save-as-template/event-broker';
import Multiselect from 'vue-multiselect';

export default {
  name: 'SaveAsTemplateModal',
  components: { Multiselect },
  props: {
    customers: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      templateName: '',
      selectedCustomer: null,
      isLoading: false,
      template: null,
      selectedTags: [],
      tags: [],
      isUpdateExistingTemplate: false,
      isTemplateAlreadyUsing: false,
    }
  },

  computed: {
    disabledSaveButton() {
      return !this.selectedCustomer || !this.templateName || this.isLoading;
    }
  },

  watch: {
    isUpdateExistingTemplate(newVal) {
      this.templateName = newVal ? this.template.name : `${this.template.name}_copy`;
    }
  },

  mounted() {
    saveAsTemplateEventBroker.on(EVENTS.INIT, this.init.bind(this));
    saveAsTemplateEventBroker.on(EVENTS.CLOSE, this.onClose.bind(this));
  },
  
  methods: {
    init({ preview }) {
      const { template } = preview;
      this.clearData();
      this.templateName = `${template.name}_copy`;
      this.selectedCustomer = this.customers.find(customer => customer.id === template.customer_id);
      this.selectedTags = template.tags.split(',').filter(i => i !== '')
      this.template = template
      this.fetchTags()
      this.checkIsTemplateUsing();

      this.$bvModal.show('save-as-template-modal');
    },
    clearData() {
      this.templateName = '';
      this.selectedCustomer = null;
      this.isLoading = false;
      this.isUpdateExistingTemplate = false;
    },
    onClose() {
      this.clearData();
      this.$bvModal.hide('save-as-template-modal');
    },
    async onSave() {
      this.isLoading = true;

      if (this.isTemplateAlreadyUsing && this.isUpdateExistingTemplate) {
        return Swal.fire({
          text: 'This template has already been used in other layouts, modifications can change the design',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          icon: 'info',
        }).then(({ value }) => {
          value ? this.saveEvent() : this.clearData();
        });
      }

      this.saveEvent();
    },
    saveEvent() {
      saveAsTemplateEventBroker.fire(EVENTS.SAVE, {
        templateName: this.templateName,
        selectedCustomerId: this.selectedCustomer.id,
        tags: this.selectedTags.join(','),
        isUpdateExistingTemplate: this.isUpdateExistingTemplate,
      });
    },
    async checkIsTemplateUsing() {
      const { data } = await axios.post(`/banner/check_used_template`, { templateId: this.template.id });
      this.isTemplateAlreadyUsing = data;
    },
    async fetchTags () {
      const { data } = await axios.get(`/projects/template/${this.template.id}/tag`)
      this.tags = _.map(data, 'name')
    },
    async createTag (name) {
      const { data } = await axios.post(`/projects/template/${this.template.id}/tag`, { name })
      this.tags.unshift(data.name)
      this.selectedTags.push(data.name)
    },
  }
}
</script>
