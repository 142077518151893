<template>
  <div class="modal-body--layers">
    <FolderLayer
      v-for="template in items"
      :key="template.name"
      :node="template"
    />
  </div>
</template>

<script>

import FolderLayer from "@frontend/components/modules/psd-layers/layer-views/FolderLayer.vue";

export default {
  name: "LayersTree",
  components: {FolderLayer},
  props: {
    templates: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    items() {
      if (this.templates.length === 1) {
        return this.templates[0].children
      }

      return this.templates;
    }
  }
}
</script>

<style scoped lang="scss">

</style>