import TemplateField from "./TemplateField";

export default class OutlineFiled extends TemplateField {

    constructor(fieldData) {
        super(fieldData)
    }

    getDefaultColorOutline() {
        return '#000000'
    }
}