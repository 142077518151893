import {EventBroker} from "../../../lib/utils/event-broker";

export const EVENTS = {
    IMPORT_BUTTON_PUSHED: 'IMPORT_BUTTON_PUSHED',
    MODAL_HIDDEN: 'MODAL_HIDDEN',
    SELECT_WAY_IMPORT_FROM_PSD: {
        INIT: 'SELECT_WAY_IMPORT_FROM_PSD_INIT',
        SAVE: 'SELECT_WAY_IMPORT_FROM_PSD_SAVE',
    }
}

export const importPsdEventBroker = new EventBroker();