export default function filePptSvg() {
  return `
      <svg
      id="file-ppt-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 550.801 550.801">
      <g>
          <path d="M280.821 252.316c-7.414 0-12.44.712-15.077 1.426v47.625c3.117.717 6.945.959 12.206.959 19.388 0 31.348-9.813 31.348-26.324 0-14.835-10.288-23.686-28.477-23.686zM170.245 276.002c0-14.835-10.28-23.686-28.468-23.686-7.412 0-12.44.712-15.077 1.426v47.625c3.116.717 6.95.959 12.205.959 19.386 0 31.34-9.813 31.34-26.324z"
                fill="#727272" data-original="#000000" class=""></path>
          <path d="M488.426 197.019H475.2v-63.816c0-.398-.063-.799-.116-1.202-.021-2.534-.827-5.023-2.562-6.995L366.325 3.694c-.032-.031-.063-.042-.085-.076-.633-.707-1.371-1.295-2.151-1.804a9.495 9.495 0 0 0-.706-.419 11.131 11.131 0 0 0-2.131-.896c-.2-.056-.38-.138-.58-.19A10.774 10.774 0 0 0 358.193 0H97.2C85.282 0 75.6 9.693 75.6 21.601v175.413H62.377c-17.049 0-30.873 13.818-30.873 30.873v160.545c0 17.043 13.824 30.87 30.873 30.87h13.224V529.2c0 11.907 9.682 21.601 21.6 21.601h356.4c11.907 0 21.6-9.693 21.6-21.601V419.302h13.226c17.044 0 30.871-13.827 30.871-30.87v-160.54c-.001-17.054-13.828-30.873-30.872-30.873zM97.2 21.605h250.193v110.513c0 5.967 4.841 10.8 10.8 10.8H453.6v54.108H97.2V21.605zm248.232 253.439c0 15.796-5.263 29.191-14.829 38.283-12.456 11.718-30.881 16.991-52.421 16.991-4.78 0-9.088-.248-12.437-.718v57.671h-36.136V228.14c11.248-1.911 27.05-3.354 49.302-3.354 22.488 0 38.524 4.311 49.288 12.928 10.293 8.14 17.233 21.536 17.233 37.33zM90.563 387.271V228.14c11.249-1.911 27.05-3.354 49.302-3.354 22.491 0 38.525 4.311 49.288 12.928 10.296 8.14 17.231 21.537 17.231 37.331 0 15.796-5.26 29.191-14.829 38.283-12.454 11.723-30.879 16.991-52.418 16.991-4.781 0-9.089-.248-12.443-.718v57.671H90.563zm363.038 136.082H97.2V419.302h356.4v104.051zm26.809-266.734h-44.033v130.652H399.77V256.619h-43.316v-30.628H480.41v30.628z"
                fill="#727272" data-original="#000000" class=""></path>
      </g>
  </svg>
`;
};