<template>
  <b-breadcrumb class="a-breadcrumb">
    <b-breadcrumb-item
      :active="!breadcrumbs.length"
      @click="$emit('goTo', {
        id: null,
        index: 0
      })"
    >
      <i class="fa fa-home" />
    </b-breadcrumb-item>

    <b-breadcrumb-item
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="breadcrumb.id"
      :active="index === (breadcrumbs.length - 1)"
      class="a-breadcrumb__item"
      @click="itemClickHandler(breadcrumb, index)"
    >
      {{ breadcrumb.text }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
export default {
  name: "BreadcrumbItem",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    itemClickHandler(breadcrumb, index) {
      if (index + 1 === this.breadcrumbs.length) {
        return;
      }

      this.$emit('goTo', {
        id: breadcrumb.id,
        index: index + 1
      })
    }
  }
}
</script>

<style scoped lang="scss">
.a-breadcrumb {
  background: unset;
  border-radius: unset;
  border: unset;
  margin-bottom: 0;

  &__item::before {
    content: ">";
  }

  &__item {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #464646;
  }
}
</style>
