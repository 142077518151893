import 'mark.js/dist/jquery.mark.js'

(function ($) {
  const DEFAULTS = {
    contextSelector: 'body',
    showReplace: false,
    hint: '',
    /** @see https://markjs.io/#api */
    markOptions: {
      separateWordSearch: false,
    },
    css: {
      maxWidth: '560px',
    },
    onClearCallback: function () {},
    template: `
    <div>
      <div class="form-row flex-nowrap mb-1">
        <div class="col-8">
          <input type="text" class="form-control" placeholder="🔍 Find" data-find="input">
          <small class="text-muted" data-find="counter"></small>
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary" data-find="prev">
            <i class="fa fa-chevron-up"></i>
          </button>
          <button class="btn btn-outline-primary" data-find="next">
            <i class="fa fa-chevron-down"></i>
          </button>
          <button class="btn btn-outline-primary" data-find="clear">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="form-row flex-nowrap d-none" data-find="replace-container">
        <div class="col-8">
            <input type="search" class="form-control" placeholder="🔃 Replace" data-find="replace-input">
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary" data-find="replace">Replace</button>
          <button class="btn btn-outline-primary" data-find="replace-all">Replace All</button>
        </div>
      </div>
      <span data-find="hint" class="form-text font-sm text-muted d-none"></span>
    </div>
`
  }

  function FindMark (element, options) {
    this.$element = $(element)
    this.settings = $.extend(true, {}, DEFAULTS, options)
    this.$template = $(this.settings.template)
    this.$input = this.$template.find('[data-find=input]')
    this.$counter = this.$template.find('[data-find=counter]')
    this.$prevBtn = this.$template.find('[data-find=prev]')
    this.$nextBtn = this.$template.find('[data-find=next]')
    this.$clearBtn = this.$template.find('[data-find=clear]')
    this.$replaceInput = this.$template.find('[data-find=replace-input]')
    this.$replaceBtn = this.$template.find('[data-find=replace]')
    this.$replaceAllBtn = this.$template.find('[data-find=replace-all]')
    this.$replaceContainer = this.$template.find('[data-find=replace-container]')
    this.$hint = this.$template.find('[data-find=hint]')
    this.$context = $(this.settings.contextSelector)
    this.index = 0
    this.ignoreMutations = false
    this.$results = $()

    this.init()
  }

  FindMark.prototype.init = function () {
    this.$template.css(this.settings.css)
    this.$element.html(this.$template)
    if (this.settings.showReplace) {
      this.$replaceContainer.removeClass('d-none')
    }
    if (this.settings.hint) {
      this.$hint.text(this.settings.hint)
      this.$hint.removeClass('d-none')
    }
    this.bindEvents()
    $('<link/>', { rel: 'stylesheet', type: 'text/css', href: '/css/includes.css' }).appendTo('head')
    this.observeContext()
  }

  FindMark.prototype.bindEvents = function () {
    this.$input.on('input', this.onInput.bind(this))
    this.$clearBtn.on('click', this.onClear.bind(this))
    this.$nextBtn.add(this.$prevBtn).on('click', this.onNextPrev.bind(this))
    this.$replaceBtn.on('click', this.onReplaceOne.bind(this))
    this.$replaceAllBtn.on('click', this.onReplaceAll.bind(this))
  }

  FindMark.prototype.onInput = function () {
    const searchVal = this.$input.val()
    this.$context.unmark({
      done: () => {
        this.$context.mark(searchVal, {
          ...this.settings.markOptions,
          done: () => {
            this.$results = this.$context.find('mark')
            this.index = 0
            this.jumpTo()
          }
        })
      }
    })
  }

  FindMark.prototype.onClear = function () {
    this.$context.unmark()
    this.$input.val('').focus()
    this.onInput()
    this.settings.onClearCallback()
  }

  FindMark.prototype.onNextPrev = function (event) {
    if (this.$results.length) {
      this.index += $(event.target).closest('button').is(this.$prevBtn) ? -1 : 1
      if (this.index < 0) this.index = this.$results.length - 1
      if (this.index > this.$results.length - 1) this.index = 0
      this.jumpTo()
    }
  }

  FindMark.prototype.jumpTo = function () {
    let counterText = ''
    if (this.$results.length) {
      const $current = this.$results.eq(this.index)
      this.$results.removeClass('current')
      if ($current.length) {
        $current.addClass('current')
        $current[0].scrollIntoView(false)
        counterText = `${this.index + 1}/${this.$results.length}`
      }
    }
    this.$counter.text(counterText)
  }

  FindMark.prototype.onReplaceAll = function () {
    const searchVal = this.$input.val()
    const replaceVal = this.$replaceInput.val()
    if (searchVal && replaceVal) {
      this.ignoreMutations = true // Disable observation during replacement

      this.$context.unmark({
        done: () => {
          this.$context.mark(searchVal, {
            ...this.settings.markOptions,
            done: () => {
              this.$results = this.$context.find('mark')
              this.$results.each(function () {
                const $currentInput = $(this).closest('[contenteditable]')
                $(this).replaceWith(replaceVal)
                if ($currentInput.length) {
                  $currentInput.trigger('focus').trigger('blur')
                }
              })

              // Re-enable observation after ensuring replacement is completed
              setTimeout(() => {
                this.ignoreMutations = false
                this.$results.length = 0
                this.jumpTo()
              }, 100) // Minimal timeout to allow DOM update
            }
          })
        }
      })
    }
  }

  FindMark.prototype.onReplaceOne = function () {
    const replaceVal = this.$replaceInput.val()
    if (this.$results.length && replaceVal) {
      const $current = this.$results.eq(this.index)
      const $currentInput = $current.closest('[contenteditable]')
      if ($current.length) {
        this.ignoreMutations = true // Disable observation during replacement

        $current.after(replaceVal)
        $current.remove()
        if ($currentInput.length) {
          $currentInput.trigger('focus').trigger('blur')
        }

        // Re-enable observation after ensuring replacement is completed
        setTimeout(() => {
          this.ignoreMutations = false
          this.$results = this.$context.find('mark')
          if (this.index >= this.$results.length) {
            this.index = 0
          }
          this.jumpTo()
        }, 100) // Minimal timeout to allow DOM update
      }
    }
  }

  FindMark.prototype.observeContext = function () {
    const observer = new MutationObserver((mutations) => {
      if (this.ignoreMutations) return // Skip processing if ignoring mutations

      let contentChanged = false
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          contentChanged = true
        }
      })

      if (contentChanged) {
        const currentContent = this.$context.html()
        if (currentContent !== this.lastContent) {
          this.lastContent = currentContent
          this.onInput()
        }
      }
    })

    this.lastContent = this.$context.html()
    observer.observe(this.$context[0], { childList: true, subtree: true, characterData: true })
    this.observer = observer
  }

  $.fn.findMark = function (options) {
    return this.each(function () {
      if (!$.data(this, 'findMark')) {
        $.data(this, 'findMark', new FindMark(this, options))
      }
    })
  }

})(jQuery)
