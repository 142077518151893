<template>
  <b-dropdown
    variant="outline-light"
    menu-class="p-4"
    :disabled="disabled"
  >
    <template #button-content>
      <slot />
    </template>
    <div>
      <label>{{ title }}</label>

      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-input-group-text @click="decrease">
            <i class="glyphicon glyphicon-minus" />
          </b-input-group-text>
        </b-input-group-prepend>

        <b-form-input
          :value="value"
          type="number"
          :step="step"
          style="width: 94px;"
          @input="$emit('input', $event)"
        />

        <b-input-group-append>
          <b-input-group-text @click="increase">
            <i class="glyphicon glyphicon-plus" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'label',
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    decrease() {
      this.$emit('input', +this.value - this.step);
    },
    increase() {
      this.$emit('input', +this.value + this.step);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .dropdown-toggle {
    padding: 2px 5px 4px 7px;
    color: #000000;
  }

  label {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    color: #464646;
    font-weight: 500;
    line-height: 24px;
    cursor: inherit;
  }

  .input-group-text {
    cursor: pointer;
    color: #000000;
  }

  .dropdown-menu {
    min-width: max-content;
  }
}
</style>
