import {
  FIELD_TYPE_BACKGROND_THEME_IMAGE,
  FIELD_TYPE_MULTI_LINE_TEXT,
  FIELD_TYPE_PRODUCT_IMAGE
} from '@frontend/constants/type-fields-of-template';
import * as fillExistedTemplateMatchers from './matchers/existed-template';
import * as newTemplateMatchers from './matchers/new-template';

export const FILL_EXISTED_TEMPLATE_MAP_RULES = {
  [FIELD_TYPE_BACKGROND_THEME_IMAGE]: [
    fillExistedTemplateMatchers.imageAreaMatcher
  ],
  [FIELD_TYPE_MULTI_LINE_TEXT]: [
    fillExistedTemplateMatchers.headlineMatcher,
  ],
  [FIELD_TYPE_PRODUCT_IMAGE]: [
    fillExistedTemplateMatchers.productImageMatcher
  ]
};

export const CREATE_NEW_TEMPLATE_MAP_RULES = {
  [FIELD_TYPE_BACKGROND_THEME_IMAGE]: [
    newTemplateMatchers.productImageMatcher
  ],
}

