<template>
  <b-modal
    id="naming-table-variables"
    title="Naming Table Variables"
    ok-only
    @ok="saveName"
  >
    <NamingTableInput
      v-model="namingTable"
      :selected-names-from-tabs="selectedNamesFromTabs"
      :naming-design-tabs="getNamingDesignTabs"
      :has-show-custom-name-input="false"
      @onSelectedNamesFromTabs="onSelectedNamesFromTabs"
    />
  </b-modal>
</template>

<script>
import NamingTableInput from '../LayoutSettingsModal/NamingTableInput.vue';
import { namingTableVariablesHandlerEventBroker, EVENTS } from '../../../../group/modules/naming-table-variables/event-broker';
import { getReplacedTabs } from '../../../../group/modules/naming-table-variables/replace-variable';
import { getFullName } from '../../../../group/modules/naming-table-variables/helpers';
import { deepCopy } from '../../../../utils';

export default {
  name: 'NamingTableVariablesModal',
  components: { NamingTableInput },
  props: {
    namingDesignTabs: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      namingTable: '',
      instance: null,
      selectedNamesFromTabs: [],
      tabsFromLayoutSettings: [],
      variblesTabs: [],
    }
  },
  computed: {
    getNamingDesignTabs() {
      return this.namingDesignTabs.filter(namingDesignTab => {
        return this.variblesTabs.find(variable => variable.tabId === namingDesignTab.id);
      });
    },
  },
  mounted() {
    namingTableVariablesHandlerEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },
  methods: {
    init({ instance, variblesTabs, tabsFromLayoutSettings, selectedNamesFromTabs }) {
      this.clearData();
      this.instance = instance;
      this.variblesTabs = variblesTabs;
      this.tabsFromLayoutSettings = tabsFromLayoutSettings;
      this.onSelectedNamesFromTabs(selectedNamesFromTabs);

      this.$bvModal.show('naming-table-variables');
    },
    clearData() {
      this.namingTable = '';
      this.selectedNamesFromTabs = [];
      this.variblesTabs = [];
    },
    onSelectedNamesFromTabs(value) {
      this.selectedNamesFromTabs = value;
      
      const variblesTabsGroup = _.groupBy(deepCopy(this.variblesTabs), 'tabId');

      this.selectedNamesFromTabs = value.reduce((acc, tab) => {
        const { tabId } = tab;
    
        const tabsGroup = _.groupBy(acc, 'tabId');

        if (tabsGroup[tabId]?.length >= variblesTabsGroup[tabId].length) {
          const indexReplaceTab = _.findLastIndex(acc, _tab => _tab.tabId === tabId);

          if (indexReplaceTab !== -1) {

              acc.splice(indexReplaceTab, 1, tab);
          }

        } else {
          acc.push(tab);
        }

        return acc;
      }, []);

      const repalcesTabs = getReplacedTabs(
        deepCopy(this.tabsFromLayoutSettings),
        deepCopy(this.selectedNamesFromTabs)
      );

      this.namingTable = getFullName(repalcesTabs);
    },
    saveName() {
      namingTableVariablesHandlerEventBroker.fire(EVENTS.SAVE, {
        instance: this.instance,
        namingTable: this.namingTable,
        selectedNamesFromTabs: this.selectedNamesFromTabs,
      })
    }
  }
}
</script>
