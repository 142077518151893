<template>
  <div>
    <b-row>
      <b-col>
        <label
          class="m-0"
          for="input-field-name"
        >Name Field</label>
        <b-form-input
          v-model="inputFields.name"
          placeholder="Enter field name"
          trim
          @change="onChangeValue('name')"
        />
      </b-col>

      <b-col v-show="inputFields.isShowAngle">
        <label
          class="m-0"
          for="input-field-name"
        >Angle</label>
        <b-form-input
          v-model.number="inputFields.angle"
          type="number"
          placeholder="Enter angle"
          @change="onChangeValue('angle')"
        />
      </b-col>
    </b-row>
    <div class="my-3" />
    <b-row>
      <b-col>
        <label
          class="m-0"
          for="input-field-link"
        >Link</label>
        <b-form-input
          v-model="inputFields.link"
          placeholder="Enter link"
          trim
          @change="onChangeValue('link')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { editorControlEventBroker, EVENTS as EDITOR_CONTROL_EVENTS } from '../../../../group/modules/editor-control-popup/event-broker';
import { propertiesModuleEventBroker, EVENTS } from '../../../../services/EditorControls/properties-module/event-broker';

export default {
  name: 'PropertiesModule',
  props: {
    target: {
      type: Object,
      require: true,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputFields: {
        fieldId: null,
        name: '',
        link: '',
        angle: 0,
        isShowAngle: true,
      }
    };
  },
  watch: {
    options (newVal, oldVal) {
      if (_.isEqual(newVal, oldVal)) {
        return
      }
      this.init(newVal)
    },
    'target.angle'(v) {
      this.inputFields.angle = v
    }
  },
  mounted() {
    if (this.options && Object.keys(this.options).length) {
      this.init(this.options);
    } else {
      if (!propertiesModuleEventBroker.isListen(EVENTS.INIT)) {
        propertiesModuleEventBroker.on(EVENTS.INIT, this.init.bind(this));
      }
    }
  },
  methods: {
    init(dto) {
      for (const key in dto) {
        this.inputFields[key] = dto[key];
      }
    },
    onChangeValue(action) {
      propertiesModuleEventBroker.fire(EVENTS.CHANGED, {
        action, 
        ...this.inputFields
      });

      editorControlEventBroker.fire(EDITOR_CONTROL_EVENTS.PROPERTIES_MODULE_CHANGED, {
        action, 
        ...this.inputFields
      })
    }
  }
}
</script>
