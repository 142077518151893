<template>
  <div>
    <b-form-group
      label="Background"
      label-cols="3"
    >
      <div class="d-flex">
        <ColorPicker
          :value="target.fill"
          :themes="themes"
          @input="onChange('bgColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          class="mx-2"
          size="sm"
          title="Copy hex color"
          @click="copyToClipboard(target.fill)"
        >
          <i class="cil-copy" />
        </b-button>
      </div>
    </b-form-group>

    <b-form-group
      label="Stroke"
      label-cols="3"
    >
      <div class="d-flex">
        <ColorPicker
          :value="target.stroke"
          :themes="themes"
          @input="onChange('strokeColor', $event)"
        />
        <b-button
          v-b-tooltip.hover
          variant="primary"
          class="mx-2"
          size="sm"
          title="Copy hex color"
          @click="copyToClipboard(target.stroke)"
        >
          <i class="cil-copy" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          variant="primary"
          size="sm"
          title="Use background color"
          @click="onChange('strokeColor', target.fill)"
        >
          <b-icon icon="back" />
        </b-button>
      </div>
    </b-form-group>

    <b-form-group
      label="Stroke width"
      :disabled="target.locked"
    >
      <b-input-group>
        <b-input
          :value="target.strokeWidth"
          :min="0"
          :max="maxStrokeWidth"
          type="range"
          @input="onChange('thickness', $event)"
        />
        <template #append>
          <b-input
            :value="target.strokeWidth"
            :min="0"
            :max="maxStrokeWidth"
            type="number"
            @input="onChange('thickness', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Opacity">
      <b-input-group>
        <b-input
          :value="target.opacity"
          :min="0"
          :max="maxOpacity"
          step="0.01"
          type="range"
          @input="onChange('opacity', $event)"
        />
        <template #append>
          <b-input
            :value="target.opacity"
            :min="0"
            :max="maxOpacity"
            step="0.01"
            type="number"
            @input="onChange('opacity', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Rounding">
      <b-input-group>
        <b-input
          :value="target.rx"
          :min="0"
          :max="maxRounding"
          type="range"
          @input="onChange('radius', $event)"
        />
        <template #append>
          <b-input
            :value="target.rx"
            :min="0"
            :max="maxRounding"
            type="number"
            @input="onChange('radius', $event)"
          />
        </template>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Apply to all">
      <b-form-checkbox-group
        v-model="selectedPropertyKeys"
        :options="applyToAllOptions"
        @change="applyToAll"
      />
    </b-form-group>

    <b-button
      variant="danger"
      @click="onChange('delete')"
    >
      <b-icon icon="trash" />
      Delete
    </b-button>

    <LockToggle />
  </div>
</template>

<script>
import { editorControlEventBroker, EVENTS } from '../../../../group/modules/editor-control-popup/event-broker'
import ColorPicker from './components/ColorPicker.vue'
import { copyToClipboard } from '../../../../services/helpers'
import LockToggle from '@frontend/components/modules/layouts-page/EditorControl/components/LockToggle.vue'

export default {
  name: 'ShapeEditorControl',

  components: {
    LockToggle,
    ColorPicker,
  },

  props: {
    target: {
      type: Object,
      default: () => {},
    },
    themes: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    maxRounding: 500,
    maxStrokeWidth: 100,
    maxOpacity: 1,
    selectedPropertyKeys: [],
    applyToAllOptions: [
      { text: 'Background', value: 'bgColor' },
      { text: 'Stroke', value: 'strokeColor' },
    ],
  }),

  methods: {
    copyToClipboard,
    onChange: _.debounce(function (action, value) {
      editorControlEventBroker.fire(EVENTS.SHAPE_CHANGED, {
        action,
        value,
        keys: [...this.selectedPropertyKeys],
      })
    }, 15),
    applyToAll([action]) {
      this.onChange(action, action === 'bgColor' ? this.target.fill : target.stroke);
    }
  },
}
</script>

<style scoped lang="scss">

</style>
