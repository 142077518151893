var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-section" },
    [
      _c("div", { staticClass: "product-input-wrapper" }, [
        _c(
          "div",
          [
            _c("SvgFileInput", {
              ref: "fileInput",
              attrs: {
                placeholder: "Select image file",
                suffix: "product-section",
              },
              on: {
                "on-file-selected": _vm.onFileSelected,
                "clear-canvas": _vm.clearCanvas,
              },
            }),
            _vm._v(" "),
            _c("ProductImagesDropdown", {
              attrs: { "selected-products": _vm.selectedProducts },
              on: {
                "dropdown-item-click": _vm.removeExtension,
                "clear-input-value": _vm.clearInputValue,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "controls" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "d-inline-block",
              attrs: { tabindex: "0", title: "Paste area" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.initPasteArea },
                },
                [_c("b-icon", { attrs: { icon: "bounding-box" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isSvgInstanceHasSelectedObjects,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.pasteSelectedSvgInstanceAsImage },
            },
            [_vm._v("\n      Paste\n    ")]
          ),
          _vm._v(" "),
          _c("DownloadBtn", {
            attrs: {
              disabled: !_vm.isProductInstanceHasObjects,
              canvas: _vm.ProductCanvas,
            },
            on: {
              load: function ($event) {
                return _vm.$emit("load")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isProductInstanceHasActiveObjects,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.clearSelections },
            },
            [_vm._v("\n      Clear selections\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.isProductInstanceHasObjects,
                variant: "outline-primary",
                size: "sm",
              },
              on: { click: _vm.resetToInitial },
            },
            [_vm._v("\n      Reset\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "d-inline-block",
              attrs: { tabindex: "0", title: "Center" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !(
                      _vm.isProductInstanceHasOneObject ||
                      _vm.isProductInstanceHasActiveObjects
                    ),
                    variant: "outline-primary",
                    size: "sm",
                  },
                  on: { click: _vm.centerSelected },
                },
                [_c("b-icon", { attrs: { icon: "fullscreen" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("ShapesDD", { attrs: { canvas: _vm.ProductCanvas } }),
          _vm._v(" "),
          _c("BrushesDD", { attrs: { canvas: _vm.ProductCanvas } }),
          _vm._v(" "),
          _c("FillImageColor", { attrs: { canvas: _vm.ProductCanvas } }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "d-inline-block",
              attrs: { tabindex: "0", title: "Color dropper" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.eyeDropper },
                },
                [_c("b-icon", { attrs: { icon: "eyedropper" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "d-inline-block",
              attrs: { tabindex: "0", title: "Color palette" },
            },
            [
              _c("ColorPickerAtom", {
                attrs: { value: _vm.getColorToDraw, format: "hex8" },
                on: { input: _vm.setColor },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "d-inline-block",
              attrs: { tabindex: "0", title: "Fill canvas background" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.fillBackground },
                },
                [_c("b-icon", { attrs: { icon: "image-fill" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Text" },
                },
                [
                  _c(
                    "b-button",
                    {
                      class: { active: _vm.textMode },
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: { click: _vm.drawTextRect },
                    },
                    [_c("b-icon", { attrs: { icon: "textarea-t" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Rotate right 90 deg" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.rotateObject(90)
                        },
                      },
                    },
                    [_c("b-icon", { attrs: { icon: "arrow-clockwise" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Rotate left 90 deg" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.rotateObject(-90)
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "arrow-counterclockwise" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Layer up" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.isProductInstanceHasActiveObjects,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.bringForward },
                    },
                    [_c("b-icon", { attrs: { icon: "arrow-up" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Layer down" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.isProductInstanceHasActiveObjects,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.sendBackwards },
                    },
                    [_c("b-icon", { attrs: { icon: "arrow-down" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Square canvas" },
                },
                [
                  _c(
                    "b-button",
                    {
                      class: { active: _vm.isSquareOrient },
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: { click: _vm.squareOrientation },
                    },
                    [_c("b-icon", { attrs: { icon: "square" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Tall canvas" },
                },
                [
                  _c(
                    "b-button",
                    {
                      class: { active: _vm.isTallOrient },
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: { click: _vm.tallOrientation },
                    },
                    [_c("b-icon", { attrs: { icon: "file" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      class: { active: _vm.isCropAreaMode },
                      staticStyle: { display: "none" },
                      attrs: { variant: "outline-primary", size: "sm" },
                      on: { click: _vm.cropAreaMode },
                    },
                    [_c("b-icon", { attrs: { icon: "crop" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Fit & Center" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.isProductInstanceHasActiveObjects,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.objectFit },
                    },
                    [_c("b-icon", { attrs: { icon: "arrows-fullscreen" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "Center" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "action-btn",
                      attrs: {
                        disabled: !_vm.isProductInstanceHasActiveObjects,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.centerSelectedObject },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "icon-arrows-fullscreen",
                        attrs: { icon: "aspect-ratio" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  class: { active: _vm.isCropAreaMode },
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.cameraButtonHandler },
                },
                [_c("b-icon", { attrs: { icon: "camera" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "d-inline-block",
                  attrs: { tabindex: "0", title: "OCR" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.isProductInstanceHasActiveObjects,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.ocr },
                    },
                    [_vm._v("\n          OCR\n        ")]
                  ),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "c-product-section__text-editbox",
                    attrs: { size: "sm" },
                    model: {
                      value: _vm.textEditbox,
                      callback: function ($$v) {
                        _vm.textEditbox = $$v
                      },
                      expression: "textEditbox",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.copyHandler },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "b-icon bi",
                      attrs: {
                        width: "1em",
                        height: "1em",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("use", {
                        attrs: { href: "/img/icons/sprite.svg#copy" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.collisionHandler },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "b-icon bi",
                      attrs: {
                        width: "1em",
                        height: "1em",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("use", {
                        attrs: { href: "/img/icons/sprite.svg#collision" },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SvgTextModal", { ref: "svgTextModal" }),
      _vm._v(" "),
      _c("SvgShapeModal", { ref: "svgShapeModal" }),
      _vm._v(" "),
      _c("SvgImageTypeModal", {
        ref: "svgImageTypeModal",
        on: { "upload-file": _vm.uploadFile },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cancel-outline",
          attrs: { tabindex: "1000" },
          on: { keyup: _vm.onKeyUp, keydown: _vm.onKeyDown },
        },
        [
          _c("canvas", {
            ref: "productCanvas",
            staticClass: "canvas-section cancel-outline",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }