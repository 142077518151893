<template>
  <b-row>
    <b-col
      v-if="!productDescriptionJobId"
      cols="12"
    >
      <b-form-group
        label="Job"
        label-cols="auto"
      >
        <Multiselect
          v-model="selectedJob"
          label="name"
          track-by="id"
          :options="productDescriptionJobs"
          :allow-empty="false"
          deselect-label=""
        />
      </b-form-group>
    </b-col>
    <template v-if="selectedProductDescriptionJobId">
      <b-col cols="12">
        <div class="d-flex justify-content-end align-items-center">
          <b-button
            class="mr-2"
            variant="outline-primary"
            size="sm"
            :href="`/banner/groups/product_description_jobs/${selectedProductDescriptionJobId}/analytics?keyword_data`"
            @click="showSuccessMessage('Keyword data download started')"
          >
            <b-icon icon="download" />
            Download Keyword data
          </b-button>
          <b-button
            class="mr-2"
            variant="outline-primary"
            size="sm"
            :href="`/banner/groups/product_description_jobs/${selectedProductDescriptionJobId}/analytics?search_volume_results`"
            @click="showSuccessMessage('Search volume results download started')"
          >
            <b-icon icon="download" />
            Search volume results
          </b-button>
          <div class="h-100 d-flex align-items-center mr-auto">
            <b-icon
              id="search-volume-info"
              class="h4 mb-0"
              icon="exclamation-circle"
            />
            <b-tooltip
              target="search-volume-info"
              triggers="hover"
            >
              <div style="white-space: nowrap;">
                In the search volume graph, "original" is calculated by multiplying the number of occurrences of a keyword in the original copy by the search<br>volume data for that keyword. "Generated" uses the same logic, but the number of occurrences comes from the generated copy.
              </div>
            </b-tooltip>
          </div>
          <b-form-radio-group
            v-show="showChart"
            v-model="keywordUsageView"
            :options="['Summary', 'Used', 'Unused']"
            size="sm"
            button-variant="outline-primary"
            class="mr-2"
            buttons
            @input="setKeywordUsageView"
          />
          <b-form-radio-group
            v-show="!showChart"
            v-model="mostUsedKeywordsView"
            :options="['Original', 'Generated', 'Summary', 'Search Volume']"
            size="sm"
            button-variant="outline-primary"
            class="mr-2"
            buttons
            @input="setMostUsedKeywordsView"
          />
          <b-button-group size="sm">
            <b-button
              variant="outline-primary"
              @click="toggleChart"
            >
              <b-icon icon="chevron-left" />
            </b-button>
            <b-button
              variant="outline-primary"
              @click="toggleChart"
            >
              <b-icon icon="chevron-right" />
            </b-button>
          </b-button-group>
        </div>
        <div class="mx-5">
          <canvas
            v-show="showChart && !showSearchVolumeChart"
            ref="keywordUsage"
          />
          <canvas
            v-show="!showChart && !showSearchVolumeChart"
            ref="mostUsedKeywords"
          />
          <canvas
            v-show="showSearchVolumeChart"
            ref="searchVolumeResults"
          />
        </div>
      </b-col>
      <b-col
        v-if="false"
        cols="4"
      >
        <canvas ref="optimizedKeywordData" />
      </b-col>
    </template>
    <b-col
      v-if="keywords"
      cols="12"
    >
      <b-button
        class="mt-2 float-right"
        variant="outline-primary"
        size="sm"
        :href="`/banner/groups/product_description_jobs/${selectedProductDescriptionJobId}/analytics?csv`"
      >
        <b-icon icon="download" />
        Download CSV
      </b-button>
      <VueWordCloud
        style="width: 100%; height: 500px;"
        :font-size-ratio="5"
        :words="keywords"
        :rotation="0"
      />
    </b-col>
  </b-row>
</template>

<script>
import { Chart } from 'chart.js/auto'
import VueWordCloud from 'vuewordcloud'
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'KeywordAnalytics',

  components: {
    VueWordCloud,
    Multiselect,
  },

  props: {
    productDescriptionJobId: {
      type: [String, Number],
      default: null,
    },
  },

  data () {
    return {
      optimizedKeywordDataChart: null,
      mostUsedKeywordsChart: null,
      searchVolumeResultsChart: null,
      keywordUsageChart: null,
      selectedProductDescriptionJobId: this.productDescriptionJobId,
      showChart: false,
      showSearchVolumeChart: false,
      keywordUsageView: 'Summary',
      mostUsedKeywordsView: 'Generated',
      optimizedKeywordData: null,
      mostUsedKeywords: null,
      mostUsedKeywordsOriginal: null,
      keywordUsage: null,
      keywords: null,
      searchVolumeData: null
    }
  },

  computed: {
    ...mapGetters('productDescription', [
      'productDescriptionJobs',
    ]),
    selectedJob: {
      get () {
        return _.find(this.productDescriptionJobs, ['id', this.selectedProductDescriptionJobId])
      },
      async set (val) {
        this.selectedProductDescriptionJobId = val.id
        await this.fetchData()
        this.showChart = false
        this.keywordUsageView = 'Summary'
        this.mostUsedKeywordsView = 'Generated'
      },
    },
  },

  async mounted () {
    await this.fetchProductDescriptionJobs()
    if (this.productDescriptionJobId) {
      this.selectedJob = this.productDescriptionJobs.at(0)
    } else {
      await this.fetchData()
    }
  },

  methods: {
    ...mapActions('productDescription', [
      'fetchProductDescriptionJobs',
    ]),
    async fetchData () {
      if (!this.productDescriptionJobId) {
        return
      }

      let response = null;
      let data = null;

      response = await axios.get(`/banner/groups/product_description_jobs/${this.productDescriptionJobId}/analytics`)
      data = response.data
      this.optimizedKeywordData = data.optimizedKeywordData
      this.mostUsedKeywords = data.mostUsedKeywords
      this.mostUsedKeywordsOriginal = data.mostUsedKeywordsOriginal
      this.keywordUsage = data.keywordUsage
      this.keywords = data.keywords
      this.searchVolumeData = data.searchVolumeData
      this.initOptimizedKeywordData()
      this.initMostUsedKeywords()
      this.initKeywordUsage()
      this.initSearchVolumeResults()
    },
    initOptimizedKeywordData () {
      if (!this.$refs['optimizedKeywordData']) {
        return
      }
      this.optimizedKeywordDataChart?.destroy()
      this.optimizedKeywordDataChart = new Chart(this.$refs['optimizedKeywordData'], {
        type: 'pie',
        data: {
          labels: ['Used Keywords', 'Unused Keywords'],
          datasets: [{
            data: this.optimizedKeywordData,
            backgroundColor: ['#1b7837', '#1dd365'],
          }],
        },
        options: {
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Optimized keyword data',
            },
          },
        },
      })
    },
    initMostUsedKeywords () {
      if (!this.$refs['mostUsedKeywords']) {
        return
      }
      this.mostUsedKeywordsChart?.destroy()
      this.mostUsedKeywordsChart = new Chart(this.$refs['mostUsedKeywords'], {
        type: 'bar',
        data: {
          labels: this.mostUsedKeywords?.labels,
          datasets: [
            {
              label: 'Original Copy',
              data: this.mostUsedKeywordsOriginal?.data,
              backgroundColor: ['#1dd365'],
              hidden: true,
            },
            {
              label: 'Generated Copy',
              data: this.mostUsedKeywords?.data,
              backgroundColor: ['#1b7837'],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Most used keywords',
            },
          },
        },
      })
    },
    initKeywordUsage () {
      if (!this.$refs['keywordUsage']) {
        return
      }
      this.keywordUsageChart?.destroy()
      this.keywordUsageChart = new Chart(this.$refs['keywordUsage'], {
        type: 'bar',
        data: {
          labels: ['Original Copy', 'Generated Copy'],
          datasets: [
            {
              label: 'Used Keywords',
              data: this.keywordUsage?.used,
              backgroundColor: ['#1b7837'],
            },
            {
              label: 'Unused Keywords',
              data: this.keywordUsage?.unused,
              backgroundColor: ['#1dd365'],
            },
          ],
        },
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Keyword usage',
            },
          },
        },
      })
    },
    initSearchVolumeResults () {
      if (!this.$refs['searchVolumeResults']) {
        return
      }
      this.searchVolumeResultsChart?.destroy()
      this.searchVolumeResultsChart = new Chart(this.$refs['searchVolumeResults'], {
        type: 'bar',
        data: {
          labels: this.searchVolumeData?.labels,
          datasets: [
            {
              label: 'Original copy',
              data: this.searchVolumeData?.svPreRead,
              backgroundColor: ['#1dd365'],
              hidden: true,
            },
            {
              label: 'Generated copy',
              data: this.searchVolumeData?.svPostRead,
              backgroundColor: ['#1b7837'],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Search volume results',
            },
          },
        },
      })
    },
    toggleChart () {
      this.showChart = !this.showChart
    },
    setMostUsedKeywordsView (val) {
      switch (val) {
        case 'Original':
          this.showChart = true;
          this.showSearchVolumeChart = false;
          this.mostUsedKeywordsChart.setDatasetVisibility(0, true)
          this.mostUsedKeywordsChart.setDatasetVisibility(1, false)
          break
        case 'Generated':
          this.showChart = true;
          this.showSearchVolumeChart = false;
          this.mostUsedKeywordsChart.setDatasetVisibility(0, false)
          this.mostUsedKeywordsChart.setDatasetVisibility(1, true)
          break
        case 'Summary':
          this.showChart = true;
          this.showSearchVolumeChart = false;
          this.mostUsedKeywordsChart.setDatasetVisibility(0, true)
          this.mostUsedKeywordsChart.setDatasetVisibility(1, true)
          break
        case 'Search Volume':
          this.showChart = false;
          this.showSearchVolumeChart = true;
          break
      }
      this.mostUsedKeywordsChart.update()
      this.searchVolumeResultsChart.update()
    },
    setKeywordUsageView (val) {
      switch (val) {
        case 'Summary':
          this.keywordUsageChart.setDatasetVisibility(0, true)
          this.keywordUsageChart.setDatasetVisibility(1, true)
          break
        case 'Used':
          this.keywordUsageChart.setDatasetVisibility(0, true)
          this.keywordUsageChart.setDatasetVisibility(1, false)
          break
        case 'Unused':
          this.keywordUsageChart.setDatasetVisibility(0, false)
          this.keywordUsageChart.setDatasetVisibility(1, true)
          break
      }
      this.keywordUsageChart.update()
    },
    showSuccessMessage (text) {
      toastr.success(text)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
