<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        Open PSD: <span> {{ name }} </span>
        <span
          v-if="templateNameAvailable"
          class="d-block"
        >
          Template: {{ template.name }}
        </span>
      </h5>
      <button
        id="psd-layers-cancel-modal-button"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <component
      :is="currentStep"
      :show-save-images-to-theme="showSaveImagesToThemeTyped"
    />
  </div>
</template>

<script>
import LayerSelector from "./LayerSelector";
import {EVENTS, psdLayersEventBroker} from "../../../group/modules/psd-layers/event-broker";
import InitialLoader from "./InitialLoader";
import SpinnerLoader from "./SpinnerLoader.vue";
import { createNamespacedHelpers } from 'vuex'
import {MUTATIONS} from "../../../store/psd-layers";
import {PSD_PREVIEW_LOADERS_TYPES} from "@frontend/components/modules/psd-layers/constants/psd-preview";

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('psdLayers')

const PSD_PREVIEW_LOADERS = {
  [PSD_PREVIEW_LOADERS_TYPES.PROGRESS]: InitialLoader,
  [PSD_PREVIEW_LOADERS_TYPES.SPINNER]: SpinnerLoader,
}

export default {
  name: "PSDPreview",
  components: {LayerSelector},
  props: {
    showSaveImagesToTheme: {
      type: String,
      default: () => 'false'
    },
  },
  data: () => ({
    name: '',
    loader: PSD_PREVIEW_LOADERS_TYPES.PROGRESS,
  }),
  computed: {
    ...mapGetters([
      'selectedTemplates',
      'templates',
      'isLoading'
    ]),
    showSaveImagesToThemeTyped() {
      return this.showSaveImagesToTheme === '1';
    },
    templateNameAvailable() {
      return this.templates.length > 1 && this.template;
    },
    currentStep() {
      return this.isLoading ? PSD_PREVIEW_LOADERS[this.loader] : LayerSelector;
    },
  },
  mounted() {
    psdLayersEventBroker.on(EVENTS.INIT, this.init.bind(this), true);
  },
  methods: {
    ...mapMutations({
      setFilename: MUTATIONS.SET_FILENAME,
      setTemplates: MUTATIONS.SET_TEMPLATES,
      reset: MUTATIONS.RESET,
      setThemeFields: MUTATIONS.SET_THEME_FIELDS,
      setFlow: MUTATIONS.SET_FLOW,
      setMultipleTemplateSelectionType: MUTATIONS.SET_MULTIPLE_TEMPLATE_SELECTION_TYPE,
      setFieldsToMap: MUTATIONS.SET_FIELDS_TO_MAP,
    }),
    ...mapActions([
      'loadFile'
    ]),
    init({file, themeFields, flow, isMultipleTemplateSelectionType, fieldsToMap, loaderType = PSD_PREVIEW_LOADERS_TYPES.PROGRESS}) {
      this.name = file.name;
      this.reset();
      fieldsToMap?.length && this.setFieldsToMap(fieldsToMap);
      this.loadFile(file);
      this.setThemeFields(themeFields);
      this.setFlow(flow);
      this.setMultipleTemplateSelectionType(isMultipleTemplateSelectionType)
      this.loader = loaderType;
    },
  }
}
</script>

<style scoped>

</style>
