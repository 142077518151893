import { GridStack } from "gridstack";
import "gridstack/dist/h5/gridstack-dd-native";
import { DEFAUL_GRID_COLUMNS } from "../../constants/grid-stack-options"; 

export default class LayoutTemplatesGrid {

    __$elementContainer = null
    templates = []
    gridInstance = null;
    options = {}
    
    constructor($elementContainer, options = {}) {
        this.__$elementContainer = $elementContainer
        this.__initOption(options)
        this.gridInstance = GridStack.init({
                column: DEFAUL_GRID_COLUMNS,
                ...(options.grid || {})
            },
            this.__$elementContainer.get(0)
        );
    }

    __initOption(options) {
        this.options = options
        this.templates = options?.templates || this.templates
    }

    __getMaxWidthAndHeightOfTemplate() {
        return this.templates.reduce(
            (acc, template) => {
                acc.maxWidth = acc.maxWidth < template.width ? template.width : acc.maxWidth;
                acc.maxHeight = acc.maxHeight < template.height ? template.height : acc.maxHeight;

                return acc;
            },
            { maxWidth: 0, maxHeight: 0 }
        )
    }

    __calculateSizeWidget(width, height) {
        const { maxWidth, maxHeight } = this.__getMaxWidthAndHeightOfTemplate()
        const maxSize = maxWidth >= maxHeight ? maxWidth : maxHeight;
        
        const w = Math.ceil(width * DEFAUL_GRID_COLUMNS / maxSize);
        const h = Math.ceil(height * DEFAUL_GRID_COLUMNS / maxSize);

        return { w, h }
    }

    __calculatePositionTopWidget() {
        return this.gridInstance.save().reduce(
            (acc, gridData) => {
                const bottonPos = gridData.y + gridData.h;
                return acc < bottonPos ? bottonPos : acc
            },
            0
        )
    }

    load(isLoadGridData = false) {
        const widgets = this.templates.map(({ instanceId, content, width, height, gridData }) => {
            const { w, h } = this.__calculateSizeWidget(width, height)
            
            return {
                id: instanceId,
                w,
                h,
                minW: w,
                minH: h,
                ...(gridData && isLoadGridData ? gridData : {}),
                content
            }
        })

        this.gridInstance.load(widgets)
    }

    addWidget(templateData) {
        const widet = {
            id: templateData.instanceId,
            x: 0,
            y: this.__calculatePositionTopWidget(),
            content: templateData.content
        }

        this.gridInstance.addWidget(widet)
    }

    add(templateData) {
        this.templates.push(templateData);
        this.addWidget(templateData)
        this.load()
    }

    remove(instanceId) {
        this.templates = this.templates.filter(
            templateData => templateData.instanceId !== instanceId
        )
        this.load()
    }

    getData() {
        const gridData = this.gridInstance.save();

        return gridData.map(({id, w, h, x, y }) => {
            const templateData = this.templates.find(item => item.instanceId === id);

            return {
                instance_id: templateData.instanceId,
                template_id: templateData.id,
                width: templateData.width,
                height: templateData.height,
                is_custom: templateData.is_custom,
                name: templateData.name,
                theme: templateData.theme,
                customer_id: templateData.customer_id,
                saveAsNew: templateData.saveAsNew,
                w,
                h,
                x,
                y
            }
        })
    }
}
