import { fabric } from "fabric";
import { applyGradientFilter } from '@frontend/lib/fabric/filters/gradient';
import { setAngleGradientForRect } from "@frontend/lib/fabric-helper";

export const GRADIENT_FILTER_ID = 'gradient_filter';
export const GRADIENT_TYPES = {
    LINEAR: 'linear',
    RADIAL: 'radial'
}

const getLinearCoords = (object, data) => {

    const coords = {
        x1: data.left * object.width,
        y1: data.top * object.height,
        x2: object.width,
        y2: object.height
    }

    return {
        ...coords,
        ...setAngleGradientForRect(data.angle, object.width, object.height),
    }
};

const getRadialCoords = (object, data) => {

    const rotatedCoords = setAngleGradientForRect(data.angle, object.width, object.height);

    const x = rotatedCoords.x1 + (object.width * data.left);
    const y = rotatedCoords.y1 + (object.height * data.top);
    
    return {
        x1: x,
        y1: y,
        x2: x,
        y2: y,
        r1: 0,
        r2: Math.max(object.width, object.height) * data.radius,
    }
};

const MAP_COORDS_HANDLER = {
    [GRADIENT_TYPES.LINEAR]: getLinearCoords,
    [GRADIENT_TYPES.RADIAL]: getRadialCoords
};

const getFilter = (object, data) => {
    return new fabric.Image.filters.Gradient({
        id: GRADIENT_FILTER_ID,
        gradient: new fabric.Gradient({
            type: data.type,
            gradientUnits: 'pixels',
            coords: MAP_COORDS_HANDLER[data.type](object, data),
            colorStops: data.colors,
        }),
        alpha: data.opacity
    });
}

export const shadowFilterHandler = () => (object, objectData) => {
    const filters = objectData?.map(data => getFilter(object, data)) || [];

    object.filters = object.filters.filter(filter => filter.id !== GRADIENT_FILTER_ID);
    object.filters = [...object.filters, ...filters];

    applyGradientFilter(object);

    return filters;
}