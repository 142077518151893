<template>
  <div>
    <div class="form-row">
      <div class="col" />
      <div class="col-3">
        <label>Start</label>
      </div>
      <div class="col-3">
        <label>End</label>
      </div>
      <div class="col-1" />
    </div>
    <div
      v-for="(node, index) in getNodes"
      :key="index"
      class="form-row mb-2 align-items-center"
    >
      <div class="col d-flex align-items-center">
        <b-form-checkbox
          v-model="node.isPlay"
          @input="onChange"
        />
        <img
          v-if="node.src"
          :src="node.src"
          class="mx-2 image-field-preview"
          width="30"
          height="30"
        >
        <div
          v-else
          v-b-tooltip.hover
          :title="node.text || '<Empty>'"
          class="container-text-running mx-2"
        >
          <svg
            width="30"
            height="20"
            class="d-block"
          >
            <use href="/img/icons/sprite.svg#eye" />
          </svg>
        </div>
        <span class="name-container">{{ node.name }}</span>
      </div>
      <div class="col-3 d-flex align-items-center">
        <b-form-input
          v-model.number="node.startTime"
          type="number"
          class="form-control noscroll"
          @input="onChange"
        />
      </div>
      <div class="col-3 d-flex align-items-center">
        <b-form-input
          v-model.number="node.endTime"
          type="number"
          class="form-control noscroll"
          @input="onChange"
        />
      </div>
      <b-button
        v-b-toggle="`animation-options-${index}`"
        class="position-relative"
        size="sm"
      >
        <b-icon icon="chevron-down" />
        <b-icon icon="chevron-up" />
        <b-badge
          v-show="node.animations.length"
          variant="primary"
          class="animation-options-count"
        >
          {{ node.animations.length }}
        </b-badge>
      </b-button>
      <b-collapse
        :id="`animation-options-${index}`"
        class="col-12 card mt-2 mb-0"
      >
        <div class="d-flex justify-content-between align-content-center">
          <div class="pt-1">
            Animation Options
          </div>
          <button
            class="btn p-0 add-new-animation-option"
            type="button"
            @click="onAddNewAnimation(node, index)"
          >
            <i class="fas fa-plus" />
          </button>
        </div>
        <div class="dropdown-divider" />
        <div>
          <div class="form-row m-0">
            <label class="col">Type</label>
            <label class="col">Direction</label>
            <label class="col">Start</label>
            <label class="col">End</label>
            <div class="col-1" />
          </div>
          <div class="container-options">
            <div
              v-for="(animation, indexAnimation) in node.animations"
              :key="indexAnimation"
              class="form-row m-0 mb-1"
            >
              <div class="col">
                <select
                  v-model="animation.type"
                  class="form-control pr-0"
                  @change="onChange"
                >
                  <option value="fade">
                    Fade
                  </option>
                  <option value="wipe">
                    Wipe
                  </option>
                </select>
              </div>
              <div class="col">
                <select
                  v-model="animation.direction"
                  class="form-control"
                  @change="onChange"
                >
                  <option value="in">
                    In
                  </option>
                  <option value="out">
                    Out
                  </option>
                </select>
              </div>
              <div class="col">
                <input
                  v-model="animation.startTime"
                  type="number"
                  class="form-control"
                  @input="onChange"
                >
              </div>
              <div class="col">
                <input
                  v-model="animation.endTime"
                  type="number"
                  class="form-control"
                  @input="onChange"
                >
              </div>
              <div class="col-1 d-flex align-content-center justify-content-center p-0">
                <button
                  class="btn p-0 delete-option"
                  type="button"
                  @click="onDeleteAnimation(node, indexAnimation)"
                >
                  <i class="fas fa-trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <b-form-group
      label="Total duration"
      label-cols="5"
      class="mb-2"
    >
      <b-form-input
        v-model="totalDuration"
        type="number"
      />
    </b-form-group>
    <b-form-group
      label="Number of loops"
      label-cols="5"
    >
      <b-form-input
        v-model="numberOfLoops"
        type="number"
      />
    </b-form-group>
    <b-button
      variant="primary"
      class="w-100 mb-2"
      @click="onPreview"
    >
      Preview
    </b-button>
    <p class="text-muted">
      Animations are supported for GIF and HTML formats.
    </p>
  </div>
</template>

<script>
import { editorControlEventBroker, EVENTS } from '../../../../group/modules/editor-control-popup/event-broker';
import { calculateTimeByTotalDuration } from '../../../../services/EditorControls/animation-module/utils';
export default {
  props: {
    target: {
      type: Object,
      require: true,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      totalDuration: 3,
      numberOfLoops: 1
    }
  },
  computed: {
    getNodes() {
      return this.calculateTimeByTotalDuration(
        this.options?.nodes || [],
        this.totalDuration
      )
    }
  },
  methods: {
    calculateTimeByTotalDuration,
    onDeleteAnimation(node, index) {
      node.animations = node.animations.filter(
        (animation, indexAnimation) => indexAnimation !== index
      );

      this.onChange();
    },
    onAddNewAnimation(node) {
      node.animations.push({
        direction: 'in',
        endTime: 0,
        startTime: 0,
        type: 'fade'
      });

      this.onChange();
    },
    onChange() {
      editorControlEventBroker.fire(EVENTS.ANIMATION_MODULE_CHANGED, {
        ...this.options,
        nodes: this.options.nodes,
        totalDuration: this.totalDuration,
        numberOfLoops: this.numberOfLoops
      })
    },
    onPreview() {
      editorControlEventBroker.fire(EVENTS.ANIMATION_MODULE_PREVIEW, {
        nodes: this.options.nodes,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.collapsed > .bi-chevron-up,
.not-collapsed .bi-chevron-down {
  display: none;
}

.animation-options-count {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
}

.image-field-preview {
  object-fit: contain;
  background-image: url("/img/icons/empty.png");
}
</style>
