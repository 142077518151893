export class BackgroundMaker {
  canvas
  ctx
  fillEdges = false
  linearGradient = false
  radialGradient = false
  aspectRatio = null
  angle = 0
  amplitude = 0
  frequency = 1
  colors = []
  fileName = 'bg'
  rounding = 0
  transparency = 0
  edges = {
    topLeft: false, topRight: false, bottomLeft: false, bottomRight: false,
  }
  colorSteps = []
  blur = 0
  presetId = null

  constructor(canvas) {
    this.canvas = canvas
    this.ctx = this.canvas.getContext('2d')
  }

  get width() {
    return this.canvas.width
  }

  set width(val) {
    this.canvas.width = val
    if (this.aspectRatio) {
      this.canvas.height = val / this.aspectRatio
    }
  }

  get height() {
    return this.canvas.height
  }

  set height(val) {
    this.canvas.height = val
    if (this.aspectRatio) {
      this.canvas.width = val * this.aspectRatio
    }
  }

  get keepAspectRatio() {
    return !!this.aspectRatio
  }

  set keepAspectRatio(val) {
    this.aspectRatio = val ? this.canvas.width / this.canvas.height : null
  }

  get lineWidth() {
    return this.height / this.lineCount
  }

  get lineCount() {
    return this.colors.length
  }

  get opacity() {
    return (100 - this.transparency) / 100
  }

  get extendedColors() {
    if (this.fillEdges) {
      return [...Array(this.lineCount).fill(this.colors.at(0)), ...this.colors, ...Array(this.lineCount).fill(this.colors.at(-1)),]
    }

    return [...this.colors, ...this.colors, ...this.colors,]
  }

  static randomColor() {
    return '#' + Math.floor(Math.random() * 2 ** 24).toString(16).padStart(6, '0')
  }

  drawLines() {
    const w = this.lineWidth
    let x = -this.width / 2 - w
    let y = -this.height / 2 - w * this.lineCount

    this.extendedColors.forEach(color => {
      this.ctx.save()
      this.ctx.translate(this.width / 2, this.height / 2)
      this.ctx.rotate(this.angle * Math.PI / 180)
      this.drawSin(x, y, color)
      this.ctx.restore()
      y += w
    })
  }

  drawSin(offsetX, offsetY, fillStyle) {
    const w = this.lineWidth
    let x = offsetX
    let y = 0
    this.ctx.fillStyle = fillStyle
    this.ctx.globalAlpha = this.opacity
    this.ctx.beginPath()
    this.ctx.moveTo(offsetX, offsetY)
    // top line
    for (; x < this.width; x++) {
      y = offsetY + this.amplitude * Math.sin((x + offsetX) / this.frequency)
      this.ctx.lineTo(x, y)
    }
    this.ctx.lineTo(x, y + w)
    // bottom line
    for (; x > offsetX; x--) {
      y = offsetY + w + this.amplitude * Math.sin((x + offsetX) / this.frequency)
      this.ctx.lineTo(x, y)
    }
    this.ctx.lineTo(x, y - w)
    this.ctx.fill()
  }

  drawGradient() {
    const diagonal = Math.sqrt(this.width * this.width + this.height * this.height)
    let newWidth = diagonal
    let newHeight = diagonal
    if (this.canvas.width === this.canvas.height) {
      newWidth *= (this.width / this.height)
      newHeight *= (this.height / this.width)
    }

    this.ctx.save()
    this.ctx.translate(this.width / 2, this.height / 2)
    this.ctx.rotate(this.angle * Math.PI / 180)
    this.ctx.globalAlpha = this.opacity
    this.ctx.fillStyle = this.createGradient()
    this.ctx.fillRect(-newWidth / 2, -newHeight / 2, newWidth, newHeight)
    this.ctx.restore()
  }

  createGradient() {
    let grd
    if (this.linearGradient) {
      grd = this.ctx.createLinearGradient(0, -this.height / 2, 0, this.height / 2)
    } else {
      grd = this.ctx.createRadialGradient(0, 0, 0, 0, 0, Math.min(this.width, this.height))
    }

    const step = 1 / (this.lineCount - 1 || Infinity)
    this.colors.forEach((color, i) => {
      grd.addColorStop(this.linearGradient ? i * step : this.colorSteps[i] / 100, this.checkAlphaChannel(color));
    })
    if (!step) {
      grd.addColorStop(1, 'transparent')
    }
    return grd
  }

  roundEdges() {
    const {width, height} = this.canvas
    const r = this.rounding

    this.ctx.globalCompositeOperation = 'destination-out'
    this.ctx.fillStyle = 'rgba(0, 0, 0, 1)'

    if (this.edges.topLeft) {
      this.ctx.beginPath()
      this.ctx.arc(r, r, r, Math.PI, 1.5 * Math.PI)
      this.ctx.lineTo(0, 0)
      this.ctx.fill()
    }

    if (this.edges.topRight) {
      this.ctx.beginPath()
      this.ctx.arc(width - r, r, r, 1.5 * Math.PI, 0)
      this.ctx.lineTo(width, 0)
      this.ctx.fill()
    }

    if (this.edges.bottomRight) {
      this.ctx.beginPath()
      this.ctx.arc(width - r, height - r, r, 0, 0.5 * Math.PI)
      this.ctx.lineTo(width, height)
      this.ctx.fill()
    }

    if (this.edges.bottomLeft) {
      this.ctx.beginPath()
      this.ctx.arc(r, height - r, r, 0.5 * Math.PI, Math.PI)
      this.ctx.lineTo(0, height)
      this.ctx.fill()
    }

    this.ctx.globalCompositeOperation = 'source-over'
  }

  reset() {
    this.ctx = this.canvas.getContext('2d')
    this.ctx.setTransform(1, 0, 0, 1, 0, 0)
    this.ctx.globalCompositeOperation = 'source-over'
    this.ctx.clearRect(0, 0, this.width, this.height)
  }

  redraw() {
    this.reset()
    this.ctx.filter = `blur(${this.blur}px)`;
    if (this.linearGradient || this.radialGradient) {
      this.drawGradient()
    } else {
      this.drawLines()
    }

    this.roundEdges()
  }

  getCanvasBlob() {
    return new Promise(resolve => {
      this.canvas.toBlob(blob => {
        resolve(blob)
      })
    })
  }

  async getFile() {
    const blob = await this.getCanvasBlob()
    return new File([blob], `${this.fileName}.png`, {
      type: blob.type,
    })
  }

  checkAlphaChannel(color) {
    if (color.substring(7, color.length) === '00') {
      return 'transparent'
    }
    return color;
  }

  getSettings() {
    const settings = {};
    for (let key of Object.keys(this)) {
      if (['ctx', 'canvas', 'fileName', 'fillEdges'].includes(key)) {
        continue
      }
      settings[key] = this[key];
    }
    return settings;
  }
}

export class BackgroundMakerV2 extends BackgroundMaker {
  static randomColor() {
    return '#' + Math.floor(Math.random() * 2 ** 24).toString(16);
  }
  createGradient() {
    let grd
    if (this.linearGradient) {
      grd = this.ctx.createLinearGradient(0, -this.height / 2, 0, this.height / 2)
    } else {
      grd = this.ctx.createRadialGradient(0, 0, 0, 0, 0, Math.min(this.width, this.height))
    }
    this.colors.forEach((color, i) => {
      grd.addColorStop(this.colorSteps[i] / 100, color);
    })
    return grd
  }

  applySettings(settings) {
    for (let key of Object.keys(settings)) {
      if (key === 'colors') {
        this[key] = settings[key].slice(0,7);
        this.presetId = settings.presetId;
        continue
      }
      this[key] = settings[key];
    }
  }
  checkAlphaChannel(color) {
    return color;
  }
}
