var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-body--layers" },
    _vm._l(_vm.items, function (template) {
      return _c("FolderLayer", {
        key: template.name,
        attrs: { node: template },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }