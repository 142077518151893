import {TABS} from "./constants";
import {FILE_TYPE} from "../../../constants/file-image-types";

const IMAGE_EXT = ['png']

const addSharePath = item => ({
    ...item,
    path: [
        location.protocol,
        '',
        location.host,
        `share?file=${item.path}`,
    ].join('/')
})

const typeSetter = (items) => {
    return items.map(item => {
        const data = addSharePath(item)

        if (!data.type) {
            data.type = data.path.split('.').pop();
        }

        if (IMAGE_EXT.includes(data.type)) {
            data.type = FILE_TYPE.IMAGE;
        }

        return data
    })
}

const TAB_MAPPERS = {
    [TABS.STOCK_IMAGES]: typeSetter,
    [TABS.PRODUCTS]: typeSetter,
    [TABS.BACKGROUND]: typeSetter,
}

export const listMapperFactory = (route) => {
    const tab = route.params.tab

    if (tab in TAB_MAPPERS) {
        return TAB_MAPPERS[tab];
    }

    return i => i;
}