function isLastLetter(textLines = []) {
    const maxRowIndex = textLines.length - 1;
    const maxStyleIndex = textLines[maxRowIndex].length - 1;
  
    return { maxRowIndex, maxStyleIndex };
}

function getAllStyles(styles) {
    const allStyles = Object.values(styles)
        .map(rows => Object.values(rows))
        .reduce((acc, row) => [...acc, ...row], []);
    
    return allStyles;
}
  
export function parseStyleToText(textLines, styles) {
    let text = '';
    let underline = null;
    let indexOfText = 0;

    const allStyle = getAllStyles(styles);

    const { maxRowIndex, maxStyleIndex } = isLastLetter(textLines);
    
    textLines.forEach((row, indexOfRow) => {
        const string = row.split('');

        string.forEach((letter, indexOfLetter) => {
            const stylesForSymbol = allStyle[indexOfText] || {};

            if (underline === null && stylesForSymbol.underline) {
                text += '<u>';
                underline = stylesForSymbol.underline;
            } else if (underline === true && !stylesForSymbol.underline) {
                text += '</u>';
                underline = null;
            }

            text += letter;
            indexOfText++;

            if (underline && maxRowIndex === +indexOfRow && maxStyleIndex === +indexOfLetter) {
                text += '</u>';
                underline = null;
            }
        });
        
        if (maxRowIndex !== +indexOfRow) {
            text += "\n";
        }
    });

    return text;
}