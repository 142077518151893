import Styles from './Styles';
import Table from './Table';
import TableColumn from './TableColumn';
import TableRow from './TableRow';
import TableCell from './TableCell';

export default {
  Styles,
  Table,
  TableColumn,
  TableRow,
  TableCell
}