import PsdDataContainer from "./psd-data-container";
import Image from './psd-types/image';
import { PARSED_FIELD_TYPES } from "@/includes/parse_psd_data/constants";
import { resizeWithAspectRatio } from "@frontend/services/helpers";
import { getInstanceByType } from "@/includes/parse_psd_data/type-resolver";

export default function parsePSDData(data, fieldsToMap = null) {
    const _data = JSON.parse(JSON.stringify(data));
    
    if (fieldsToMap?.length) {
      _data.templates.forEach(template => {
        const mappedFields = [];
        
        template.fields.forEach(field => {
          const fieldToChange = fieldsToMap.find(item => field.name.includes(item.name));
          
          switch (fieldToChange?.type) {
            case PARSED_FIELD_TYPES.USER_TEXT:
              field.text.value = fieldToChange.value;
              field.isReplacedWithUserField = true;
              mappedFields.push(fieldToChange);
              
              break;
            case PARSED_FIELD_TYPES.USER_IMAGE: 
              const { width, height } = resizeWithAspectRatio(
                fieldToChange.width, 
                fieldToChange.height,
                field.width,
                field.height
              );
              
              field.publicPath = fieldToChange.publicPath;
              field.width = width;
              field.height = height;
              field.isReplacedWithUserField = true;
              mappedFields.push(fieldToChange);
              
              break;
            default:
              break;
          }
        });
        
        template.fields = [
          ...template.fields,
          ...fieldsToMap
            .filter(field => !mappedFields.find(mappedField => mappedField.name === field.name))
        ];
      });
    }

    return new PsdDataContainer(_data);
}

export function parsePSDStateData(state) {

  state.layers = state.layers.map(layer => {
    const instance = getInstanceByType(layer.data);

    instance.id = layer.id;

    if (layer.field) {
      instance.field = layer.field;
    }

    if (instance instanceof Image) {
      instance.isFlipV = !!layer.isFlipV;
      instance.isFlipH = !!layer.isFlipH;
    }

    return instance;
  });

  return state;
}

export const recursiveWalker = (nodes, callback) => {
  if (!nodes) {
    return undefined;
  }
  nodes.forEach(node => {
    callback(node)
    if (node.children) {
      recursiveWalker(node.children, callback)
    }
  })
}

export const recursiveFind = (nodes, callback) => {
  for (const node of nodes) {
    if (callback(node)) {
      return node;
    }
    if (node.children) {
      const found = recursiveFind(node.children, callback)
      if (found) {
        return found
      }
    }
  }

  return undefined
}
