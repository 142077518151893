export default function fileAviSvg () {
  return `
  <svg
      id="file-avi-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 550.801 550.801">
      <g>
          <path d="M176.571 283.163c-2.869-9.566-5.74-21.537-8.145-31.105h-.479c-2.386 9.568-4.778 21.771-7.415 31.105l-9.563 34.225h35.651l-10.049-34.225z"
                fill="#727272" data-original="#000000" class=""></path>
          <path d="M488.426 197.019H475.2v-63.816c0-.398-.063-.799-.116-1.202-.021-2.534-.827-5.023-2.562-6.995L366.325 3.694c-.032-.031-.063-.042-.085-.076-.633-.707-1.371-1.295-2.151-1.804a9.495 9.495 0 0 0-.706-.419 11.131 11.131 0 0 0-2.131-.896c-.2-.056-.38-.138-.58-.19A10.774 10.774 0 0 0 358.193 0H97.2C85.282 0 75.6 9.693 75.6 21.601v175.413H62.377c-17.049 0-30.873 13.818-30.873 30.873v160.545c0 17.043 13.824 30.87 30.873 30.87h13.224V529.2c0 11.907 9.682 21.601 21.6 21.601h356.4c11.907 0 21.6-9.693 21.6-21.601V419.302h13.226c17.044 0 30.871-13.827 30.871-30.87v-160.54c-.001-17.054-13.828-30.873-30.872-30.873zM96.407 386.068l49.286-161.288h47.856l50.019 161.288h-39.242l-12.451-41.401h-46.188l-11.48 41.401h-37.8zM237.358 224.78h39.964l19.62 68.202c5.511 19.143 10.531 37.567 14.359 57.67h.728c4.066-19.385 9.092-38.527 14.598-56.953l20.576-68.919h38.76l-54.311 161.288h-42.604l-51.69-161.288zm216.243 298.573H97.2V419.302h356.4v104.051zm-50.399-137.285V224.78h36.619v161.288h-36.619zm50.399-189.049H97.2V21.605h250.193v110.513c0 5.967 4.841 10.8 10.8 10.8H453.6v54.101z"
                fill="#727272" data-original="#000000" class=""></path>
      </g>
  </svg>
`
};
