export default {
  create(data) {
    return axios.post('/shadow-group', data);
  },

  getShadowGroups() {
    return axios.get('/shadow-group');
  },
  
  updateShadowGroup(id, data) {
    return axios.put(`/shadow-group/${id}`, data);
  },

  deleteShadowGroup(id) {
    return axios.delete(`/shadow-group/${id}`);
  }
}
