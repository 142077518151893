const url = new URL(window.location.href);
const searchParams = url.searchParams;



export default {
    hasParam(key) {
        return searchParams.has(key);
    },
    deleteParam(key) {
        if (!this.hasParam(key)) {
            return this;
        }

        searchParams.delete(key)
        window.history.replaceState({}, document.title, url.toString());

        return this;
    },
    addParam(key, value) {
        if (this.hasParam(key)) {
            searchParams.set(key, value)
        } else {
            searchParams.append(key, value)
        }

        window.history.replaceState({}, document.title, url.toString());

        return this
    },
    reload() {
        window.location.reload()
    }
}

