var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Object Fill", inline: "" } },
        [
          _c("b-form-select", {
            attrs: {
              options: _vm.objectFitAndFillOptions.objectFillOptions,
              "text-field": "name",
              "value-field": "value",
            },
            on: {
              change: function ($event) {
                return _vm.onChange("objectFillSettings")
              },
            },
            model: {
              value: _vm.objectFillSettings.align_to_fill,
              callback: function ($$v) {
                _vm.$set(_vm.objectFillSettings, "align_to_fill", $$v)
              },
              expression: "objectFillSettings.align_to_fill",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              attrs: { name: "crop-mode", switch: "" },
              on: {
                change: function ($event) {
                  return _vm.onChange("objectFillSettings")
                },
              },
              model: {
                value: _vm.objectFillCropMode,
                callback: function ($$v) {
                  _vm.objectFillCropMode = $$v
                },
                expression: "objectFillCropMode",
              },
            },
            [_vm._v("\n      Crop\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Object Fit", inline: "" } },
        [
          _c("b-form-select", {
            attrs: {
              options: _vm.objectFitAndFillOptions.objectFitOptions,
              "text-field": "name",
              "value-field": "value",
            },
            on: {
              change: function ($event) {
                return _vm.onChange("objectFitSettings")
              },
            },
            model: {
              value: _vm.objectFitSettings.align_to_fit,
              callback: function ($$v) {
                _vm.$set(_vm.objectFitSettings, "align_to_fit", $$v)
              },
              expression: "objectFitSettings.align_to_fit",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }