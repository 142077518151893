<template>
  <b-row class="bg-maker-wrap">
    <b-col cols="7">
      <b-row class="mb-2">
        <b-col cols="3">
          <label>Shape</label>
        </b-col>
        <b-col class="d-flex justify-content-around">
          <button
            v-for="(shape, key) in shapes"
            :key="key"
            class="btn btn-outline-primary shape"
            :class="[`shape-${key}`, { active: key === activeShape }]"
            @click="setShape(key)"
          >
            <span 
              v-if="key==='none'"
              aria-hidden="true"
            >
              ×
            </span>
          </button>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="auto">
          <b-checkbox
            v-model="editor.fillEdges"
            @input="redraw"
          >
            Fill edges
          </b-checkbox>
        </b-col>
        <b-col cols="auto">
          <b-checkbox
            v-model="trimTransparentArea"
          >
            Crop transparent area
          </b-checkbox>
        </b-col>
      </b-row>
      <b-form-group
        label="Gradient"
        label-cols="3"
      >
        <b-checkbox
          v-model="editor.linearGradient"
          class="mt-2"
          inline
          @input="redraw"
          @change="editor.radialGradient = false"
        >
          Linear
        </b-checkbox>
        <b-checkbox
          v-model="editor.radialGradient"
          class="mt-2"
          inline
          @input="redraw"
          @change="editor.linearGradient = false"
        >
          Radial
        </b-checkbox>
      </b-form-group>
      <b-form-group
        label="Rounding"
        label-cols="3"
        :disabled="disabledRounding"
      >
        <b-input-group>
          <b-input
            v-model="editor.rounding"
            type="range"
            min="0"
            :max="maxRounding"
            number
            @input="redraw"
          />
          <template #append>
            <b-input
              v-model="editor.rounding"
              min="0"
              :max="maxRounding"
              type="number"
              number
              @input="redraw"
            />
          </template>
        </b-input-group>
        <b-checkbox-group
          v-show="editor.rounding && !disabledRounding"
          v-model="selectedCorners"
          size="sm"
          button-variant="outline-primary"
          buttons
          class="mt-1"
          @input="redraw"
        >
          <b-checkbox value="topLeft">
            <b-icon icon="arrow-up-left" />
          </b-checkbox>
          <b-checkbox value="topRight">
            <b-icon icon="arrow-up-right" />
          </b-checkbox>
          <b-checkbox value="bottomRight">
            <b-icon icon="arrow-down-right" />
          </b-checkbox>
          <b-checkbox value="bottomLeft">
            <b-icon icon="arrow-down-left" />
          </b-checkbox>
        </b-checkbox-group>
      </b-form-group>
      <b-form-group
        label="Angle"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="editor.angle"
            type="range"
            min="0"
            max="360"
            number
            @input="redraw"
          />
          <template #append>
            <b-input
              v-model="editor.angle"
              min="0"
              max="360"
              type="number"
              number
              @input="redraw"
            />
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Transparency"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="editor.transparency"
            type="range"
            min="0"
            max="100"
            number
            @input="redraw"
          />
          <template #append>
            <b-input
              v-model="editor.transparency"
              min="0"
              max="100"
              type="number"
              number
              @input="redraw"
            />
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Amplitude"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="editor.amplitude"
            type="range"
            min="0"
            max="75"
            @input="redraw"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Frequency"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="editor.frequency"
            type="range"
            min="1"
            max="100"
            @input="redraw"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Gaussian blur"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="editor.blur"
            min="0"
            max="200"
            type="range"
            @input="redraw"
          />
          <template #append>
            <b-input
              v-model="editor.blur"
              max="200"
              min="0"
              number
              type="number"
              @input="redraw"
            />
          </template>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Colors"
        label-cols="3"
      >
        <b-input-group>
          <b-input
            v-model="colorsNum"
            type="range"
            min="1"
            max="5"
            @input="colorsNumChange"
          />
        </b-input-group>
        <div
          tabindex="0"
          @blur="hideColorPicker"
        >
          <div 
            v-if="!editor.radialGradient"
            class="colors-wrap mt-2"
          >
            <div
              v-for="(color, index) in editor.colors"
              :key="index"
              :style="`background: ${color}`"
              class="color-item"
              @click="setActiveColor(color, index)"
            />
          </div>
          <div v-if="editor.radialGradient">
            <b-row
              v-for="(color, index) in editor.colors"
              :key="index"
              class="colors-wrap mt-2 d-inline-flex"
              @click="setActiveColor(color, index)"
            >
              <b-col
                :style="`background: ${color}`"
                class="color-item"
                cols="2"
              />
              <b-col cols="9">
                <b-row>
                  Color radius:
                </b-row>
                <b-row>
                  <b-col 
                    class="m-0 p-0 justify-content-center align-center"
                    cols="7"
                  >
                    <b-input
                      v-model="editor.colorSteps[index]"
                      :max="100"
                      :min="0"
                      class="color-stop-range"
                      number
                      type="range"
                      @input="(value) => updateStepOfColor(index, value)"
                    />
                  </b-col>
                  <b-col>
                    <b-input
                      v-model="editor.colorSteps[index]"
                      :max="100"
                      :min="0"
                      number
                      type="number"
                      @input="(value) => updateStepOfColor(index, value)"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <Chrome
            v-show="showColorPicker"
            ref="colorPicker"
            class="position-absolute mt-2"
            :value="activeColor"
            @input="updateColor"
          />
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="5">
      <div
        class="canvas-wrapper"
        :style="canvasWrapperStyle"
      >
        <canvas
          ref="canvas"
          :width="width"
          :height="height"
          :style="canvasStyle"
        />
      </div>
      <b-form-row class="my-3">
        <b-col cols="6">
          <b-form-group
            label="Width"
            class="mb-0"
          >
            <b-input
              v-model="editor.width"
              type="number"
              number
              @input="updateCanvasDimensions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Height"
            class="mb-0"
          >
            <b-input
              v-model="editor.height"
              type="number"
              number
              @input="updateCanvasDimensions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-checkbox v-model="editor.keepAspectRatio">
            Keep aspect ratio
          </b-checkbox>
        </b-col>
      </b-form-row>
      <b-form-group label="Save image as">
        <b-input v-model="fileName" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BackgroundMaker } from '../BackgroundMaker'
import { Chrome } from 'vue-color'

const MAX_DIMENSION = 300
const MAX_DIMENSION_W_UNIT = `${MAX_DIMENSION}px`

export default {
  name: 'BackgroundMaker',

  components: {
    Chrome,
  },

  props: {
    width: {
      type: Number,
      default: 1000,
    },
    height: {
      type: Number,
      default: 1000,
    },
  },

  data () {
    return {
      canvasStyle: {
        width: MAX_DIMENSION_W_UNIT,
        height: MAX_DIMENSION_W_UNIT,
      },
      canvasWrapperStyle: {
        width: MAX_DIMENSION_W_UNIT,
        height: MAX_DIMENSION_W_UNIT,
      },
      editor: {},
      colorsNum: 1,
      showColorPicker: false,
      activeColor: '#FFFFFF',
      activeColorIndex: 0,
      trimTransparentArea: false,
      maxRounding: Math.max(this.width, this.height) / 2,
      activeShape: 'none',
      shapes: {
        none: {
          width: 1000,
          height: 1000,
          rounding: 0,
          edges: false,
        },
        circle: {
          width: 500,
          height: 500,
          rounding: 250,
          edges: true,
        },
        square: {
          width: 500,
          height: 500,
          rounding: 0,
          edges: false,
        },
        rectangle: {
          width: 500,
          height: 200,
          rounding: 0,
          edges: false,
        },
      },
    }
  },

  computed: {
    fileName: {
      get () {
        return this.editor.fileName
      },
      set (val) {
        this.editor.fileName = val
      },
    },
    selectedCorners: {
      get () {
        return _.keys(_.pickBy(this.editor.edges))
      },
      set (val) {
        this.$set(this.editor, 'edges', _.mapValues(this.editor.edges, (v, k) => _.includes(val, k)))
      },
    },
    disabledRounding () {
      return this.activeShape === 'circle'
    },
  },

  mounted () {
    this.editor = new BackgroundMaker(this.$refs.canvas)
    this.editor.width = this.width
    this.editor.height = this.height
    this.colorsNumChange()
    this.activeColor = this.editor.colors[this.activeColorIndex]
    this.updateCanvasDimensions()
  },

  methods: {
    redraw () {
      this.editor.redraw()
    },

    colorsNumChange () {
      const delta = this.colorsNum - this.editor.colors.length
      if (delta > 0) {
        for (let i = 0; i < delta; i++) {
          this.editor.colors.push(BackgroundMaker.randomColor())
        }
      } else {
        this.editor.colors.splice(delta)
      }
      for (let i = 0; i < this.editor.colors.length ; i++) {
        this.editor.colorSteps[i] = 50;  
      }
      this.redraw()
    },
    updateStepOfColor(index, value) {
      this.editor.colorSteps[index] = value;
      this.redraw();
    },
    setActiveColor (color, index) {
      this.activeColor = color
      this.activeColorIndex = index
      this.$nextTick(() => {
        this.showColorPicker = true
      })
    },

    hideColorPicker (event) {
      if (!this.$refs.colorPicker.$el.contains(event.relatedTarget)) {
        this.showColorPicker = false
      }
    },

    updateColor (color) {
      this.$set(this.editor.colors, this.activeColorIndex, color.hex8)
      this.redraw()
    },

    async updateCanvasDimensions () {
      const aspectRatio = this.editor.width / this.editor.height
      if (aspectRatio >= 1) {
        this.canvasStyle.width = MAX_DIMENSION_W_UNIT
        this.canvasStyle.height = `${MAX_DIMENSION / aspectRatio}px`
      } else {
        this.canvasStyle.height = MAX_DIMENSION_W_UNIT
        this.canvasStyle.width = `${MAX_DIMENSION * aspectRatio}px`
      }

      this.maxRounding = Math.floor(Math.max(this.editor.width, this.editor.height) / 2)
      if (this.activeShape === 'circle') {
        this.editor.rounding = this.maxRounding
      }

      this.redraw()
    },

    setShape (shape) {
      this.editor.keepAspectRatio = false
      const { width, height, rounding, edges } = this.shapes[shape]
      this.$set(this.editor, 'width', width)
      this.$set(this.editor, 'height', height)
      this.$set(this.editor, 'rounding', rounding)
      this.$set(this.editor.edges, 'topLeft', edges)
      this.$set(this.editor.edges, 'topRight', edges)
      this.$set(this.editor.edges, 'bottomLeft', edges)
      this.$set(this.editor.edges, 'bottomRight', edges)
      this.activeShape = shape
      this.editor.keepAspectRatio = true
      this.updateCanvasDimensions()
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-maker-wrap {
  min-height: 450px;
}

.colors-wrap {
  display: flex;
  gap: 10px;
  width: 100%;

  .color-item {
    width: calc(20% - 10px);
    position: relative;
    border: 1px solid black;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}

.canvas-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shape {
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 0;

  &-circle {
    border-radius: 50%;
  }

  &-rectangle {
    width: 3rem;
  }
  &-none {
    span {
      display: block;
      position: absolute;
      font-size: 44px;
      top: -16px;
      left: 42px;
    }
  }
  
}

.color-stop-range {
  margin: 15px 0 0 0;
}
</style>
