const replaceSymbols = {
  "\\r": "\n",
  "</u>": "<u>"
}

function replaceAllSymbols(text = '') {
  let newString = text;

  for (const key in replaceSymbols) {
    newString = newString.replaceAll(key, replaceSymbols[key]);
  }

  return newString;
}

export default function parseText(input, default_color) {
    if (!input) {
      return { text: "", styles: {} };
    }
    var styles = {};
    var charIndex = 0;
    var lineIndex = 0;
    var lineStyle = {};
    var text = "";
    // force to convert to string as it was failing for numbers
    input = input + "";
    const textArray = replaceAllSymbols(input).split("<u>");
      
    textArray.forEach((t, i) => {
      var color = "";
      var subArray = t.split("<c ");

      subArray.forEach((element, j) => {
        var subText = element;
        if (j) {
          var pos = element.indexOf("'>");
          color = element.slice(1, pos);
          subText = element.slice(pos + 2, element.length);
        }
        for (let k = 0; k < subText.length; k++) {
          if (subText[k] == "\n") {
            styles = { ...styles, [lineIndex]: lineStyle };
            lineStyle = {};
            lineIndex += 1;
            charIndex = 0;
          } else {
            const charStyle = {
              fill: color || default_color,
              underline: i % 2 == 1,
            };
            lineStyle = { ...lineStyle, [charIndex]: charStyle };
            charIndex += 1;
          }
        }
        text += subText;
      });
    });

    styles = { ...styles, [lineIndex]: lineStyle };

    return { text, styles };
}