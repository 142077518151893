var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Radius %")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.radius,
              expression: "input.radius",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number", step: "1" },
          domProps: { value: _vm.input.radius },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "radius", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Left %")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.x_center,
              expression: "input.x_center",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number", step: "1" },
          domProps: { value: _vm.input.x_center },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "x_center", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Top %")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.y_center,
              expression: "input.y_center",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number", step: "1" },
          domProps: { value: _vm.input.y_center },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "y_center", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }