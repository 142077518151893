var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "custom-template-size-modal",
        centered: "",
        title: "Add Custom Template Size",
        "ok-title": "Save",
      },
      on: { ok: _vm.onSaved, shown: _vm.shown },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center w-100",
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      model: {
                        value: _vm.inputForm.saveAsNew,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputForm, "saveAsNew", $$v)
                        },
                        expression: "inputForm.saveAsNew",
                      },
                    },
                    [_vm._v("\n        Save as new template\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("b-button", { on: { click: cancel } }, [
                        _vm._v("\n          Cancel\n        "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.idDisabledSave,
                          },
                          on: { click: ok },
                        },
                        [_vm._v("\n          Save\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("label", [_vm._v("Name")]),
              _vm._v(" "),
              _c("b-form-input", {
                attrs: { placeholder: "Name" },
                model: {
                  value: _vm.inputForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputForm, "name", $$v)
                  },
                  expression: "inputForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "mt-3", attrs: { cols: "12" } },
            [
              _c("label", [_vm._v("Customer")]),
              _vm._v(" "),
              _c(
                "b-form-select",
                {
                  model: {
                    value: _vm.inputForm.customer,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputForm, "customer", $$v)
                    },
                    expression: "inputForm.customer",
                  },
                },
                _vm._l(_vm.customers, function (customer, index) {
                  return _c(
                    "b-form-select-option",
                    {
                      key: "key_" + index + "_" + customer.id,
                      attrs: { value: customer.id },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(customer.name) + "\n        "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "mt-3", attrs: { cols: "12" } },
            [
              _c("label", [_vm._v("Theme")]),
              _vm._v(" "),
              _c(
                "b-form-select",
                {
                  attrs: { disabled: !_vm.inputForm.customer },
                  model: {
                    value: _vm.inputForm.theme,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputForm, "theme", $$v)
                    },
                    expression: "inputForm.theme",
                  },
                },
                _vm._l(_vm.themes, function (theme, index) {
                  return _c(
                    "b-form-select-option",
                    {
                      key: "key_" + index + "_" + theme.id,
                      attrs: { value: theme.id },
                    },
                    [_vm._v("\n          " + _vm._s(theme.name) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { staticClass: "mt-3", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("b-form-input", {
                  attrs: { type: "number", min: "0", placeholder: "Width" },
                  model: {
                    value: _vm.inputForm.width,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputForm, "width", _vm._n($$v))
                    },
                    expression: "inputForm.width",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-text" }, [_vm._v("×")]),
                _vm._v(" "),
                _c("b-form-input", {
                  attrs: { type: "number", min: "0", placeholder: "Height" },
                  model: {
                    value: _vm.inputForm.height,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputForm, "height", _vm._n($$v))
                    },
                    expression: "inputForm.height",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }