import {FOLDERS_ROUTE_NAME} from "../components/modules/file-browser/constants";

export default [
  {
    path: '/video',
    component: () => import('../components/VideoTable.vue'),
    children: [
      {
        path: '',
        name: 'video.index',
      },
      {
        path: ':type/:id',
        name: 'video.entity',
      },
    ],
  },
  {
    path: '/file/browser',
    component: () => import('../components/modules/file-browser/FileBrowser.vue'),
    children: [
      {
        path: ':tab',
        name: 'file.tab',
        children: [
          {
            path: '*',
            name: FOLDERS_ROUTE_NAME,
          }
        ]
      }
    ],
  },
  {
    path: '/prompt-generator',
    name: 'prompt-generator',
    component: () => import('../components/PromptGeneratorPage.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../components/DashboardPage.vue'),
    children: [
      {
        path: 'display-ad',
        name: 'dashboard.display-ad',
        component: () => import('../components/common/dashboard/DisplayAd.vue'),
      },
      {
        path: 'retailer-banners',
        name: 'dashboard.retailer-banners',
        component: () => import('../components/common/dashboard/RetailerBanners.vue'),
      },
      {
        path: 'mobile-hero',
        name: 'dashboard.mobile-hero',
        component: () => import('../components/common/dashboard/MobileHeroCreator.vue'),
      },
      {
        path: 'template-psd',
        name: 'dashboard.template-psd',
        component: () => import('../components/common/dashboard/TemplatePsd.vue'),
      },
      {
        path: 'video-builder',
        name: 'dashboard.video.add',
        component: () => import('../components/common/dashboard/VideoCreator.vue'),
      },
      {
        path: 'sell-sheet',
        name: 'dashboard.sell-sheet',
        component: () => import('../components/common/dashboard/SellSheet.vue'),
      },
    ],
  }
]
