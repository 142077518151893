var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: { hover: true },
        },
      ],
      staticClass: "shapes-dd",
      attrs: {
        text: "",
        variant: "outline-primary",
        size: "sm",
        "no-caret": "",
        title: "Shapes",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c(
                "svg",
                {
                  staticClass: "b-icon bi",
                  attrs: { width: "1em", height: "1em", fill: "currentColor" },
                },
                [
                  _c("use", {
                    attrs: { href: "/img/icons/sprite.svg#shapes-svg" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          staticClass: "border-wrapper",
          attrs: { value: true, "unchecked-value": false },
          model: {
            value: _vm.isBorder,
            callback: function ($$v) {
              _vm.isBorder = $$v
            },
            expression: "isBorder",
          },
        },
        [_vm._v("\n    Border\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isBorder,
              expression: "isBorder",
            },
          ],
        },
        [
          _c(
            "b-input-group",
            { staticClass: "n-input mr-3", attrs: { append: "px" } },
            [
              _c("b-form-input", {
                attrs: { type: "number", value: _vm.borderSize },
                on: { input: _vm.changeBorderSize },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "input-group range-wrapper" }, [
            _c("input", {
              attrs: { type: "range", min: "0", max: "100", step: "1" },
              domProps: { value: _vm.borderSize },
              on: { input: _vm.changeBorderSize },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { on: { click: _vm.clearShape } },
        [
          _c("b-icon", {
            staticClass: "mr-2 transparent-icon",
            attrs: { icon: "circle-fill" },
          }),
          _vm._v("\n    None\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.shapes, function (shape) {
        return _c(
          "b-dropdown-item",
          {
            key: shape.name,
            on: {
              click: function ($event) {
                return _vm.selectShape(shape.name)
              },
            },
          },
          [
            _c("b-icon", { class: shape.class, attrs: { icon: shape.icon } }),
            _vm._v("\n    " + _vm._s(shape.name) + "\n  "),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }