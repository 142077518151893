<template>
  <b-modal
    id="select-folder-modal"
    title="Select Folder"
    size="lg"
    ok-only
    :ok-disabled="!selectedItems.length"
    @ok="$emit('onSelectedItems', selectedItems)"
  >
    <FolderContentView
      :is-only-folder="true"
      @onSelectedItems="(items) => selectedItems = items"
    />
  </b-modal>
</template>

<script>
import FolderContentView from '../FolderContentView.vue';

export default {
  components: { FolderContentView },
  data() {
    return {
      selectedItems: []
    }
  },
}
</script>