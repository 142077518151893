import { fabric } from "fabric";

export const ellipseHandler = (defaultOptions) => (object, objectData) => {
    const leftEllipse = objectData.left * object.width / 2;
    const topEllipse = objectData.top * object.height / 2;
    const rx = (objectData.right * object.width - objectData.left * object.width) / 2;
    const ry = (objectData.bottom * object.height - objectData.top * object.height) / 2;

    return new fabric.Ellipse({
        ...defaultOptions,
        left: leftEllipse - ((object.width - objectData.right * object.width) / 2),
        top: topEllipse - ((object.height - objectData.bottom * object.height) / 2),
        rx,
        ry,
        objectData: objectData
    });
}