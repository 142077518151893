<template>
  <modal
    name="svg-shape-modal"
    draggable=".drag-area"
    class="wrapper"
    height="auto"
    :shift-x="0.5"
    :shift-y="0.5"
    :click-to-close="false"
    width="360"
  >
    <div class="drag-area">
      <span class="title">Shape settings</span>
      <b-button
        variant="light"
        class="close-btn"
        @click="close"
      >
        <b-icon
          icon="x-lg"
          class="close-icon"
        />
      </b-button>
    </div>

    <div class="content-area">
      <b-form-row>
        <b-col cols="3">
          <label
            class="my-0 mr-1 ml-6"
            for="border"
          >
            Border
          </label>
          <b-form-checkbox
            id="border"
            v-model="isStrokeShape"
            class="border-wrapper"
            :value="true"
            :unchecked-value="false"
            switch
            size="lg"
            @change="onStrokeShapeChange"
          />
        </b-col>

        <b-col cols="6">
          <div class="text-editor-modal__settings-field">
            <label
              class="my-0 mr-1 ml-6"
              for="border-width"
            >
              Border width
            </label>
            <b-input
              id="border-width"
              :value="strokeWidth"
              type="number"
              class="form-control form-control-sm"
              @input="setStokeWidth"
            />
          </div>
        </b-col>

        <b-col cols="3">
          <label
            class="my-0 mr-1 ml-6"
            for="font-color"
          >
            Color
          </label>
          <b-form-input
            id="font-color"
            class="color-input"
            type="color"
            :value="color"
            @input="setColor"
          />
        </b-col>
      </b-form-row>
    </div>

    <div class="control-area">
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-primary mr-2"
            @click="apply"
            @keyup.enter="apply"
          >
            Apply
          </button>
          <button
            class="btn btn-secondary"
            @click="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SvgShapeModal',

  data: () => ({
    strokeWidth: 2,
    color: '#000000',
    productInstance: null,
    shapeObject: null,
    isStrokeShape: false,
    initShapeParams: null,
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getColorToDraw',
      'getProductInstance'
    ]),
  },

  mounted() {
    window.addEventListener('keydown', this.handleEnterPress);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleEnterPress);
  },

  methods: {
    init() {
      this.productInstance = this.getProductInstance;
      this.shapeObject = this.productInstance.getActiveObject();
      this.strokeWidth = this.shapeObject.strokeWidth;
      this.isStrokeShape = this.shapeObject.isStrokeShape;
      this.color = this.isStrokeShape ? this.shapeObject.stroke : this.shapeObject.fill;
      this.setInitShapeParams();
    },

    show() {
      this.init();
      this.$modal.show('svg-shape-modal');
    },

    close() {
      this.updateShapeOnCanvas(this.initShapeParams);
      this.$modal.hide('svg-shape-modal');
    },

    setColor(hexColor) {
      this.color = hexColor;
      this.updateColor();
    },

    updateColor() {
      if (this.isStrokeShape) {
        this.updateShapeOnCanvas({
          fill: 'transparent',
          stroke: this.color,
          isStrokeShape: this.isStrokeShape,
        })
      } else {
        this.updateShapeOnCanvas({
          fill: this.color,
          stroke: this.color,
          isStrokeShape: this.isStrokeShape,
        })
      }
    },

    setStokeWidth(stokeWidth) {
      this.strokeWidth = stokeWidth;
      this.updateShapeOnCanvas({ strokeWidth: Number(this.strokeWidth) });
    },

    onStrokeShapeChange(payload) {
      this.isStrokeShape = payload
      this.updateColor();
    },

    updateShapeOnCanvas(params) {
      this.shapeObject.set({ ...params });
      this.productInstance.renderAll();
    },

    apply() {
      this.initShapeParams = null;
      this.$modal.hide('svg-shape-modal');
    },

    setInitShapeParams() {
      this.initShapeParams = {
        isStrokeShape: this.shapeObject.isStrokeShape,
        strokeWidth: this.shapeObject.strokeWidth,
        fill: this.shapeObject.fill,
        stroke: this.shapeObject.stroke,
      };
    },

    handleEnterPress(event) {
      switch (event.keyCode) {
        // Enter key
        case 13:
        case 36:
        case 76:
          this.apply();
          break;
      }
    }
  },
};
</script>

<style scoped>
.wrapper {
  ::v-deep .vm--modal {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0 4px 20px;
    background-color: #f6f6f6;
  }

  .drag-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    position: relative;
    padding: 16px 16px;

    .title {
      font-size: 20px;
    }

    .close-btn {
      position: absolute;
      border: none;
      right: 8px;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        border: none;

        .close-icon {
          color: black;
        }
      }

      .close-icon {
        color: #7f7f7f;
        font-size: 15px;

        &:hover {
          color: black;
        }
      }
    }
  }

  .content-area {
    padding: 0 16px 16px 16px;

    .color-input {
      padding: 0;
      height: 32px;
    }
  }

  .control-area {
    padding: 0 16px 16px 16px;
  }
  
  .border-wrapper {
    margin: 1px 0 0 2px;
  }
}
</style>

<style>
.wrapper {
  .vm--overlay {
    background: transparent !important;
  }
}
</style>