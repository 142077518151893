export const CIRCLE = 'circle';
export const CIRCLE_WIPE = {
  title: 'Circle wipe',
  description: 'A custom circle wipe transition',
  vertexShader: `
    attribute vec2 a_position;
    attribute vec2 a_texCoord;
    varying vec2 v_texCoord;
    void main() {
        gl_Position = vec4(vec2(2.0, 2.0) * a_position - vec2(1.0, 1.0), 0.0, 1.0);
        v_texCoord = a_texCoord;
    }
  `,
  fragmentShader: `
    precision mediump float;
    uniform sampler2D u_image_a;
    uniform sampler2D u_image_b;
    uniform float progress;
    varying vec2 v_texCoord;
    uniform vec2 u_size_canvas;
    uniform vec2 u_size;

    void main() {
        vec4 color_a = texture2D(u_image_b, v_texCoord);
        vec4 color_b = texture2D(u_image_a, v_texCoord);

        // Calculate the aspect ratio
        float aspect = u_size_canvas.x / u_size_canvas.y;
        vec2 center = vec2(0.5, 0.5);

        // Scale the texture coordinates by the aspect ratio
        vec2 scaledTexCoords = v_texCoord;
        if (aspect > 1.0) {
            scaledTexCoords.y /= aspect;
            center.y /= aspect;
        } else {
            scaledTexCoords.x *= aspect;
            center.x *= aspect;
        }

        // Calculate the distance from the center of the screen
        float centerDistance = distance(scaledTexCoords, center);
        float circleSize = clamp(progress, 0.0, 1.0);

        if (centerDistance <= circleSize) {
            gl_FragColor = color_b;
        } else {
            gl_FragColor = color_a;
        }
    }
  `,
  properties: {
    progress: { 'type': 'uniform', 'value': 0.0 },
    u_size_canvas: { type: 'uniform', value: [0.0, 0.0] },
  },
  inputs: ["u_image_a", "u_image_b"]
};

export const CIRCLE_WIPE_IN = {
  title: 'Circle wipe',
  description: 'A custom circle wipe transition',
  vertexShader: `
    attribute vec2 a_position;
    attribute vec2 a_texCoord;
    varying vec2 v_texCoord;
    void main() {
        gl_Position = vec4(vec2(2.0, 2.0) * a_position - vec2(1.0, 1.0), 0.0, 1.0);
        v_texCoord = a_texCoord;
    }
  `,
  fragmentShader: `
    precision mediump float;
    uniform sampler2D u_image_a;
    uniform sampler2D u_image_b;
    uniform float progress;
    varying vec2 v_texCoord;
    uniform vec2 u_size_canvas;
    uniform vec2 u_size;

    void main() {
        vec4 color_a = texture2D(u_image_a, v_texCoord);
        vec4 color_b = texture2D(u_image_b, v_texCoord);

        // Calculate the aspect ratio
        float aspect = u_size_canvas.x / u_size_canvas.y;
        vec2 center = vec2(0.5, 0.5);

        // Scale the texture coordinates by the aspect ratio
        vec2 scaledTexCoords = v_texCoord;
        if (aspect > 1.0) {
            scaledTexCoords.y /= aspect;
            center.y /= aspect;
        } else {
            scaledTexCoords.x *= aspect;
            center.x *= aspect;
        }

        // Calculate the distance from the center of the screen
        float centerDistance = distance(scaledTexCoords, center);
        float circleSize = clamp(1.0 - progress, 0.0, 1.0);

        if (centerDistance <= circleSize) {
            gl_FragColor = color_b;
        } else {
            gl_FragColor = color_a;
        }
    }
`,
  properties: {
    progress: { 'type': 'uniform', 'value': 0.0 },
    u_size_canvas: { type: 'uniform', value: [0.0, 0.0] },
  },
  inputs: ["u_image_a", "u_image_b"]
};










