var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: { hover: true },
        },
      ],
      attrs: {
        variant: "outline-primary",
        title: _vm.target.locked ? "Unlock" : "Lock",
      },
      on: { click: _vm.toggle },
    },
    [
      _c("b-icon", {
        attrs: { icon: _vm.target.locked ? "lock-fill" : "unlock" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }