import BuilderTable from './builder';
import { getDataURLFromBlob } from "../../../services/helpers";

const CACHE_FONT_DATA_URL = {};

export async function getFontFaceStyle(fonts = []) {
  let fontFace = '';
  const fontFamilyManager = window.fontFamilyManager;

  if (!fontFamilyManager) return fonts;

  for (const font of fonts) {
    let fontInfo = CACHE_FONT_DATA_URL[font];
    let url = '';

    if (!fontInfo) {
      fontInfo = fontFamilyManager.getFontByIndex(font);

      if (fontInfo) {
        const blob = await fetch(`/fonts/${fontInfo.file}`).then(res => res.blob());
        url = await getDataURLFromBlob(blob);
      }
    } else {
      url = fontInfo.url;
    }

    if (!fontInfo) continue;

    fontFace += `@font-face{ font-family: ${fontInfo.index}; font-weight: ${fontInfo.weight}; src: url("${url}");}`
    CACHE_FONT_DATA_URL[font] = {
      ...fontInfo,
      url
    };
  }

  return fontFace;
}


function initDataBuilderCells(cells = []) {
  return cells.map(_cell => {
    const cell = new BuilderTable.TableCell();
    const styles = new BuilderTable.Styles();

    styles.setRules(_cell.styles.rules);

    cell.setId(_cell.id);
    cell.setStyles(styles);
    cell.setValue(_cell.value);
    cell.setColspan(_cell.colspan);

    return cell;
  });
}

export function initDataBuilderTable(data) {
  if (!data) return new BuilderTable.Table();

  const table = new BuilderTable.Table();
  const styles = new BuilderTable.Styles();

  styles.setRules(data.styles.rules);

  table.setId(data.id);
  table.setStyles(styles);

  const headers = initDataBuilderCells(data.headers);
  const rows = data.rows.map(_row => {
    const row = new BuilderTable.TableRow();
    const styles = new BuilderTable.Styles();

    styles.setRules(styles.rules);

    row.setId(_row.id);
    row.setStyles(styles);
    row.setCells(initDataBuilderCells(_row.cells));

    return row;
  });

  table.setHeaders(headers);
  table.setRows(rows);

  return table;
}