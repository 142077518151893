<template>
  <div class="modal-body d-flex justify-content-center align-items-center">
    <i class="fas fa-spinner fa-spin fa-3x" />
  </div>
</template>

<script>

export default {
  name: "SpinnerLoader",
}

</script>
