var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.MODAL_ID,
        title: "Rename",
        "ok-title": "Save",
        "ok-disabled": _vm.isSaveBtnDisabled,
      },
      on: { ok: _vm.handleSave },
    },
    [
      _c("b-form-input", {
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }