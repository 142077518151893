import TemplateField from "./TemplateField";

export class ShapeTemplateField extends TemplateField  {

    constructor(fieldData) {
        super(fieldData);
    }

    getStrokeWidth() {
        return +this.options.option2 || 0;
    }
}
