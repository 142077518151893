export default {
  fetchFolders(data) {
    return axios.post('/video/projects/fetch-folders', data);
  },
  createFolder(data) {
    return axios.post('/video/projects/create-folder', data);
  },
  updateFolder(data) {
    return axios.post('/video/projects/update-folder', data);
  },
  deleteFolder(data) {
    return axios.post('/video/projects/delete-folder', data);
  },
  deleteFolderItem(data) {
    return axios.post('/video/projects/delete-folder-item', data);
  },
  folderContent(data) {
    return axios.post('/video/projects/folder-content', data);
  },
  addToProjectFolder(data) {
    return axios.post('/video/projects/add-to-folder', data);
  },
  moveFromProjectFolder(data) {
    return axios.post('/video/projects/move-from-folder', data);
  },
  
  getProjects(params = {}) {
    return axios.get('/video/projects/all', {params})
  },
  getProjectsNew(params = {}) {
    return axios.post('/projects/create/getProjects', {params})
  },
  saveProject(data) {
    return axios.post('/video/projects', data)
  },
  copyProject(id, data = {}) {
    return axios.post(`/video/projects/${id}/copy`, data)
  },
  loadProject(id) {
    return axios.get(`/video/projects/${id}`)
  },
  deleteProject (id) {
    return axios.delete(`/video/projects/${id}`)
  },
  saveThumbnails (payload) {
    const formData = new FormData()
    payload.thumbnails.forEach(({ thumbnail, sceneNumber }) => {
      formData.append('thumbnails[]', thumbnail)
      formData.append('scenes[]', sceneNumber)
    })
    return axios.post(`/video/projects/${payload.id}/thumbnails`, formData)
  },
  base64ToFile(url, filename = 'temp') {
    const mimeType = (url.match(/^data:([^;]+);/)||'')[1];
    return fetch(url)
        .then((response) => response.arrayBuffer())
        .then(buffer => new File([buffer], `${filename}.${mimeType}`, { type: mimeType }));
  },
  clearThumbnails(id) {
    return axios.delete(`/video/projects/${id}/thumbnails`)
  },
  clearUpdateData(id) {
    return axios.delete(`/video/projects/${id}/update_data`)
  },
  attachTagToProject (projectId, tag) {
    return axios.post(`/video/projects/${projectId}/tag`, { tag })
  },
  detachTagFromProject (projectId, tag) {
    return axios.delete(`/video/projects/${projectId}/tag/${tag}`)
  },
  getAllTags () {
    return axios.get('/video/projects/tag')
  },
}
