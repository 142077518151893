<template>
  <b-modal
    id="replace-text-modal"
    title="Replace Text"
    ok-title="Replace"
    ok-only
    @ok="onReplaceText"
  >
    <b-form-row>
      <label class="m-0">Find Text</label>
      <b-form-input
        v-model="inputData.findText"
        autofocus
      />
    </b-form-row>
    <div class="my-3" />
    <b-form-row>
      <label class="m-0">Replace Text</label>
      <b-form-input v-model="inputData.replaceText" />
    </b-form-row>
    <div class="my-2" />
    <b-form-row>
      <b-form-checkbox
        v-model="inputData.hasMatchCase"
      >
        Match Case
      </b-form-checkbox>
      <div class="mx-2" />
      <b-form-checkbox
        v-model="inputData.hasMatchWholeWord"
      >
        Match Whole Word
      </b-form-checkbox>
    </b-form-row>
    <template #modal-footer>
      <b-btn
        variant="primary"
        :disabled="disabledReplaceBtn"
        @click="onReplaceText(true)"
      >
        Replace All
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import { replaceTextHandlerEventBroker, EVENTS } from '../../../../group/modules/replace-text/event-broker';

const DEFAULT_INPUT_DATA = {
  findText: '',
  replaceText: '',
  hasMatchCase: false,
  hasMatchWholeWord: false,
}

export default {
  data() {
    return {
      inputData: {
        ...DEFAULT_INPUT_DATA
      }
    }
  },
  computed: {
    disabledReplaceBtn() {
      return !this.inputData.findText || !this.inputData.replaceText;
    }
  },
  mounted() {
    replaceTextHandlerEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },
  methods: {
    init() {
      this.clear();
      this.$bvModal.show('replace-text-modal');
    },
    clear() {
      this.inputData = {
        ...DEFAULT_INPUT_DATA
      };
    },
    onReplaceText(hasReplaceAll) {
      replaceTextHandlerEventBroker.fire(EVENTS.REPLACE, {
        ...this.inputData,
        hasReplaceAll
      });
      this.$bvModal.hide('replace-text-modal');
    }
  }
}
</script>
