import {resolveImageInstance} from "../../../lib/fabric-helper";
import {fabric} from "fabric";
import {EXTRA_FEATURES} from "../../../lib/fabric-overrides";
import { parseJsonData } from "../../../services/helpers";
import { ALIGN_CONTENT, addPointByOrigin } from "../copy-and-resize/helpers";

export const ID_GROUP_MASK = 'group-mask';

const handleSpecificSelection = function (event) {
    const canvas = event.target.canvas

    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.__corner) {
        return;
    }

    const finder = (parent) => {
        const objects = parent
            .getObjects()
            .sort((a, b) => a.order < b.order ? -1 : 1)
            .filter(({specificType}) => specificType !== 'technic');

        for (const target of objects) {
            if (target.type === 'group') {
                const found = finder(target)
                if (found) {
                    return
                } else {
                    continue
                }
            }

            const pointer = event.pointer;

            const matrix = [...target.calcTransformMatrix()]

            const top = matrix.pop()
            const left = matrix.pop()

            const halfWidth = target.getScaledWidth() / 2;
            const halfHeight = target.getScaledHeight() / 2

            const widthCondition = pointer.x > left - halfWidth && pointer.x < left + halfWidth;
            const heightCondition = pointer.y > top - halfHeight && pointer.y < top + halfHeight;

            if (widthCondition && heightCondition) {
                canvas.setActiveObject(target)
                canvas._setupCurrentTransform(event, target, true)
                canvas.requestRenderAll()
                return true
            }
        }
    }
    finder(canvas)
}

export const maskFabricBuilder = async (masks, canvas, oImg) => {
    const { x: scaleX, y: scaleY, alignContent } = parseJsonData(
        oImg.manualResizeGroupsCoefficient,
        {
            x: 1,
            y: 1,
            alignContent: { originX: ALIGN_CONTENT.START, originY: ALIGN_CONTENT.START }
        }
    );

    const scale = Math.min(scaleX, scaleY);

    const groups = await Promise.all(masks.map(async ({ mask }) => {
        const maskInstance = await resolveImageInstance(mask.image)

        if (!mask.scaleY) {
            mask.scaleY = 1;
        }
        if (!mask.scaleX) {
            mask.scaleX = 1;
        }

        maskInstance.set({
            customId: 'maskInstance',
            width: mask.width,
            height: mask.height,
            top: mask.top * mask.scaleY,
            left: mask.left * mask.scaleX,
            specificType: 'technic',
            scaleX: mask.scaleX,
            scaleY: mask.scaleY,
        })

        const maskInside = new fabric.Rect({
            customId: 'maskInside',
            width: mask.width,
            height: mask.height,
            top: mask.top * mask.scaleY,
            left: mask.left * mask.scaleX,
            fill: mask.defaultColor,
            globalCompositeOperation: 'source-out',
            specificType: 'technic',
            scaleX: mask.scaleX,
            scaleY: mask.scaleY,
        })

        const out = new fabric.Rect({
            customId: 'out',
            width: canvas.width,
            height: canvas.height,
            top: 0,
            left: 0,
            fill: mask.defaultColor,
            specificType: 'technic',
            globalCompositeOperation: 'source-out',
            scaleX: mask.scaleX / scale,
            scaleY: mask.scaleY / scale,
        })

        return [
            maskInstance,
            maskInside,
            out
        ];
    }))

    const group = groups.flat()

    oImg.set({
        globalCompositeOperation: 'source-in',
        extraFeatures: [EXTRA_FEATURES.TRANSFORM_IN_GROUP],
    })

    group.push(oImg)

    const groupInstance = new fabric.Group(group)

    groupInstance.on('mousedown', handleSpecificSelection)
    const origins = ['right', 'bottom'];
    const points = groupInstance.getPointByOrigin(...origins);
    const additionPoint = addPointByOrigin({
        toDimension: {
            width: canvas.width,
            height: canvas.height,
        },
        fromDimension: {
            width: canvas.width / scaleX,
            height: canvas.height / scaleY,
        },
        scale,
        alignContent
    });
    
    groupInstance.set({
        id: ID_GROUP_MASK,
        order: oImg.order - 1,
        lockMovementX: true,
        lockMovementY: true,
        scaleX: scale,
        scaleY: scale,
    });
    groupInstance.setPositionByOrigin({
        x: points.x * scale + additionPoint.x,
        y: points.y * scale + additionPoint.y,
    }, ...origins);

    groupInstance.setCoords();
    
    return groupInstance
}