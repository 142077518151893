import { fabric } from 'fabric';

export default class Shapes {
  static CIRCLE = 'Circle';
  static ELLIPSE = 'Ellipse';
  static RECT = 'Rect';
  static TRIANGLE = 'Triangle';

  shape = null;
  color = '#000000'

  constructor({
    canvas,
    shapeType,
    x,
    y,
    color,
    strokeColor,
    strokeWidth,
    isStrokeShape,
  }) {
      this.canvas = canvas;
      this.shapeType = shapeType;
      this.pointerStartX = x;
      this.pointerStartY = y;
      this.color = color;
      this.stroke = strokeColor;
      this.strokeWidth = strokeWidth;
      this.isStrokeShape = isStrokeShape;
  }

  setStartPoint(x, y) {
    this.pointerStartX = x;
    this.pointerStartY = y;
  }

  setColor(color) {
    this.color = color;
  }

  createCircleShape() {
    this.shape = new fabric.Circle({
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: this.color,
      radius: 1,
      stroke: this.stroke,
      strokeWidth: this.strokeWidth,
      isStrokeShape: this.isStrokeShape,
    })

    this.canvas.add(this.shape);

    return this.shape;
  }

  drawCircleShape(event) {
    const pointer = this.canvas.getPointer(event);

    const distX = pointer.x - this.shape.left;
    const distY = pointer.y - this.shape.top;
    const distance = Math.sqrt(distX * distX + distY * distY);

    this.shape.set({ radius: distance });
    this.canvas.requestRenderAll();
  }

  createEllipseShape() {
    this.shape = new fabric.Ellipse({
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: this.color,
      rx: 0,
      ry: 0,
      stroke: this.stroke,
      strokeWidth: this.strokeWidth,
      isStrokeShape: this.isStrokeShape,
    });

    this.canvas.add(this.shape);
    
    return this.shape;
  }

  drawEllipseShape(event) {
    const pointer = this.canvas.getPointer(event);

    const distX = Math.abs(pointer.x - this.shape.left);
    const distY = Math.abs(pointer.y - this.shape.top);

    this.shape.set({ rx: distX, ry: distY });
    this.canvas.requestRenderAll();
  }

  createRectShape() {
    this.shape = new fabric.Rect({
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: this.color,
      width: 0,
      height: 0,
      stroke: this.stroke,
      strokeWidth: this.strokeWidth,
      isStrokeShape: this.isStrokeShape,
    });

    this.canvas.add(this.shape);

    return this.shape;
  }

  drawRectShape(event) {
    const pointer = this.canvas.getPointer(event);

    const distX = pointer.x - this.shape.left;
    const distY = pointer.y - this.shape.top;

    this.shape.set({ width: distX, height: distY });
    this.canvas.requestRenderAll();
  }

  createTriangleShape() {
    this.shape = new fabric.Triangle({
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: this.color,
      width: 0,
      height: 0,
      stroke: this.stroke,
      strokeWidth: this.strokeWidth,
      isStrokeShape: this.isStrokeShape,
    });

    this.canvas.add(this.shape);

    return this.shape;
  }

  drawTriangleShape(event) {
    const pointer = this.canvas.getPointer(event);

    const distX = pointer.x - this.shape.left;
    const distY = pointer.y - this.shape.top;

    this.shape.set({ width: distX, height: distY });
    this.canvas.requestRenderAll();
  }
  
  getShape() {
    return this.shape;
  }

  createShape() {
    switch(this.shapeType) {
      case Shapes.CIRCLE:
        return this.createCircleShape();
      case Shapes.ELLIPSE:
        return this.createEllipseShape();
      case Shapes.RECT:
        return this.createRectShape();
      case Shapes.TRIANGLE:
        return this.createTriangleShape();
    }
  }

  drawShape() {
    switch(this.shapeType) {
      case Shapes.CIRCLE:
        return this.drawCircleShape();
      case Shapes.ELLIPSE:
        return this.drawEllipseShape();
      case Shapes.RECT:
        return this.drawRectShape();
      case Shapes.TRIANGLE:
        return this.drawTriangleShape();
    }
  }
}