<template>
  <b-form-row>
    <b-col
      v-if="aspectRatioOption"
      cols="12"
      class="mb-2"
    >
      <b-checkbox
        v-model="keepAspectRatioValue"
        :disabled="target.locked"
      >
        Keep aspect ratio
      </b-checkbox>
    </b-col>

    <b-col cols="6">
      <b-form-group
        label="X"
        label-cols="2"
        :disabled="target.locked || !moveable"
      >
        <b-input
          :value="x.toFixed(2)"
          type="number"
          @input="onInput('x', $event)"
          @dblclick="selectValueOnDblclick"
        />
      </b-form-group>
    </b-col>

    <b-col cols="6">
      <b-form-group
        label="Y"
        label-cols="2"
        :disabled="target.locked || !moveable"
      >
        <b-input
          :value="y.toFixed(2)"
          type="number"
          @input="onInput('y', $event)"
          @dblclick="selectValueOnDblclick"
        />
      </b-form-group>
    </b-col>

    <b-col cols="6">
      <b-form-group
        label="W"
        label-cols="2"
        :disabled="target.locked"
      >
        <b-input
          :value="w.toFixed(2)"
          type="number"
          @input="onInput('w', $event)"
          @dblclick="selectValueOnDblclick"
        />
      </b-form-group>
    </b-col>

    <b-col cols="6">
      <b-form-group
        label="H"
        label-cols="2"
        :disabled="target.locked"
      >
        <b-input
          :value="h.toFixed(2)"
          type="number"
          @input="onInput('h', $event)"
          @dblclick="selectValueOnDblclick"
        />
      </b-form-group>
    </b-col>
  </b-form-row>
</template>

<script>
import { mapGetters } from 'vuex'
import mixinInput from "@frontend/components/input-mixins";

export default {
  name: 'PositionAndSize',

  mixins: [mixinInput],

  props: {
    x: {
      type: [String, Number],
      required: true,
    },
    y: {
      type: [String, Number],
      required: true,
    },
    w: {
      type: [String, Number],
      required: true,
    },
    h: {
      type: [String, Number],
      required: true,
    },
    aspectRatioOption: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters('layout', [
      'keepAspectRatio',
      'target',
    ]),
    keepAspectRatioValue: {
      get () {
        return this.keepAspectRatio
      },
      set (val) {
        this.$store.commit('layout/SET_KEEP_ASPECT_RATIO', val)
      },
    },
    aspectRatio () {
      return this.keepAspectRatio ? this.w / this.h : null
    },
    moveable () {
      if (this.target.name === 'Product Dimensions') return true;
      return !this.target.templateField || this.target.templateField.isMoveable()
    },
  },

  methods: {
    onInput: _.debounce(function (key, val) {
      this.$emit('input', key, val)
      if (this.aspectRatio && this.aspectRatioOption) {
        if (key === 'w') {
          // Width has changed, calculate new height based on aspect ratio and emit both changes
          const newHeight = val / this.aspectRatio
          this.$emit('input', 'h', newHeight.toFixed(2))
        } else if (key === 'h') {
          // Height has changed, calculate new width based on aspect ratio and emit both changes
          const newWidth = val * this.aspectRatio
          this.$emit('input', 'w', newWidth.toFixed(2))
        }
      }
    }, 1500),
  }
}
</script>

<style scoped lang="scss">

</style>
