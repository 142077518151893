var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button-toolbar",
        [
          _vm.showItemActions
            ? _c(
                "b-button-group",
                { staticClass: "mr-3" },
                [
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Show on canvas" },
                      on: { click: _vm.showOnCanvas },
                    },
                    [_c("i", { staticClass: "fas fa-crosshairs" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Replace" },
                      on: { click: _vm.handleReplace },
                    },
                    [_c("i", { staticClass: "fas fa-redo-alt" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: { title: "Delete" },
                      on: { click: _vm.handleDelete },
                    },
                    [_c("i", { staticClass: "fas fa-trash" })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mode !== _vm.MODES.VIEW
            ? _c(
                "b-button-group",
                { staticClass: "mr-3" },
                [
                  _c(
                    "ButtonOutline",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "cancel-button",
                      attrs: { title: "Cancel" },
                      on: { click: _vm.goBackToView },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x-circle-fill", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mode === _vm.MODES.VIEW,
                  expression: "mode === MODES.VIEW",
                },
              ],
              staticClass: "mr-3",
            },
            [
              _c(
                "ButtonOutline",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  class: _vm.isFolderViewEnabled ? "active" : "",
                  attrs: { title: "Folder view" },
                  on: { click: _vm.toggleFolderView },
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "folder-check", "aria-hidden": "true" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ButtonOutline",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  class: _vm.grouped ? "active" : "",
                  attrs: { title: "Grouped view" },
                  on: { click: _vm.toggleGroupMode },
                },
                [_c("i", { staticClass: "fas fa-layer-group" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ButtonOutline",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mode !== _vm.MODES.VIEW,
                  expression: "mode !== MODES.VIEW",
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              class: _vm.isFolderViewEnabled ? "active" : "",
              attrs: { title: "Folder view" },
              on: { click: _vm.toggleFolderView },
            },
            [
              _c("b-icon", {
                attrs: { icon: "folder-check", "aria-hidden": "true" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }