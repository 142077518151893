var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-item-overlay",
      class: { folder_overlay: _vm.item.type == "folder" },
    },
    [
      _vm.isAvailableEditAction && _vm.item.type != "folder"
        ? _c(
            "div",
            {
              staticClass: "c-item-overlay__item edit-item",
              on: { click: _vm.editItem },
            },
            [_c("i", { staticClass: "fa fa-edit" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "c-item-overlay__item delete-item",
          on: { click: _vm.deleteItem },
        },
        [
          _c("svg", { attrs: { width: "20px", height: "20px" } }, [
            _c("use", {
              attrs: { href: "/img/icons/sprite.svg#file-circle-close" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isAvailableEditAction && _vm.item.type == "folder"
        ? _c(
            "div",
            {
              staticClass: "c-item-overlay__item edit-item",
              on: { click: _vm.editItem },
            },
            [_c("i", { staticClass: "fa fa-edit" })]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }