import { fabric } from "fabric";

export const rectangleHandler = (defaultOptions) => (object, objectData) => {
    const left = objectData.left * object.width;
    const top = objectData.top * object.height;
    const width = objectData.right * object.width;
    const height = objectData.bottom * object.height;

    return new fabric.Rect({
        ...defaultOptions,
        left: left - ((object.width - objectData.right * object.width) / 2),
        top: top - ((object.height - objectData.bottom * object.height) / 2),
        width,
        height,
        objectData: objectData
    });
}