var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("b-form-input", { attrs: { readonly: "", value: _vm.value } }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasShowCustomNameInput,
                expression: "hasShowCustomNameInput",
              },
            ],
            staticClass: "form-group input-group",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customName,
                  expression: "customName",
                },
              ],
              staticClass: "form-control with-group-append",
              domProps: { value: _vm.customName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.customName = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-group-append" },
              [
                _c(
                  "b-btn",
                  {
                    attrs: { variant: "primary", disabled: !_vm.customName },
                    on: { click: _vm.onAddCustomName },
                  },
                  [_vm._v("\n          Add\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.selectedNamesFromTabs.length
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  title:
                    "You can select a tag and replace it with another selection by clicking the tag and choosing a new option.",
                },
              },
              [
                _c("img", {
                  staticClass: "custom-tooltip-icon",
                  attrs: {
                    src: "/img/icons/info.png",
                    alt: "Show variables",
                    width: "20",
                    height: "20",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.selectedNamesFromTabs, function (name) {
          return _c(
            "b-form-tag",
            {
              key: name.id,
              staticClass: "mr-1",
              attrs: {
                title: `${name.tabName} - ${name.value}`,
                variant: _vm.getTabVariant(name),
              },
              on: {
                remove: function ($event) {
                  return _vm.onRemoveTag(name)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.onSelectTag(name)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(name.tabName) +
                      " - " +
                      _vm._s(name.value) +
                      "\n      "
                  ),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3" }),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("Multiselect", {
            attrs: {
              options: _vm.namingDesignTabs,
              searchable: "",
              placeholder: "Select naming tab",
              label: "name",
            },
            on: { select: _vm.onSelectedNamingDesignTab },
            model: {
              value: _vm.selectedNamingDesignTab,
              callback: function ($$v) {
                _vm.selectedNamingDesignTab = $$v
              },
              expression: "selectedNamingDesignTab",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("SelectNamingValueDropdown", {
            attrs: {
              text: "Select Name",
              "naming-design-tab": _vm.selectedNamingDesignTab,
              "naming-values-of-tab": _vm.getNameOfTab,
              disabled: _vm.disabledSelectName,
              "has-choose-variables": _vm.hasChooseVariables,
            },
            on: { onSelectName: _vm.onSelectName },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedTab && _vm.selectedTab.hasVariable,
              expression: "selectedTab && selectedTab.hasVariable",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c("SelectNamingValueDropdown", {
            attrs: {
              text: "Select Default Name for Variable",
              "naming-design-tab": _vm.selectedNamingDesignTab,
              "naming-values-of-tab": _vm.getNameOfTab,
              disabled: _vm.disabledSelectName,
            },
            on: { onSelectName: _vm.onSelectDefaultNameForVariable },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }