<template>
  <div>
    <b-button-toolbar>
      <b-button-group
        v-if="showItemActions"
        class="mr-3"
      >
        <ButtonOutline
          v-b-tooltip.hover
          title="Show on canvas"
          @click="showOnCanvas"
        >
          <i class="fas fa-crosshairs" />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover 
          title="Replace"
          @click="handleReplace"
        >
          <i class="fas fa-redo-alt" />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover 
          title="Delete"
          @click="handleDelete"
        >
          <i class="fas fa-trash" />
        </ButtonOutline>
      </b-button-group>
      
      <b-button-group
        v-if="mode !== MODES.VIEW"
        class="mr-3"
      >
        <ButtonOutline 
          v-b-tooltip.hover 
          title="Cancel"
          class="cancel-button"
          @click="goBackToView"
        >
          <b-icon
            icon="x-circle-fill"
            aria-hidden="true"
          />
        </ButtonOutline>
      </b-button-group>
      
      <b-button-group
        v-show="mode === MODES.VIEW"
        class="mr-3"
      >
        <ButtonOutline
          v-b-tooltip.hover
          title="Folder view"
          :class="isFolderViewEnabled ? 'active' : ''"
          @click="toggleFolderView"
        >
          <b-icon
            icon="folder-check"
            aria-hidden="true"
          />
        </ButtonOutline>
        <ButtonOutline
          v-b-tooltip.hover
          title="Grouped view"
          :class="grouped ? 'active' : ''"
          @click="toggleGroupMode"
        >
          <i class="fas fa-layer-group" />
        </ButtonOutline>
      </b-button-group>

      <ButtonOutline
        v-show="mode !== MODES.VIEW"
        v-b-tooltip.hover
        title="Folder view"
        :class="isFolderViewEnabled ? 'active' : ''"
        @click="toggleFolderView"
      >
        <b-icon
          icon="folder-check"
          aria-hidden="true"
        />
      </ButtonOutline>
    </b-button-toolbar>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {MODES} from "@frontend/components/modules/file-browser-product-selector/constants";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";
import common from "@frontend/components/modules/file-browser-product-selector/common";

export default {
  name: "ToolBar",
  components: {ButtonOutline},
  mixins: [
    common
  ],
  data: () => ({}),
  computed: {
    MODES() {
      return MODES
    },
    ...mapGetters('fileBrowserProductSelector', [
      'firstSelectedItem',
      'isAnySelected',
      'isAnyPicked',
      'isFolderViewEnabled',
      'showOnlySelected',
      'isTopBarSwitchersEnabled',
      'mode',
      'grouped',
      'selected',
      'selectedItems',
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    showItemActions() {
      if (this.grouped) {
        return false;
      }
      return this.mode === MODES.VIEW && this.firstSelectedItem && this.firstSelectedItem.type !== 'folder'
    }
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions('fileBrowserProductSelector', [
      'setMode', 
      'enableReplaceMode', 
      'toggleFolderView', 
      'goBackToView', 
      'toggleGrouped'
    ]),
    toggleGroupMode() {
      this.toggleGrouped();
    }
  }
}
</script>

<style scoped lang="scss">
.save-button {
  border-color: #28a745;
  border-radius: 8px;
  background: #28a745;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.cancel-button {
  color: #f25d5d;
}
</style>
