var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        label: "Write the topic/occasion for the suggested headline",
        "content-cols": "12",
      },
    },
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            model: {
              value: _vm.chatGPTInput,
              callback: function ($$v) {
                _vm.chatGPTInput = $$v
              },
              expression: "chatGPTInput",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    disabled: !_vm.chatGPTInput || _vm.isLoading,
                  },
                  on: { click: _vm.onGenerate },
                },
                [
                  _vm.isLoading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : [
                        _c("svg", { staticClass: "w-6 h-6" }, [
                          _c("use", {
                            attrs: { href: "/img/icons/sprite.svg#chat-gpt" },
                          }),
                        ]),
                        _vm._v("\n          Suggest\n        "),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }