import {editorControlEventBroker, EVENTS} from "@frontend/group/modules/editor-control-popup/event-broker";

export default {
  methods: {
    showOnCanvas() {
      const canvas = this.selectedInstance.canvas;

      const target = canvas.getObjects()
        .find((obj) => obj.id === this.selected[0])

      const simulatedEvent = {target: canvas};
      this.selectedInstance.canvas.fire('selection:created', { e: simulatedEvent, target });
      canvas.setActiveObject(target);
      canvas.renderAll();
    },
    handleReplace() {
      this.showOnCanvas();
      this.enableReplaceMode();
    },
    handleConfirmReplace() {
      this.selectedInstance.canvas.fire('product-images-selector:replace-product-image', this.selectedInstance.id);
    },
    handleDelete() {
      this.showOnCanvas();
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, {
        action: 'deleteConfirmation', value: null
      })
    }
  }
}
