import mediaAPI from '../services/api/media'

export default {
  namespaced: true,

  state: () => ({
    stock: [],
    stock_pagination: {
      stock_page: 1,
      stock_total_elements: 0,
      stock_total_pages: 0,
    },
    current_page: 1,
    from: 0,
    last_page: 0,
    selected: [],
  }),

  getters: {
    stock: state => state.stock.map(item => {
      item.selected = _.some(state.selected, ['path', item.path])
      return item
    }),
  },

  mutations: {
    SET_ALL_DATA (state, payload) {
      state.stock = payload.stock
      state.stock_pagination = payload.stock_pagination
      state.current_page = payload.current_page
      state.from = payload.from
      state.last_page = payload.last_page
    },
    SET_CURRENT_PAGE (state, payload) {
      state.current_page = payload
    },
    ADD_SELECTED (state, payload) {
      state.selected.push(payload)
    },
    DELETE_SELECTED (state, payload) {
      const index = _.findIndex(state.selected, ['path', payload.path])
      if (index !== -1) {
        state.selected.splice(index, 1)
      }
    },
    CLEAR_SELECTION (state) {
      state.selected.splice(0, state.selected.length)
    },
  },

  actions: {
    async fetchBackgroundStockImages ({ commit }, payload) {
      const { data } = await mediaAPI.getBackgroundStockImages(payload)
      commit('SET_ALL_DATA', data)
    },
    async toggleSelection ({ commit }, payload) {
      if (payload.selected) {
        commit('DELETE_SELECTED', payload)
      } else {
        commit('ADD_SELECTED', payload)
      }
    },
  },
}
