var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      staticClass: "w-100 select-name-dropdown",
      attrs: {
        text: _vm.text,
        variant: "primary",
        disabled:
          _vm.disabled ||
          (!_vm.getNamingValuesOfTab.length && !_vm.searchNamingValueOfTab),
      },
    },
    [
      _c(
        "b-dropdown-header",
        { attrs: { id: "dropdown-header-label" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.searchNamingValueOfTab,
              callback: function ($$v) {
                _vm.searchNamingValueOfTab = $$v
              },
              expression: "searchNamingValueOfTab",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.getNamingValuesOfTab.length
        ? _vm._l(_vm.getNamingValuesOfTab, function (name) {
            return _c(
              "b-dropdown-item-button",
              {
                key: name.id,
                staticClass: "text-truncate",
                on: {
                  click: function ($event) {
                    return _vm.$emit("onSelectName", name)
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      name.name +
                        (name.name !== name._value ? ` - (${name._value})` : "")
                    ) +
                    "\n    "
                ),
              ]
            )
          })
        : _c("b-dropdown-item-button", { attrs: { disabled: "" } }, [
            _vm._v("\n    List is empty\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }