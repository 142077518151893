<template>
  <b-dropdown
    variant="outline-light"
    :disabled="disabled"
    @hide="onHide"
  >
    <template #button-content>
      <slot />
    </template>

    <h6 class="dropdown-header">
      Line Height
    </h6>
    <button
      v-for="option in options"
      :key="option.value"
      :class="{ active: option.value === value }"
      class="dropdown-item"
      type="button"
      @click="onChange(option.value)"
    >
      {{ option.text }}
    </button>
    <div class="mx-3 mt-2">
      <b-form-input
        v-model.number="lineHeight"
        size="sm"
        type="number"
        min="0.01"
        step="0.01"
        max="2"
      />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      options: [
        {
          text: 'Single',
          value: 1,
        },
        {
          text: '1.15',
          value: 1.15,
        },
        {
          text: '1.5',
          value: 1.5,
        },
        {
          text: 'Double',
          value: 2,
        },
      ],
      visibleValue: this.value,
    }
  },

  computed: {
    lineHeight: {
      get () {
        return this.visibleValue
      },
      set (val) {
        this.visibleValue = val
        if (!val) {
          return
        }
        this.onChange(val)
      }
    }
  },

  methods: {
    onChange (val) {
      this.visibleValue = val
      this.$emit('input', +val || 1)
    },
    onHide () {
      this.lineHeight = +this.visibleValue || 1
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .dropdown-toggle {
    padding: 2px 5px 4px 7px;
    color: #000000;
  }

  label {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    color: #464646;
    font-weight: 500;
    line-height: 24px;
    cursor: inherit;
  }

  .input-group-text {
    cursor: pointer;
    color: #000000;
  }

  .dropdown-menu {
    min-width: 10em;

    a.active:hover {
      background-color: var(--blue);
    }
  }
}
</style>
