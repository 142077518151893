<template>
  <b-modal
    ref="modal"
    v-model="isModalOpened"
    size="xl"
    centered
    @hidden="handleModalClose"
  >
    <template #modal-title>
      {{ title }}
    </template>
    
    <template #modal-footer>
      <ButtonPrimary
        variant="primary"
        :disabled="isConfirmDisabled"
        @click="confirm"
      >
        {{ fileBrowserModalType }}
      </ButtonPrimary>
      <ButtonOutline
        variant="outline-primary"
        @click="isModalOpened=false"
      >
        Cancel
      </ButtonOutline>
    </template>
    <layout-folder-browser />
  </b-modal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import ButtonOutline from "@frontend/components/common/atoms/ButtonOutline.vue";
import ButtonPrimary from "@frontend/components/common/atoms/ButtonPrimary.vue";
import {bus} from "@frontend/app";

const { mapActions, mapGetters } = createNamespacedHelpers('layoutFolderBrowser')
export default {
  name: "LayoutFolderBrowserModal",
  components: {ButtonOutline, ButtonPrimary},
  data() {
    return {
      isModalOpened: true,
    }
  },
  computed: {
    ...mapGetters(['isFileBrowserModalOpened', 'fileBrowserModalType', 'selectedFolders', 'selectedCount']),
    title() {
      return this.fileBrowserModalType + ' to folder' + (this.fileBrowserModalType === 'copy' ? 's' : '');
    },
    isConfirmDisabled() {
      let isDisabled = true;
      
      if (this.selectedFolders.length > 0 && this.selectedFolders.length === this.selectedCount) {
        isDisabled = false;
      }
      
      return isDisabled;
    }
  },
  watch: {
    isFileBrowserModalOpened: {
      immediate: true,
      handler(newValue) {
        this.isModalOpened = newValue;
      },
    },
    isModalOpened(newValue) {
      this.setIsFileBrowserModalOpened(newValue);
    }
  },
  methods: {
    ...mapActions(['setIsFileBrowserModalOpened', 'disableMultiselect']),
    confirm() {
      bus.$emit('folderActionConfirmed', this.fileBrowserModalType);
      this.setIsFileBrowserModalOpened(false);
    },
    handleModalClose() {
      this.disableMultiselect();
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .modal-xl {
    max-width: 80%;
  }
  .c-main-view__container {
    min-height: 500px !important;
  }
}
</style>
