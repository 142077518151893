const LAST_CUSTOM_TEMPLATE_SETTINGS = 'last-custom-template-settings';
const LAYOUTS_PAGE_VIEW = 'layouts-page-view';
export const TABLE_VIEW = 'table-view';
export const THUMBNAIL_VIEW = 'thumbnail-view';

export const localStorageService = {
  setCustomTemplateSettings: (data) => {
    localStorage.setItem(LAST_CUSTOM_TEMPLATE_SETTINGS, JSON.stringify(data));
  },

  getCustomTemplateSettings: () => {
    return JSON.parse(localStorage.getItem(LAST_CUSTOM_TEMPLATE_SETTINGS));
  },
  
  setLayoutsPageView: (currentView) => {
    localStorage.setItem(LAYOUTS_PAGE_VIEW, currentView);
  },
  
  getLayoutsPageView: () => {
    return localStorage.getItem(LAYOUTS_PAGE_VIEW);
  }
}
