var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "mb-3",
    },
    [
      _c("img", { ref: "image", staticStyle: { "max-height": "400px" } }),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [
              _c("b-spinner", { attrs: { variant: "primary" } }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v("\n      Loading...\n    "),
              ]),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("b-form-input", {
                staticClass: "my-3",
                model: {
                  value: _vm.filename,
                  callback: function ($$v) {
                    _vm.filename = $$v
                  },
                  expression: "filename",
                },
              }),
              _vm._v(" "),
              _c(
                "b-input-group",
                { staticClass: "align-items-center mb-3" },
                [
                  _c("b-form-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.width,
                      callback: function ($$v) {
                        _vm.width = _vm._n($$v)
                      },
                      expression: "width",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-icon", {
                    staticClass: "mx-2",
                    attrs: { icon: "x-lg" },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.height,
                      callback: function ($$v) {
                        _vm.height = _vm._n($$v)
                      },
                      expression: "height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    disabled: _vm.isSaving || !_vm.filename,
                  },
                  on: { click: _vm.onSave },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.isSaving ? "Saving..." : "Save") +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.cropperInstance.reset()
                    },
                  },
                },
                [_vm._v("\n      Reset\n    ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onCancel")
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }