import {PARSED_FIELD_TYPES} from "@/includes/parse_psd_data/constants";
import MultiLineText from "@/includes/parse_psd_data/psd-types/multi-line-text";
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";
import Template from "@/includes/parse_psd_data/template";
import UserText from "@/includes/parse_psd_data/psd-types/user-text";
import UserImage from "@/includes/parse_psd_data/psd-types/user-image";
import {Folder} from "@/includes/parse_psd_data/psd-types/folder";

export function getInstanceByType(field) {
  switch (field.type) {
    case PARSED_FIELD_TYPES.MULTI_LINE_TEXT:
      return new MultiLineText(field);
    case PARSED_FIELD_TYPES.BACKGROUND_IMAGE:
      return new BackgroundImage(field);
    case PARSED_FIELD_TYPES.FOLDER:
      return new Folder(field);
    case PARSED_FIELD_TYPES.TEMPLATE:
      return new Template(field)
    case PARSED_FIELD_TYPES.USER_TEXT:
      return new UserText(field);
    case PARSED_FIELD_TYPES.USER_IMAGE:
      return new UserImage(field);
    default:
      return undefined;
  }
}