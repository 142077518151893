<template>
  <div
    class="c-item-overlay"
  />
</template>


<script>
import common from "@frontend/components/modules/file-browser-product-selector/common";

export default {
  name: "ItemOverlay",
  components: {},
  mixins: [
    common
  ],
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">
.c-item-overlay {
  margin-top: 3px;
  padding: 2px;
  display: flex;
  align-items: end;

  &__item {
    padding-right: 3px;
    cursor: pointer;
  }
}
</style>
