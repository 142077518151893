import BaseElement from './BaseElement';

export default class TableRow extends BaseElement {

  cells = [];

  constructor(cells = []) {
    super();

    this.cells = cells;
  }

  setCells(cells) {
    this.cells = cells;
  }

  addCell(cell) {
    this.cells.push(cell);
  }

  toHTML() {
    return `<tr ${this.getStyleAttribute()}>${this.cells.map(cell => cell.toHTML()).join('')}</tr>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      cells: this.cells.map(cell => cell.toData())
    }
  }
}