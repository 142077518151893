<template>
  <b-modal
    id="rename-catalog-modal"
    title="Rename"
    centered
    @ok="accept"
  >
    <b-form-input v-model="name" />
  </b-modal>
</template>

<script>
import CatalogService from "@frontend/services/api/catalogs";

export default {
  name: 'RenameCatalogModal',
  props: {
    catalog: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    name: ''
  }),
  watch: {
    catalog(value) {
      this.name = value.name;
    }
  },
  methods: {
    async accept() {
      try {
        await CatalogService.update(this.catalog.id, {
          name: this.name
        });
        
        this.$emit('nameUpdated');
      } catch (error) {
        toastr.error('Something went wrong while renaming the catalog')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
