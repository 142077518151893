var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mb-2" }, [_vm._v("\n    Group:\n  ")]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Background", "label-cols": "3" } },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("ColorPicker", {
                attrs: { value: _vm.bgColor, themes: _vm.themes },
                on: {
                  input: function ($event) {
                    return _vm.onChange("bgColor", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "mx-2",
                  attrs: {
                    variant: "primary",
                    size: "sm",
                    title: "Copy hex color",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.copyToClipboard(_vm.target.fill)
                    },
                  },
                },
                [_c("i", { staticClass: "cil-copy" })]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-form-group", { attrs: { label: "Stroke", "label-cols": "3" } }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("ColorPicker", {
              attrs: { value: _vm.strokeColor, themes: _vm.themes },
              on: {
                input: function ($event) {
                  return _vm.onChange("strokeColor", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "mx-2",
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Copy hex color",
                },
                on: {
                  click: function ($event) {
                    return _vm.copyToClipboard(_vm.target.stroke)
                  },
                },
              },
              [_c("i", { staticClass: "cil-copy" })]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  variant: "primary",
                  size: "sm",
                  title: "Use background color",
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange("strokeColor", _vm.target.fill)
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "back" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Stroke width", disabled: _vm.target.locked } },
        [
          _c(
            "b-input-group",
            {
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("b-input", {
                        attrs: {
                          value: _vm.strokeWidth,
                          min: 0,
                          max: _vm.maxStrokeWidth,
                          type: "number",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onChange("thickness", $event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  value: _vm.strokeWidth,
                  min: 0,
                  max: _vm.maxStrokeWidth,
                  type: "range",
                },
                on: {
                  input: function ($event) {
                    return _vm.onChange("thickness", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: {
          h: _vm.target.height * _vm.target.scaleY,
          w: _vm.target.width * _vm.target.scaleX,
          x: _vm.target.left,
          y: _vm.target.top,
        },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.onChange(_vm.isCustomGroup ? "ungroup" : "group", null)
            },
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.isCustomGroup ? "Ungroup" : "Group") + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isTextObjects
        ? _c("ApplyToAllTextDropdown", {
            attrs: { options: _vm.options, target: _vm.target },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isProductImageObjects
        ? _c("ApplyToAllImageDropdown", {
            staticClass: "mt-3",
            attrs: { target: _vm.target },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }