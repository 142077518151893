import {FIELD_TYPES as BASIC_FIELD_TYPES} from '@frontend/constants/field-types'
export const PARSED_FIELD_TYPES = {
    MULTI_LINE_TEXT: 'MultiLineText',
    TEXT: 'Text',
    BACKGROUND_IMAGE: 'BackgroundImage',
    FOLDER: 'Folder',
    TEMPLATE: 'Template',
    USER_TEXT: 'UserText',
    USER_IMAGE: 'UserImage',
}

export const FIELD_TYPES = {
    TEXT: 'Text',
    MULTI_LINE_TEXT: 'Multi Line Text',
    BACKGROUND_IMAGE: 'Background Theme Image'
}

export const PSD_LAYER_TYPE_TO_BASIC_MAP = {
    [PARSED_FIELD_TYPES.TEXT]: BASIC_FIELD_TYPES.TEXT,
    [PARSED_FIELD_TYPES.MULTI_LINE_TEXT]: BASIC_FIELD_TYPES.TEXT,
    [PARSED_FIELD_TYPES.BACKGROUND_IMAGE]: BASIC_FIELD_TYPES.IMAGE,
}

export const PSD_LAYERS_DATA_TYPES = [
  PARSED_FIELD_TYPES.TEXT,
  PARSED_FIELD_TYPES.MULTI_LINE_TEXT,
  PARSED_FIELD_TYPES.BACKGROUND_IMAGE
]
