var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "wrapper",
      attrs: {
        name: "svg-image-type-modal",
        draggable: ".drag-area",
        height: "auto",
        "shift-x": 0.5,
        "shift-y": 0.5,
        "click-to-close": false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drag-area" },
        [
          _c(
            "b-button",
            {
              staticClass: "close-btn",
              attrs: { variant: "light" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon", {
                staticClass: "close-icon",
                attrs: { icon: "x-lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-area" }, [
        _vm._v("\n    Select how the image will be uploaded\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control-area" }, [
        _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-end justify-content-end" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.apply(_vm.asBackground)
                    },
                  },
                },
                [_vm._v("\n          As background\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.apply(_vm.asImage)
                    },
                  },
                },
                [_vm._v("\n          As image\n        ")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }