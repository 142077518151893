import {fabric} from "fabric";
window.isPositioningLinesDisabled = true;
//original y constants
const ORIGINAL_POINT_Y_TOP = 'top';
const ORIGINAL_POINT_Y_CENTER = 'center';
const ORIGINAL_POINT_Y_BOTTOM = 'bottom';
//original x constants
const ORIGINAL_POINT_X_LEFT = 'left';
const ORIGINAL_POINT_X_CENTER = 'center';
const ORIGINAL_POINT_X_RIGHT = 'right';
const DEFAULT_CENTERING_AREA = 5;
export const H_LINE_ID = 'h-template-center-line';
export const V_LINE_ID = 'v-template-center-line';
export const ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID = 'align-top-between-objects-line';
export const ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID = 'align-bottom-between-objects-line';
export const ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID = 'align-left-between-objects-line';
export const VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS = 'vertical-line-of-intersect_id_center_of_objects';
export const HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS = 'horizontal-line-of-intersect_id_center_of_objects';
export const ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID = 'align-right-between-objects-line';
const PIXEL_BACKGROUND_ID = 'pixel_background';
const CANVAS_TEMPLATE_RECTANGLE = 'canvas_template_rectangle';


export const POSITIONING_LINES = [
  ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
  H_LINE_ID,
  V_LINE_ID,
  HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
  VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS
];

const DEFAULT_LINES_OPTIONS = {
  strokeDashArray: [10, 10],
  stroke: 'black',
  order: 0,
  opacity: 0,
  strokeWidth: 5,
  selectable: false,
  moveble: false,
};

let currentCanvas;
function initDisabling() {
  if (!localStorage.hasOwnProperty('isPositioningLinesDisabled')) {
    return
  }
  window.isPositioningLinesDisabled = !!localStorage.getItem('isPositioningLinesDisabled');
  window.isPositioningLinesDisabled ? $('#snap-to-guidelines').removeAttr('checked') : $('#snap-to-guidelines').attr('checked', 'checked');
}
function hideLines(except = false) {
  if (isPositioningLinesDisabled) {
    return
  }
  for (let line of POSITIONING_LINES) {
    if (Array.isArray(except) && except.includes(line)) continue;
    getLineById(this, line).set({opacity: 0});
  }
}


let computedTop = undefined;
let computedLeft = undefined;
let isCheckingCenterVertical = false;
function alignCenterCanvas(e) {
  if (isPositioningLinesDisabled) {
    return
  }
  const $canvasButton = $('#preview-popup .canvas-button.preview-control')
  const isCanvasOrCanvasRectangleMode = $canvasButton.length && ($canvasButton.hasClass('canvas') || $canvasButton.hasClass('canvas-template-rectangle'));


  const {x: objectCenterX, y: objectCenterY} = e.target.getPointByOrigin('center', 'center');
  let canvasCenterX = this.getCenter().left;
  let canvasCenterY = this.getCenter().top;

  if (isCanvasOrCanvasRectangleMode) {
    canvasCenterX = template_data.width / 2;
    canvasCenterY = template_data.height / 2;
  }
  /*
  * this condition displays a horizontal line and fixes the element in the center of the y-axis 
  * otherwise hides it 
  */
  const actionAreaOffset = canvasCenterY - objectCenterY;
  if (Math.abs(actionAreaOffset) <= DEFAULT_CENTERING_AREA) {
    getLineById(this, H_LINE_ID).set({opacity: 1}).bringToFront();
    e.target.setPositionByOrigin({x: e.target.getPointByOrigin().x, y: canvasCenterY}, 'center', 'center');
  } else {
    computedTop = undefined;
    getLineById(this, H_LINE_ID).set({opacity: 0})
  }
  /*
  * this condition displays a vertical line and fixes the element in the center of the x-axis 
  * otherwise hides it
  */

  if (Math.abs(canvasCenterX - objectCenterX) <= DEFAULT_CENTERING_AREA) {
    getLineById(this, V_LINE_ID).set({opacity: 1}).bringToFront();
    e.target.setPositionByOrigin({x: canvasCenterX, y: e.target.getPointByOrigin().y}, 'center', 'center');
    isCheckingCenterVertical = true;
  } else {
    computedLeft = undefined;
    getLineById(this, V_LINE_ID).set({opacity: 0});
    isCheckingCenterVertical = false;
  }
}

function getLineById(canvas, id) {
  return canvas.getObjects().find(obj => obj.id === id);
}

function minTLPointX(objs) {
  return Math.min(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'min', 'x');
  }));
}

function maxTRPointX(objs) {
  return Math.max(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'max', 'x');
  }));
}

function maxBLPointX(objs) {
  return Math.max(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'max', 'x');
  }));
}

function minTLPointY(objs) {
  return Math.min(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'min', 'y');
  }));
}

function maxTRPointY(objs) {
  return Math.max(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'max', 'y');
  }));
}

function maxBLPointY(objs) {
  return Math.max(...objs.map(function (obj) {
    return getMinOrMaxObjectAxisValue(obj, 'max', 'y');
  }));
}
function getObjectAxisCenterValue(object, axisKey) {
  const objectTop = getMinOrMaxObjectAxisValue(object, 'min', axisKey);
  const objectBottom = getMinOrMaxObjectAxisValue(object, 'max', axisKey);
  return objectTop + (objectBottom - objectTop) / 2
}
function getMinOrMaxObjectAxisValue(object, minMaxKey, axisKey) {
  let tops = [];
  
  const x = ['left', 'center', 'right'];
  const y = ['top', 'center', 'bottom'];
  for (let sideX of x) {
    for (let sideY of y) {
      tops.push(object.getPointByOrigin(sideX, sideY)[axisKey]);
    }
  }
  return Math[minMaxKey](...tops);
}

let resultTop = undefined;

function alignTopBetweenObjects(e) {
  if (isPositioningLinesDisabled) {
    return
  }
  const allObjects = [...this.getObjects()]
    // .sort(function (a, b) {
    //   return getMinOrMaxObjectAxisValue(a, 'min', 'x') - getMinOrMaxObjectAxisValue(b, 'max', 'x');
    // });
  const objectsWithTheSameTop = [];
  const objectsWithTheSameCenter = [];
  const objectsWithTheSameBottom = [];
  const objectTop = getMinOrMaxObjectAxisValue(e.target, 'min', 'y');
  const objectBottom = getMinOrMaxObjectAxisValue(e.target, 'max', 'y');
  const objectCenter = getObjectAxisCenterValue(e.target, 'y');

  let isAligned = false;
  for (let obj of allObjects) {
    if (objectsWithTheSameTop.length ||
      objectsWithTheSameCenter.length ||
      objectsWithTheSameBottom.length) break;
    if (e.target === obj
      || [
        H_LINE_ID,
        V_LINE_ID,
        ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
        HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        PIXEL_BACKGROUND_ID,
        CANVAS_TEMPLATE_RECTANGLE
      ].includes(obj.id)) {
      continue;
    }

    if (Math.abs(getMinOrMaxObjectAxisValue(obj, 'min', 'y') - objectTop) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameTop.push(obj);
    }
    if (Math.abs(getObjectAxisCenterValue(obj, 'y') - objectCenter) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameCenter.push(obj);
    }
    if (Math.abs(getMinOrMaxObjectAxisValue(obj, 'max', 'y') - objectBottom) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameBottom.push(obj);
    }
  }
  objectsWithTheSameTop.sort(function (a, b) {
    return a.aCoords.tl.y - b.aCoords.tl.y;
  });
  for (let i = 0; i < objectsWithTheSameTop.length; i++) {
    if (getMinOrMaxObjectAxisValue(objectsWithTheSameTop[i], 'min', 'y') === objectTop) {
      isAligned = true;
      break;
    }
  }
  const line = getLineById(this, ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID);
  const line2 = getLineById(this, ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID);

  if (objectsWithTheSameTop.length || objectsWithTheSameCenter.length || objectsWithTheSameBottom.length) {
    const objects = objectsWithTheSameTop.length
      ? objectsWithTheSameTop
      : objectsWithTheSameCenter.length
        ? objectsWithTheSameCenter
        : objectsWithTheSameBottom;
    let x1 = minTLPointX([...objects, e.target]);
    let x2 = maxTRPointX([...objects, e.target]);
    const nearestTop = objects.length
      ? (objectsWithTheSameTop.length
        ? getMinOrMaxObjectAxisValue(objects[0], 'min', 'y')
        : objectsWithTheSameCenter.length
          ? getObjectAxisCenterValue(objects[0], 'y')
          : getMinOrMaxObjectAxisValue(objects[0], 'max', 'y'))
      : false;

    if (nearestTop) {
      const objectOriginY = e.target.getPointByOrigin('left', 'top').y;
      if (objectsWithTheSameTop.length && !objectsWithTheSameCenter.length && !objectsWithTheSameBottom.length) {
        switch (e.target.originY) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultTop)
              resultTop = nearestTop + e.target.getBoundingRect().height / 2;
            break;
          case ORIGINAL_POINT_Y_BOTTOM:
            if (!resultTop)
              resultTop = nearestTop + e.target.getScaledHeight();
            break;
          default:
            if (!resultTop)
              resultTop = nearestTop
                + (objectOriginY - objects[0].getBoundingRect().top) //offset
                + (getMinOrMaxObjectAxisValue(objects[0], 'min', 'y') - objectTop) //action area distance
        }
      }
      if (objectsWithTheSameCenter.length && !objectsWithTheSameTop.length && !objectsWithTheSameBottom.length) {
        const halfHeightBoundingRect = objects[0].getBoundingRect().height / 2;
        switch (e.target.originY) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultTop)
              resultTop = nearestTop;
            break;
          case ORIGINAL_POINT_Y_BOTTOM:

            break;
          default:
            if (!resultTop)
              resultTop = nearestTop
                - halfHeightBoundingRect
                + (objectOriginY - objects[0].getBoundingRect().top) //offset between object and object`s bounding rect points(left&Top)
                + (getObjectAxisCenterValue(objects[0], 'y') - objectCenter);
        }
      }
      if (objectsWithTheSameBottom.length && !objectsWithTheSameTop.length && !objectsWithTheSameCenter.length) {
        switch (e.target.originY) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultTop)
              resultTop = nearestTop - e.target.getBoundingRect().height / 2;
            break;
          case ORIGINAL_POINT_Y_BOTTOM:
            if (!resultTop)
              resultTop = nearestTop + e.target.getScaledHeight();
            break;
          default:
            if (!resultTop)
              resultTop = nearestTop - objects[0].getBoundingRect().height
                + (objectOriginY - objects[0].getBoundingRect().top)
                + (getMinOrMaxObjectAxisValue(objects[0], 'max', 'y') - objectBottom);
        }
      }
      if (objectsWithTheSameBottom.length && objectsWithTheSameTop.length && objectsWithTheSameCenter.length) {
        line2.set({
          top: nearestTop + e.target.getBoundingRect().height,
          left: x1,
          opacity: 1,
          width: x2 - x1
        });
        switch (e.target.originY) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultTop)
              resultTop = nearestTop + e.target.getBoundingRect().height / 2;
            break;
          case ORIGINAL_POINT_Y_BOTTOM:
            if (!resultTop)
              resultTop = nearestTop + e.target.getScaledHeight();
            break;
          default:
            if (!resultTop)
              resultTop = nearestTop
                + (objectOriginY - objects[0].getBoundingRect().top)
                + (getMinOrMaxObjectAxisValue(objects[0], 'max', 'y') - objectBottom);
        }
        line2.bringToFront();
      }
        
      if (resultTop) {
          e.target.set({top: resultTop});
          e.target.setCoords();
          this.renderAll();
      }
     
    }
    const obj1 = objects[0].getBoundingRect();
    const obj2 = e.target.getBoundingRect();
    const lineLeft = x1;
    const lineTop = nearestTop;
   
    
    let lineWidth = x2 - x1;
    line.set({
      width: lineWidth,
      opacity: 1,
      left: lineLeft,
      top: lineTop
    });
    line.bringToFront();
  } else {
    resultTop = undefined;
    line.set({opacity: 0});
    line2.set({opacity: 0})
  }
}


let resultLeft = undefined;
function alignLeftBetweenObjects(e) {

  const line = getLineById(this, ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID);
  const line2 = getLineById(this, ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID);

  if (isPositioningLinesDisabled || isCheckingCenterVertical) {
    line.set({ opacity: 0 });
    line2.set({ opacity: 0 });

    return;
  }
  const allObjects = [...this.getObjects()]
    // .sort(function (a, b) {
    //   return getMinOrMaxObjectAxisValue(a, 'min', 'x') - getMinOrMaxObjectAxisValue(b, 'min', 'x');
    // });
  
  
  const objectsWithTheSameLeft = [];
  const objectsWithTheSameCenter = [];
  const objectsWithTheSameRight = [];
  const objectLeft = getMinOrMaxObjectAxisValue(e.target, 'min', 'x');
  const objectCenter = getObjectAxisCenterValue(e.target, 'x');
  const objectRight = getMinOrMaxObjectAxisValue(e.target, 'max', 'x');

  for (let obj of allObjects) {
    if (objectsWithTheSameLeft.length ||
      objectsWithTheSameCenter.length ||
      objectsWithTheSameRight.length) break;
    if (e.target === obj
      || [
        H_LINE_ID,
        V_LINE_ID,
        ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
        HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        PIXEL_BACKGROUND_ID,
        CANVAS_TEMPLATE_RECTANGLE
      ].includes(obj.id)) {
      continue;
    }
    
    if (Math.abs(getMinOrMaxObjectAxisValue(obj,'min', 'x') - objectLeft) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameLeft.push(obj);
    }
    if (Math.abs(obj.getCenterPoint().x - objectCenter) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameCenter.push(obj);
    }
    if (Math.abs(getMinOrMaxObjectAxisValue(obj,'max', 'x') - objectRight) < DEFAULT_CENTERING_AREA) {
      objectsWithTheSameRight.push(obj);
    }
  }
  

  objectsWithTheSameLeft.sort(function (a, b) {
    return getMinOrMaxObjectAxisValue(a, 'min', 'x') - getMinOrMaxObjectAxisValue(b, 'min', 'x');
  });
  
  if (objectsWithTheSameLeft.length || objectsWithTheSameCenter.length || objectsWithTheSameRight.length) {
    
    const objects = objectsWithTheSameLeft.length
      ? objectsWithTheSameLeft
      : objectsWithTheSameCenter.length
        ? objectsWithTheSameCenter
        : objectsWithTheSameRight;
    
    let y1 = minTLPointY([...objects, e.target]);
    let y2 = maxBLPointY([...objects, e.target]);
    
    const nearestLeft = objects.length
      ? (objectsWithTheSameLeft.length
        ? getMinOrMaxObjectAxisValue(objects[0], 'min', 'x')
        : objectsWithTheSameCenter.length
          ? objects[0].getCenterPoint().x
          : getMinOrMaxObjectAxisValue(objects[0], 'max', 'x'))
      : false;

    if (nearestLeft) {
      let objectOriginX = e.target.getPointByOrigin('left', 'top').x;
      if (objectsWithTheSameLeft.length && !objectsWithTheSameCenter.length && !objectsWithTheSameRight.length) {
        switch (e.target.originX) {
          case ORIGINAL_POINT_X_CENTER:
            if (!resultLeft) {
              resultLeft = nearestLeft 
              + e.target.getBoundingRect().width / 2;
            }
            break;
          case ORIGINAL_POINT_X_RIGHT:
            if (!resultLeft) {
              resultLeft = nearestLeft 
                + e.target.getBoundingRect().width;
            }
            break;
          default:
            if (!resultLeft) {
              resultLeft = nearestLeft
              + (objectOriginX - e.target.getBoundingRect().left);
            }
        }
      }
      if (objectsWithTheSameCenter.length && !objectsWithTheSameLeft.length && !objectsWithTheSameRight.length) {
        y1 = minTLPointY([...objects, e.target]);
        y2 = maxBLPointY([...objects, e.target]);
        switch (e.target.originX) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultLeft)
              resultLeft = nearestLeft;
            break;
          case ORIGINAL_POINT_Y_BOTTOM:
            if (!resultLeft)
              resultLeft = nearestLeft + e.target.getBoundingRect().width
            break;
          default:
            if (!resultLeft)
              resultLeft = nearestLeft 
              - e.target.getBoundingRect().width / 2
              + (objectOriginX - e.target.getBoundingRect().left);
        }
      }
      if (objectsWithTheSameRight.length && !objectsWithTheSameLeft.length && !objectsWithTheSameCenter.length) {
        y1 = minTLPointY([...objects, e.target]);
        y2 = maxBLPointY([...objects, e.target]);
       
        switch (e.target.originX) {
          case ORIGINAL_POINT_Y_CENTER:
            if (!resultLeft) {
              resultLeft = nearestLeft
                - e.target.getBoundingRect().width / 2;
            }
            break;
          case ORIGINAL_POINT_Y_BOTTOM:
            if (!resultLeft)
              resultLeft = nearestLeft + e.target.getBoundingRect().width;
            break;
          default:
            if (!resultLeft)
              resultLeft = nearestLeft 
                - e.target.getBoundingRect().width 
                + (objectOriginX - e.target.getBoundingRect().left);
        }
      }
      if (objectsWithTheSameRight.length && objectsWithTheSameLeft.length && objectsWithTheSameCenter.length) {

        line2.set({
          left: nearestLeft + e.target.getBoundingRect().width,
          top: y1,
          opacity: 1,
          height: y2 - y1,
        });
        line2.bringToFront();
        switch (e.target.originX) {
          case ORIGINAL_POINT_X_CENTER:
            if (!resultLeft) {
              resultLeft = nearestLeft
                + e.target.getBoundingRect().width / 2;
            }
            break;
          case ORIGINAL_POINT_X_RIGHT:
            if (!resultLeft) {
              resultLeft = nearestLeft + e.target.getBoundingRect().width;
            }
            break;
          default:
            if (!resultLeft) {
              resultLeft = nearestLeft
                + (objectOriginX - e.target.getBoundingRect().left);
            }
        }
      }
      if (resultLeft) {
        e.target.left = resultLeft;
        e.target.setCoords();
        this.renderAll();
      }
    }
    line.set({
      height:  y2 - y1,
      opacity: 1,
      left: nearestLeft,
      top: y1
    });
    line.bringToFront()
  }else {
    resultLeft = undefined;
    line.set({opacity: 0});
    line2.set({opacity: 0})
  }
}

function fixPositionInTheCenterOfTheNearestObject(nearestCenteredObject, target) {
  const centerPoint = nearestCenteredObject.getPointByOrigin('center', 'center');
  target.setPositionByOrigin(centerPoint, 'center', 'center');
}

function showIntersectLinesBetweenObjects(objects) {
  let vLine = getLineById(this, VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS);
  const vlineParams = {
    x1: objects[0].getCenterPoint().x ,
    y1: minTLPointY(objects) - 50,
    x2: objects[0].getCenterPoint().x,
    y2: maxBLPointY(objects) + 50,
    opacity: 1
  };
  vLine.set(vlineParams);
  
  let hLine = getLineById(this, HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS);
  const hLineParams = {
    x1: minTLPointX(objects) - 50,
    y1: objects[0].getCenterPoint().y,
    x2: maxTRPointX(objects) + 50,
    y2: objects[0].getCenterPoint().y,
    opacity: 1
  };
  hLine.set(hLineParams);
}


function checkCenteringObjects({target}) {
  if (isPositioningLinesDisabled) {
    return
  }
  const centerOfMoveableObject = target.getCenterPoint();
  let nearestCenteredObject = null;
  for (let obj of this.getObjects()) {
    if (target === obj
      || [
        H_LINE_ID,
        V_LINE_ID,
        ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
        ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
        HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
        PIXEL_BACKGROUND_ID,
        CANVAS_TEMPLATE_RECTANGLE
      ].includes(obj.id)) {
      continue;
    }
    if (obj.getPointByOrigin('center', 'center').distanceFrom(centerOfMoveableObject) < DEFAULT_CENTERING_AREA) {
      nearestCenteredObject = obj;
    }
  }
  
  if (nearestCenteredObject) {
    fixPositionInTheCenterOfTheNearestObject(nearestCenteredObject, target);
    showIntersectLinesBetweenObjects.bind(this)([nearestCenteredObject,target]);
    hideLines.bind(this)([HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
      VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS]);
    return
  }
  hideLines.bind(this)([ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
    ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
    ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
    H_LINE_ID,
    V_LINE_ID]);
}

export function setOrderForGuidelines() {
  if (!currentCanvas) return;
  
  const lines = currentCanvas.getObjects().filter(obj => [
      H_LINE_ID,
      V_LINE_ID,
      ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
      ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
      ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
      VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
      HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS
    ].includes(obj.id)
  );
  lines.forEach(line => line.bringToFront());
}

export function addPositioningLines(canvas, options = {}) {
  initDisabling();
  currentCanvas = canvas;

  const hLineParams = {
    id: H_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options
  };
  const hLineCoords = [
    0, +(canvas.height / 2).toFixed(),
    canvas.width, +(canvas.height / 2).toFixed()
  ];

  const vLineParams = {
    id: V_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options,
    originY: 'center'
  };
  const alignLinesBetweenObjectsCoords = [
    0, 0,
    0, 0
  ];
  const alignTopBetweenObjectsLineCoordsParams = {
    id: ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options
  };
  const alignLeftBetweenObjectsLineCoordsParams = {
    id: ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options
  };
  const alignRightBetweenObjectsLineCoordsParams = {
    id: ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options
  };
  const alignBottomBetweenObjectsLineCoordsParams = {
    id: ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
    ...DEFAULT_LINES_OPTIONS,
    ...options
  };
  const vLineCoords = [
    +(canvas.width / 2).toFixed(), 0,
    +(canvas.width / 2).toFixed(), canvas.height
  ];
  const horizontalLineOfCenterCanvas = new fabric.Line(hLineCoords, hLineParams);
  const verticalLineOfCenterCanvas = new fabric.Line(vLineCoords, vLineParams);
  const alignTopBetweenObjectsLine = new fabric.Line(alignLinesBetweenObjectsCoords, alignTopBetweenObjectsLineCoordsParams);
  const alignLeftBetweenObjectsLine = new fabric.Line(alignLinesBetweenObjectsCoords, alignLeftBetweenObjectsLineCoordsParams);
  const alignRightBetweenObjectsLine = new fabric.Line(alignLinesBetweenObjectsCoords, alignRightBetweenObjectsLineCoordsParams);
  const alignBottomBetweenObjectsLine = new fabric.Line(alignLinesBetweenObjectsCoords, alignBottomBetweenObjectsLineCoordsParams);
  const verticalIntersectLine = new fabric.Line([0,0,0,0], {
    id: VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
    ...DEFAULT_LINES_OPTIONS,
    ...options,
    
  });
  const hLinIntersectLine = new fabric.Line([0,0,0,0], {
    id: HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
    ...DEFAULT_LINES_OPTIONS,
    ...options,
   
  });
  canvas.add(horizontalLineOfCenterCanvas); 
  canvas.add(verticalLineOfCenterCanvas);
  canvas.add(alignTopBetweenObjectsLine);
  canvas.add(alignLeftBetweenObjectsLine);
  canvas.add(alignRightBetweenObjectsLine);
  canvas.add(alignBottomBetweenObjectsLine);
  canvas.add(verticalIntersectLine);
  canvas.add(hLinIntersectLine);
  canvas.on('object:moving', alignCenterCanvas);
  canvas.on('object:moving', alignTopBetweenObjects);
  canvas.on('object:moving', alignLeftBetweenObjects);
  canvas.on('object:moving', checkCenteringObjects);
  canvas.on('mouse:up', hideLines);
}
