var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { staticClass: "pr-0", attrs: { cols: "8" } },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.shadowGroups,
                  "text-field": "name",
                  "value-field": "id",
                },
                on: { change: _vm.handleShadowGroupChange },
                scopedSlots: _vm._u([
                  {
                    key: "first",
                    fn: function () {
                      return [
                        _c("b-select-option", { attrs: { value: "" } }, [
                          _vm._v("\n            Create new group\n          "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.selectedShadowGroup,
                  callback: function ($$v) {
                    _vm.selectedShadowGroup = $$v
                  },
                  expression: "selectedShadowGroup",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "px-1", attrs: { cols: "4" } },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: {
                    title: !_vm.selectedPreset ? "Save" : "Update",
                    type: "button",
                    variant: "outline-primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.show("shadow-group-name-modal")
                    },
                  },
                },
                [
                  !_vm.selectedShadowGroup
                    ? _c("b-icon", { attrs: { icon: "save-fill" } })
                    : _c("b-icon", { attrs: { icon: "pencil-fill" } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectedShadowGroup
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title: "Delete",
                        type: "button",
                        variant: "outline-danger",
                      },
                      on: { click: _vm.deleteShadowGroup },
                    },
                    [_c("b-icon", { attrs: { icon: "bucket-fill" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("ShadowGroupNameModal", {
            attrs: {
              title: _vm.shadowGroupNameModal.title,
              value: _vm.shadowGroupNameModal.value,
              placeholder: "Please enter a shadow group name",
              "submit-btn-title": "Continue",
            },
            on: { submit: _vm.handleShadowGroupSubmit },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-tabs",
            {
              attrs: { card: "" },
              scopedSlots: _vm._u([
                {
                  key: "tabs-end",
                  fn: function () {
                    return [
                      _c(
                        "b-nav-item",
                        {
                          attrs: { href: "#", role: "presentation" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.newTab.apply(null, arguments)
                            },
                          },
                        },
                        [_c("b", [_vm._v("+")])]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "text-center text-muted" }, [
                        _vm._v("\n          There are no open tabs"),
                        _c("br"),
                        _vm._v("\n          Open a new tab using the "),
                        _c("b", [_vm._v("+")]),
                        _vm._v(" button above.\n        "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            _vm._l(_vm.tabs, function (shadow, key) {
              return _c(
                "b-tab",
                {
                  key: "key" + key,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s("Shadow " + (key + 1)) +
                                "\n          "
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "float-right close-button",
                                attrs: {
                                  pill: "",
                                  size: "sm",
                                  variant: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeTab(key)
                                  },
                                },
                              },
                              [_vm._v("\n            X\n          ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-form",
                    [
                      _c(
                        "b-form-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n              Shadow Presets\n            "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "pr-1", attrs: { cols: "6" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.shadowPresets,
                                      "text-field": "name",
                                      "value-field": "id",
                                    },
                                    on: {
                                      change: _vm.handleSelectPresetChange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "first",
                                          fn: function () {
                                            return [
                                              _c(
                                                "b-select-option",
                                                { attrs: { value: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                      create new\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selectedPreset,
                                      callback: function ($$v) {
                                        _vm.selectedPreset = $$v
                                      },
                                      expression: "selectedPreset",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { staticClass: "p-0", attrs: { cols: "6" } },
                                [
                                  _vm.selectedPreset
                                    ? _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            title: "Apply",
                                            type: "button",
                                            variant: "primary",
                                          },
                                          on: { click: _vm.applyPreset },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "check-lg" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        title: _vm.selectedPreset
                                          ? `Edit`
                                          : "Save",
                                        type: "button",
                                        variant: "outline-primary",
                                      },
                                      on: { click: _vm.savePresetOfOvalShadow },
                                    },
                                    [
                                      !_vm.selectedPreset
                                        ? _c("b-icon", {
                                            attrs: { icon: "save-fill" },
                                          })
                                        : _c("b-icon", {
                                            attrs: { icon: "pencil-fill" },
                                          }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.selectedPreset
                                    ? _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          attrs: {
                                            title: "Delete",
                                            type: "button",
                                            variant: "outline-danger",
                                          },
                                          on: { click: _vm.deletePreset },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "bucket-fill" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Blur" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: 0,
                                        type: "number",
                                      },
                                      on: { change: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalBlur,
                                        callback: function ($$v) {
                                          _vm.ovalBlur = $$v
                                        },
                                        expression: "ovalBlur",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: { max: 100, min: 0, type: "range" },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalBlur,
                              callback: function ($$v) {
                                _vm.ovalBlur = $$v
                              },
                              expression: "ovalBlur",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Width" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: 0,
                                        type: "number",
                                      },
                                      on: { change: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalWidth,
                                        callback: function ($$v) {
                                          _vm.ovalWidth = $$v
                                        },
                                        expression: "ovalWidth",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: { max: 100, min: 0, type: "range" },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalWidth,
                              callback: function ($$v) {
                                _vm.ovalWidth = $$v
                              },
                              expression: "ovalWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Height" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: 0,
                                        type: "number",
                                      },
                                      on: { change: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalHeight,
                                        callback: function ($$v) {
                                          _vm.ovalHeight = $$v
                                        },
                                        expression: "ovalHeight",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: { max: 100, min: 0, type: "range" },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalHeight,
                              callback: function ($$v) {
                                _vm.ovalHeight = $$v
                              },
                              expression: "ovalHeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Offset X" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: -100,
                                        type: "number",
                                      },
                                      on: { input: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalOffsetX,
                                        callback: function ($$v) {
                                          _vm.ovalOffsetX = $$v
                                        },
                                        expression: "ovalOffsetX",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              max: 100,
                              min: -100,
                              step: 0.01,
                              type: "range",
                            },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalOffsetX,
                              callback: function ($$v) {
                                _vm.ovalOffsetX = $$v
                              },
                              expression: "ovalOffsetX",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Offset Y" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: -100,
                                        type: "number",
                                      },
                                      on: { input: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalOffsetY,
                                        callback: function ($$v) {
                                          _vm.ovalOffsetY = $$v
                                        },
                                        expression: "ovalOffsetY",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: {
                              max: 100,
                              min: -100,
                              step: 0.01,
                              type: "range",
                            },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalOffsetY,
                              callback: function ($$v) {
                                _vm.ovalOffsetY = $$v
                              },
                              expression: "ovalOffsetY",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Intensity" } },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c("b-input", {
                                      attrs: {
                                        max: 100,
                                        min: 0,
                                        type: "number",
                                      },
                                      on: { input: _vm.onOvalShadowChange },
                                      model: {
                                        value: _vm.ovalOpacity,
                                        callback: function ($$v) {
                                          _vm.ovalOpacity = $$v
                                        },
                                        expression: "ovalOpacity",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("b-input", {
                            attrs: { max: 100, min: 0, type: "range" },
                            on: { input: _vm.onOvalShadowChange },
                            model: {
                              value: _vm.ovalOpacity,
                              callback: function ($$v) {
                                _vm.ovalOpacity = $$v
                              },
                              expression: "ovalOpacity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Display mode" } },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: `display-mode-${key}`,
                          options: _vm.displayModeOptions,
                        },
                        on: { change: _vm.onOvalShadowChange },
                        model: {
                          value: _vm.displayModeSelected,
                          callback: function ($$v) {
                            _vm.displayModeSelected = $$v
                          },
                          expression: "displayModeSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-form-group", {
                    attrs: { label: "Fill types" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ ariaDescribedby }) {
                            return [
                              _c("b-form-radio-group", {
                                attrs: {
                                  "aria-describedby": ariaDescribedby,
                                  options: _vm.colorTypes,
                                },
                                on: { change: _vm.onFillTypeChange },
                                model: {
                                  value: _vm.selectedFillType,
                                  callback: function ($$v) {
                                    _vm.selectedFillType = $$v
                                  },
                                  expression: "selectedFillType",
                                },
                              }),
                              _vm._v(" "),
                              _vm.selectedFillType === "Color"
                                ? _c("b-form-input", {
                                    attrs: {
                                      id: `input-color-${key}`,
                                      type: "color",
                                      debounce: "50",
                                    },
                                    on: { update: _vm.onOvalShadowChange },
                                    model: {
                                      value: _vm.ovalColor,
                                      callback: function ($$v) {
                                        _vm.ovalColor = $$v
                                      },
                                      expression: "ovalColor",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedFillType === "Linear"
                                ? _c("b-form-select", {
                                    attrs: {
                                      options: _vm.linearDirectionsOptions,
                                      size: "sm",
                                    },
                                    on: { change: _vm.onOvalShadowChange },
                                    model: {
                                      value: _vm.selectedLinearDirection,
                                      callback: function ($$v) {
                                        _vm.selectedLinearDirection = $$v
                                      },
                                      expression: "selectedLinearDirection",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              ["Linear", "Radial"].includes(
                                _vm.selectedFillType
                              )
                                ? _c(
                                    "b-form-group",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-flex justify-content-between align-items-center w-100",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                Color stops "
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          size: "sm",
                                                          variant:
                                                            "outline-success",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addColorStop,
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            "font-scale": "1",
                                                            icon: "plus",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.colorStops,
                                        function (colorStop, colorKey) {
                                          return _c(
                                            "div",
                                            {
                                              key: colorKey,
                                              staticClass:
                                                "color-stop-container",
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass: "color-stop",
                                                attrs: {
                                                  id: `color-stop-${colorKey}`,
                                                  type: "color",
                                                  debounce: "50",
                                                },
                                                on: {
                                                  update: function ($event) {
                                                    return _vm.onColorStopChange(
                                                      $event,
                                                      colorKey
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: colorStop.color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      colorStop,
                                                      "color",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "colorStop.color",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: `offset-${key}`,
                                                  type: "range",
                                                  debounce: "50",
                                                  max: "1",
                                                  min: "0",
                                                  size: "sm",
                                                  step: "0.01",
                                                },
                                                on: {
                                                  update: function ($event) {
                                                    return _vm.onColorOffsetChange(
                                                      $event,
                                                      key
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: colorStop.offset,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      colorStop,
                                                      "offset",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "colorStop.offset",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                staticClass:
                                                  "color-stop-numbers",
                                                attrs: {
                                                  id: `offset-${key}`,
                                                  type: "number",
                                                  max: "1",
                                                  min: "0",
                                                  size: "sm",
                                                  step: "0.01",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onColorOffsetChange(
                                                      $event,
                                                      key
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: colorStop.offset,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      colorStop,
                                                      "offset",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "colorStop.offset",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.colorStops.length <=
                                                      2,
                                                    size: "sm",
                                                    variant: "outline-danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeColorStop(
                                                        key
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      "font-scale": "1",
                                                      icon: "archive-fill",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }