var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    attrs: {
      id: "createLayoutFolderModal",
      title: "Create layout folder",
      centered: "",
    },
    on: { ok: _vm.createAndSelectFolder, hide: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ ok }) {
          return [
            _c(
              "b-form-group",
              {
                attrs: {
                  label: "Name",
                  state: _vm.nameState,
                  "valid-feedback": _vm.nameValidFeedback,
                  "invalid-feedback": _vm.nameInvalidFeedback,
                },
              },
              [
                _c("b-input", {
                  attrs: { autofocus: "" },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return ok.apply(null, arguments)
                    },
                    input: _vm.validateName,
                  },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }