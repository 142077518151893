export class EventBroker {
    listeners = []

    on(name, handler, shouldBeSingleSubscription = false) {
        if (shouldBeSingleSubscription) {
            this.listeners = this.listeners.filter(listener => listener.name !== name);
        }
        this.listeners.push({ name, handler })
    }

    fire(_name, dto) {
        this.listeners
            .filter(({ name }) => name === _name)
            .forEach(({ handler }) => {
                try {
                    handler(dto);
                } catch (error) {
                    console.error(error);
                }
            });
    }

    off(_name, _handler) {
        this.listeners = this.listeners.filter(({ name, handler }) => name !== _name || handler !== _handler);
    }

    isListen(_name) {
        return !!this.listeners.find(({ name }) => name === _name)
    }
}