<template>
  <modal
    name="svg-text-modal"
    draggable=".drag-area"
    class="wrapper"
    height="auto"
    :shift-x="0.5"
    :shift-y="0.5"
    :click-to-close="false"
  >
    <div class="drag-area">
      <span class="title">Text settings</span>
      <b-button
        variant="light"
        class="close-btn"
        @click="close"
      >
        <b-icon
          icon="x-lg"
          class="close-icon"
        />
      </b-button>
    </div>

    <div class="content-area">
      <b-form-row>
        <b-col cols="5">
          <div class="text-editor-modal__settings-field">
            <label
              class="my-0 mr-1 ml-6"
              for="font-family"
            >
              Font Family
            </label>
            <select
              id="font-family"
              class="form-control form-control-sm"
              :value="fontFamily"
              @input="setFontFamily"
            >
              <option
                v-for="(currentFont, index) in fontsList"
                :key="(currentFont.index || currentFont.created_at) + index"
                :value="currentFont.index || currentFont"
                :style="{'font-family': currentFont.index}"
              >
                {{ currentFont.name || currentFont.title }}
              </option>
            </select>
          </div>
        </b-col>

        <b-col cols="5">
          <div class="text-editor-modal__settings-field">
            <label
              class="my-0 mr-1 ml-6"
              for="font-size"
            >
              Size
            </label>
            <b-input
              id="font-size"
              :value="fontSize"
              type="number"
              class="form-control form-control-sm"
              @input="setFontSize"
            />
          </div>
        </b-col>

        <b-col cols="2">
          <label
            class="my-0 mr-1 ml-6"
            for="font-color"
          >
            Color
          </label>
          <b-form-input
            id="font-color"
            class="color-input"
            type="color"
            :value="fontColor"
            @input="setColor"
          />
        </b-col>
      </b-form-row>
    </div>

    <div class="control-area">
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-primary mr-2"
            @click="apply"
            @keyup.enter="apply"
          >
            Apply
          </button>
          <button
            class="btn btn-secondary"
            @click="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FontFamilyManager from '../../../common/load-fonts';

export default {
  name: 'SvgTextModal',

  data: () => ({
    fontFamilyManager: new FontFamilyManager(),
    fontFamily: '',
    fontSize: 0,
    fontColor: '#000000',
    fontWeight: 400,
    fontsList: [],
    fontObject: {},
    productInstance: null,
    textObject: null,
    initTextParams: null,
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getColorToDraw',
      'getProductInstance'
    ]),
  },

  mounted() {
    window.fontFamilyManager = this.fontFamilyManager;
    this.fontFamilyManager.loadData();
    window.addEventListener('keydown', this.handleEnterPress);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleEnterPress);
  },

  methods: {
    init() {
      this.productInstance = this.getProductInstance;
      this.textObject = this.productInstance.getActiveObject();
      this.fontFamily = this.textObject.fontFamily;
      this.fontSize = this.textObject.fontSize;
      this.fontColor = this.textObject.fill;
      this.fontWeight = this.textObject.fontWeight;
      this.fontsList = this.fontFamilyManager.fonts;
      this.fontObject = this.getFontObjectFromList(this.fontFamily);
      this.setInitFontParams();
    },

    show() {
      this.init();
      this.$modal.show('svg-text-modal');
    },

    close() {
      this.updateTextOnCanvas(this.initTextParams);
      this.$modal.hide('svg-text-modal');
    },

    setColor(hexColor) {
      this.fontColor = hexColor;
      this.updateTextOnCanvas({ fill: this.fontColor });
    },

    setFontSize(newFontSize) {
      this.fontSize = newFontSize;
      this.updateTextOnCanvas({ fontSize: this.fontSize });
    },

    setFontFamily(fontFamily) {
      this.fontObject = this.getFontObjectFromList(fontFamily.target.value);
      this.fontFamily = this.fontObject.index;
      this.updateTextOnCanvas({
        fontFamily: this.fontFamily,
        fontWeight: this.fontObject.weight
      });
    },

    getFontObjectFromList(font) {
      return this.fontsList.find(o => o.index === font);
    },

    updateTextOnCanvas(params) {
      this.textObject.set({ ...params });
      this.productInstance.renderAll();
    },

    setInitFontParams() {
      this.initTextParams = {
        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        fill: this.fontColor,
        fontWeight: this.fontWeight,
      };
    },

    apply() {
      if (this.initTextParams) {
        this.initTextParams = null;
        this.$modal.hide('svg-text-modal');
      }
    },

    handleEnterPress(event) {
      switch (event.keyCode) {
        // Enter key
        case 13:
        case 36:
        case 76:
          this.apply();
          break;
      }
    }
  },
};
</script>

<style scoped>
.wrapper {
  ::v-deep .vm--modal {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0 4px 20px;
    background-color: #f6f6f6;
  }

  .drag-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    position: relative;
    padding: 16px 16px;

    .title {
      font-size: 20px;
    }

    .close-btn {
      position: absolute;
      border: none;
      right: 8px;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        border: none;

        .close-icon {
          color: black;
        }
      }

      .close-icon {
        color: #7f7f7f;
        font-size: 15px;

        &:hover {
          color: black;
        }
      }
    }
  }

  .content-area {
    padding: 0 16px 16px 16px;

    .color-input {
      padding: 0;
      height: 32px;
    }
  }

  .control-area {
    padding: 0 16px 16px 16px;
  }
}
</style>

<style>
.wrapper {
  .vm--overlay {
    background: transparent !important;
  }
}
</style>