<template>
  <modal
    name="svg-extract-modal"
    draggable=".drag-area"
    class="wrapper"
    height="auto"
    :shift-x="0.5"
    :shift-y="0.5"
    :click-to-close="false"
    width="1200"
  >
    <div class="drag-area">
      <b-button
        variant="light"
        class="close-btn"
        @click="close"
      >
        <b-icon
          icon="x-lg"
          class="close-icon"
        />
      </b-button>
    </div>

    <div class="content-area">
      <div
        v-for="(image, index) in images"
        :key="index"
        :class="{ 'content-image selected': image.isSelected, 'content-image': !image.isSelected }"
        @click="selectImage(index)"
      >
        <span class="title">{{ image.title }}</span>
        <img
          class="content-image"
          :src="image.src"
          crossorigin="anonymous"
        >
      </div>
    </div>

    <div class="control-area">
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-primary mr-2"
            @click="apply"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SvgExtractModal',

  data: () => ({
    svgInstance: null,
    svgCanvasInstance: null,
    images: [],
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getSvgInstance',
      'getSvgCanvasInstance',
      'isInteractiveMode',
    ]),
  },

  methods: {
    init() {
      this.svgInstance = this.getSvgInstance;
      this.svgCanvasInstance = this.getSvgCanvasInstance;
    },

    show(data) {
      this.init();
      this.images = data.map(image => ({
        src: image.src,
        title: image.lang,
        tiff: image.tiff,
        isSelected: false,
      }));
      this.$modal.show('svg-extract-modal');
    },

    close() {
      this.$emit('on-file-loaded');
      this.$modal.hide('svg-extract-modal');
    },

    selectImage(imageIndex) {
      this.images = this.images.map((image, index) => ({
        ...image,
        isSelected: index === imageIndex,
      }));
    },

    async apply() {
      const selected = this.images.find((image) => image.isSelected);

      if (selected) {
        const dataUrl = await this.svgCanvasInstance.urlToBase64(selected.src);
        this.svgCanvasInstance.pasteImageToRHSFromUrl(dataUrl);
        this.images = [];
        return this.close();
      }

      toastr.warning('Please select image');
    },
  },
};
</script>

<style scoped>
.wrapper {
  ::v-deep .vm--modal {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0 4px 20px;
    background-color: #f6f6f6;
  }

  .drag-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    position: relative;
    padding: 16px 16px;

    .title {
      font-size: 20px;
    }

    .close-btn {
      position: absolute;
      border: none;
      right: 8px;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        border: none;

        .close-icon {
          color: black;
        }
      }

      .close-icon {
        color: #7f7f7f;
        font-size: 15px;

        &:hover {
          color: black;
        }
      }
    }
  }

  .content-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2px;
    padding: 0 16px 16px 16px;

    .content-image {
      width: 100%;
      height: auto;
      padding: 2px;

      &.selected {
        border: 2px solid #209c86;
      }
    }
  }
  
  .control-area {
    padding: 0 8px 8px 0;
  }
}
</style>

<style>
.wrapper {
  .vm--overlay {
    background: transparent !important;
  }
}
</style>