var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-row",
    [
      _vm.aspectRatioOption
        ? _c(
            "b-col",
            { staticClass: "mb-2", attrs: { cols: "12" } },
            [
              _c(
                "b-checkbox",
                {
                  attrs: { disabled: _vm.target.locked },
                  model: {
                    value: _vm.keepAspectRatioValue,
                    callback: function ($$v) {
                      _vm.keepAspectRatioValue = $$v
                    },
                    expression: "keepAspectRatioValue",
                  },
                },
                [_vm._v("\n      Keep aspect ratio\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "X",
                "label-cols": "2",
                disabled: _vm.target.locked || !_vm.moveable,
              },
            },
            [
              _c("b-input", {
                attrs: { value: _vm.x.toFixed(2), type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.onInput("x", $event)
                  },
                  dblclick: _vm.selectValueOnDblclick,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Y",
                "label-cols": "2",
                disabled: _vm.target.locked || !_vm.moveable,
              },
            },
            [
              _c("b-input", {
                attrs: { value: _vm.y.toFixed(2), type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.onInput("y", $event)
                  },
                  dblclick: _vm.selectValueOnDblclick,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "W",
                "label-cols": "2",
                disabled: _vm.target.locked,
              },
            },
            [
              _c("b-input", {
                attrs: { value: _vm.w.toFixed(2), type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.onInput("w", $event)
                  },
                  dblclick: _vm.selectValueOnDblclick,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "H",
                "label-cols": "2",
                disabled: _vm.target.locked,
              },
            },
            [
              _c("b-input", {
                attrs: { value: _vm.h.toFixed(2), type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.onInput("h", $event)
                  },
                  dblclick: _vm.selectValueOnDblclick,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }