var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "svg-preview-modal",
        title: "Preview",
        size: "xl",
        centered: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-end justify-content-end" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary mr-2",
                        on: { click: _vm.hide },
                      },
                      [_vm._v("\n          Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-2",
                        on: { click: _vm.onSaveLHS },
                      },
                      [_vm._v("\n          Close & Update Layers\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-5",
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.modifiedSvgText) } }),
        _vm._v(" "),
        _vm.isInnerGTags()
          ? _c("div", { staticClass: "group-list" }, [
              _c(
                "ul",
                _vm._l(_vm.innerGTags, function (gTag, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "div",
                      {
                        class: {
                          item: _vm.isRemovedTag(index),
                          "item selected": !_vm.isRemovedTag(index),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeGTag(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getTagDisplayName(gTag, index)) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }