function editDistance(stringFirst, stringSecond) {
    stringFirst = stringFirst.toLowerCase();
    stringSecond = stringSecond.toLowerCase();
  
    const costs = new Array();

    for (let i = 0; i <= stringFirst.length; i++) {

      let lastValue = i;

      for (let j = 0; j <= stringSecond.length; j++) {

        if (i == 0) {
            costs[j] = j;
        } else {
          if (j > 0) {
            let newValue = costs[j - 1];

            if (stringFirst.charAt(i - 1) != stringSecond.charAt(j - 1)) {
                newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }
              
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }

      if (i > 0) {
        costs[stringSecond.length] = lastValue;
      }
        
    }
    
    return costs[stringSecond.length];
}

export default function similarity(stringFirst, stringSecond) {
    let longer = stringFirst;
    let shorter = stringSecond;

    if (stringFirst.length < stringSecond.length) {
      longer = stringSecond;
      shorter = stringFirst;
    }

    const longerLength = longer.length;

    if (longerLength == 0) {
      return 1.0;
    }

    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}