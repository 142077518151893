const base = '/preset';
export default {
  getAll(params = {}) {
    return axios.get(base, { params })
  },
  create(params = {}) {
    return axios.post(base, { ...params });
  },
  update(id, params = {}) {
    return axios.post(base + `/${id}`, { ...params });
  },
  delete(id, params = {}) {
    return axios.delete(base + `/${id}`, { ...params });
  }
}