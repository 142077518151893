<template>
  <div>
    <div>
      <b-form-input
        readonly
        :value="value"
      />
      <div class="mt-3" />
      <div
        v-show="hasShowCustomNameInput"
        class="form-group input-group"
      >
        <input
          v-model="customName"
          class="form-control with-group-append"
        >
        <div class="input-group-append">
          <b-btn
            variant="primary"
            :disabled="!customName"
            @click="onAddCustomName"
          >
            Add
          </b-btn>
        </div>
      </div>
      <span
        v-if="selectedNamesFromTabs.length"
        v-b-tooltip.hover
        title="You can select a tag and replace it with another selection by clicking the tag and choosing a new option."
      >
        <img
          class="custom-tooltip-icon"
          src="/img/icons/info.png"
          alt="Show variables"
          width="20"
          height="20"
        >
      </span>
      <b-form-tag
        v-for="name in selectedNamesFromTabs"
        :key="name.id"
        :title="`${name.tabName} - ${name.value}`"
        class="mr-1"
        :variant="getTabVariant(name)"
        @remove="onRemoveTag(name)"
      >
        <span
          class="cursor-pointer"
          @click="onSelectTag(name)"
        >
          {{ name.tabName }} - {{ name.value }}
        </span>
      </b-form-tag>
    </div>
    <div class="mt-3" />
    <div class="form-row">
      <div class="col">
        <Multiselect
          v-model="selectedNamingDesignTab"
          :options="namingDesignTabs"
          searchable
          placeholder="Select naming tab"
          label="name"
          @select="onSelectedNamingDesignTab"
        />
      </div>
      <div class="col">
        <SelectNamingValueDropdown
          text="Select Name"
          :naming-design-tab="selectedNamingDesignTab"
          :naming-values-of-tab="getNameOfTab"
          :disabled="disabledSelectName"
          :has-choose-variables="hasChooseVariables"
          @onSelectName="onSelectName"
        />
      </div>
      <div
        v-show="selectedTab && selectedTab.hasVariable"
        class="col-12"
      >
        <SelectNamingValueDropdown
          text="Select Default Name for Variable"
          :naming-design-tab="selectedNamingDesignTab"
          :naming-values-of-tab="getNameOfTab"
          :disabled="disabledSelectName"
          @onSelectName="onSelectDefaultNameForVariable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SelectNamingValueDropdown from './SelectNamingValueDropdown.vue';

const namingTabDTO = ({id, tabId, tabName, value, hasVariable }) => ({
  id,
  tabId,
  tabName,
  hasVariable: !!hasVariable,
  value
});

export default {
  components: {
    Multiselect,
    SelectNamingValueDropdown
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    namingDesignTabs: {
      type: Array,
      default: () => []
    },
    selectedNamesFromTabs: {
      type: Array,
      default: () => []
    },
    hasChooseVariables: {
      type: Boolean,
      default: false
    },
    hasShowCustomNameInput: {
      type: Boolean,
      default: true,
    },
    disabledSelectName: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      customName: '',
      searchNameOfTab: '',
      namesOfTab: {},
      selectedNamingDesignTab: null,
      selectedTab: null,
    }
  },
  computed: {
    getNameOfTab() {
      return this.namesOfTab?.[this.selectedNamingDesignTab?.id] || [];
    },
    getTabVariant() {
      return (name) => this.selectedTab === name ? 'warning' : (name.hasVariable ? 'info' : 'primary')
    }
  },
  methods: {
    onSelectTag(tag) {
      this.selectedTab = this.selectedTab?.id === tag.id ? null : tag;
      const foundNamingDesignTab = this.namingDesignTabs.find(
        namingDesignTab => namingDesignTab.id === this.selectedTab?.tabId
      );

      if (foundNamingDesignTab) {
        this.selectedNamingDesignTab = foundNamingDesignTab;
        this.onSelectedNamingDesignTab()
      }
    },
    onRemoveTag(name) {
      this.updateSelectedNamesFromTabs(
        this.selectedNamesFromTabs
          .filter((_name) => _name.id !== name.id)
      );
    },
    onAddCustomName() {
      this.replaceOrAddSelectedNamesFromTabs('Custom Name', this.customName);

      this.customName = '';
    },
    replaceOrAddSelectedNamesFromTabs(tabName, value, hasVariable = false) {
      const namingDesignTab = this.selectedNamingDesignTab;

      if (this.selectedTab) {
        this.selectedTab.tabId = namingDesignTab.id;
        this.selectedTab.tabName = tabName;
        this.selectedTab.value = value;
        this.selectedTab.hasVariable = hasVariable;

        this.selectedTab = null;
        this.updateSelectedNamesFromTabs([...this.selectedNamesFromTabs]);
        return;
      }

      const id = +new Date + this.selectedNamesFromTabs.length;

      const tabDTO = namingTabDTO({
        id,
        tabId: namingDesignTab.id,
        tabName,
        value,
        hasVariable,
      });

      this.updateSelectedNamesFromTabs([...this.selectedNamesFromTabs, tabDTO]);
    },
    updateSelectedNamesFromTabs(selectedNamesFromTabs) {
      this.$emit('onSelectedNamesFromTabs', selectedNamesFromTabs);
    },
    async onSelectedNamingDesignTab() {
      const tabId = this.selectedNamingDesignTab?.id;
      this.searchNameOfTab = '';

      if (this.namesOfTab?.[tabId]) return;

      try {
        const { data } = await axios.get(`/admin/auth/settings/naming/tab/${tabId}/get-values-of-tab`);

        this.namesOfTab = {
            ...this.namesOfTab,
            [tabId]: data
        };
      } catch(error) {
        console.error(error);
      }
    },
    onSelectName(name) {
      const { name: tabName } = this.selectedNamingDesignTab;

      this.replaceOrAddSelectedNamesFromTabs(tabName, name._value, name.hasVariable);
    },
    onSelectDefaultNameForVariable(name) {
      const { name: tabName } = this.selectedNamingDesignTab;
      
      this.replaceOrAddSelectedNamesFromTabs(tabName, name._value, true);
    }
  }
}
</script>
