<template>
  <div class="modal-body d-flex justify-content-between">
    <div class="w-100">
      <b-progress
        v-if="!isError"
        class="w-100"
        :max="1"
      >
        <b-progress-bar
          animated
          :value="progress"
          :label="`${(progress * 100).toFixed(0)}%`"
        />
      </b-progress>
      <b-alert
        variant="danger"
        :show="isError"
      >
        {{ errorMessage }}
      </b-alert>
    </div>
    <div class="w-100 d-flex justify-content-end">
      <b-button @click="cancel">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('psdLayers')

export default {
  name: "InitialLoader",
  computed: {
    ...mapGetters(['progress', 'isError', 'errorMessage'])
  },
  methods: {
    ...mapActions(['cancel'])
  }
}
</script>

<style scoped>

</style>