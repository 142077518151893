import axios from 'axios'

export default {
  namespaced: true,

  state: () => ({
    user: {},
    customers: [],
    layouts: [],
  }),

  getters: {
    user: state => state.user,
    customers: state => state.customers,
    layouts: state => state.layouts,
  },

  mutations: {
    SET_USER_DATA (state, payload) {
      state.user = payload
    },
    SET_CUSTOMERS (state, payload) {
      state.customers = payload
    },
    SET_LAYOUTS (state, payload) {
      state.layouts = payload
    },
  },

  actions: {
    async fetchUserData ({ commit }) {
      const { data } = await axios.get('/dashboard/user')
      commit('SET_USER_DATA', data)
    },
    async fetchCustomers ({ commit }) {
      const { data } = await axios.get('/banner/customers')
      commit('SET_CUSTOMERS', data)
    },
    async fetchLayouts ({ commit }) {
      const { data } = await axios.get('/banner/groups/layouts')
      commit('SET_LAYOUTS', data)
    },
  },
}
