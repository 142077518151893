import {
  CREATE_NEW_TEMPLATE_MAP_RULES,
  FILL_EXISTED_TEMPLATE_MAP_RULES
} from "@frontend/store/psd-layers/features/auto-map/map-rules";

export const isSourceNodeMappable = ({ sourceName, targetType, targetName }) => {
  if (sourceName.toLowerCase().includes(targetName.toLowerCase())) {
    return true;
  }

  const rules =  FILL_EXISTED_TEMPLATE_MAP_RULES[targetType];

  if (!rules) {
    return false;
  }

  const matcher = rules.find(matcher => matcher.activator(targetName));

  if (!matcher) {
    return false;
  }

  return matcher.match({ targetName, sourceName });
}

export const getAutoMappedFieldType = ({ sourceFieldType, sourceName }) => {
  const rules = CREATE_NEW_TEMPLATE_MAP_RULES[sourceFieldType];

  if (!rules) {
    return null;
  }

  const matcher = rules.find(matcher => matcher.activator(sourceName))

  if (!matcher) {
    return null;
  }

  return matcher.match({ sourceName });
}