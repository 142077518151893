const escapeRegexChars = (str) => {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const generateKeyPhrasesRegex = (
  keyPhrase,
  { includeNumber = false } = {}
) => {
  if (!keyPhrase?.length) {
    return null;
  }

  const escapedPhrase = escapeRegexChars(keyPhrase);
  const pattern = includeNumber
    ? `(${escapedPhrase})(\\s*(\\d+))?`
    : escapedPhrase;

  return new RegExp(`\\b${pattern}\\b`, 'i');
}