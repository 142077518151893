import similarity from "@frontend/services/helpers/similarity-text";

export const matchKeyPhrase = (name, keyPhrases) => {
  return keyPhrases.reduce((bestMatch, phrase) => {
    const similarityPercent = similarity(name, phrase);

    return similarityPercent > bestMatch.maxSimilarity
      ? { phrase, maxSimilarity: similarityPercent }
      : bestMatch;
  }, { phrase: null, maxSimilarity: 0 }).phrase;
}