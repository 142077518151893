import Template from '../../../../../banner_template/Template';
import { resizeWithAspectRatio } from '../../../../services/helpers';
import { updateObject } from '../helpers';

export const BACKGROUND_PROPERTIES = {
  SIZE: {
    COVER: 'cover',
    CONTAIN: 'contain',
    FROM_DESTINATION_TEMPLATE: 'from destination template'
  },
  POSITION: {
    LEFT_AND_TOP: 'left_and_top',
    CENTER: 'center',
    FROM_DESTINATION_TEMPLATE: 'from_destination_template'
  }
}

const sizeCoverDecorator = ({ dimension, object }) => {
  const { width } = resizeWithAspectRatio(
    object.width,
    object.height,
    dimension.width,
    dimension.height,
    true
  );

  object.scaleToWidth(width);
}

const sizeContainDecorator = ({ dimension, object }) => {
  object.scaleToWidth(dimension.width);
}

const sizeFromDestinationTemplate = ({ object, findMappedField }) => {
  const { width } = resizeWithAspectRatio(
    object.width,
    object.height,
    findMappedField.getWidth(),
    findMappedField.getHeight(),
    true
  );

  object.scaleToWidth(width);
}

const positionLeftTopDecorator = ({ object }) => {
  object.setPositionByOrigin({x: 0, y: 0}, 'left', 'top');
}

const positionCenterDecorator = ({ dimension, object }) => {
  object.setPositionByOrigin({
    x: (dimension.width - object.getScaledWidth()) / 2,
    y: (dimension.height - object.getScaledHeight()) / 2,
  }, 'left', 'top');
}

const positionFromDestinationTemplate = ({ object, findMappedField }) => {
  object.setPositionByOrigin({
    x: findMappedField.getLeft(),
    y: findMappedField.getTop()
  }, 'left', 'top');
}

const MAP_DECORATORS = {
  [BACKGROUND_PROPERTIES.SIZE.COVER]: sizeCoverDecorator,
  [BACKGROUND_PROPERTIES.SIZE.CONTAIN]: sizeContainDecorator,
  [BACKGROUND_PROPERTIES.SIZE.FROM_DESTINATION_TEMPLATE]: sizeFromDestinationTemplate,
  [BACKGROUND_PROPERTIES.POSITION.LEFT_AND_TOP]: positionLeftTopDecorator,
  [BACKGROUND_PROPERTIES.POSITION.LEFT_AND_TOP]: positionCenterDecorator,
  [BACKGROUND_PROPERTIES.POSITION.FROM_DESTINATION_TEMPLATE]: positionFromDestinationTemplate,
}

export const resizeBackgroundHandler = ({ isCopyObject, dimension, object: _object, backgroundProperties }) => {

  if (!_object) return;
  
  const template = new Template(dimension.template || {});
  const findMappedField = template.getFieldByName(_object?.templateField?.name);
  const object = isCopyObject ? _.cloneDeep(_object) : _object;

  const properties = [
    backgroundProperties.size,
    backgroundProperties.position,
  ];

  properties.forEach(property => {
    const handler = MAP_DECORATORS[property];

    if (handler) {
      handler({ object, dimension, findMappedField });
    }
  })

  object.setCoords();
  object.canvas.renderAll();
  updateObject(object);
}
