var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "shadow-group-name-modal",
        title: _vm.title,
        centered: "",
        "ok-title": _vm.submitBtnTitle,
      },
      on: {
        ok: function ($event) {
          return _vm.$emit("submit", _vm.inputValue)
        },
        show: _vm.init,
      },
    },
    [
      _c("b-form-input", {
        attrs: { placeholder: _vm.placeholder },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }