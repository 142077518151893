var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bottom-bar" },
    [
      _c(
        "b-pagination",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showOnlySelected,
              expression: "!showOnlySelected",
            },
          ],
          ref: "pa",
          staticClass: "c-pagination",
          attrs: {
            pills: "",
            align: "center",
            "total-rows": _vm.totalCount,
            "per-page": 12,
          },
          on: { change: _vm.paginationChange },
          model: {
            value: _vm.current,
            callback: function ($$v) {
              _vm.current = $$v
            },
            expression: "current",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }