var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "m-0", attrs: { for: "input-field-name" } },
                [_vm._v("Name Field")]
              ),
              _vm._v(" "),
              _c("b-form-input", {
                attrs: { placeholder: "Enter field name", trim: "" },
                on: {
                  change: function ($event) {
                    return _vm.onChangeValue("name")
                  },
                },
                model: {
                  value: _vm.inputFields.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputFields, "name", $$v)
                  },
                  expression: "inputFields.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inputFields.isShowAngle,
                  expression: "inputFields.isShowAngle",
                },
              ],
            },
            [
              _c(
                "label",
                { staticClass: "m-0", attrs: { for: "input-field-name" } },
                [_vm._v("Angle")]
              ),
              _vm._v(" "),
              _c("b-form-input", {
                attrs: { type: "number", placeholder: "Enter angle" },
                on: {
                  change: function ($event) {
                    return _vm.onChangeValue("angle")
                  },
                },
                model: {
                  value: _vm.inputFields.angle,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputFields, "angle", _vm._n($$v))
                  },
                  expression: "inputFields.angle",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "label",
                { staticClass: "m-0", attrs: { for: "input-field-link" } },
                [_vm._v("Link")]
              ),
              _vm._v(" "),
              _c("b-form-input", {
                attrs: { placeholder: "Enter link", trim: "" },
                on: {
                  change: function ($event) {
                    return _vm.onChangeValue("link")
                  },
                },
                model: {
                  value: _vm.inputFields.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputFields, "link", $$v)
                  },
                  expression: "inputFields.link",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }