export default function fetchListDTO(data = {}) {
    return {
        params: {
            tab: data.params.tab || '',
            pathMatch: data.params.pathMatch || null,
        },
        query: {
            page: data.query.page || 1,
            search: data.query.search || '',
            type: data.query.type || '',
        }
    }
}

export const fetchListDataBasedActionCreator = (tab, pathMatch, page, search, type) => ({
    params: {
        tab,
        pathMatch,
    },
    query: {
        page,
        search,
        type,
    }
})

export const fetchListRouterBasedActionCreator = route => route;