import {recursiveWalker} from "@/includes/parse_psd_data";
import {PSD_LAYERS_DATA_TYPES} from "@/includes/parse_psd_data/constants";

export const extractLayersFromTree = tree => {
    const layers = []

    recursiveWalker(tree, node => {
        if (!PSD_LAYERS_DATA_TYPES.includes(node.type) || !node.visible) {
            return;
        }

        layers.push(node)
    })

    return layers
}

export const filterLayersByTemplate = (layers, template) => layers.filter(layer => template.fields.find(field => field.uuid === layer.uuid))

export const multipleTemplateHandler = (callback, postHandler) => async state => {
    for (const template of state.templates) {
        await callback({
            ...state,
            template,
            layers: filterLayersByTemplate(state.layers, {fields: extractLayersFromTree(template.children)}),
        })
    }

    postHandler()
}

export const getOptionsFromLibraryModal = () => {
    let options = {}

    try {
        options = JSON.parse($('#selectBkImgModal').data('options'))
    } catch (error) {
        console.error('No options in file browser modal provided')
    }

    return options
}
