var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { size: "xl", centered: "" },
      on: { hidden: _vm.handleModalClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "ButtonPrimary",
                {
                  attrs: {
                    variant: "primary",
                    disabled: _vm.isConfirmDisabled,
                  },
                  on: { click: _vm.confirm },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.fileBrowserModalType) + "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ButtonOutline",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      _vm.isModalOpened = false
                    },
                  },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isModalOpened,
        callback: function ($$v) {
          _vm.isModalOpened = $$v
        },
        expression: "isModalOpened",
      },
    },
    [_vm._v(" "), _vm._v(" "), _c("layout-folder-browser")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }