var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-content" },
    [
      _c("div", { staticClass: "modal-header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v("\n      Open PSD: "),
          _c("span", [_vm._v(" " + _vm._s(_vm.name) + " ")]),
          _vm._v(" "),
          _vm.templateNameAvailable
            ? _c("span", { staticClass: "d-block" }, [
                _vm._v(
                  "\n        Template: " +
                    _vm._s(_vm.template.name) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(_vm.currentStep, {
        tag: "component",
        attrs: { "show-save-images-to-theme": _vm.showSaveImagesToThemeTyped },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          id: "psd-layers-cancel-modal-button",
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }