import BaseElement from "./BaseElement";

export default class TableCell extends BaseElement {

  value = '';
  colspan = 1;

  constructor(value = '') {
    super();

    this.styles.setRules({
      'border-width': '1px',
      'border-color': '#000000',
      'border-style': 'solid',
      'padding-top': '2px',
      'padding-bottom': '2px',
      'padding-left': '5px',
      'padding-right': '5px',
    });

    this.value = value;
  }

  setValue(value) {
    this.value = value;
  }

  setColspan(value) {
    this.colspan = value ?? this.colspan;
  }

  getColspanAttribute() {
    return `colspan="${this.colspan}"`;
  }

  toHTML() {
    return `<td ${this.getStyleAttribute()} ${this.getColspanAttribute()}>${this.value}</td>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      value: this.value,
      colspan: this.colspan,
    }
  }
}