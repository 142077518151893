<template>
  <div>
    <b-btn
      size="sm"
      variant="outline-success"
      @click="addFilter"
    >
      Add Filter
    </b-btn>
    <div style="max-height: 300px; overflow-y: auto;">
      <div
        v-for="(input, filterIndex) in filters"
        :key="filterIndex"
      >
        <div class="d-flex align-items-center">
          <hr class="dropdown-divider w-100">
          <div class="mx-1" />
          <b-btn
            size="sm"
            variant="outline-danger"
            class="py-0 px-1 text-nowrap"
            @click="deleteFilter(filterIndex)"
          >
            Delete Filter
          </b-btn>
        </div>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Opacity">
              <b-input-group>
                <b-input
                  v-model.number="input.opacity"
                  :min="0"
                  step="0.01"
                  :max="1"
                  type="range"
                  @input="onChange"
                />
                <template #append>
                  <b-input
                    v-model.number="input.opacity"
                    :min="0"
                    step="0.01"
                    :max="1"
                    type="number"
                    @input="onChange"
                  />
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Angle">
              <b-input-group>
                <b-input
                  v-model.number="input.angle"
                  :min="-360"
                  step="1"
                  :max="360"
                  type="range"
                  @input="onChange"
                />
                <template #append>
                  <b-input
                    v-model.number="input.angle"
                    :min="-360"
                    step="1"
                    :max="360"
                    type="number"
                    @input="onChange"
                  />
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <label>Type Gradient</label>
            <b-form-radio
              v-model="input.type"
              :name="`gradient-type-${filterIndex}`"
              :value="GRADIENT_TYPES.LINEAR"
              @input="onChange"
            >
              Linear
            </b-form-radio>
            <div class="mx-3" />
            <b-form-radio
              v-model="input.type"
              :name="`gradient-type-${filterIndex}`"
              :value="GRADIENT_TYPES.RADIAL"
              @input="onChange"
            >
              Radial
            </b-form-radio>
          </b-col>
          <template v-if="input.type === GRADIENT_TYPES.RADIAL">
            <b-col cols="3">
              <b-form-group label="Left">
                <b-input
                  v-model.number="input.left"
                  step="1"
                  type="number"
                  @input="onChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Top">
                <b-input
                  v-model.number="input.top"
                  step="1"
                  type="number"
                  @input="onChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Radius">
                <b-input
                  v-model.number="input.radius"
                  step="1"
                  type="number"
                  @input="onChange"
                />
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <div class="d-flex justify-content-between">
                  <div>Colors</div>
                  <b-btn
                    size="sm"
                    variant="outline-success"
                    class="py-0 px-1"
                    @click="addColor(filterIndex)"
                  >
                    <i class="fas fa-plus" />
                  </b-btn>
                </div>
              </template>
              <div
                v-for="(color, index) in input.colors"
                :key="index"
                class="d-flex mb-1"
              >
                <ColorPicker
                  v-model="color.color"
                  :position="`top-middle`"
                  @input="onChange"
                />
                <b-input-group>
                  <b-input
                    v-model.number="color.offset"
                    :min="0"
                    step="0.01"
                    :max="1"
                    type="range"
                    @input="onChange"
                  />
                  <template #append>
                    <b-input
                      v-model.number="color.offset"
                      :min="0"
                      step="0.01"
                      :max="1"
                      type="number"
                      @input="onChange"
                    />
                  </template>
                </b-input-group>
                <div class="mx-1" />
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  class="py-0 px-1"
                  @click="deleteColor(filterIndex, index)"
                >
                  <i class="fas fa-trash" />
                </b-btn>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_MASK, DEFAULT_OBJECT_MASKS } from '@frontend/group/modules/mask-settings';
import { deepCopy } from '@frontend/utils';
import ColorPicker from '../../EditorControl/components/ColorPicker.vue';
import { GRADIENT_TYPES } from '@frontend/group/modules/mask-settings/type-mask/shadow-filter-handler';
import { percentageToInteger, integerToPercentage } from '@frontend/group/modules/mask-settings/helpers';

const transformCoords = (filters, reverse = false) => {
  const handler = reverse ? percentageToInteger : integerToPercentage; 

  return filters.map(filter => {
    filter.left = handler(filter.left);
    filter.top = handler(filter.top);
    filter.radius = handler(filter.radius);

    return filter;
  });
}

export default {
  components: {
    ColorPicker
  },
  props: {
    objectData: {
      type: Array,
      default: () => transformCoords(DEFAULT_OBJECT_MASKS()[TYPE_MASK.SHADOW_FILTER]),
    }
  },
  data() {
    return {
      GRADIENT_TYPES,
      filters: [
        ...transformCoords(DEFAULT_OBJECT_MASKS()[TYPE_MASK.SHADOW_FILTER])
      ],
    };
  },
  watch: {
    objectData() {
      this.filters = transformCoords(deepCopy(this.objectData));
    }
  },
  methods: {
    addFilter() {
      this.filters.push(DEFAULT_OBJECT_MASKS()[TYPE_MASK.SHADOW_FILTER].shift());
    },
    deleteFilter(filterIndex) {
      this.filters = this.filters.filter((color, i) => i !== filterIndex);

      this.onChange();
    },
    addColor(filterIndex) {
      this.filters[filterIndex].colors.push({
        color: '#000000',
        offset: 0
      });

      this.onChange();
    },
    deleteColor(filterIndex, colorIndex) {
      this.filters[filterIndex].colors = this.filters[filterIndex].colors.filter((color, i) => i !== colorIndex);

      this.onChange();
    },
    onChange: _.debounce(function () {

      this.$emit('input', {
        typeMask: TYPE_MASK.SHADOW_FILTER,
        objectData: transformCoords(deepCopy(this.filters), true)
      });

    }, 100)
  }
}
</script>
