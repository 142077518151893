import {fabric} from "fabric";

export const CUSTOM_TEXT_BOX_CLASS_NAME = 'CustomTextBox'

export class CustomTextBox extends fabric.Textbox {
    type = CUSTOM_TEXT_BOX_CLASS_NAME

    toJSON() {
        return this.toObject([
            'extraFields',
            'cornerStyle',
            'cornerColor',
            'transparentCorners'
        ])
    }

    static fromObject(object, callback) {
        return fabric.Object._fromObject(CUSTOM_TEXT_BOX_CLASS_NAME, object, callback, 'text')
    }
}