var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-list-group",
        [
          _c(
            "draggable",
            {
              attrs: { list: _vm.layersOrdered, group: "layers" },
              on: { end: _vm.dragEnd },
            },
            _vm._l(_vm.layersOrdered, function (field) {
              return _c(
                "b-list-group-item",
                {
                  key: field.id,
                  class: [
                    "d-flex justify-content-start align-items-center px-2 py-1",
                    { "font-weight-bold": !!field.relatedCanvasObject },
                  ],
                  attrs: {
                    button: "",
                    active: _vm.isActive(field),
                    variant: field.relatedCanvasObject ? "" : "light",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setActiveObject(field.relatedCanvasObject)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.d500",
                          modifiers: { hover: true, d500: true },
                        },
                      ],
                      staticClass: "flex-grow-1 text-center text-truncate",
                      attrs: {
                        title: field.name,
                        disabled: _vm.isTooltipDisabled(field.name),
                      },
                    },
                    [_vm._v("\n          " + _vm._s(field.name) + "\n        ")]
                  ),
                  _vm._v(" "),
                  _c("b-icon", { attrs: { icon: "grip-vertical" } }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }