<template>
  <div class="d-flex align-items-center">
    <b-form-checkbox
      v-model="isApplyToAll"
      class="mr-1"
      @change="onChange('applyToAll', isApplyToAll)"
    >
      Apply to
    </b-form-checkbox>

    <SelectTemplateDropdown
      :local-storage-key="LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE"
    />
  </div>
</template>

<script>
import SelectTemplateDropdown from './SelectTemplateDropdown.vue';
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker';
import {
  LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
} from '@frontend/group/modules/editor-control-popup/selected-template-for-apply-to-all'

export default {
  name: 'ApplyToAllImageDropdown',

  components: { SelectTemplateDropdown },

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
      isApplyToAll: false,
    };
  },

  methods: {
    onChange(action, value) {
      editorControlEventBroker.fire(EVENTS.IMAGE_CHANGED, { action, value })
    },
  },
};
</script>

<style scoped>
</style>