<template>
  <div class="d-inline-block">
    <b-dropdown
      text="Export"
      variant="outline-primary"
      size="sm"
      :disabled="disabled"
    >
      <b-dropdown-item
        @click="downloadImage(formats.PNG)"
      >
        PNG
      </b-dropdown-item>
      <b-dropdown-item
        @click="downloadImage(formats.TIFF)"
      >
        TIFF
      </b-dropdown-item>
      <b-dropdown-item
        @click="downloadClippedTiff()"
      >
        Clipped TIFF
      </b-dropdown-item>
      <b-dropdown-item
        @click="downloadClippedTiff(true)"
      >
        Clipped Product TIFF
      </b-dropdown-item>
      <b-dropdown-item
        @click="downloadImage(formats.JPG)"
      >
        JPG
      </b-dropdown-item>
      <b-dropdown-item
        @click="downloadPDF()"
      >
        PDF
      </b-dropdown-item>
      <b-dropdown-item
        @click="saveToProducts()"
      >
        To Products
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.select-folder-modal
      >
        To File Browser
      </b-dropdown-item>
    </b-dropdown>

    <div>
      <b-modal
        id="svg_file_export_modal"
        :title="modalTitle"
        centered
        @shown="handleModalShown"
        @hide="close"
      >
        <div class="f-name">
          <b-form-input
            v-model="fileName"
            type="text"
            placeholder="File name"
          />
          <b-button 
            v-b-tooltip.hover
            variant="light"
            class="icon-btn"
            title="Paste from clipboard"
            @click="onPasteFromClipboard"
          >
            <b-icon
              icon="clipboard"
              class="icon-clipboard"
            />
          </b-button>
        </div>
        
        <div
          v-if="isExportToFileBrowser"
          class="mt-3"
        >
          <select
            id="file-format"
            class="form-control form-control-sm"
            :value="selectedFormat"
            @input="setExportFormat"
          >
            <option
              v-for="(format, index) in formats"
              :key="index"
              :value="format"
            >
              {{ format.toUpperCase() }}
            </option>
          </select>
        </div>

        <template #modal-footer>
          <div
            v-if="isClippedTiffExport"
            class="d-flex flex-wrap mb-2 w-100"
          >
            <div class="compress-switch-wrapper d-flex align-items-center">
              <b-form-checkbox
                id="removeShadow"
                v-model="isRemoveShadowChecked"
                :unchecked-value="false"
                switch
                size="lg"
                @change="(payload) => setIsRemoveShadow(payload)"
              />
              <label
                class="align-self-center compress-switch-label"
                for="removeShadow"
              >
                Remove shadow
              </label>
            </div>
          </div>
          
          <div
            v-if="isClippedTiffExport"
            class="d-flex flex-wrap mb-2"
          >
            <div class="compress-switch-wrapper d-flex align-items-center">
              <b-form-checkbox
                id="compressTiffFile"
                v-model="isCompressTiffChecked"
                :unchecked-value="false"
                switch
                size="lg"
                @change="(payload) => setIsCompressTiffFile(payload)"
              />
              <label
                class="align-self-center compress-switch-label"
                for="compressTiffFile"
              >
                Compress file
              </label>
            </div>
          </div>
          
          <div 
            class="radio-tiff"
          >
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-if="isTiffExport"
                id="radio-group-tiff"
                v-model="tiffFormat"
                :aria-describedby="ariaDescribedby"
                name="select_tiff_format"
              >
                <span class="radio-title">File extension: </span>
                <b-form-radio
                  value="tiff"
                >
                  TIFF
                </b-form-radio>
                <b-form-radio
                  value="tif"
                >
                  TIF
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          
          <div>
            <button
              class="btn btn-secondary"
              @click="close"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary"
              @click="handleSave"
            >
              Save
            </button>
          </div>
        </template>
      </b-modal>
      
      <SelectFolderModal 
        @onSelectedItems="selectFolder"
      />
    </div>
  </div>
</template>

<script>
import SelectFolderModal from '@frontend/components/modules/file-browser/modals/SelectFolderModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DownloadBtn',
  components: { SelectFolderModal },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    canvas: {
      type: Object,
      default: null,
    }
  },
  
  data: () => ({
    fileName: '',
    formats: {
      PNG: 'png',
      TIFF: 'tiff',
      JPG: 'jpg',
    },
    selectedFormat: 'png',
    selectedFolder: null,
    saveFunction: null,
    modalTitle: '',
    tiffFormat: 'tiff',
    isTiffExport: false,
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'isCompressTiffFile',
      'isClippedTiffExport',
      'isRemoveShadow',
    ]),
    
    isExportToFileBrowser() {
      return this.modalTitle === 'Export to File Browser';
    },

    isCompressTiffChecked: {
      get(){
        return this.isCompressTiffFile
      },
      set(newValue){
        return newValue
      }
    },

    isRemoveShadowChecked: {
      get(){
        return this.isRemoveShadow
      },
      set(newValue){
        return newValue
      }
    },
  },

  methods: {
    ...mapActions('svgEditor', [
      'setIsProductImage',
      'setIsCompressTiffFile',
      'setIsClippedTiffExport',
      'setIsRemoveShadow',
    ]),
    
    downloadImage(format) {
      this.modalTitle = `Export ${format.toUpperCase()}`;
      this.setIsTiffExport(false);
      
      if (format === this.formats.TIFF) {
        this.setIsTiffExport(true);

        this.saveFunction = (format, fileName) => { this.canvas.downloadImage(format, fileName) };
        return this.show();
      }
      
      this.saveFunction = (fileName) => { this.canvas.downloadImage(format, fileName) };
      this.show();
    },

    downloadClippedTiff(product = false) {
      this.setIsTiffExport(true);
      this.setIsClippedTiffExport(true);
      this.setIsCompressTiffFile(false);
      this.setIsRemoveShadow(false);
      this.setIsProductImage(product);
      this.modalTitle = product ? 'Export Clipped Product Image TIFF' : 'Export Clipped TIFF';
      this.saveFunction = (format, fileName) => { this.canvas.downloadClippedTiff(format, fileName) };
      this.show();
    },

    downloadPDF() {
      this.modalTitle = 'Export PDF';
      this.saveFunction = (fileName) => { this.canvas.downloadPDF(fileName) };
      this.show();
    },

    saveToProducts() {
      this.modalTitle = 'Export to Products';
      this.saveFunction = (fileName) => { this.canvas.saveTo(fileName) };
      this.show();
    },

    selectFolder(folder) {
      [this.selectedFolder] = folder;
      this.modalTitle = 'Export to File Browser';
      this.saveFunction = (fileName, fileFormat, folder) => {
        this.canvas.saveToFileBrowser(fileName, fileFormat, folder)
      };
      this.show();
    },

    setExportFormat(event) {
      this.selectedFormat = event.target.value;
    },
    
    handleSave() {
      if (this.isTiffExport) {
        this.saveFunction(this.tiffFormat, this.fileName);  
      } else {
        this.saveFunction(this.fileName, this.selectedFormat, this.selectedFolder);
      }
      
      this.close();
      this.$emit('load');
    },
    
    show() {
      this.tiffFormat = this.formats.TIFF;
      this.$bvModal.show('svg_file_export_modal');
    },
    
    close() {
      this.$bvModal.hide('svg_file_export_modal');
      this.setIsTiffExport(false);
      this.setIsClippedTiffExport(false);
    },

    handleModalShown() {
      if (this.canvas && this.canvas.getDownloadFileName) {
        this.fileName = this.canvas.getDownloadFileName();
      }
    },

    async onPasteFromClipboard() {
      if (!navigator.clipboard) return;
      
      try {
        this.fileName = await navigator.clipboard.readText();
      } catch (err) {
        console.error('Failed to read clipboard:', err);
      }
    },

    setIsTiffExport(value) {
      this.isTiffExport = value;
    },
  },
};
</script>

<style lang="scss">
#svg_file_export_modal {
  .modal-title {
    font-weight: bold;
  }
  
  .f-name {
    display: flex;
    align-items: center;
  }

  .icon-btn {
    position: relative;
    border: none;
    margin: 0 -6px 0 5px;
    width: 35px;
    height: 35px;

    &:hover {
      border: none;
      background-color: transparent;
    }

    &:disabled {
      border: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: none !important;
      background-color: transparent !important;
    }

    &.active {
      background-color: #209c86 !important;
      color: white !important;
      border: 1px solid #209c86 !important;
    }
  }
  
  .icon-clipboard {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: 170% !important;
    color: #7f7f7f;
  }

  #file-format {
    height: 38px!important;
  }
  
  .modal-footer {
    justify-content: space-between !important;
    
    .radio-tiff {
      .form-group {
        display: contents;
        
        .radio-title {
          margin-right: 5px;
        }
      }
    }
  }

  .compress-switch-wrapper {
    margin: -5px 5px 0 5px;

    .compress-switch-label {
      margin: 8px 0 0 5px;
    }
  }
}
</style>