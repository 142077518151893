import Base from './Base';

function initColors(list = []) {
    return list.map(color => {
        const options = color?.list?.reduce((acc, option) => {
            const name = option.name.toLocaleLowerCase();

            return {
                ...acc,
                [name]: option.value,
            }
        }, {}) || {};

        return {
            name: color?.name || null,
            fullColor: [options.type, options.color].join(),
            ...options
        };
    });
}

export default class BackgroundColors extends Base {

    isColorPicker = false;

    colors = [];

    constructor(data) {
        super(data);

        this.isColorPicker = data.color_picker || this.isColorPicker;
        this.colors = initColors(this.list);
    }

    getFirstColor() {
        return this.colors[0];
    }

}