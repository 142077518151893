<template>
  <div
    class="c-file-browser__item d-flex justify-content-center align-items-center w-100 h-100 content"
    v-html="drawFileSvg(item)"
  />
</template>

<script>
import {drawFileSvg} from '@frontend/utils';

export default {
  name: "ItemView",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  methods: {
    drawFileSvg: drawFileSvg,
  }
}
</script>

<style lang="scss">
.c-file-browser__item {
  img, svg {
    max-width: 170px;
    max-height: 136px;
  }
}

.folder-items {
  .content {
    width: 16% !important;
    margin-right: 5px;

    img, svg {
      max-height: 30px;
    }
  }
}
</style>
