<template>
  <div class="d-flex">
    <b-tooltip
      :target="tooltipTarget"
      :title="title"
      placement="left"
      triggers="hover"
      boundary="viewport"
      noninteractive
    />
    <template v-if="icon">
      <b-icon :icon="icon" />
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TabTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },

  methods: {
    tooltipTarget () {
      return this.$parent.$el
    },
  },
}
</script>

<style scoped lang="scss">

</style>
