var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "layout-theme-modal", title: "Theme", centered: "" },
      on: { ok: _vm.ok, cancel: _vm.cancel },
    },
    [
      _vm.selectedInstance
        ? [
            _c(
              "b-form-group",
              [
                _c("Multiselect", {
                  attrs: {
                    placeholder: "Theme",
                    "track-by": "id",
                    label: "name",
                    options: _vm.themes,
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.theme,
                    callback: function ($$v) {
                      _vm.theme = $$v
                    },
                    expression: "theme",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }