import TemplateFieldOption from "./TemplateFieldOption"

const SELECT_VALUE_NO = 'No';
const SELECT_VALUE_YES = 'Yes';
const SELECT_VALUE_THEME = 'Theme';

export default class TemplateField {

    id = null
    element_id = null
    grid_col = null
    name = null
    order = null
    options = null
    template_id = null
    type = null

    constructor(fieldData) {
        this.id = fieldData.id
        this.element_id = fieldData.element_id
        this.grid_col = fieldData.grid_col
        this.name = fieldData.name
        this.order = fieldData.order
        this.options = new TemplateFieldOption(JSON.parse(fieldData.options))
        this.template_id = fieldData.template_id
        this.type = fieldData.type
    }

    isMoveable() {
        return this.options.moveable === 'Yes'
    }

    getId() {
        return this.id
    }

    getType() {
        return this.type
    }

    getWidth() {
        return this.options.width
    }

    getHeight() {
        return this.options.height
    }

    getLeft() {
        return this.options.x
    }

    getTop() {
        return this.options.y
    }

    getOrder() {
        return this.order
    }

    getGroupName() {
        return this.options.groupName
    }

    getLink() {
        return this.options.link
    }

    setLink(link) {
        this.options.link = link;
    }

    isColorSelector() {
        return ![null, SELECT_VALUE_NO].includes(this.options.colorSelector);
    }

    isThemeColorSelector() {
        return this.options.colorSelector === SELECT_VALUE_THEME;
    }
    
    getFontFamily() {
      return this.options.font;
    }

    getFontColor() {
        return this.options.fontColor;
    }
}
