var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "save-as-template-modal",
        title: "Save as Template",
        centered: "",
        "ok-title": "Save",
        "ok-disabled": _vm.disabledSaveButton,
      },
      on: { ok: _vm.onSave, cancel: _vm.onClose },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Name" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.templateName,
              callback: function ($$v) {
                _vm.templateName = $$v
              },
              expression: "templateName",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Customer" } },
        [
          _c("Multiselect", {
            attrs: {
              "track-by": "name",
              label: "name",
              placeholder: "Select Customer",
              options: _vm.customers,
            },
            model: {
              value: _vm.selectedCustomer,
              callback: function ($$v) {
                _vm.selectedCustomer = $$v
              },
              expression: "selectedCustomer",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Tags" } },
        [
          _c("Multiselect", {
            attrs: {
              multiple: "",
              taggable: "",
              options: _vm.tags,
              "close-on-select": false,
            },
            on: { tag: _vm.createTag },
            model: {
              value: _vm.selectedTags,
              callback: function ($$v) {
                _vm.selectedTags = $$v
              },
              expression: "selectedTags",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "form-check mr-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isUpdateExistingTemplate,
                  expression: "isUpdateExistingTemplate",
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                id: "update_existing_template",
                type: "checkbox",
                name: "update_existing_template",
              },
              domProps: {
                checked: Array.isArray(_vm.isUpdateExistingTemplate)
                  ? _vm._i(_vm.isUpdateExistingTemplate, null) > -1
                  : _vm.isUpdateExistingTemplate,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isUpdateExistingTemplate,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        (_vm.isUpdateExistingTemplate = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isUpdateExistingTemplate = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isUpdateExistingTemplate = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "update_existing_template" },
              },
              [_vm._v("\n          Update existing template\n        ")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }