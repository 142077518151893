var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("b-tooltip", {
        attrs: {
          target: _vm.tooltipTarget,
          title: _vm.title,
          placement: "left",
          triggers: "hover",
          boundary: "viewport",
          noninteractive: "",
        },
      }),
      _vm._v(" "),
      _vm.icon
        ? [_c("b-icon", { attrs: { icon: _vm.icon } })]
        : [_vm._t("default")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }