<template>
  <MultipleSelect
    v-bind="$attrs"
    multiple="multiple"
    class="multple-select-jquery form-control"
    v-on="$listeners"
  >
    <slot />
  </MultipleSelect>
</template>

<script>
import 'multiple-select/dist/multiple-select.js'
import 'multiple-select/dist/multiple-select-vue'

export default {
  components: {
    MultipleSelect: MultipleSelect
  },
}
</script>