<template>
  <div>
    <b-modal
      v-model="isOpened"
      title="Catalog Management"
      centered
      ok-title="Go to catalog"
      :ok-disabled="!(handledCatalog || selectedCatalog)"
      @ok="goToCatalog"
      @cancel="onClose"
    >
      <b-tabs content-class="mt-3">
        <b-tab
          title="Create"
          active
          @click="onTabClick"
        >
          <div role="group">
            <label for="input-live">Name of catalog:</label>
            <b-form-input
              v-model="name"
              :state="nameState"
              placeholder="Enter name"
              aria-describedby="input-live-feedback"
              trim
            />
            <b-form-invalid-feedback id="input-live-feedback">
              Enter a name of at least 1 and less than 255 chars.
            </b-form-invalid-feedback>
          </div>
          <b-button
            class="mt-3"
            variant="primary"
            :disabled="!nameState"
            @click="create"
          >
            Create catalog
          </b-button>
        </b-tab>
        <b-tab
          title="Catalogs"
          @click="onTabClick"
        >
          <b-pagination
            v-model="catalogsPage"
            :total-rows="catalogsCount"
            :per-page="catalogsPerPage"
            @change="getCatalogList"
          />
          <b-list-group>
            <b-list-group-item
              v-for="catalog in catalogs"
              :key="catalog.id"
              class="cursor-pointer d-flex justify-content-between catalog-item"
              :active="selectedCatalog === catalog"
              @click="selectedCatalog = catalog"
            >
              {{ catalog.name }}
              <div class="catalog-item__buttons">
                <b-button
                  class="btn-sm"
                  variant="outline-dark"
                  @click.stop="onRenameBtnClick(catalog)"
                >
                  <i class="bi bi-pencil-square" />
                </b-button>
                <b-button
                  class="btn-sm"
                  variant="outline-danger"
                  @click.stop="deleteCatalog(catalog.id)"
                >
                  <i class="bi bi-trash" />
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-button
            class="mt-3"
            variant="primary"
            :disabled="!selectedCatalog"
            @click="addToCatalog"
          >
            Add to selected catalog
          </b-button>
        </b-tab>
      </b-tabs>
    </b-modal>
    <RenameCatalogModal
      :catalog="selectedCatalog"
      @nameUpdated="onCatalogNameUpdated"
    />
  </div>
</template>

<script>
import {
  CATALOG_MANAGEMENT_EVENTS,
  catalogManagementEventBroker
} from "@frontend/group/modules/catalog-management/event-broker";
import CatalogService from '@/frontend/services/api/catalogs'
import RenameCatalogModal from "@frontend/components/modules/layouts-page/modals/RenameCatalogModal.vue";

const initialState = () => ({
  isOpened: false,
  name: 'New Catalog',
  layoutIds: [],
  catalogs: [],
  selectedCatalog: undefined,
  catalogsPage: 1,
  catalogsPerPage: undefined,
  catalogsCount: undefined,
  handledCatalog: undefined,
  isLivewireUpdated: false,
})
export default {
  name: "CatalogManagementModal",
  components: { RenameCatalogModal },
  data: initialState,
  computed: {
    nameState() {
      return this.name.length <= 255 && this.name.length > 0
    }
  },
  mounted() {
    catalogManagementEventBroker.on(CATALOG_MANAGEMENT_EVENTS.INIT, this.init.bind(this))
    catalogManagementEventBroker.on('openModalEdit', (config) => {
      this.isLivewireUpdated = true;
      this.selectedCatalog = {
        id: config.id,
        name: config.name
      }
      this.$bvModal.show('rename-catalog-modal');
    })
  },
  methods: {
    init({ layoutIds }) {
      this.isOpened = true;
      this.layoutIds = layoutIds;

      this.getCatalogList(this.catalogsPage);
    },
    async getCatalogList(page) {
      const { data } = await CatalogService.list(page)
      this.catalogs = data.catalogs;
      this.catalogsCount = data.count;
      this.catalogsPerPage = data.perPage;
    },
    async create(event) {
      event.preventDefault()
      try {
        const { data } = await CatalogService.create({
          name: this.name,
          layoutIds: this.layoutIds,
        });

        this.handledCatalog = data;
        
        await this.getCatalogList(this.catalogsPage);

        toastr.success('Catalog created');
        Livewire.emit('refreshCatalogs');
      } catch (error) {
        toastr.error('Something went wrong')
      }
    },
    async addToCatalog() {
      try {
        await CatalogService.update(this.selectedCatalog.id, {
          layoutIds: this.layoutIds
        });

        this.handledCatalog = this.selectedCatalog;

        toastr.success('Catalog updated')
      } catch (error) {
        toastr.error('Something went wrong')
      }
    },
    goToCatalog() {
      const lnk = document.createElement('a');
      lnk.href = `/catalogs/${this.handledCatalog?.slug || this.selectedCatalog.slug}`;
      lnk.target = 'blank';
      lnk.click();
      this.onClose();
    },
    onClose() {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        this[key] = initial[key]
      });
    },
    onTabClick() {
      const initial = initialState();
      this.selectedCatalog = initial.selectedCatalog;
      this.handledCatalog = initial.handledCatalog;
      this.name = initial.name;
    },
    onRenameBtnClick(catalog) {
      this.selectedCatalog = catalog;
      this.$bvModal.show('rename-catalog-modal');
    },
    async deleteCatalog(catalogId) {
      try {
        const { value } = await Swal.fire({
          title: "Do you want to delete the catalog?",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          icon: "warning",
        });
        
        if (value) {
          await CatalogService.delete(catalogId);
          await this.getCatalogList(this.catalogsPage);
          
          toastr.success('Catalog deleted');
        }
        
      } catch (error) {
        toastr.error('Something went wrong')
      }
    },
    async onCatalogNameUpdated() {
      this.selectedCatalog = undefined;
      
      await this.getCatalogList(this.catalogsPage);
      
      toastr.success('Catalog name updated');

      if (this.isLivewireUpdated) {
        Livewire.emit('refreshCatalogs');
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .catalog-item {
    &__buttons {
      opacity: 0;
      visibility: hidden;
      transition: all ease .3s;
    }
    
    &:hover {
      .catalog-item__buttons {
        opacity: 1;
        visibility: visible;
      }
    }
  }
</style>
