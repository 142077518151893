export const TEXT_WEIGHT = {
    100: 'Thin',
    200: 'Extra Light',
    300: 'Light',
    400: 'Normal (Regular)',
    500: 'Medium',
    600: 'Semi Bold',
    700: 'Bold',
    800: 'Extra Bold',
    900: 'Black (Heavy)'
}

