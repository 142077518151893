<template>
  <b-modal
    id="createLayoutFolderModal"
    title="Create layout folder"
    centered
    @ok="createAndSelectFolder"
    @hide="onHide"
  >
    <template #default="{ ok }">
      <b-form-group
        label="Name"
        :state="nameState"
        :valid-feedback="nameValidFeedback"
        :invalid-feedback="nameInvalidFeedback"
      >
        <b-input
          v-model="name"
          autofocus
          @keyup.enter="ok"
          @input="validateName"
        />
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreateLayoutFolderModal',

  data: () => ({
    name: null,
    nameState: null,
    nameInvalidFeedback: null,
    nameValidFeedback: null,
  }),

  methods: {
    ...mapActions('layout', [
      'createFolder',
      'checkFolderName',
    ]),
    validateName: _.debounce(async function () {
      const { state, feedback } = await this.checkFolderName(this.name)
      this.nameState = state
      this.nameValidFeedback = state ? feedback : null
      this.nameInvalidFeedback = !state ? feedback : null
    }, 500),
    async createAndSelectFolder () {
      const folder = await this.createFolder({ name: this.name })
      this.$emit('ok', folder)
    },
    onHide () {
      this.name = null
      this.nameState = null
      this.nameValidFeedback = null
      this.nameInvalidFeedback = null
    },
  }
}
</script>

<style scoped lang="scss">

</style>
