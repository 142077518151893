<template>
  <div>
    <div
      v-if="!previews.length"
      class="text-center mt-5"
    >
      <b-spinner variant="primary" />
      <p class="mt-2">
        Generating Previews...
      </p>
    </div>

    <div v-else>
      <div>
        <h3>{{ creative.name }}</h3>
        <h4>Status: <span :class="walmart.getStatusBadge(creative.status)">{{ creative.status }}</span></h4>
      </div>
      <div
        v-for="(preview, index) in previews"
        :key="index"
        class="mt-3 mb-2 border rounded p-3"
      >
        <h3>{{ preview.name }}</h3>
        <img
          :src="preview.url"
          alt="preview"
          class="img-fluid"
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as walmart from '@frontend/components/helpers/walmart-helpers.js';

export default {
  name: 'WalmartShowPreview',
  data() {
    return {
      walmart,
      formData: {
        creative_id: null,
        platform_id: null,
        company_id: null,
      },
      previews: [],
      creative: {
        name: '',
        status: ''
      }
    }
  },
  async mounted() {
    await this.getParams();
    await this.getCreativeData();
    await this.fetchPreviews();
  },
  methods: {
    async getParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.formData.creative_id = urlParams.get('creative_id');
      this.formData.platform_id = urlParams.get('platform_id');
      this.formData.company_id = urlParams.get('company_id');
    },
    async fetchPreviews() {
      const { data } = await axios.post('/walmart/fetch-previews', this.formData);

      if (data.status === 'error') {
        toastr.options = {
          timeOut: 0,
          extendedTimeOut: 0
        };
        toastr.error(data.message);
        return;
      }

      if (data.status === 'failed') {
        window.location.reload();
        return;
      }

      data.forEach((preview) => {
        let newPreview = {};

        newPreview.name = preview.name;

        newPreview.url = this.generateUrl(preview.image);

        this.previews.push(newPreview);
      })
    },
    async getCreativeData() {
      this.formData.is_specific_creative = true;

      const { data } = await axios.post('/walmart/get-creatives', this.formData);

      if (data?.result?.original?.status === 'error') {
        console.log('error', data.result.original.message);
      }

      if (data?.status === 'error') {
        console.log('error', data.message);
      }

      this.creative.name = data.data.response[0].name;
      this.creative.status = data.data.response[0].status;
    },
    generateUrl(imageBase64) {
      const byteCharacters = atob(imageBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      const previewUrl = URL.createObjectURL(blob);

      return previewUrl;
    }
  },
}
</script>