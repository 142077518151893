import mediaAPI from '../../services/api/media'

export default {
  namespaced: true,

  state: {
    videos: [],
    stock_videos: [],
    loading: null,
    current_page: null,
    last_page: null,
    stock_current_page: null,
    stock_last_page: null,
    previewSizes: [],
    previewSizeIndex: 1,
  },

  getters: {
    videos: state => state.videos,
    stock_videos: state => state.stock_videos,
    loading: state => state.loading,
    pagination: state => ({
      current_page: state.current_page,
      last_page: state.last_page,
    }),
    stock_pagination: state => ({
      current_page: state.stock_current_page,
      last_page: state.stock_last_page,
    }),
    previewSizes: state => state.previewSizes,
    previewSizeIndex: state => state.previewSizeIndex,
    currentPreviewSize: state => state.previewSizes.at(state.previewSizeIndex) || {},
    getPreviewSizeByDimensions: state => (width, height) => _.find(state.previewSizes, { width, height }),
  },

  mutations: {
    FETCH_VIDEOS (state, payload) {
      state.videos = payload.data
      state.current_page = payload.current_page
      state.last_page = payload.last_page
    },
    FETCH_STOCK_VIDEOS (state, payload) {
      state.stock_videos = payload.data
      state.stock_current_page = payload.current_page
      state.stock_last_page = payload.last_page
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_CURRENT_PAGE (state, payload) {
      state.current_page = payload
    },
    SET_STOCK_CURRENT_PAGE (state, payload) {
      state.stock_current_page = payload
    },
    FETCH_PREVIEW_SIZES (state, payload) {
      state.previewSizes = payload
    },
    SET_PREVIEW_SIZE_INDEX (state, payload) {
      state.previewSizeIndex = payload
    },
    ADD_PREVIEW_SIZE (state, payload) {
      state.previewSizes.push(payload)
    },
  },

  actions: {
    async fetchVideos ({ commit }, payload) {
      commit('SET_LOADING', true)
      try {
        const { data } = await mediaAPI.getVideos(payload)
        if (payload.my) {
          commit('FETCH_VIDEOS', data)
        } else {
          commit('FETCH_STOCK_VIDEOS', data)
        }
        return data
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteVideo ({ commit }, payload) {
      await mediaAPI.deleteFile(payload)
    },
    async duplicateVideo ({ commit }, payload) {
      return mediaAPI.copyFile(payload)
    },
    async fetchPreviewSizes ({ commit }) {
      const { data } = await axios.get('/video/size')
      commit('FETCH_PREVIEW_SIZES', data)
    },
    async addPreviewSize ({ commit }, payload) {
      const { data } = await axios.post('/video/size', payload)
      commit('ADD_PREVIEW_SIZE', data)
    },
  },
}
