var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "replace-text-modal",
        title: "Replace Text",
        "ok-title": "Replace",
        "ok-only": "",
      },
      on: { ok: _vm.onReplaceText },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-btn",
                {
                  attrs: {
                    variant: "primary",
                    disabled: _vm.disabledReplaceBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onReplaceText(true)
                    },
                  },
                },
                [_vm._v("\n      Replace All\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-form-row",
        [
          _c("label", { staticClass: "m-0" }, [_vm._v("Find Text")]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { autofocus: "" },
            model: {
              value: _vm.inputData.findText,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "findText", $$v)
              },
              expression: "inputData.findText",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c("label", { staticClass: "m-0" }, [_vm._v("Replace Text")]),
          _vm._v(" "),
          _c("b-form-input", {
            model: {
              value: _vm.inputData.replaceText,
              callback: function ($$v) {
                _vm.$set(_vm.inputData, "replaceText", $$v)
              },
              expression: "inputData.replaceText",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-checkbox",
            {
              model: {
                value: _vm.inputData.hasMatchCase,
                callback: function ($$v) {
                  _vm.$set(_vm.inputData, "hasMatchCase", $$v)
                },
                expression: "inputData.hasMatchCase",
              },
            },
            [_vm._v("\n      Match Case\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mx-2" }),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              model: {
                value: _vm.inputData.hasMatchWholeWord,
                callback: function ($$v) {
                  _vm.$set(_vm.inputData, "hasMatchWholeWord", $$v)
                },
                expression: "inputData.hasMatchWholeWord",
              },
            },
            [_vm._v("\n      Match Whole Word\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }