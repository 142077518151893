export default class Base {
    
    list = [];

    name;

    constructor(data) {
        this.name = data.name;
        this.list = data.list || this.list;
    }
}