<template>
  <b-modal
    id="svg-pdf-api-modal"
    title="Choose PDF Converter"
    size="md"
    centered
    @hide="hide()"
  >
    <div class="content">
      <select
        id="pdf-api-select"
        class="form-control form-control-sm"
        :value="api"
        @input="setAPI"
      >
        <option
          v-for="(currentAPI, index) in apiList"
          :key="(currentAPI.name) + index"
          :value="currentAPI.value"
        >
          {{ currentAPI.value }}
        </option>
      </select>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-secondary mr-2"
            @click="$bvModal.hide('svg-pdf-api-modal')"
          >
            Close
          </button>
          <button
            class="btn btn-primary mr-2"
            @click="$bvModal.hide('svg-pdf-api-modal')"
          >
            Ok
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SvgPdfApi',

  props: {},

  data: () => ({
    file: null,
    api: 'CloudConvert',
    apiList: [
      { name: 'CloudConvert', value: 'CloudConvert' },
      { name: 'ConvertAPI', value: 'ConvertAPI' },
    ],
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getProductCanvasInstance',
      'getSvgCanvasInstance',
      'isInteractiveMode',
    ]),
  },

  methods: {
    show(file) {
      this.file = file;
      this.$bvModal.show('svg-pdf-api-modal');
    },

    hide() {
      if (this.api === 'CloudConvert') {
        this.$emit('cloud-convert', this.file);
      } else {
        this.$emit('convert-api', this.file);
      }
    },

    onSave() {
      this.hide();
    },

    setAPI(event) {
      this.api = event.target.value;
    }
  }
};
</script>
