import mediaAPI from '../../services/api/media';
import Vue from 'vue'

const module = {
  namespaced: true,
  state: {
    themes: [],
    historiesProjectComments: [],
  },

  getters: {
    historiesProjectComments: state => state.historiesProjectComments,
    historiesProjectCommentsSelected: state => _.filter(state.historiesProjectComments, ['selected', true])
  },

  actions: {
    async importFile({ commit }, formData) {
      try {
        const response = await mediaAPI.importFile(formData);

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async importTemplate({ commit }, id) {
      try {
        const response = await mediaAPI.importTemplate(id);

        return response;
      } catch (error) {
        throw error;
      }
    },

    createVideo({ commit }, payload) {
      return mediaAPI.createVideo(payload)
    },

    async exportTemplate({ commit }, { rows, outputFile }) {
      const filename = outputFile ? outputFile : "New Template";

      try {
        const response = await mediaAPI.exportTemplate(rows);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        throw err;
      }
    },

    async exportAssets({ commit }, { rows, outputFile }) {
      const filename = outputFile ? outputFile : "New Assets";

      try {
        const response = await mediaAPI.exportAssets(rows);

        const link = document.createElement("a");
        link.setAttribute("download", `${filename}.zip`);
        link.href = response.data.asset_path;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async saveTemplate({ commit }, { rows, outputFile, themeID, newFontColor, newStrokeColor }) {
      const filename = outputFile ? outputFile : "Output";

      try {
        const { data } = await mediaAPI.saveTemplate(rows, filename, themeID, newFontColor, newStrokeColor);

        toastr.success(data.message);
        return data.template
      } catch (error) {
        toastr.error(error.response.data.message);
        throw error;
      }
    },

    async uploadFile({ commit }, payload) {
      try {
        const response = await mediaAPI.uploadFile(payload);

        return response;
      } catch (err) {
        throw err;
      }
    },

    unzip({ commit }, payload) {
      return mediaAPI.unzip(payload);
    },

    addTiffData(data, payload) {
        return mediaAPI.addTiffData(payload);
    },
    async convertVideo({ commit }, payload) {
      try {
        const response = await mediaAPI.convertVideo(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async convertSharedVideo({ commit }, payload) {
      try {
        return await mediaAPI.convertSharedVideo(payload);
      } catch (err) {
        throw err;
      }
    },

    async compressVideo({ commit }, payload) {
      try {
        return await mediaAPI.compressVideo(payload);
      } catch (err) {
        throw err;
      }
    },

    async uploadThumb({ commit }, payload) {
      try {
        const response = await mediaAPI.uploadThumb(payload);

        return response;
      } catch (err) {
        throw err;
      }
    },

    async trimVideo({ commit }, payload) {
      try {
        const response = await mediaAPI.trimVideo(payload);

        return response;
      } catch (err) {
        console.log(err);
      }
    },

    async cropVideo({ commit }, payload) {
      try {
        return await mediaAPI.cropVideo(payload);
      } catch (err) {
        console.log(err);
      }
    },

    async getThemes({ commit }) {
      try {
        const response = await mediaAPI.getThemes();
        
        
        commit("SET_THEMES", response.data.themes);
      } catch (err) {
        throw err;
      }
    },

    async saveCroppedImage({ commit }, payload) {
      try {
        const response = await mediaAPI.saveCroppedImage(payload);

        return response;
      } catch (err) {
        throw err;
      }
    },

    async saveRemoveBGImage({ commit }, payload) {
      try {
        const response = await mediaAPI.saveRemoveBGImage(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async getProjectVideoComments({ commit }, payload) {
      try {
        const response = await mediaAPI.getProjectVideoComments(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async addProjectVideoComment({ commit }, payload) {
      try {
        const response = await mediaAPI.addProjectVideoComment(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async addProjectVideoApproval({ commit }, payload) {
      try {
        const response = await mediaAPI.addProjectVideoApproval(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async deleteProjectVideoComment({ commit }, payload) {
      try {
        const response = await mediaAPI.deleteProjectVideoComment(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async addHistoriesProjectComment({ commit }, payload) {
      try {
        const response = await mediaAPI.addHistoriesProjectComment(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async getHistoriesProjectComments({ commit }, payload) {
      try {
        const { data: { comment } } = await mediaAPI.getHistoriesProjectComments(payload);
        commit('FETCH_HISTORIES_PROJECT_COMMENTS', comment)
      } catch (err) {
        throw err;
      }
    },

    async addHistoriesProjectApproval({ commit }, payload) {
      try {
        const response = await mediaAPI.addHistoriesProjectApproval(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async deleteHistoriesProjectComment({ commit }, payload) {
      await mediaAPI.deleteHistoriesProjectComment({ comment_id: payload.id })
      commit('DELETE_HISTORIES_PROJECT_COMMENT', payload)
    },

    async resolveHistoriesProjectComment({ commit }, payload) {
      await mediaAPI.resolveHistoriesProjectComment({ comment_id: payload.id })
      payload.resolved = true
      commit('UPDATE_HISTORIES_PROJECT_COMMENT', payload)
    },

    async sendMail({ commit }, payload) {
      try {
        const response = await mediaAPI.sendMail(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },
    
    async addVideoScreenshot({ commit }, payload) {
      try {
        const response = await mediaAPI.addVideoScreenshot(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },
    
    async deleteScreenshot({ commit }, payload) {
      try {
        const response = await mediaAPI.deleteScreenshot(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async addNewProjectComment({ commit }, payload) {
      try {
        const response = await mediaAPI.addNewProjectComment(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async getNewProjectComments({ commit }, payload) {
      try {
        const response = await mediaAPI.getNewProjectComments(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async deleteNewProjectComment({ commit }, payload) {
      try {
        const response = await mediaAPI.deleteNewProjectComment(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

    async addNewProjectApproval({ commit }, payload) {
      try {
        const response = await mediaAPI.addNewProjectApproval(payload);
        return response;
      } catch (err) {
        throw err;
      }
    },

  },

  mutations: {
    SET_THEMES(state, themes) {
      state.themes = themes;
    },

    FETCH_HISTORIES_PROJECT_COMMENTS (state, payload) {
      state.historiesProjectComments = payload
    },

    UPDATE_HISTORIES_PROJECT_COMMENT (state, payload) {
      const index = _.findIndex(state.historiesProjectComments, ['id', payload.id])
      if (index !== -1) {
        Vue.set(state.historiesProjectComments, index, payload)
      }
    },

    DELETE_HISTORIES_PROJECT_COMMENT (state, payload) {
      const index = _.findIndex(state.historiesProjectComments, ['id', payload.id])
      if (index !== -1) {
        state.historiesProjectComments.splice(index, 1)
      }
    },
  },
};

export default module;
