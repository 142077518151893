var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "background-theme-color-selector",
      attrs: { id: "background-theme-color-selector" },
      on: { ok: _vm.apply },
    },
    [
      _c(
        "div",
        { staticClass: "background-theme-color-selector__content" },
        _vm._l(_vm.fields, function (field) {
          return _c(
            "b-row",
            {
              key: field.id,
              staticClass: "background-theme-color-selector__content--item",
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    { staticClass: "background-theme-color-selector__name" },
                    [_vm._v("\n          " + _vm._s(field.name) + "\n        ")]
                  ),
                  _vm._v(" "),
                  _c("ColorPicker", {
                    staticClass: "d-flex justify-content-center",
                    attrs: { colors: _vm.options },
                    model: {
                      value: field.value,
                      callback: function ($$v) {
                        _vm.$set(field, "value", $$v)
                      },
                      expression: "field.value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-end justify-content-center",
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "background-theme-color-selector__name",
                        },
                        [_vm._v("\n            Apply to:\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown",
                        { staticClass: "ml-3" },
                        [
                          _c(
                            "b-dropdown-item-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.selectAllHandler(field)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              Select all unselected\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.applyToAllOptions,
                            function (template, templateIndex) {
                              return _c(
                                "b-dropdown-group",
                                {
                                  key: templateIndex,
                                  staticClass: "ml-2",
                                  attrs: { header: template.name },
                                },
                                _vm._l(
                                  template.fields,
                                  function (applyToField, filedIndex) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: filedIndex,
                                        attrs: {
                                          disabled:
                                            applyToField.selectedFieldId &&
                                            applyToField.selectedFieldId !==
                                              field.id,
                                          checked:
                                            !!applyToField.selectedFieldId,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.applyToClickHandler(
                                              field,
                                              applyToField,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(applyToField.name) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }