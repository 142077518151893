import BaseElement from "./BaseElement";
import TableBuilder from './index';
import { getFontFaceStyle } from "../helpers";
const DEFAUL_COLUMN_TITLE = 'Column';
const DEFAUL_CELL_VALUE = 'Value';
export default class Table extends BaseElement {

  headers = [];

  rows = [];

  constructor() {
    super();

    this.styles.setRules({
      'border-width': '1px',
      'border-color': '#000000',
      'border-style': 'solid',
      'border-collapse': 'collapse',
      'color': '#000000',
    });
  }

  setHeaders(headers) {
    this.headers = headers;
  }

  setRows(rows) {
    this.rows = rows;
  }

  setCellsRules(rules) {
    this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .forEach(cell => {
        cell.styles.setRules(rules);
      });
  }

  getSize() {
    let width = 0;
    let height = 0;

    const $table = $(this.toHTML());
    $table.css({
      display: 'block',
      visibility: 'hidden',
      position: 'absolute'
    });
    $table.appendTo('body');

    width = Math.ceil($table.width());
    height = Math.ceil($table.height());
    this.styles.setRules({
      width: `${width}px`,
      height: `${height}px`,
    })

    $table.remove();

    return { width, height }
  }

  addColumn(column) {
    this.headers.push(column);
  }

  addRow(row) {
    this.rows.push(row);
  }

  addNewColumn(index = null) {
    index = index ? index : this.headers.length;

    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);

    this.headers.splice(index, 0, cell);

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);

      this.rows[i].cells.splice(index, 0, cell);
    }
  }

  addNewRow(index = null) {
    index = index ? index : this.rows.length;

    const row = new TableBuilder.TableRow();

    for (let i = 0; i < this.headers.length; i++) {
      row.addCell(new TableBuilder.TableCell(DEFAUL_CELL_VALUE));
    }

    this.rows.splice(index, 0, row);
  }

  removeNewRow(index = null) {
    index = index ? index : this.rows.length;

    this.rows.splice(index -1, 1);
  }

  removeNewColumn(index = null) {
    index = index ? index : this.headers.length;

    this.headers.splice(index -1, 1);

    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].cells.splice(index - 1, 1);
    }
  }

  createTableGrid(columns = 10, rows = 10) {
    this.headers = [];
    this.rows = [];

    for (let i = 0; i < columns; i++) {
      this.addColumn(new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE));
    }

    for (let i = 0; i < (rows - 1); i++) {
      const row = new TableBuilder.TableRow();

      for (let i = 0; i < columns; i++) {
        row.addCell(new TableBuilder.TableCell('Value'));
      }

      this.addRow(row);
    }
  }

  addCellRow(index = null) {
    index = index ? index : this.headers.length;

    for (let i = 0; i < this.rows.length; i++) {
      const cell = new TableBuilder.TableCell(DEFAUL_CELL_VALUE);
      this.rows[i].cells.splice(index, 0, cell);
    }
  }
  
  addCellHeader(index = null) {
    const cell = new TableBuilder.TableCell(DEFAUL_COLUMN_TITLE);
    this.headers.splice(index, 0, cell);
  }
  
  updateCells(colspan) {
    colspan = colspan < 0 ? -colspan : colspan;
    
    const totalRowValue = this.rows[0].cells.reduce((sum, item) => {
      const value = Number(item.colspan);
      return sum + (isNaN(value) ? 1 : value);
    }, 0);
    
    const totalHeadersValue = this.headers.reduce((sum, item) => {
      const value = Number(item.colspan);
      return sum + (isNaN(value) ? 1 : value);
    }, 0);

    if (totalRowValue < totalHeadersValue) {
      for (let i = 0; i < colspan; i++) {
        this.addCellRow();
      }
    }

    if (totalRowValue > totalHeadersValue) {
      let index = index ? index : this.headers.length;
      for (let i = 0; i < colspan; i++) {
        this.addCellHeader(index + 1);
      }
    }
  }

  changeCellValue(cellId, data) {
    const cell = this.rows
      .reduce((acc, row) => [...acc, ...row.cells], [...this.headers])
      .find(cell => cell.id === cellId);

    if (!cell) return;

    const colspan = cell.colspan - data.colspan
    cell.setValue(data.value);
    cell.setColspan(data.colspan);
    this.updateCells(colspan)
  }

  toHTML() {
    const head = `<thead>${this.headers.map(column => column.toHTML()).join('')}</thead>`;
    const body = `<tbody>${this.rows.map(row => row.toHTML()).join('')}</tbody>`;

    return `<table ${this.getStyleAttribute()}>${head}${body}</table>`;
  }

  async toSVG() {
    const fonts = `<style>${await getFontFaceStyle([this.styles.getRule('font-family')])}</style>`;
    const size = this.getSize();
    const div = `<div xmlns="http://www.w3.org/1999/xhtml">${fonts}${this.toHTML()}</div>`;
    const foreignObject = `<foreignObject width="100%" height="100%">${div}</foreignObject>`;

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${size.width}" height="${size.height}">${foreignObject}</svg>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      headers: this.headers.map(column => column.toData()),
      rows: this.rows.map(rows => rows.toData()),
    }
  }

  async toBlob() {
    const data = await this.toSVG();

    return new Blob([data], {
      type: "image/svg+xml;charset=utf-8"
    });
  }
}