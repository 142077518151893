import LayoutTemplatesGrid from '../services/LayoutTemplatesGrid';
import { v4 as uuidv4 } from "uuid";

export const MOCK_INSTANCE_ID = 'new';

function getTransformGridData(gridData) {
    const oldTemplates = gridData.filter(template => !template.instance_id.startsWith(MOCK_INSTANCE_ID));
    const newTemplates = gridData.filter(template => template.instance_id.startsWith(MOCK_INSTANCE_ID))
        .map(template => ({ ...template, instance_id: uuidv4() }));

    return { oldTemplates, newTemplates };
}

export function getUpdatedDataLayoutTemplatesGrid(oldTemplates = [], newTemplates = []) {
    const $containerGrid = $('<div style="opacity: 0;"></div>');

    $('body').append($containerGrid);

    const layoutTemplatesGrid = new LayoutTemplatesGrid(
        $containerGrid,
        {
            templates: _.cloneDeep(oldTemplates),
            grid: {
                disableOneColumnMode: true
            }
        }
    );

    layoutTemplatesGrid.load(true);

    newTemplates.forEach(({ id, name, width, height }, index) => {
        layoutTemplatesGrid.add({
            id: id || 0,
            instanceId: `${MOCK_INSTANCE_ID}_${index}`,
            content: $('<div></div>'),
            name: name || '',
            width: width,
            height: height,
        });
    });

    $containerGrid.remove();

    return getTransformGridData(layoutTemplatesGrid.getData());
}