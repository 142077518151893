<template>
  <b-dropdown
    size="sm"
    variant="primary"
    text=". . ."
  >
    <b-dropdown-form>
      <b-list-group flush>
        <b-list-group-item>
          <b-form-checkbox
            v-model="isSelectedAllTemplate"
            size="sm"
            @change="onSelectAllTemplates"
          >
            All templates
          </b-form-checkbox>
        </b-list-group-item>
        <b-list-group-item
          v-for="template in templates"
          :key="template.instanceId"
          :disabled="getCurrentInstanceId === template.instanceId"
        >
          <b-form-checkbox
            v-model="template.isSelected"
            :disabled="getCurrentInstanceId === template.instanceId"
            size="sm"
            @change="onSelectTemplate"
          >
            {{ template.name }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import selectedTemplateForApplyToAll from '../../../../../group/modules/editor-control-popup/selected-template-for-apply-to-all';

export default {
  props: {
    localStorageKey: {
      type: String,
      default: '',
      require: true,
    },
    clearLocalStorage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectedAllTemplate: true,
      templates: [],
    }
  },
  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    getCurrentInstanceId () {
      return this.selectedInstance.id
    },
  },
  watch: {
    getCurrentInstanceId() {
      this.initSelectTemplates();
    },
  },
  mounted() {
    this.initSelectTemplates();
  },
  methods: {
    initSelectTemplates() {
      if (this.clearLocalStorage) {
        localStorage.setItem(this.localStorageKey, []);  
      }
      const selectedTemplates = selectedTemplateForApplyToAll.getSelectedTempaltes(this.localStorageKey);
      const previews = window.previews || [];

      if (!selectedTemplateForApplyToAll.hasSelectedTemplatesKey(this.localStorageKey)) {
        previews.forEach(preview => {
          selectedTemplateForApplyToAll.addSelectedTemplate(preview.id, this.localStorageKey)
        })
      }

      this.templates = previews.map(({ id, template: { name } }) => ({
        instanceId: id,
        name,
        isSelected: this.getCurrentInstanceId === id ? true : selectedTemplateForApplyToAll.hasSelectedTemplates(this.localStorageKey, id),
      }));
      
      selectedTemplates.forEach((instanceID) => {
        const template = this.templates.find(template => template.instanceId === instanceID);

        if (template) {
          template.isSelected = true;
        }
      });

      this.isSelectedAllTemplate = !this.templates.find(template => !template.isSelected);
    },
    onSelectTemplate() {
      this.isSelectedAllTemplate = !this.templates.find(template => !template.isSelected);

      this.templates.forEach(template => {
        if (template.isSelected) {
          selectedTemplateForApplyToAll.addSelectedTemplate(template.instanceId, this.localStorageKey)
        } else {
          selectedTemplateForApplyToAll.removeSelectedTemplate(template.instanceId, this.localStorageKey)
        }
      });
    },
    onSelectAllTemplates() {
      this.templates.forEach(template => {
        if (template.instanceId !== this.getCurrentInstanceId) {
          template.isSelected = this.isSelectedAllTemplate;
        }

        if (template.isSelected) {
          selectedTemplateForApplyToAll.addSelectedTemplate(template.instanceId, this.localStorageKey)
        } else {
          selectedTemplateForApplyToAll.removeSelectedTemplate(template.instanceId, this.localStorageKey)
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.b-dropdown-form {
  max-height: 40vh;
  width: 418px;
  overflow: auto;
  overflow-wrap: anywhere;
  padding: 0;
}
</style>
