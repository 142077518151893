var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "bg-maker-wrap" },
    [
      _c(
        "b-col",
        { attrs: { cols: "7" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", [_vm._v("Shape")]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-around" },
                _vm._l(_vm.shapes, function (shape, key) {
                  return _c(
                    "button",
                    {
                      key: key,
                      staticClass: "btn btn-outline-primary shape",
                      class: [
                        `shape-${key}`,
                        { active: key === _vm.activeShape },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.setShape(key)
                        },
                      },
                    },
                    [
                      key === "none"
                        ? _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("\n            ×\n          "),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "my-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      on: { input: _vm.redraw },
                      model: {
                        value: _vm.editor.fillEdges,
                        callback: function ($$v) {
                          _vm.$set(_vm.editor, "fillEdges", $$v)
                        },
                        expression: "editor.fillEdges",
                      },
                    },
                    [_vm._v("\n          Fill edges\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.trimTransparentArea,
                        callback: function ($$v) {
                          _vm.trimTransparentArea = $$v
                        },
                        expression: "trimTransparentArea",
                      },
                    },
                    [_vm._v("\n          Crop transparent area\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Gradient", "label-cols": "3" } },
            [
              _c(
                "b-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: { inline: "" },
                  on: {
                    input: _vm.redraw,
                    change: function ($event) {
                      _vm.editor.radialGradient = false
                    },
                  },
                  model: {
                    value: _vm.editor.linearGradient,
                    callback: function ($$v) {
                      _vm.$set(_vm.editor, "linearGradient", $$v)
                    },
                    expression: "editor.linearGradient",
                  },
                },
                [_vm._v("\n        Linear\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: { inline: "" },
                  on: {
                    input: _vm.redraw,
                    change: function ($event) {
                      _vm.editor.linearGradient = false
                    },
                  },
                  model: {
                    value: _vm.editor.radialGradient,
                    callback: function ($$v) {
                      _vm.$set(_vm.editor, "radialGradient", $$v)
                    },
                    expression: "editor.radialGradient",
                  },
                },
                [_vm._v("\n        Radial\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Rounding",
                "label-cols": "3",
                disabled: _vm.disabledRounding,
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c("b-input", {
                            attrs: {
                              min: "0",
                              max: _vm.maxRounding,
                              type: "number",
                              number: "",
                            },
                            on: { input: _vm.redraw },
                            model: {
                              value: _vm.editor.rounding,
                              callback: function ($$v) {
                                _vm.$set(_vm.editor, "rounding", $$v)
                              },
                              expression: "editor.rounding",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "range",
                      min: "0",
                      max: _vm.maxRounding,
                      number: "",
                    },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.rounding,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "rounding", $$v)
                      },
                      expression: "editor.rounding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-checkbox-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editor.rounding && !_vm.disabledRounding,
                      expression: "editor.rounding && !disabledRounding",
                    },
                  ],
                  staticClass: "mt-1",
                  attrs: {
                    size: "sm",
                    "button-variant": "outline-primary",
                    buttons: "",
                  },
                  on: { input: _vm.redraw },
                  model: {
                    value: _vm.selectedCorners,
                    callback: function ($$v) {
                      _vm.selectedCorners = $$v
                    },
                    expression: "selectedCorners",
                  },
                },
                [
                  _c(
                    "b-checkbox",
                    { attrs: { value: "topLeft" } },
                    [_c("b-icon", { attrs: { icon: "arrow-up-left" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-checkbox",
                    { attrs: { value: "topRight" } },
                    [_c("b-icon", { attrs: { icon: "arrow-up-right" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-checkbox",
                    { attrs: { value: "bottomRight" } },
                    [_c("b-icon", { attrs: { icon: "arrow-down-right" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-checkbox",
                    { attrs: { value: "bottomLeft" } },
                    [_c("b-icon", { attrs: { icon: "arrow-down-left" } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Angle", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c("b-input", {
                            attrs: {
                              min: "0",
                              max: "360",
                              type: "number",
                              number: "",
                            },
                            on: { input: _vm.redraw },
                            model: {
                              value: _vm.editor.angle,
                              callback: function ($$v) {
                                _vm.$set(_vm.editor, "angle", $$v)
                              },
                              expression: "editor.angle",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-input", {
                    attrs: { type: "range", min: "0", max: "360", number: "" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.angle,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "angle", $$v)
                      },
                      expression: "editor.angle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Transparency", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c("b-input", {
                            attrs: {
                              min: "0",
                              max: "100",
                              type: "number",
                              number: "",
                            },
                            on: { input: _vm.redraw },
                            model: {
                              value: _vm.editor.transparency,
                              callback: function ($$v) {
                                _vm.$set(_vm.editor, "transparency", $$v)
                              },
                              expression: "editor.transparency",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-input", {
                    attrs: { type: "range", min: "0", max: "100", number: "" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.transparency,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "transparency", $$v)
                      },
                      expression: "editor.transparency",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Amplitude", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: { type: "range", min: "0", max: "75" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.amplitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "amplitude", $$v)
                      },
                      expression: "editor.amplitude",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Frequency", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: { type: "range", min: "1", max: "100" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.frequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "frequency", $$v)
                      },
                      expression: "editor.frequency",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Gaussian blur", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c("b-input", {
                            attrs: {
                              max: "200",
                              min: "0",
                              number: "",
                              type: "number",
                            },
                            on: { input: _vm.redraw },
                            model: {
                              value: _vm.editor.blur,
                              callback: function ($$v) {
                                _vm.$set(_vm.editor, "blur", $$v)
                              },
                              expression: "editor.blur",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-input", {
                    attrs: { min: "0", max: "200", type: "range" },
                    on: { input: _vm.redraw },
                    model: {
                      value: _vm.editor.blur,
                      callback: function ($$v) {
                        _vm.$set(_vm.editor, "blur", $$v)
                      },
                      expression: "editor.blur",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Colors", "label-cols": "3" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input", {
                    attrs: { type: "range", min: "1", max: "5" },
                    on: { input: _vm.colorsNumChange },
                    model: {
                      value: _vm.colorsNum,
                      callback: function ($$v) {
                        _vm.colorsNum = $$v
                      },
                      expression: "colorsNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { tabindex: "0" }, on: { blur: _vm.hideColorPicker } },
                [
                  !_vm.editor.radialGradient
                    ? _c(
                        "div",
                        { staticClass: "colors-wrap mt-2" },
                        _vm._l(_vm.editor.colors, function (color, index) {
                          return _c("div", {
                            key: index,
                            staticClass: "color-item",
                            style: `background: ${color}`,
                            on: {
                              click: function ($event) {
                                return _vm.setActiveColor(color, index)
                              },
                            },
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editor.radialGradient
                    ? _c(
                        "div",
                        _vm._l(_vm.editor.colors, function (color, index) {
                          return _c(
                            "b-row",
                            {
                              key: index,
                              staticClass: "colors-wrap mt-2 d-inline-flex",
                              on: {
                                click: function ($event) {
                                  return _vm.setActiveColor(color, index)
                                },
                              },
                            },
                            [
                              _c("b-col", {
                                staticClass: "color-item",
                                style: `background: ${color}`,
                                attrs: { cols: "2" },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("b-row", [
                                    _vm._v(
                                      "\n                Color radius:\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "m-0 p-0 justify-content-center align-center",
                                          attrs: { cols: "7" },
                                        },
                                        [
                                          _c("b-input", {
                                            staticClass: "color-stop-range",
                                            attrs: {
                                              max: 100,
                                              min: 0,
                                              number: "",
                                              type: "range",
                                            },
                                            on: {
                                              input: (value) =>
                                                _vm.updateStepOfColor(
                                                  index,
                                                  value
                                                ),
                                            },
                                            model: {
                                              value:
                                                _vm.editor.colorSteps[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editor.colorSteps,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editor.colorSteps[index]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _c("b-input", {
                                            attrs: {
                                              max: 100,
                                              min: 0,
                                              number: "",
                                              type: "number",
                                            },
                                            on: {
                                              input: (value) =>
                                                _vm.updateStepOfColor(
                                                  index,
                                                  value
                                                ),
                                            },
                                            model: {
                                              value:
                                                _vm.editor.colorSteps[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editor.colorSteps,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editor.colorSteps[index]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Chrome", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showColorPicker,
                        expression: "showColorPicker",
                      },
                    ],
                    ref: "colorPicker",
                    staticClass: "position-absolute mt-2",
                    attrs: { value: _vm.activeColor },
                    on: { input: _vm.updateColor },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "5" } },
        [
          _c(
            "div",
            { staticClass: "canvas-wrapper", style: _vm.canvasWrapperStyle },
            [
              _c("canvas", {
                ref: "canvas",
                style: _vm.canvasStyle,
                attrs: { width: _vm.width, height: _vm.height },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            { staticClass: "my-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "mb-0", attrs: { label: "Width" } },
                    [
                      _c("b-input", {
                        attrs: { type: "number", number: "" },
                        on: { input: _vm.updateCanvasDimensions },
                        model: {
                          value: _vm.editor.width,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "width", $$v)
                          },
                          expression: "editor.width",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "mb-0", attrs: { label: "Height" } },
                    [
                      _c("b-input", {
                        attrs: { type: "number", number: "" },
                        on: { input: _vm.updateCanvasDimensions },
                        model: {
                          value: _vm.editor.height,
                          callback: function ($$v) {
                            _vm.$set(_vm.editor, "height", $$v)
                          },
                          expression: "editor.height",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.editor.keepAspectRatio,
                        callback: function ($$v) {
                          _vm.$set(_vm.editor, "keepAspectRatio", $$v)
                        },
                        expression: "editor.keepAspectRatio",
                      },
                    },
                    [_vm._v("\n          Keep aspect ratio\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Save image as" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.fileName,
                  callback: function ($$v) {
                    _vm.fileName = $$v
                  },
                  expression: "fileName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }