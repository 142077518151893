export default class Styles {

  rules = {};

  constructor(rules = {}) {
    this.rules = rules;
  }

  setRule(key, value) {
    this.rules[key] = value;
  }

  setRules(rules) {
    for (const key in rules) {
      this.setRule(key, rules[key]);
    }
  }

  getRule(key) {
    return this.rules[key] || null;
  }

  getStyle() {
    let style = '';

    for(const key in this.rules) {

      const value = this.rules[key];

      if (!value) continue;

      style += `${key}: ${value};`;
    }

    return style;
  }
}