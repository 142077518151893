<template>
  <div
    class="c-item-overlay"
  >
    <div
      v-b-tooltip.hover
      title="Replace"
      class="c-item-overlay__item delete-item"
      @click="replaceItem"
    >
      <i
        class="fas fa-redo-alt"
        style="color: #0d6854"
      />
    </div>
    <div
      v-b-tooltip.hover
      title="Delete"
      class="c-item-overlay__item"
      @click="deleteItem"
    >
      <i
        class="fas fa-trash"
        style="color: #0d6854"
      />
    </div>
  </div>
</template>


<script>
import common from "@frontend/components/modules/file-browser-product-selector/common";

import {mapGetters, mapActions} from 'vuex';

export default {
  name: "ItemOverlay",
  components: {},
  mixins: [
    common
  ],
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('fileBrowserProductSelector', [
      'selected',
    ]),
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
  },
  watch:{
    item(newVal) {
      if (this.count !== newVal.count) {
        this.count = newVal.count;
      }
    }
  },
  methods: {
    ...mapActions('fileBrowserProductSelector', [
      'enableReplaceMode',
      'setSelection',
    ]),
    replaceItem() {
      this.setSelection([this.item.id]);
      this.handleReplace();
    },
    deleteItem() {
      this.setSelection([this.item.id]);
      this.handleDelete();
    }
  }
}
</script>

<style scoped lang="scss">
  .c-item-overlay {
    margin-top: 3px;
    padding: 2px;
    display: flex;
    flex-direction: column;

    &__item {
      padding-right: 3px;
      cursor: pointer;

      .fa-edit {
        margin-top: 5px;
        color: #747474;
      }
    }
  }
</style>
