var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "form-row align-items-start" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "form-check", staticStyle: { "margin-top": "6px" } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.layoutOptions.use_custom_naming,
                  expression: "layoutOptions.use_custom_naming",
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                id: "use_custom_naming",
                type: "checkbox",
                name: "use_custom_naming",
              },
              domProps: {
                value: _vm.layoutOptions.use_custom_naming,
                checked: Array.isArray(_vm.layoutOptions.use_custom_naming)
                  ? _vm._i(
                      _vm.layoutOptions.use_custom_naming,
                      _vm.layoutOptions.use_custom_naming
                    ) > -1
                  : _vm.layoutOptions.use_custom_naming,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.layoutOptions.use_custom_naming,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.layoutOptions.use_custom_naming,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.layoutOptions,
                            "use_custom_naming",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.layoutOptions,
                            "use_custom_naming",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.layoutOptions, "use_custom_naming", $$c)
                    }
                  },
                  function ($event) {
                    return _vm.onChangeNaming("use_custom_naming")
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "use_custom_naming" },
              },
              [_vm._v("Custom naming")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.layoutOptions.custom_name,
              expression: "layoutOptions.custom_name",
            },
          ],
          staticClass: "form-control",
          attrs: { name: "custom_name" },
          domProps: { value: _vm.layoutOptions.custom_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.layoutOptions, "custom_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm._m(1),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row align-items-start" },
      [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-check", staticStyle: { "margin-top": "6px" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.layoutOptions.use_naming_table,
                    expression: "layoutOptions.use_naming_table",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  id: "use_naming_table",
                  type: "checkbox",
                  name: "use_naming_table",
                },
                domProps: {
                  value: _vm.layoutOptions.use_naming_table,
                  checked: Array.isArray(_vm.layoutOptions.use_naming_table)
                    ? _vm._i(
                        _vm.layoutOptions.use_naming_table,
                        _vm.layoutOptions.use_naming_table
                      ) > -1
                    : _vm.layoutOptions.use_naming_table,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.layoutOptions.use_naming_table,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.layoutOptions.use_naming_table,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.layoutOptions,
                              "use_naming_table",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.layoutOptions,
                              "use_naming_table",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.layoutOptions, "use_naming_table", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.onChangeNaming("use_naming_table")
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "use_naming_table" },
                },
                [_vm._v("Use Naming Table")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "text", name: "naming_table", hidden: "" },
          domProps: { value: _vm.layoutOptions.naming_table },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "text", name: "naming_table_tabs", hidden: "" },
          domProps: { value: _vm.getNamingTableTabs },
        }),
        _vm._v(" "),
        _c("NamingTableInput", {
          staticClass: "col form-group",
          attrs: {
            "selected-names-from-tabs": _vm.selectedNamesFromTabs,
            "naming-design-tabs": _vm.namingDesignTabs,
            "has-choose-variables": true,
          },
          on: { onSelectedNamesFromTabs: _vm.onSelectedNamesFromTabs },
          model: {
            value: _vm.layoutOptions.naming_table,
            callback: function ($$v) {
              _vm.$set(_vm.layoutOptions, "naming_table", $$v)
            },
            expression: "layoutOptions.naming_table",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "form-check mr-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.layoutOptions.compress_max_file_size,
                expression: "layoutOptions.compress_max_file_size",
              },
            ],
            staticClass: "form-check-input",
            attrs: {
              id: "compress_max_file_size",
              type: "checkbox",
              name: "compress_max_file_size",
            },
            domProps: {
              checked: Array.isArray(_vm.layoutOptions.compress_max_file_size)
                ? _vm._i(_vm.layoutOptions.compress_max_file_size, null) > -1
                : _vm.layoutOptions.compress_max_file_size,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.layoutOptions.compress_max_file_size,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.layoutOptions,
                        "compress_max_file_size",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.layoutOptions,
                        "compress_max_file_size",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.layoutOptions, "compress_max_file_size", $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "compress_max_file_size" },
            },
            [_vm._v("Compress to Max File Size")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.layoutOptions.compress_max_file_size
        ? _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.layoutOptions.compress_max_file_size_value,
                  expression: "layoutOptions.compress_max_file_size_value",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "compress_max_file_size_value",
                type: "number",
                name: "compress_max_file_size_value",
              },
              domProps: {
                value: _vm.layoutOptions.compress_max_file_size_value,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.layoutOptions,
                    "compress_max_file_size_value",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _c("label", { staticClass: "ml-2" }, [_vm._v("Kb")]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row mt-2" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "form-check mr-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.layoutOptions.images_full_resolution,
                expression: "layoutOptions.images_full_resolution",
              },
            ],
            staticClass: "form-check-input",
            attrs: {
              id: "images_full_resolution",
              type: "checkbox",
              name: "images_full_resolution",
            },
            domProps: {
              checked: Array.isArray(_vm.layoutOptions.images_full_resolution)
                ? _vm._i(_vm.layoutOptions.images_full_resolution, null) > -1
                : _vm.layoutOptions.images_full_resolution,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.layoutOptions.images_full_resolution,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.layoutOptions,
                        "images_full_resolution",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.layoutOptions,
                        "images_full_resolution",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.layoutOptions, "images_full_resolution", $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "images_full_resolution" },
            },
            [_vm._v("Use images in full resolution")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "form-row align-items-start",
        attrs: { id: "groups_templates" },
      },
      [
        _c("div", { staticClass: "col-md-2" }, [
          _c("label", [_vm._v("Template groups")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-10 form-group" }, [
          _c("div", {
            staticClass: "overflow-auto groups-container",
            staticStyle: { "max-height": "100px" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10" }, [
              _c("label", [_vm._v("Templates")]),
              _vm._v(" "),
              _c("select", {
                staticClass: "form-control",
                attrs: { multiple: "multiple" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("label", [_vm._v("Duration")]),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control duration",
                attrs: { type: "number", value: "3" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 d-flex justify-content-end mt-3" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary text-nowrap",
                    attrs: { type: "button" },
                  },
                  [_vm._v("\n            Add Group\n          ")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-items-center" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("strong", [_vm._v("Variables:")]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-tooltip" }, [
        _c("img", {
          staticClass: "custom-tooltip-icon",
          attrs: { src: "/img/icons/info.png", alt: "Show variables" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "custom-tooltip-container" }, [
          _c(
            "div",
            { staticClass: "custom-tooltip-content bg-dark text-light" },
            [
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%Brand%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%TemplateName%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%ProjectName%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%LayoutName%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%Version%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%TemplateWidth%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%TemplateHeight%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%LayoutTitle%")]
              ),
              _vm._v(",\n              "),
              _c(
                "a",
                { staticClass: "custom-naming-variable", attrs: { href: "#" } },
                [_vm._v("%SpaceToUnderscore%")]
              ),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }