<template>
  <div
    v-show="isShow"
    class="mb-3"
  >
    <img
      ref="image"
      style="max-height: 400px;"
    >

    <div
      v-if="isLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <b-spinner variant="primary" />
      <span class="ml-2">
        Loading...
      </span>
    </div>
    <div v-else>
      <b-form-input
        v-model="filename"
        class="my-3"
      />

      <b-input-group class="align-items-center mb-3">
        <b-form-input
          v-model.number="width"
          type="number"
        />
        <b-icon
          icon="x-lg"
          class="mx-2"
        />
        <b-form-input
          v-model.number="height"
          type="number"
        />
      </b-input-group>

      <b-button
        variant="primary"
        :disabled="isSaving || !filename"
        @click="onSave"
      >
        {{ isSaving ? 'Saving...' : 'Save' }}
      </b-button>

      <b-button
        variant="primary"
        @click="cropperInstance.reset()"
      >
        Reset
      </b-button>

      <b-button
        variant="primary"
        @click="$emit('onCancel')"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      cropperInstance: null,
      filename: '',
      width: 0,
      height: 0
    };
  },
  watch: {
    isShow() {
      if (this.isShow) {
        this.init();
      } else {
        this.destroy();
      }
    },
    src() {
      if (this.isShow) {
        this.destroy();
        this.init();
      }
    }
  },
  methods: {
    init() {
      const name = this.src.substring(this.src.lastIndexOf("/") + 1).split(".");
      const filename = name[0];
      const extension = name[1];
      this.filename = filename + "_cropped." + extension;

      this.isLoading = true;

      this.$refs.image.onload = async (event) => {

        await this.initCrop(this.$refs.image);

        this.isLoading = false;
      }
      this.$refs.image.src = this.src;
    },
    initCrop(imageElement) {
      return new Promise((resolve, reject) => {
        this.cropperInstance = new Cropper(imageElement, {
          autoCropArea: 1,
          zoomable: false,
          ready: () => {
            resolve();
          },
          crop: (event) => {
              this.width = Math.round(event.detail.width);
              this.height = Math.round(event.detail.height);
          }
        });
      })
    },
    destroy() {
      this.destroyCropper();
      this.cropperInstance = null;
      this.filename = '';
      this.width = 0;
      this.height = 0;
    },
    destroyCropper() {
      if (this.cropperInstance) {
        this.cropperInstance.destroy()
      }
    },
    onSave() {
      this.isSaving = true;

      this.cropperInstance.getCroppedCanvas().toBlob((blob) => {
        this.$emit('onSave', { blob, fileName: this.filename });
        this.$emit('onCancel');
        this.isSaving = false;
      });
    },
  }
}
</script>
