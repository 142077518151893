import {
  PADDING, PADDING_BOTTOM_KEY,
  PADDING_LEFT_KEY, PADDING_RIGHT_KEY, PADDING_TOP_KEY
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/constants";
import {updateObject} from "@frontend/group/modules/copy-and-resize/helpers";

const applyPadding = ({ dimension, object, x, y, scaleX, scaleY, originalPoints, canvas }) => {
  let newX = x, newY = y, newScaleX = scaleX, newScaleY = scaleY;

  for (const paddingDirection in PADDING) {
    let padding = 0
    let spaceLeft = 0
    switch (paddingDirection) {
      case PADDING_LEFT_KEY:
        padding = dimension.width * PADDING[paddingDirection]
        newX = newX >= padding ? newX : padding
        break;
      case PADDING_TOP_KEY:
        padding = dimension.height * PADDING[paddingDirection]
        newY = newY >= padding ? newY : padding
        break;
      case PADDING_RIGHT_KEY:
        padding = dimension.width * PADDING[paddingDirection]
        spaceLeft = dimension.width - (newX + object.getScaledWidth())
        newX = (spaceLeft >= padding) ? newX : (newX - padding - spaceLeft)

        break;
      case PADDING_BOTTOM_KEY:
        padding = dimension.height * PADDING[paddingDirection]
        spaceLeft = dimension.height - (newY + object.getScaledHeight())
        newY = (spaceLeft >= padding) ? newY : (newY - padding - spaceLeft)
        break;
    }
  }

  const paddingY = newY - y;
  const paddingX = newX - x;

  if (
    (newY + object.height * scaleY > dimension.height - paddingY) &&
    (originalPoints.y + object.getScaledHeight() <= canvas.height)
  ) {
    const desiredHeight = dimension.height - newY - paddingY;
    const downscaleFactor = desiredHeight / (object.height * scaleY);

    newScaleY *= downscaleFactor;
    newScaleX *= downscaleFactor;
  }

  if (
    (newX + object.width * scaleX > dimension.width - paddingX) &&
    (originalPoints.x + object.getScaledWidth() <= canvas.width)
  ) {
    const desiredWidth = dimension.width - newX - paddingX;
    const downscaleFactor = desiredWidth / (object.width * scaleX);

    newScaleY *= downscaleFactor;
    newScaleX *= downscaleFactor;
  }

  return { x: newX, y: newY, scaleX: newScaleX, scaleY: newScaleY }
}

export const applyPaddings = ({ objects, dimension, rsW, rsH, canvas }) => {
  for (const object of objects) {
    const position = object.getPointByOrigin('left', 'top')
    const scaleX = object.get('scaleX')
    const scaleY = object.get('scaleY')
    const { x, y, scaleX: updatedScaleX, scaleY: updatedScaleY } = applyPadding({
      dimension,
      object,
      x: position.x,
      y: position.y,
      scaleX,
      scaleY,
      canvas,
      originalPoints: position
    });

    object.setPositionByOrigin({
      x,
      y,
    }, 'left', 'top')

    object.set({
      scaleX: updatedScaleX,
      scaleY: updatedScaleY,
    })

    updateObject(object, { x: rsW, y: rsH });
  }
}