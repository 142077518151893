import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { EventBroker } from '../../lib/utils/event-broker';
import { SVG_EDITOR_EVENTS } from './events';
import OCRService from '../api/ocr';
import SvgImageEditor from '../api/svg-image-editor';
import { fileLoaded, getLassoAsImageInstance, getSelectionBoxAsImageInstance } from './canvas-trait';

const CROSS_HAIR = 'crosshair';
const MOVE = 'move';
const SVG_STORE = 'svgEditor/';
const RECTANGLE_MASK_ID = 'rectangleMask';
const SELECTION_BOX_ID = 'selectionBox';
const PRODUCT_ID = 'bgProductImage';
const LANG = {
  BOTH: 'Both',
  EN: 'English',
  FR: 'French',
}

export default class SVGCanvas {
  eventBroker;
  static DEFAULT_ELEMENT_HEIGHT = 1024;
  canvas;
  vueStore;
  initialState = null;
  initialSvgUrl = null;
  selectionRectangle = null;
  isSelecting = false;
  isAltSelectionMode = false;
  selectedObjects = [];
  zoomLevel = 1;
  minZoomLevel = 0.47;
  maxZoomLevel = 6;
  pointerStartX = 0;
  pointerStartY = 0;
  workingArea = null;
  isRectangleMaskMode = false;
  rectangleMask = null;
  beforeRectangleMask = null;
  isSelectionBoxMode = false;
  selectionBox = null;
  modifiedSvgXml = null;
  modifiedSvgUrl = null;
  removedGTagsIndexes = [];
  isLassoMode = false;
  loadingProcess = false;
  extractModalRef = null;
  
  canvasState = [];
  currentStateIndex = 0;
  undoStatus = false;
  redoStatus = false;

  Modes = {
    ALT_SELECTION: 'isAltSelectionMode',
    SELECTION_BOX: 'isSelectionBoxMode',
    RECTANGLE_MASK: 'isRectangleMaskMode',
    LASSO: 'isLassoMode',
  };
  
  // traits
  getSelectionBoxAsImageInstance = getSelectionBoxAsImageInstance;
  getLassoAsImageInstance = getLassoAsImageInstance;
  fileLoaded = fileLoaded;

  constructor(canvasElement, store, height = SVGCanvas.DEFAULT_ELEMENT_HEIGHT) {
    canvasElement.width = window.innerWidth * 0.4;
    SVGCanvas.DEFAULT_ELEMENT_HEIGHT = height;
    canvasElement.height = height;
    this.canvas = new fabric.Canvas(canvasElement);
    this.vueStore = store;
    this.eventBroker = new EventBroker();
    this.objectBeSelectedWithoutBoundingRectangle();
    this.bindEvents();
    this.updateSvgInstance();
  }

  get selectionBoxIds() {
    return [SELECTION_BOX_ID, PRODUCT_ID]
  }

  get multiplier() {
    return this.getZoomMultiplier()
  }

  on(...args) {
    this.eventBroker.on(...args)
  }

  bindEvents() {
    this.canvas.on('mouse:down', this.onMouseDown.bind(this));
    this.canvas.on('mouse:up:before', this.beforeOnMouseUp.bind(this));
    this.canvas.on('mouse:up', this.onMouseUp.bind(this));
    this.canvas.on('mouse:move', this.onMouseMove.bind(this));
    this.canvas.on('before:selection:cleared', this.beforeOnSelectionCleared.bind(this));
    this.canvas.on('selection:cleared', this.onSelectionCleared.bind(this));
    
    this.canvas.on('object:modified', this.saveCanvasState.bind(this));
  }
  
  initSaveCanvasState() {
    this.canvasState = [];
    this.currentStateIndex = 0;
    this.saveCanvasState();
  }

  saveCanvasState() {
    const isSomeModeOn = [
      this.isRectangleMaskMode,
      this.isSelectionBoxMode,
      this.isLassoMode,
      this.undoStatus,
      this.redoStatus,
    ].some(Boolean);

    if (isSomeModeOn) return;
    
    const jsonData = this.canvas.toJSON();

    if (this.currentStateIndex < this.canvasState.length - 1) {
      this.canvasState = this.canvasState.slice(0, this.currentStateIndex + 1);
    }

    this.canvasState.push(jsonData);
    this.currentStateIndex = this.canvasState.length - 1;

    if (this.currentStateIndex > 10) {
      this.canvasState.shift();
      this.currentStateIndex--;
    }
  }

  undoCanvasState() {
    const isSomeModeOn = [
      this.isRectangleMaskMode,
      this.isSelectionBoxMode,
      this.isLassoMode,
    ].some(Boolean);

    if (isSomeModeOn) return;

    if (this.currentStateIndex <= 0) {
      return;
    }
    
    this.undoStatus = true;
    this.canvas.loadFromJSON(this.canvasState[--this.currentStateIndex], () => {
      this.canvas.renderAll();
      this.updateCanvasCursor();
      this.undoStatus = false;
    });
  }

  redoCanvasState() {
    const isSomeModeOn = [
      this.isRectangleMaskMode,
      this.isSelectionBoxMode,
      this.isLassoMode,
    ].some(Boolean);

    if (isSomeModeOn) return;
    
    if (this.currentStateIndex >= this.canvasState.length - 1) {
      return;
    }

    this.redoStatus = true;
    this.canvas.loadFromJSON(this.canvasState[++this.currentStateIndex], () => {
      this.canvas.renderAll();
      this.updateCanvasCursor();
      this.redoStatus = false;
    });
  }

  onMouseDown(event) {
    this.setPointerStart(event);
    
    if (this.isAltSelectionMode) {
      this.createSelectionRectangle();
    }
    
    if (this.isRectangleMaskMode) {
      this.createMaskRectangle();
    }
    
    if (this.isSelectionBoxMode) {
      this.createSelectionBoxRectangle();
    }
  }

  onMouseMove(event) {
    if (this.isAltSelectionMode) {
      this.drawSelectionRectangle(event);
    }

    if (this.isRectangleMaskMode) {
      this.drawMaskRectangle(event);
    }
    
    if (this.isSelectionBoxMode) {
      this.drawSelectionBox(event);
    }
  }

  beforeOnMouseUp() {
    if (this.isAltSelectionMode) {
      this.selectAllInsideSR();
    }
  }

  onMouseUp() {
    this.discardActiveObjectOnProductCanvas();
    
    if (this.selectedObjects.length) {
      this.selectedObjects.forEach(obj => this.altModeMakeSelectedObject(obj));
    }

    if (this.isRectangleMaskMode) {
      this.stopRectangleMask();
    }
    
    if (this.isSelectionBoxMode) {
      this.stopSelectionBox();
    }
    
    if (this.isLassoMode) {
      this.stopLasso();
    }

    // uncomment if needed (auto select working area in alt selection mode)
    // if (this.isAltSelectionMode) {
    //   this.drawFromSelectedObjects();
    // }

    this.updateSvgInstance();
  }

  beforeOnSelectionCleared() {
    const objects = this.canvas.getObjects();
    
    if (this.isAltSelectionMode) {
      objects.forEach((obj) => {
        obj.set({ selectable: false, active: false });
      });
    }
  }

  onSelectionCleared() {
    this.updateSvgInstance();
  }

  onKeyUp(e) {
    switch (e.keyCode) {
      // Delete key
      case 46:
      case 8:
        return this.deleteSelectedObjects();
      // F key
      case 70:
        return this.enlargeToSelectionBox();
      // G key
      case 71:
        return this.altSelectionMode();
      // Enter key
      case 13:
      case 36:
      case 76:
        this.applyRectangleMask();
        this.applySelectionBoxTarget();
        this.applyLasso();
        this.selectWorkingAreaInAltSelectionMode();
        break;
      // Esc key
      case 27:
      case 53:
        return this.applyBeforeMaskCanvas();
    }
  }

  switchToMode(mode) {
    const operations = {
      [this.Modes.ALT_SELECTION]: this.clearAltSelectionMode.bind(this),
      [this.Modes.SELECTION_BOX]: this.clearSelectionBox.bind(this),
      [this.Modes.RECTANGLE_MASK]: this.clearRectangleMask.bind(this),
      [this.Modes.LASSO]: this.clearLasso.bind(this),
    };

    for (const [modeName, operation] of Object.entries(operations)) {
      if (mode !== modeName) {
        operation();
      }
    }
  }

  objectBeSelectedWithoutBoundingRectangle() {
    this.canvas._shouldObjectBeSelected = (e, target) => {
      const targetPoint = new fabric.Point(target.left, target.top);
      const pointer = this.canvas.getPointer(e);
      
      const xPoints = [pointer.x, targetPoint.x, targetPoint.x + target.width * target.scaleX];
      const yPoints = [pointer.y, targetPoint.y, targetPoint.y + target.height * target.scaleY];
      const minX = Math.min(...xPoints);
      const minY = Math.min(...yPoints);
      const maxX = Math.max(...xPoints);
      const maxY = Math.max(...yPoints);

      return (minX <= pointer.x && maxX >= pointer.x) && (minY <= pointer.y && maxY >= pointer.y);
    };
  }
  
  discardActiveObjectOnProductCanvas() {
    const isProductInstanceHasActiveObjects = this.vueStore.getters[`${SVG_STORE}isProductInstanceHasActiveObjects`];
    
    if (isProductInstanceHasActiveObjects) {
      const productInstance = this.vueStore.getters[`${SVG_STORE}getProductInstance`];
      productInstance.discardActiveObject().renderAll();
    }
  }

  getBgImageInstance() {
    return this.vueStore.getters[`${SVG_STORE}getBgImageInstance`];
  }

  isBgPreviewOn() {
    return this.vueStore.getters[`${SVG_STORE}isBgPreviewOn`];
  }
  
  setPointerStart(event) {
    const pointer = this.canvas.getPointer(event);

    this.pointerStartX = pointer.x;
    this.pointerStartY = pointer.y;
  }
  
  createSelectionRectangle() {
    this.isSelecting = true;

    this.selectionRectangle = new fabric.Rect({
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: 'rgba(10,10,255,0.2)',
      width: 0,
      height: 0,
    });

    this.canvas.add(this.selectionRectangle);
  }
  
  drawSelectionRectangle(event) {
    if (!this.isSelecting || !!this.canvas.getActiveObjects().length) return;

    const pointer = this.canvas.getPointer(event);

    this.selectionRectangle.set({
      left: Math.min(pointer.x, this.pointerStartX),
      top: Math.min(pointer.y, this.pointerStartY),
      width: Math.abs(pointer.x - this.pointerStartX),
      height: Math.abs(pointer.y - this.pointerStartY),
    });
  }
  
  selectAllInsideSR() {
    this.isSelecting = false;
    this.canvas.remove(this.selectionRectangle);

    const selectionRectCoords = {
      left: Math.min(this.selectionRectangle.left, this.selectionRectangle.left + this.selectionRectangle.width),
      top: Math.min(this.selectionRectangle.top, this.selectionRectangle.top + this.selectionRectangle.height),
      right: Math.max(this.selectionRectangle.left, this.selectionRectangle.left + this.selectionRectangle.width),
      bottom: Math.max(this.selectionRectangle.top, this.selectionRectangle.top + this.selectionRectangle.height),
    };

    this.selectedObjects = this.canvas.getObjects().filter(obj => {
      const objRect = obj.getBoundingRect(true, true);

      return (
        objRect.left >= selectionRectCoords.left &&
        objRect.top >= selectionRectCoords.top &&
        objRect.left + objRect.width <= selectionRectCoords.right &&
        objRect.top + objRect.height <= selectionRectCoords.bottom
      );
    });

    if (this.selectedObjects.length) {
      this.selectedObjects.forEach(obj => this.altModeMakeSelectedObject(obj));
    }
  }

  altModeMakeSelectedObject(obj) {
    obj.set({ selectable: obj.id !== PRODUCT_ID, active: obj.id !== PRODUCT_ID });
  }
  
  createMaskRectangle() {
    this.rectangleMask = new fabric.Rect({
      id: RECTANGLE_MASK_ID,
      left: this.pointerStartX,
      top: this.pointerStartY,
      strokeDashArray: [5, 5],
      fill: 'rgba(253,115,115,0.2)',
      stroke: 'black',
      selectable: true,
      lockRotation: true,
    });
    
    this.rectangleMask.setControlVisible('mtr', false);
    this.canvas.add(this.rectangleMask);
  }

  drawMaskRectangle(event) {
    if (!this.rectangleMask) return;
    
    const pointer = this.canvas.getPointer(event);

    this.rectangleMask.set({
      left: Math.min(pointer.x, this.pointerStartX),
      top: Math.min(pointer.y, this.pointerStartY),
      width: Math.abs(pointer.x - this.pointerStartX),
      height: Math.abs(pointer.y - this.pointerStartY),
    });
    
    this.canvas.renderAll();
  }
  
  stopRectangleMask() {
    this.isRectangleMaskMode = !this.isRectangleMaskMode;
    this.rectangleMask.setCoords();
  }
  
  clearRectangleMask() {
    if (this.rectangleMask) {
      this.canvas.remove(this.rectangleMask);
      this.rectangleMask = null;
      this.isRectangleMaskMode = false;
      this.updateRectMaskModeState();
    } else {
      this.rectangleMask = null;
      this.isRectangleMaskMode = false;
      this.beforeRectangleMask = null;
      this.updateRectMaskModeState();
    }
  }

  saveBeforeRecMask() {
    this.canvas.remove(this.rectangleMask);
    this.beforeRectangleMask = JSON.stringify(this.canvas.toJSON());
  }

  applyRectangleMask() {
    if (!this.rectangleMask) return;
    
    this.removeBgImage();
    this.saveBeforeRecMask();
    this.setRectangleMaskToCanvas();
    this.drawImageFromRectangleMask();
  }

  applyBeforeMaskCanvas() {
    if (this.beforeRectangleMask) {
      this.canvas.clear();
      this.canvas.loadFromJSON(JSON.parse(this.beforeRectangleMask));
      this.drawBgImagePreview();
      this.beforeRectangleMask = null;
      this.isRectangleMaskMode = false;
      this.updateRectMaskModeState();
      this.canvas.requestRenderAll();
    }

    if (this.checkMaskExist()) {
      this.rectangleMaskMode();
    }
  }
  
  setRectangleMaskToCanvas() {
    this.rectangleMask.set({originX: 'left', originY: 'top'});

    this.canvas.getObjects().forEach((obj) => {
      if (obj.id !== RECTANGLE_MASK_ID) {
        obj.set({
          clipPath: null,
          dirty: true
        });
      }
    });

    const { width, height } = this.rectangleMask;
    const zoom = this.canvas.getZoom();

    const tempScaleX = this.rectangleMask.scaleX;
    const tempScaleY = this.rectangleMask.scaleY;
    this.rectangleMask.set({ scaleX: 1, scaleY: 1, width: width * tempScaleX, height: height * tempScaleY });

    const boundingRect = this.rectangleMask.getBoundingRect();

    const rectangleClipPath = new fabric.Rect({
      left: boundingRect.left / zoom,
      top: boundingRect.top / zoom,
      width: boundingRect.width / zoom,
      height: boundingRect.height / zoom,
      absolutePositioned: true,
    });

    this.rectangleMask.set({ scaleX: tempScaleX, scaleY: tempScaleY, width: width, height: height });
    
    this.canvas.getObjects().forEach((obj) => {
      obj.set({
        clipPath: rectangleClipPath,
        dirty: true
      });
    });
  }

  drawImageFromRectangleMask() {
    const boundingBox = {
      left: this.rectangleMask.left,
      top: this.rectangleMask.top,
      width: this.rectangleMask.width * this.rectangleMask.scaleX,
      height: this.rectangleMask.height * this.rectangleMask.scaleY
    };

    const zoom = this.canvas.getZoom();
    const multiplier = this.getZoomMultiplier();

    const offScreenCanvas = document.createElement('canvas');
    const context = offScreenCanvas.getContext('2d');
    offScreenCanvas.width = boundingBox.width * multiplier;
    offScreenCanvas.height = boundingBox.height * multiplier;

    let img = new Image();

    img.onload = () => {
      context.drawImage(
        img,
        boundingBox.left * multiplier * zoom,
        boundingBox.top * multiplier * zoom,
        boundingBox.width * multiplier * zoom,
        boundingBox.height * multiplier * zoom,
        0,
        0,
        offScreenCanvas.width,
        offScreenCanvas.height
      );

      const dataUrl = offScreenCanvas.toDataURL();

      fabric.Image.fromURL(dataUrl, (newImg) => {
        newImg.set({
          left: boundingBox.left,
          top: boundingBox.top,
          scaleX: 1 / multiplier,
          scaleY: 1 / multiplier
        });
        
        this.canvas.clear().add(newImg).setActiveObject(newImg).renderAll();
        this.updateSvgInstance();
      });
    };

    img.src = this.canvas.toDataURL({ multiplier: multiplier });
    this.clearRectangleMask();
  }

  rectangleMaskMode() {
    this.isRectangleMaskMode = !this.isRectangleMaskMode;
    this.updateRectMaskModeState();
    
    if (this.checkMaskExist()) {
      this.clearRectangleMask();
    }
    
    this.switchToMode(this.Modes.RECTANGLE_MASK);
    this.isRectangleMaskMode && toastr.success('Press Enter to set mask');
    this.updateCanvasCursor();

    this.canvas.discardActiveObject().renderAll();

    if (this.checkIsMaskModActive()) {
      this.applyBeforeMaskCanvas();
    }
  }
  
  updateRectMaskModeState() {
    this.vueStore.dispatch(`${SVG_STORE}setRecMaskMode`, this.checkIsMaskModActive());
  }
  
  checkMaskExist() {
    const objects = this.canvas.getObjects();
    return !!objects.find((o) => o.id === RECTANGLE_MASK_ID);
  }
  
  checkIsMaskModActive() {
    return (!!this.checkMaskExist() || !!this.isRectangleMaskMode || !!this.beforeRectangleMask);
  }

  zoomIn() {
    this.zoomLevel += 0.1;
    this.applyZoom();
  }

  zoomOut() {
    this.zoomLevel -= 0.1;
    this.applyZoom();
  }
  
  setZoomLevel(zoomLevel) {
    this.zoomLevel = zoomLevel;
    this.canvas.setZoom(this.zoomLevel);
  }
  
  interactiveMode() {
    if (!this.vueStore.getters[`${SVG_STORE}isInteractiveMode`]) {
      this.vueStore.dispatch(`${SVG_STORE}setInteractiveMode`, true);
    }

    this.canvas.clear();
    this.setZoomLevel(this.minZoomLevel);

    fabric.loadSVGFromString(this.modifiedSvgXml, (objects) => {
      this.loadSvgFromStringProcess(objects);
      this.initSaveCanvasState();
      document.activeElement.blur();
    });
  }
  
  loadSvgFromStringProcess(objects) {
    this.addObjectsProcess(objects);
    this.updateSvgInstance();
    this.updateCanvasCursor();
    this.redrawBgImage();
    this.enlargeAndCentered();
  }
  
  enlargeAndCentered() {
    this.selectAllObjects();
    this.enlargeSelected();
  };

  applyZoom() {
    this.canvas.setZoom(this.zoomLevel);
    this.canvas.renderAll();
    this.redrawBgImage();
  }

  removeBG() {
    const selectedObject = this.canvas.getActiveObject();

    if (selectedObject) {
      if (selectedObject.type === 'image') {
        if (!selectedObject?.bgSet) {
          const base64 = selectedObject.getSrc().replace(/^data:image\/\w+;base64,/, '');
          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });
          const file = new File([blob], 'filename.png', { type: 'image/png' });
          const formData = new FormData();
          formData.append('file', file);

          axios.post('/video/remove-bg-image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(({ data }) => {
            selectedObject.setSrc(data.data.path, () => {
              selectedObject.set({
                scaleX: selectedObject.scaleX * 1.001,
                scaleY: selectedObject.scaleY * 1.001
              });

              selectedObject.canvas.renderAll();
              selectedObject.bgSet = true;
              this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED)
              toastr.success('Background removed');
            });
            selectedObject.bgSet = true;
          }).catch((error) => {
            toastr.error('Remove background failed');
            console.log(error);
            this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED)
          });
        }
      }
    }
  }

  addObjectsProcess(objects) {
    for (let i = 0; i < objects.length; i++) {
      this.addObject(objects[i]);
    }
    
    this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    toastr.success('File loaded');
  }

  getObjectProperty(object, property, defaultValue) {
    return object ? object[property] : defaultValue;
  }

  getScaleForImage(img) {
    return Math.min(this.canvas.width / img.width, this.canvas.height / img.height);
  }

  makePNGDataUrl(imgSrc) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(imgSrc, (img, isError) => {
        if (isError) {
          reject('File loading fail');
          toastr.error('File loading fail');
        }

        img.set({
          scaleX: this.getScaleForImage(img),
          scaleY: this.getScaleForImage(img),
        });

        resolve(img.toDataURL({ format: 'png', multiplier: 4 }));
      });
    });
  }

  async pasteImageFromUrl(imgSrc) {
    try {
      this.canvas.clear();
      this.setZoomLevel(1);
      const dataUrl = await this.makePNGDataUrl(imgSrc);

      fabric.Image.fromURL(dataUrl, (img, isError) => {
        if (isError) return toastr.error('File loading fail');

        img.set({
          scaleX: this.getScaleForImage(img),
          scaleY: this.getScaleForImage(img),
        });

        this.canvas.add(img).renderAll();
        this.updateSvgInstance();
        this.fileLoaded(this.vueStore.getters[`${SVG_STORE}getProductCanvasInstance`]);
        this.initSaveCanvasState();
        this.updateCanvasCursor();
        this.redrawBgImage();
      });
    } catch (error) {
      console.error(error);
    }
  }

  loadSvgFromString(svgContent) {
    this.canvas.clear();
    this.setZoomLevel(this.minZoomLevel);

    fabric.loadSVGFromString(svgContent, (objects) => {
      this.loadSvgFromStringProcess(objects);
      this.initSaveCanvasState();
      document.activeElement.blur();
    });
  }

  loadSvgFromLayers(svgContent) {
    this.canvas.clear();

    fabric.loadSVGFromString(svgContent, (objects) => {
      this.loadSvgFromStringProcess(objects);
      this.saveCanvasState();
      document.activeElement.blur();
    });
  }

  async resetToInitialState() {
    this.canvas.clear();
    this.clearRectangleMask();
    this.clearWorkingArea();
    this.clearAltSelectionMode();
    this.clearSelectionBox();
    this.clearLasso();
    this.resetGLayers(this.initialState, this.initialSvgUrl);
    
    if (this.initialSvgUrl) {
      await this.pasteImageFromUrl(this.initialSvgUrl);
    } else if (this.initialState) {
      this.loadSvgFromString(this.initialState);
    }

    this.redrawBgImage();
  }
  
  clearWorkingArea() {
    this.workingArea = null;
    this.setWorkingArea();
  }
  
  clearAltSelectionMode() {
    this.isAltSelectionMode = false;
    this.vueStore.dispatch(`${SVG_STORE}setAltSelectionMode`, this.isAltSelectionMode);
  }

  saveWorkingArea() {
    this.workingArea = JSON.stringify(this.canvas.toJSON());
    this.setWorkingArea();
  }
  
  setWorkingArea() {
    this.vueStore.dispatch(`${SVG_STORE}setWorkingArea`, this.workingArea);
  }

  resetWorkingArea() {
    this.clearRectangleMask();
    this.clearAltSelectionMode();
    this.clearSelectionBox();
    this.clearLasso();
    
    if (this.workingArea) {
      this.canvas.clear();
      this.canvas.loadFromJSON(JSON.parse(this.workingArea));
      this.setWorkingArea();
      this.drawBgImagePreview();
      document.activeElement.blur();
    }
  }

  deleteSelectedObjects() {
    const activeObjects = this.getSelectedObjects();

    if (!activeObjects.length) {
      return
    }

    activeObjects.forEach(obj => {
      if (obj.id === RECTANGLE_MASK_ID) {
        this.clearRectangleMask();
        this.updateCanvasCursor();
      }

      if (obj.id === SELECTION_BOX_ID) {
        this.clearSelectionBox();
        this.updateCanvasCursor();
      }

      if (obj.isLasso) {
        this.clearLasso();
      }

      this.canvas.remove(obj);
    });

    this.canvas.discardActiveObject().renderAll();
    this.saveCanvasState();
  }
  
  beforeAltSelectionMode() {
    if (!this.vueStore.getters[`${SVG_STORE}isInteractiveMode`] && !this.isAltSelectionMode) {
      this.vueStore.dispatch(`${SVG_STORE}setInteractiveMode`, true);
      this.canvas.clear();
      this.setZoomLevel(this.minZoomLevel);
      
      return fabric.loadSVGFromString(this.modifiedSvgXml, (objects) => {
        this.loadSvgFromStringProcess(objects);
        this.initSaveCanvasState();
        this.altSelectionMode();
        document.activeElement.blur();
      });
    }
    
    return this.altSelectionMode();
  }

  altSelectionMode() {
    this.isAltSelectionMode = !this.isAltSelectionMode;
    this.vueStore.dispatch(`${SVG_STORE}setAltSelectionMode`, this.isAltSelectionMode);
    this.switchToMode(this.Modes.ALT_SELECTION);
    this.isAltSelectionMode ? toastr.success('Alt selection On') : toastr.success('Alt selection Off')
    this.updateCanvasCursor();
  }
  
  updateCanvasCursor() {
    const objects = this.canvas.getObjects();
    const isSomeModeOn = [
      this.isAltSelectionMode,
      this.isRectangleMaskMode,
      this.isSelectionBoxMode,
      this.isLassoMode
    ].some(Boolean);

    if (isSomeModeOn) {
      objects.forEach((obj) => {
        obj.set({ selectable: false, hoverCursor: CROSS_HAIR });
      });

      this.canvas.discardActiveObject().renderAll();
    } else {
      objects.forEach((obj) => {
        obj.set({
          selectable: obj.id !== PRODUCT_ID,
          hoverCursor: isSomeModeOn ? CROSS_HAIR : MOVE
        })
      });
    }
  }

  getSelectedObjects() {
    return this.canvas.getActiveObjects();
  }

  selectWorkingAreaInAltSelectionMode() {
    if (this.isAltSelectionMode) {
      this.drawFromSelectedObjects();
    }
  }

  drawFromSelectedObjects() {
    const activeObjects = this.getSelectedObjects();

    if (activeObjects.length) {
      this.canvas.clear();

      activeObjects.forEach((obj) => {
        this.addObject(obj);
      });
      
      this.selectAllObjects();
      this.enlargeSelected();
      this.deselectAll();
      this.saveWorkingArea();
      this.updateCanvasCursor();
      document.activeElement.blur();
      this.selectAllObjects();
    } else {
      this.beforeAltSelectionMode();
    }
  }

  closeAltSelectionMode() {
    if (this.isAltSelectionMode) {
      this.altSelectionMode();
    }
  }

  addObject(object) {
    object.set('opacity', 1);
    object.setCoords();
    this.canvas.calcOffset();
    this.canvas.add(object);
  }
  
  selectAllObjects() {
    this.canvas.discardActiveObject();
    const objects = this.canvas
      .getObjects()
      .filter((obj) => obj.id !== PRODUCT_ID);
    
    const activeSelection = new fabric.ActiveSelection(objects, {
      canvas: this.canvas,
    });
    
    this.canvas.setActiveObject(activeSelection).renderAll();
    this.updateSvgInstance();

    this.clearRectangleMask();
    this.clearAltSelectionMode();
    this.clearSelectionBox();
  }
  
  deselectAll() {
    this.canvas.discardActiveObject().renderAll();
  }

  saveAsSVG() {
    const svgString = this.canvas.toSVG();
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${uuidv4()}.svg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  updateSvgInstance() {
    this.vueStore.dispatch(`${SVG_STORE}setSvgFabricInstance`, this.canvas);
    this.vueStore.dispatch(`${SVG_STORE}setSvgCanvasInstance`, this);
  }

  setOrientation (orientation) {
    let widthScaling = 0.4
    if (orientation === 'landscape') {
      widthScaling = $('.svg-wrapper').width() / window.innerWidth
    }
    const innerW = Number((window.innerWidth * widthScaling).toFixed(0))
    this.canvas.setDimensions({ width: innerW, height: SVGCanvas.DEFAULT_ELEMENT_HEIGHT })
  }

  centerSelected() {
    const active = this.canvas.getActiveObject();
    this.canvas.viewportCenterObject(active);
    this.canvas.renderAll();
  }

  enlargeSelected() {
    const active = this.canvas.getActiveObject();

    if (active) {
      this.zoomLevel = 1;
      this.canvas.setZoom(this.zoomLevel);
      const canvasWidth = this.canvas.width * 0.9;
      const scaleFactor = canvasWidth / active.width;

      active.set({
        scaleX: active.scaleX * scaleFactor,
        scaleY: active.scaleY * scaleFactor,
      });

      active.setCoords();

      this.redrawBgImage();
      this.centerSelected();
      document.activeElement.blur();
    }
  }
  
  drawBgImagePreview() {
    const bgImage = this.canvas.getObjects().find((obj) => obj.id === PRODUCT_ID);
    this.vueStore.dispatch(`${SVG_STORE}setBgPreviewOn`, false);

    if (bgImage) {
      return this.canvas.remove(bgImage).renderAll();
    }
    
    const bgImageInstance = this.getBgImageInstance();

    if (bgImageInstance) {
      fabric.Image.fromURL(bgImageInstance.path, (img, isError) => {
        if (isError) return toastr.error('File loading fail');

        let canvasZoom = this.canvas.getZoom();
        const maxScale = Math.min(this.canvas.width / img.width, this.canvas.height / img.height);

        img.set({
          scaleX: maxScale / canvasZoom,
          scaleY: maxScale / canvasZoom,
          selectable: false,
          hoverCursor: MOVE,
          id: PRODUCT_ID
        });

        this.canvas.insertAt(img, 0).renderAll();
        this.vueStore.dispatch(`${SVG_STORE}setBgPreviewOn`, true);
        this.canvas.requestRenderAll();
      });
    }
  }
  
  removeBgImage() {
    const bgImage = this.canvas.getObjects().find((obj) => obj.id === PRODUCT_ID);
    
    if (bgImage) {
      this.canvas.remove(bgImage).renderAll();
      this.vueStore.dispatch(`${SVG_STORE}setBgPreviewOn`, false);
    }
  }
  
  redrawBgImage() {
    if (this.isBgPreviewOn()) {
      this.removeBgImage();
      this.drawBgImagePreview();
    }
  }

  switchSelectionBoxMode() {
    if (this.selectionBox) {
      this.stopSelectionBox();
      this.removeSelectionBox();
    }
    
    this.isSelectionBoxMode = !this.isSelectionBoxMode;
    this.switchToMode(this.Modes.SELECTION_BOX);
    this.updateSelectionBoxState();
    this.updateCanvasCursor();
  }

  createSelectionBoxRectangle() {
    this.selectionBox = new fabric.Rect({
      id: SELECTION_BOX_ID,
      left: this.pointerStartX,
      top: this.pointerStartY,
      fill: 'rgba(0,121,100,0.2)',
      stroke: '#007964',
      strokeWidth: 0.2,
      selectable: true,
      lockRotation: true,
    })
    
    this.selectionBox.setControlVisible('mtr', false);
    this.canvas.add(this.selectionBox);
  }

  drawSelectionBox(event) {
    if (!this.selectionBox) return;

    const {x, y} = this.canvas.getPointer(event);

    this.selectionBox.set({
      left: Math.min(x, this.pointerStartX),
      top: Math.min(y, this.pointerStartY),
      width: Math.abs(x - this.pointerStartX),
      height: Math.abs(y - this.pointerStartY),
    });

    this.canvas.renderAll();
  }

  stopSelectionBox() {
    this.isSelectionBoxMode = !this.isSelectionBoxMode;
    this.selectionBox.setCoords();
  }

  removeSelectionBox() {
    if (this.selectionBox) {
      this.canvas.remove(this.selectionBox).renderAll();
      this.selectionBox = null;
    }
  }

  clearSelectionBox() {
    if (this.selectionBox) {
      this.removeSelectionBox();
      this.isSelectionBoxMode = false;
      this.updateSelectionBoxState();
    } else {
      this.isSelectionBoxMode = false;
      this.updateSelectionBoxState();
    }
  }

  enlargeToSelectionBox() {
    if (!this.selectionBox) return;
    
    this.setZoomLevel(1);
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;

    const rect = this.selectionBox.getBoundingRect();
    const rectCenter = this.selectionBox.getCenterPoint();

    const scaleX = canvasWidth / rect.width;
    const scaleY = canvasHeight / rect.height;

    const scale = Math.min(scaleX, scaleY);

    this.canvas.forEachObject((obj) => {
      obj.scaleX *= scale;
      obj.scaleY *= scale;

      const originalLeft = obj.left;
      const originalTop = obj.top;

      obj.left = (originalLeft - rectCenter.x) * scale + canvasWidth / 2;
      obj.top = (originalTop - rectCenter.y) * scale + canvasHeight / 2;

      obj.setCoords();
    });

    this.canvas.renderAll();
  }
  
  updateSelectionBoxState() {
    this.vueStore.dispatch(`${SVG_STORE}setSelectionBoxMode`, this.isSelectionBoxMode);
  }

  sendToProductCanvas(instance) {
    this.canvas.add(instance).setActiveObject(instance);
    this.canvas.renderAll();

    this.updateSvgInstance();
    const productCanvas = this.vueStore.getters[`${SVG_STORE}getProductCanvasInstance`];
    productCanvas.pasteSelectedSvgInstanceAsImage(false);

    const active = this.canvas.getActiveObject();
    this.canvas.remove(active).renderAll();
    this.updateCanvasCursor();
  }

  async applySelectionBoxTarget() {
    if (!this.selectionBox) return;

    const clippedImg = await this.getSelectionBoxAsImageInstance(this.selectionBox);

    this.sendToProductCanvas(clippedImg);
    this.clearSelectionBox();
  }
  
  getZoomMultiplier() {
    return this.canvas.getZoom() < 1 ? 8 : 6
  }

  async selectedToImage() {
    const selectedObject = this.selectionBox
        ? await this.getSelectionBoxAsImageInstance(this.selectionBox)
        : this.canvas.getActiveObject();

    const base64 = selectedObject.toDataURL({
      multiplier: 5
    })

    return fetch(base64).then(r => r.blob())
  }

  async convertSelectedToText() {
    const image = await this.selectedToImage();

    const { data } = await OCRService.recognizeImage(image)

    if (!data.text) {
      toastr.error('No text found in selected area.')
      throw new Error('Nothing recognized');
    }

    return data.text;
  }
  
  clearCanvas() {
    this.canvas.clear();
    this.updateSvgInstance();
    this.initialState = null;
    this.initialSvgUrl = null;
    this.resetGLayers();
    this.clearRectangleMask();
    this.clearWorkingArea();
    this.clearAltSelectionMode();
    this.clearSelectionBox();
    this.clearLasso();
  }
  
  resetGLayers(xmlSvg = null, urlSvg = null) {
    this.modifiedSvgXml = xmlSvg;
    this.modifiedSvgUrl = urlSvg;
    this.removedGTagsIndexes = [];
    this.vueStore.dispatch(`${SVG_STORE}setInteractiveMode`, false);
  }

  pasteImageToRHSFromUrl(dataUrl) {
    const productCanvas = this.vueStore.getters[`${SVG_STORE}getProductCanvasInstance`];
    productCanvas.pasteImageFromUrl(dataUrl, true);
  }

  async urlToBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  
  async getExtractedFile() {
    const activeObject = this.canvas.getActiveObject();
    
    if (activeObject && this.isAltSelectionMode) {
      const objectDataURL = activeObject.toDataURL();

      const response = await fetch(objectDataURL);
      const blob = await response.blob();
      
      return new File([blob], 'image.png', { type: 'image/png' });
    }
    
    const blobSvg = new Blob([this.modifiedSvgXml], { type: 'image/svg+xml' });
    
    return new File([blobSvg], 'image.svg');
  }
  
  async getExtractedFormData() {
    const file = await this.getExtractedFile();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('result_width', 3000);
    formData.append('result_height', 4000);
    formData.append('canvas_width', 3000);
    formData.append('canvas_height', 4000);
    formData.append('output_filename', 'result_image');
    
    return formData;
  }

  targetLanguage(lang) {
    switch (lang) {
      case 'en':
        return LANG.EN;
      case 'fr':
        return LANG.FR;
      default:
        return LANG.BOTH;
    }
  }

  async extractInfo(endpoint = 'ingredients') {
    try {
      const formData = await this.getExtractedFormData();
      const { data } = await SvgImageEditor.extractInfo(formData, endpoint);
      
      if (data.length) {
        if (data.length > 1) {
          const multiLangData = data.map((res) => ({
            src: res.png,
            lang: this.targetLanguage(res.language),
          }))

          return this.extractModalRef.show(multiLangData);
        }

        const [{ png }] = data;
        const dataUrl = await this.urlToBase64(png);
        this.pasteImageToRHSFromUrl(dataUrl);
      } else {
        toastr.warning('Nothing was found for this request');
      }
      
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    } catch (error) {
      toastr.warning('Nothing was found for this request');
      this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
      console.error(error);
    }
  }

  initLasso() {
    if (this.isLassoMode) {
      return this.clearLasso();
    }
    
    this.isLassoMode = true;
    this.switchToMode(this.Modes.LASSO);
    this.updateLassoState();
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.color = '#00ff00';
    this.canvas.on('path:created', (e) => {
      if (this.isLassoMode) {
        e.path.isLasso = true;
      }
    });
    this.updateCanvasCursor();
  }

  stopLasso() {
    this.canvas.getObjects().forEach(o => {
      if (o.isLasso && this.canvas.isDrawingMode) {
        o.set({ fill: 'rgba(0,255,0,0.3)', stroke: null });
        this.canvas.setActiveObject(o);
        this.canvas.isDrawingMode = false;
      }
    });

    this.canvas.renderAll();
  }

  clearLasso() {
    if (this.isLassoMode) {
      const lasso = this.canvas.getObjects().find(obj => (obj.isLasso));
      this.canvas.remove(lasso);
      this.canvas.isDrawingMode = false;
    }
    
    this.isLassoMode = false;
    this.updateLassoState();
    this.updateCanvasCursor();
  }
  
  updateLassoState() {
    this.vueStore.dispatch(`${SVG_STORE}setSvgLassoMode`, this.isLassoMode);
  }

  async applyLasso() {
    if (!this.isLassoMode) return;

    const clippedImg = await this.getLassoAsImageInstance();
    this.sendToProductCanvas(clippedImg);
    this.isLassoMode = false;
    this.updateLassoState();
    this.updateCanvasCursor();
  }
}
