var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-row",
    { staticClass: "svg-wrapper", attrs: { id: "SVGEditor" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("span", [_vm._v("Show:")]),
          _vm._v(" "),
          _c("b-form-checkbox-group", {
            staticClass: "d-inline-block ml-2 mb-2",
            attrs: { options: _vm.visibleSectionsOptions },
            on: { input: _vm.onVisibleSectionsChange },
            model: {
              value: _vm.visibleSections,
              callback: function ($$v) {
                _vm.visibleSections = $$v
              },
              expression: "visibleSections",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visibleSections.includes("svgSection"),
              expression: "visibleSections.includes('svgSection')",
            },
          ],
        },
        [
          _c("SVGSection", {
            ref: "svgSection",
            on: { loaded: _vm.onLoaded, load: _vm.onLoad },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visibleSections.includes("productSection"),
              expression: "visibleSections.includes('productSection')",
            },
          ],
        },
        [
          _c("ProductSection", {
            ref: "productSection",
            on: { loaded: _vm.onLoaded, load: _vm.onLoad },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSvgLoading
        ? _c("div", { staticClass: "svg-wrapper__overlay" }, [
            _c("div", { staticClass: "svg-wrapper__overlay--background" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "svg-wrapper__overlay--spinner" },
              [_c("b-spinner", { attrs: { size: "lg" } })],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }