<template>
  <b-form-group
    label="Write the topic/occasion for the suggested headline"
    content-cols="12"
  >
    <b-input-group>
      <b-form-input v-model="chatGPTInput" />
      <b-input-group-append>
        <b-button
          variant="primary"
          :disabled="!chatGPTInput || isLoading"
          @click="onGenerate"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <template v-else>
            <svg class="w-6 h-6">
              <use href="/img/icons/sprite.svg#chat-gpt" />
            </svg>
            Suggest
          </template>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chatGPTInput: '',
      isLoading: false,
    }
  },
  methods: {
    async onGenerate() {
      this.isLoading = true;

      try {

        const { data } = await axios.post('/banner/groups/chat_gpt', {
          input: this.chatGPTInput,
        });

        const message = data.message.replaceAll('\n', '');

        this.$emit('change', 'inputText', message);
        this.$emit('input', message);
        
      } catch(error) {
        console.error(error);
      } finally{
        this.isLoading = false;
      }
    }
  }
}
</script>
