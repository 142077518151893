<template>
  <div>
    <p class="mb-2">
      Product dimensions:
    </p>
    <PositionAndSize
      :h="height"
      :w="width"
      :x="x"
      :y="y"
      @input="onChange"
    />
    <b-btn
      :disabled="isButtonDisabled"
      variant="primary"
      @click="onSave"
    >
      Save
    </b-btn>
    <b-btn
      variant="outline-primary"
      @click="onClose"
    >
      Cancel
    </b-btn>
  </div>
</template>

<script>
import PositionAndSize from "./components/PositionAndSize.vue";
import { editorControlEventBroker, EVENTS } from '@frontend/group/modules/editor-control-popup/event-broker'
export default {
  components: {
    PositionAndSize,
  },

  props: {
    target: {
      type: Object,
      default: () => {
      },
    },
    options: {
      type: Object,
      default: () => {
      },
    },
  },

  computed: {
    height: function () {
      return this.target.fieldOptionChanges.Height;
    },
    width: function () {
      return this.target.fieldOptionChanges.Width;
    },
    x: function () {
      return this.target.fieldOptionChanges.X;
    },
    y: function () {
      return this.target.fieldOptionChanges.Y;
    },
    isButtonDisabled: function() {
      return this.target.fieldOptionChanges.isEqual;
    }
  },

  methods: {
    onChange(action, value) {
      editorControlEventBroker.fire(EVENTS.PRODUCT_DIMENSION_CHANGED, {
        action, value
      })
    },
    onSave() {
      this.onChange('save', null);
    },
    onClose() {
      this.onChange('close', null);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
