export const SHOULD_CLOSED_TO_THE_BOTTOM = [ 'legal', 'claims' ]

export const PADDING_TOP_KEY = 'TOP'
export const PADDING_LEFT_KEY = 'LEFT'
export const PADDING_RIGHT_KEY = 'RIGHT'
export const PADDING_BOTTOM_KEY = 'BOTTOM'

export const PADDING = {
  [PADDING_RIGHT_KEY]: 0.03,
  [PADDING_BOTTOM_KEY]: 0.03,
  [PADDING_TOP_KEY]: 0.03,
  [PADDING_LEFT_KEY]: 0.03,
}

export const PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING = 0.7
export const MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING = 2;
export const COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT = 1.03;