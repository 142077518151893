<template>
  <b-dropdown
    v-b-tooltip.hover
    text=""
    variant="outline-primary"
    class="fill-dd"
    :class="{ 'active': isPaintBucketOn }"
    :disabled="!isProductInstanceHasObjects"
    size="sm"
    no-caret
    title="Fill image color"
  >
    <template #button-content>
      <b-icon icon="paint-bucket" />
    </template>

    <label class="ml-4 mb-0">Intensity</label>
    <b-input-group
      class="n-input mr-3"
      append="%"
    >
      <b-form-input
        type="number"
        :value="fillIntensity"
        @input="changeFillIntensity"
      />
    </b-input-group>

    <div class="input-group range-wrapper">
      <input
        type="range"
        min="0"
        max="50"
        step="1"
        :value="fillIntensity"
        @input="changeFillIntensity"
      >
    </div>

    <b-dropdown-item
      @click="selectType('none')"
    >
      None
    </b-dropdown-item>
    <b-dropdown-item
      @click="selectType('replace_color')"
    >
      Replace Color
    </b-dropdown-item>
    <b-dropdown-item
      @click="selectType('replace_gradient')"
    >
      Replace Gradient
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FillImageColor',

  props: {
    canvas: {
      type: Object,
      default: null,
    }
  },

  computed: {
    ...mapGetters('svgEditor', [
      'fillIntensity',
      'isPaintBucketOn',
      'isProductInstanceHasObjects',
    ]),
  },

  methods: {
    ...mapActions('svgEditor', ['setFillIntensity']),

    selectType(type) {
      this.canvas.handlePaintBucket(type);
      // this.canvas.setDrawMode(brush);
    },

    changeFillIntensity(event) {
      this.setFillIntensity(Number(event.target ? event.target.value : event))
    }
  },
};
</script>

<style lang="scss" scoped>
.fill-dd {

  .range-wrapper {
    padding: 10px 6px 10px 24px;
  }

  .n-input {
    padding: 5px 23px 5px 24px;
    width: 100%;
  }
  
  &.active {
    .btn {
      background-color: #209c86 !important;
      color: white !important;
      border: 1px solid #209c86 !important
    }
  }
}

input[type=range] {
  -webkit-appearance: none;
  background: #209C86;
  height: 6px;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 6px;
  background: #209C86 !important;
  border: none;
  border-radius: 3px;
}

input[type=range]:hover::-webkit-slider-runnable-track {
  background: #209C86;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid white;
  background: #209C86 !important;
  margin-top: -4px;
}

input[type=range]:focus {
  outline: none;
}
</style>