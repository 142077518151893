import Text from "./text";
import { FIELD_TYPES } from "@/includes/parse_psd_data/constants";

export default class UserText extends Text {
  
  constructor(field) {
    super(field);
    
    this.text = field.value;
    
    this.data = {
      ...field,
      visible: true
    }
    
    this.fieldType = FIELD_TYPES.MULTI_LINE_TEXT;
  }
}
