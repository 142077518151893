<template>
  <b-dropdown
    v-b-tooltip.hover
    text=""
    variant="outline-primary"
    class="brushes-dd"
    size="sm"
    no-caret
    title="Brushes"
  >
    <template #button-content>
      <b-icon icon="brush" />
    </template>

    <b-input-group
      class="n-input mr-3"
      append="px"
    >
      <b-form-input
        type="number"
        :value="brushSize"
        @input="changeBrushSize"
      />
    </b-input-group>

    <div class="input-group range-wrapper">
      <input
        type="range"
        min="0"
        max="100"
        step="1"
        :value="brushSize"
        @input="changeBrushSize"
      >
    </div>

    <b-dropdown-item
      @click="selectBrush('None')"
    >
      None
    </b-dropdown-item>
    <b-dropdown-item
      @click="selectBrush('PencilBrush')"
    >
      Pencil
    </b-dropdown-item>
    <b-dropdown-item
      @click="selectBrush('CircleBrush')"
    >
      Circle
    </b-dropdown-item>
    <b-dropdown-item
      @click="selectBrush('SprayBrush')"
    >
      Spray
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BrushesDD',

  props: {
    canvas: {
      type: Object,
      default: null,
    }
  },

  computed: {
    ...mapGetters('svgEditor', ['brushSize']),
  },

  methods: {
    ...mapActions('svgEditor', ['setBrushSize']),

    selectBrush(brush) {
      this.canvas.setDrawMode(brush);
    },

    changeBrushSize(event) {
      this.setBrushSize(Number(event.target ? event.target.value : event))
    }
  },
};
</script>

<style lang="scss" scoped>
.brushes-dd {

  .range-wrapper {
    padding: 10px 6px 10px 24px;
  }

  .n-input {
    padding: 5px 23px 5px 24px;
    width: 100%;
  }
}

input[type=range] {
  -webkit-appearance: none;
  background: #209C86;
  height: 6px;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 6px;
  background: #209C86 !important;
  border: none;
  border-radius: 3px;
}

input[type=range]:hover::-webkit-slider-runnable-track {
  background: #209C86;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 1px solid white;
  background: #209C86 !important;
  margin-top: -4px;
}

input[type=range]:focus {
  outline: none;
}
</style>