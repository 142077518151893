export const STATUS_MAP = {
    DRAFT: 'DRAFT',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
    PENDING_APPROVAL: 'PENDING_APPROVAL'
}

export const getStatusBadge = (status) => {
    switch (status) {
        case STATUS_MAP.DRAFT:
            return 'badge badge-secondary';
        case STATUS_MAP.REJECTED:
            return 'badge badge-danger';
        case STATUS_MAP.APPROVED:
            return 'badge badge-success';
        case STATUS_MAP.PENDING_APPROVAL:
            return 'badge badge-warning';
        default:
            return 'badge badge-light';
    }
}

export const statusName = (status) => {
    if (status === STATUS_MAP.PENDING_APPROVAL) {
        return status.replace('_', ' ');
    } else {
        return status;
    }
}