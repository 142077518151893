import {fabric} from "fabric";
import { SVG_EDITOR_EVENTS } from './events';

// it's necessary to implement getters:
// - multiplier (should return number)
// - selectionBoxIds (should return array)
export function getSelectionBoxAsImageInstance (selectionBox) {
    const {left, top, width, height, scaleX, scaleY} = selectionBox;
    const boundingBox = {
        left,
        top,
        width: width * scaleX,
        height: height * scaleY
    };

    const zoom = this.canvas.getZoom();
    const multiplier = this.multiplier

    const originalRenderFunc = fabric.StaticCanvas.prototype._renderObjects;

    this.canvas.constructor.prototype._renderObjects = (context, objects) => {
        const excludeIds = this.selectionBoxIds;
        objects = objects.filter(obj => !excludeIds.includes(obj.id));
        originalRenderFunc.call(this, context, objects);
    };

    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = boundingBox.width * multiplier;
    offScreenCanvas.height = boundingBox.height * multiplier;

    let img = new Image();

    return new Promise((resolve) => {
        img.onload = () => {
            const context = offScreenCanvas.getContext('2d');

            context.drawImage(
                img,
                boundingBox.left * multiplier * zoom,
                boundingBox.top * multiplier * zoom,
                boundingBox.width * multiplier * zoom,
                boundingBox.height * multiplier * zoom,
                0,
                0,
                boundingBox.width * multiplier,
                boundingBox.height * multiplier
            );

            this.canvas.constructor.prototype._renderObjects = originalRenderFunc;

            const dataUrl = offScreenCanvas.toDataURL();

            fabric.Image.fromURL(dataUrl, (clippedImg) => {
                clippedImg.set({
                    left: boundingBox.left,
                    top: boundingBox.top,
                    scaleX: 1 / multiplier,
                    scaleY: 1 / multiplier
                });

                resolve(clippedImg)
            });
        };

        img.src = this.canvas.toDataURL({multiplier: multiplier});
    })
}

export function getLassoAsImageInstance() {
    const lasso = this.canvas.getObjects().find(obj => (obj.isLasso));
    
    if (lasso) {
        const zoom = this.canvas.getZoom();
        const originalRenderFunc = fabric.StaticCanvas.prototype._renderObjects;
        lasso.set({ fill: '#000000' });

        this.canvas.constructor.prototype._renderObjects = (context, objects) => {
            objects = objects.filter(obj => !obj.isLasso);
            originalRenderFunc.call(this, context, objects);
        };

        this.canvas.clipPath = lasso;

        return new Promise((resolve) => {
            const dataUrl = this.canvas.toDataURL({
                left: lasso.left * zoom,
                top: lasso.top * zoom,
                width: lasso.width * lasso.scaleX * zoom,
                height: lasso.height * lasso.scaleY * zoom,
                multiplier: this.multiplier,
            });

            fabric.Image.fromURL(dataUrl, (clippedImg) => {
                clippedImg.set({
                    left: lasso.left,
                    top: lasso.top,
                    scaleX: 1 / (this.multiplier * zoom),
                    scaleY: 1 / (this.multiplier * zoom)
                });

                this.canvas.constructor.prototype._renderObjects = originalRenderFunc;
                this.canvas.clipPath = null;
                this.canvas.remove(lasso);
                resolve(clippedImg);
            });
        });
    }
}

export function fileLoaded(canvasInstance) {
    this.loadingProcess = false;
    
    if (!canvasInstance.loadingProcess && !this.loadingProcess) {
        this.eventBroker.fire(SVG_EDITOR_EVENTS.FILE_LOADED);
    }
}