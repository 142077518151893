import {PARSED_FIELD_TYPES} from "@/includes/parse_psd_data/constants";
import Base from "@/includes/parse_psd_data/psd-types/base";
import { getInstanceByType } from "@/includes/parse_psd_data/type-resolver";



export class Folder extends Base {
  name;

  type = PARSED_FIELD_TYPES.FOLDER

  visible;

  children = [];

  uuid;

  width;

  height;

  left;

  top;

  constructor(templateData) {
    super(templateData)
    this.name = templateData.name;
    this.visible = templateData.visible;
    this.uuid = templateData.uuid;
    this.width = templateData.width;
    this.height = templateData.height;
    this.left = templateData.left;
    this.top = templateData.top;

    this.children = templateData.children.map((field) => {
      return getInstanceByType({...field, parent: this});
    }).filter(i => !!i);
  }
}