<template>
  <b-modal
    id="layout-theme-modal"
    title="Theme"
    centered
    @ok="ok"
    @cancel="cancel"
  >
    <template v-if="selectedInstance">
      <b-form-group>
        <Multiselect
          v-model="theme"
          placeholder="Theme"
          track-by="id"
          label="name"
          :options="themes"
          :allow-empty="false"
        />
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutThemeModal',

  components: {
    Multiselect,
  },

  data: () => ({
    selectedTheme: null,
  }),

  computed: {
    ...mapGetters('layout', [
      'selectedInstance',
    ]),
    theme: {
      get () {
        return this.selectedTheme || _.find(this.themes, ['id', this.selectedInstance.data.theme])
      },
      set (val) {
        this.selectedTheme = val
      },
    },
    themes () {
      return _.toArray(this.selectedInstance.themes)
    },
  },

  methods: {
    show () {
      this.$bvModal.show('layout-theme-modal')
    },
    ok () {
      this.$emit('change-theme', {
        instance_id: this.selectedInstance.id,
        value: this.selectedTheme.id,
      })
    },
    cancel () {
      this.selectedTheme = null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
