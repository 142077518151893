<template>
  <b-modal
    id="background-theme-color-selector"
    class="background-theme-color-selector"
    @ok="apply"
  >
    <div class="background-theme-color-selector__content">
      <b-row
        v-for="field in fields"
        :key="field.id"
        class="background-theme-color-selector__content--item"
      >
        <b-col cols="6">
          <div class="background-theme-color-selector__name">
            {{ field.name }}
          </div>
          <ColorPicker
            v-model="field.value"
            class="d-flex justify-content-center"
            :colors="options"
          />
        </b-col>
        <b-col
          cols="6"
          class="d-flex align-items-end justify-content-center"
        >
          <div class="d-flex align-items-center">
            <div class="background-theme-color-selector__name">
              Apply to:
            </div>
            <b-dropdown class="ml-3">
              <b-dropdown-item-button @click="selectAllHandler(field)">
                Select all unselected
              </b-dropdown-item-button>
              <b-dropdown-group
                v-for="(template, templateIndex) in applyToAllOptions"
                :key="templateIndex"
                class="ml-2"
                :header="template.name"
              >
                <b-form-checkbox
                  v-for="(applyToField, filedIndex) in template.fields"
                  :key="filedIndex"
                  :disabled="applyToField.selectedFieldId && applyToField.selectedFieldId !== field.id"
                  :checked="!!applyToField.selectedFieldId"
                  @change="applyToClickHandler(field, applyToField, $event)"
                >
                  {{ applyToField.name }}
                </b-form-checkbox>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BACKGROUND_THEME_COLOR_FIELD_TYPE,
  backgroundThemeHandlerEventBroker, CHANGE_BACKGROUND_THEME_COLOR,
  INIT_BACKGROUND_THEME_COLOR_MODAL
} from "../../../group/modules/background-theme-color/button-handler";
import ColorPicker from "../../ColorPicker";
import {deepCopy} from "../../../utils";
import ThemesContainer from "../../../../banner_template/themes/ThemesContainer";

const BACKGROUND_COLOR_KEY_IN_DATA = 'background_color'

export default {
  name: "BackgroundThemeColor",
  components: {
    ColorPicker,
  },

  data: () => ({
    fields: [],
    options: [],
    applyToAllOptions: [],
    instanceId: null,
  }),
  mounted() {
    backgroundThemeHandlerEventBroker.on(INIT_BACKGROUND_THEME_COLOR_MODAL, this.init.bind(this))
  },
  methods: {
    init(dto) {
      this.createFields(dto)
      this.$bvModal.show('background-theme-color-selector')
    },
    createFields({ instance, preview, previews }) {
      const selectedTheme = +preview.data?.theme;
      let themes = new ThemesContainer(instance.themes);
      const theme = themes.getThemeById(selectedTheme);

      this.instanceId = instance.instance_id;
      this.fields = instance.template.fields
        .filter(({ type }) => BACKGROUND_THEME_COLOR_FIELD_TYPE === type)
        .map((field, fieldIndex) => ({ ...field, value: this.getValueFromSettings(preview, fieldIndex, theme) }));

      this.applyToAllOptions = previews.map(preview => ({
        name: preview.template.name,
        instanceId: preview.id,
        fields: preview.template.fields
          .filter(({ type }) => BACKGROUND_THEME_COLOR_FIELD_TYPE === type)
          .map(field => ({
            ...field,
            selectedFieldId: undefined,
          }))
      }))

      if (theme) {
        this.options = theme.getColorsFromBackgroundColors()
        .map(color => ({ text: color.name, value: color.fullColor }));
      }
    },
    getValueFromSettings(preview, indexOfField, theme) {
      const values = preview?.data[BACKGROUND_COLOR_KEY_IN_DATA]

      if (!values || !values[indexOfField]) {
        return theme?.getBackgroundColors()?.getFirstColor()?.fullColor || '#ffffff';
      }

      return values[indexOfField];
    },
    applyToClickHandler(field, applyToField, isSelect) {
      applyToField.selectedFieldId = isSelect ? field.id : undefined;
    },
    selectAllHandler({ id }) {
      this.applyToAllOptions.forEach(({ fields }) => {
        fields.forEach(field => {
          if (field.selectedFieldId) {
            return;
          }

          field.selectedFieldId = id;
        })
      })
    },
    apply() {
      this.fields.forEach(field => {
        this.fireEvent(field, this.instanceId)

        this.applyToAllOptions.forEach(template => template.fields.forEach(({selectedFieldId, ..._field}) => {
          if (selectedFieldId !== field.id) {
            return;
          }

          this.fireEvent({
            ..._field,
            value: field.value,
          }, template.instanceId)
        }))
      })
    },
    fireEvent(field, instanceId) {
      backgroundThemeHandlerEventBroker
        .fire(
          CHANGE_BACKGROUND_THEME_COLOR,
          {
            field,
            instanceId,
          }
        );
    }
  }
}
</script>

<style scoped lang="scss">

</style>