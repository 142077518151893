var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.max
    ? _c(
        "b-form-group",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function () {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.label))]),
                    _vm._v(" "),
                    _c(
                      "b-badge",
                      {
                        staticClass: "float-right",
                        attrs: {
                          variant: _vm.state === null ? "success" : "danger",
                        },
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.count) +
                            "/" +
                            _vm._s(_vm.max) +
                            "\n    "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1464952644
          ),
        },
        [_vm._v(" "), _vm._t("default", null, { state: _vm.state })],
        2
      )
    : _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }