<template>
  <b-row>
    <b-col cols="12">
      <b-form-group
        label="Job"
        label-cols="auto"
      >
        <Multiselect
          v-model="selectedJob"
          label="name"
          track-by="id"
          :options="productDescriptionJobs"
          :allow-empty="false"
          deselect-label=""
        />
      </b-form-group>
      <div class="d-flex justify-content-between align-items-end mb-3">
        <div class="find-mark-container mb-0" />
        <b-button
          v-if="anySaveBtnVisible"
          variant="primary"
          size="sm"
          @click="saveAll"
        >
          Save all
        </b-button>
      </div>
    </b-col>
    <template v-if="selectedProductDescriptionJobId">
      <b-col cols="12">
        <b-table
          sticky-header
          style="max-height: calc(100vh - 490px)"
          :fields="[
            { key: 'source_data', label: 'Original Copy' },
            { key: 'result', label: 'Generated Copy' },
            { key: 'actions', label: '', thStyle: 'width: 0' },
          ]"
          :items="prompts"
        >
          <template #cell(source_data)="data">
            <div
              style="white-space: break-spaces;"
              class="text-muted"
              v-text="data.item.variables.source_data"
            />
          </template>
          <template #cell(result)="data">
            <contenteditable
              class="generated-copy-text"
              style="white-space: break-spaces;"
              :value="data.item.result"
              tag="div"
              :contenteditable="true"
              :no-h-t-m-l="false"
              @input="onChangeResult($event, data.item)"
            />
          </template>
          <template #cell(actions)="data">
            <template v-if="!isEqualResult(data.item.id)">
              <b-button
                v-b-tooltip.hover.left
                variant="primary"
                size="sm"
                class="mb-2 mr-2"
                title="Save"
                @click="save(data.item)"
              >
                <b-icon icon="check-lg" />
              </b-button>
              <b-button
                v-b-tooltip.hover.left
                variant="danger"
                size="sm"
                class="mb-2 mr-2"
                title="Cancel"
                @click="cancel(data.item)"
              >
                <b-icon icon="x-lg" />
              </b-button>
            </template>
            <b-button
              v-show="showUpdateCopyToOriginal(data.item.id)"
              v-b-tooltip.hover.left
              variant="outline-danger"
              size="sm"
              class="mb-2 mr-2"
              title="Update copy to original"
              @click="updateCopyToOriginal(data.item)"
            >
              <b-icon icon="arrow90deg-right" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import '@/includes/find-mark'

export default {
  name: 'ProductDescriptionsCompare',

  components: {
    Multiselect,
  },

  data: () => ({
    jobs: null,
    selectedProductDescriptionJobId: null,
  }),

  computed: {
    ...mapGetters('productDescription', [
      'productDescriptionJobs',
      'prompts',
      'promptsOriginal',
      'promptIndexById',
    ]),
    selectedJob: {
      get () {
        return _.find(this.productDescriptionJobs, ['id', this.selectedProductDescriptionJobId])
      },
      async set (val) {
        this.selectedProductDescriptionJobId = val.id
        await this.fetchPrompts(val.id)
        $('.find-mark-container').findMark({
          contextSelector: 'product-descriptions-compare-ce .generated-copy-text',
          showReplace: true,
        })
      },
    },
    anySaveBtnVisible() {
      return this.prompts.some(prompt => !this.isEqualResult(prompt.id));
    },
  },

  methods: {
    ...mapActions('productDescription', [
      'fetchPrompts',
      'updatePrompt',
    ]),
    ...mapMutations('productDescription', [
      'SET_PROMPT',
    ]),
    onChangeResult (result, item) {
      item.result = result
      this.SET_PROMPT(item)
    },
    isEqualResult (id) {
      const index = this.promptIndexById(id)
      return _.isEqual(this.prompts[index].result, this.promptsOriginal[index].result)
    },
    save (item) {
      this.updatePrompt(item)
    },
    saveAll() {
      this.prompts.forEach(this.save);
    },
    cancel (item) {
      const index = this.promptIndexById(item.id)
      item.result = this.promptsOriginal[index].result
      this.SET_PROMPT(item)
    },
    showUpdateCopyToOriginal (id) {
      const index = this.promptIndexById(id)
      return !_.isEqual(this.prompts[index].result, this.prompts[index].variables.source_data)
    },
    updateCopyToOriginal (item) {
      item.result = item.variables.source_data
      this.SET_PROMPT(item)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
