<template>
  <div class="form-row">
    <div class="col-md-2">
      <label>Layouts</label>
    </div>
    <div class="col-md-10">
      <input
        v-for="selectedLayout in selectedLayouts"
        :key="selectedLayout.id"
        :value="selectedLayout.id"
        type="hidden"
        name="layouts[]"
      >
      <Multiselect
        v-model="selectedLayouts"
        :options="layoutsOfCustomer"
        :loading="isLoading"
        :hide-selected="false"
        :clear-on-select="false"
        :close-on-select="false"
        searchable
        placeholder="Select layouts"
        label="name"
        track-by="id"
        multiple
        @search-change="getCustomerLayouts"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import LayoutAPI from '@frontend/services/api/layout';

export default {
  components: { Multiselect },
  props: {
    customerId: {
      type: Number,
      default: null
    },
    selectedLayoutsIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      layoutsOfCustomer: [],
      selectedLayouts: [],
      isLoading: false
    }
  },
  mounted() {
    this.getCustomerLayouts();

    if (this.selectedLayoutsIds?.length) {
      this.getSelectedLayouts();
    }
  },
  methods: {
    async getCustomerLayouts(search) {
      this.isLoading = true;

      const { data } = await LayoutAPI.getLayoutsCustomer(this.customerId, {
        params: { search }
      });

      this.isLoading = false

      this.layoutsOfCustomer = data;
    },
    async getSelectedLayouts() {
      this.isLoading = true;

      const { data } = await LayoutAPI.getLayoutsCustomer(this.customerId, {
        params: { selected_ids: this.selectedLayoutsIds }
      });

      this.isLoading = false

      this.selectedLayouts = data;
    }
  }
}
</script>

