var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "svg-pdf-api-modal",
        title: "Choose PDF Converter",
        size: "md",
        centered: "",
      },
      on: {
        hide: function ($event) {
          return _vm.hide()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-end justify-content-end" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("svg-pdf-api-modal")
                          },
                        },
                      },
                      [_vm._v("\n          Close\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("svg-pdf-api-modal")
                          },
                        },
                      },
                      [_vm._v("\n          Ok\n        ")]
                    ),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "select",
          {
            staticClass: "form-control form-control-sm",
            attrs: { id: "pdf-api-select" },
            domProps: { value: _vm.api },
            on: { input: _vm.setAPI },
          },
          _vm._l(_vm.apiList, function (currentAPI, index) {
            return _c(
              "option",
              {
                key: currentAPI.name + index,
                domProps: { value: currentAPI.value },
              },
              [_vm._v("\n        " + _vm._s(currentAPI.value) + "\n      ")]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }