import { v4 as uuid } from "uuid";
import Styles from "./Styles";

export default class BaseElement {

  id = uuid();

  styles = new Styles();

  setId(id) {
    this.id = id;
  }

  setStyles(styles) {
    this.styles = styles;
  }

  getStyleAttribute() {
    const styles = this.styles.getStyle();

    return styles ? `style="${styles}"` : '';
  }
}