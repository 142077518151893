<template>
  <b-modal
    id="upload-modal"
    :visible="visible"
    :ok-disabled="!files.length"
    title="Upload files"
    ok-title="Upload"
    size="xl"
    centered
    @ok="ok"
    @hide="visible = false"
  >
    <Vue2Dropzone
      id="uploadDropzone"
      ref="fileInput"
      :options="dropzoneOptions"
      @vdropzone-file-added="setFiles"
      @vdropzone-removed-file="setFiles"
    />
  </b-modal>
</template>

<script>
import Vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'UploadModal',

  components: {
    Vue2Dropzone,
  },

  data: () => ({
    visible: false,
    files: [],
    dropzoneOptions: {
      url: 'http',
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictDefaultMessage: 'Drag & Drop your files or <a href="#" onclick="event.preventDefault()">Browse</a>',
    },
  }),

  methods: {
    ok () {
      this.$emit('ok', { files: this.files })
    },
    show () {
      this.visible = true
    },
    setFiles () {
      this.$nextTick(() => {
        this.files = this.$refs.fileInput ? this.$refs.fileInput.getAcceptedFiles() : []
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .dropzone {
    position: relative;
    min-height: 236px;
    padding: 0;

    .dz-message {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .dz-progress {
      display: none;
    }
  }
}
</style>
