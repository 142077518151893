import { fabric } from "fabric";

export const circleHandler = (defaultOptions) => (object, objectData) => {
    const percentBase = object.width <= object.height ? object.width : object.height
    const left = objectData.x_center * object.width - object.width / 2;
    const top = objectData.y_center * object.height - object.height / 2;
    const radius = objectData.radius * percentBase / 2;

    return new fabric.Circle({
        ...defaultOptions,
        left,
        top,
        radius,
        objectData: objectData
    });
}