import { render, staticRenderFns } from "./PositionAndSize.vue?vue&type=template&id=dfb353c2&scoped=true"
import script from "./PositionAndSize.vue?vue&type=script&lang=js"
export * from "./PositionAndSize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfb353c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_diego/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfb353c2')) {
      api.createRecord('dfb353c2', component.options)
    } else {
      api.reload('dfb353c2', component.options)
    }
    module.hot.accept("./PositionAndSize.vue?vue&type=template&id=dfb353c2&scoped=true", function () {
      api.rerender('dfb353c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/EditorControl/components/PositionAndSize.vue"
export default component.exports