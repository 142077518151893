var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-folder-layer__item d-flex align-items-center mt-2" },
    [
      _c("div", { staticClass: "c-folder-layer__item--preview mr-2" }, [
        _c("img", {
          staticClass: "layer--item__img",
          style: _vm.getStyle,
          attrs: { src: _vm.src, alt: _vm.node.data.name },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.top.viewport.noninteractive",
              value: {
                title: _vm.node.data.name,
                disabled: _vm.isNotTruncated(_vm.node.data.name),
              },
              expression:
                "{ title: node.data.name, disabled: isNotTruncated(node.data.name) }",
              modifiers: {
                hover: true,
                top: true,
                viewport: true,
                noninteractive: true,
              },
            },
          ],
          staticClass: "layer--item__text",
        },
        [
          _vm.isLayerEditable
            ? _c("EditableTextElement", {
                attrs: {
                  "default-value": _vm.node.data.name,
                  disabled: _vm.isSavingNodesChanges,
                  "render-value": _vm.truncateFieldName,
                },
                on: { change: _vm.changeLayerName },
              })
            : _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.truncateFieldName(_vm.node.data.name)) + " "
                ),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }