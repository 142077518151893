import { ID_GROUP_MASK } from "../import-template-from-psd/mask-generator";
import { TYPE_DIMENSION } from "./handlers/resize-template-handler";
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';

const getShowPageScope = () => ShowPageScopeCollector.get([
  'handleObjectMoving',
  'updateLayoutChanges',
  'updateGroup'
]);

export function getTypeDimension(width, height, canvasSize) {
  if (width / height === canvasSize.width / canvasSize.height) {
    return TYPE_DIMENSION.PROPORTIONAL; 
  } else if (width > height) {
    return TYPE_DIMENSION.BROAD
  } else if (width < height) {
    return TYPE_DIMENSION.HIGH
  }

  return TYPE_DIMENSION.PROPORTIONAL;
}

export function updateObject(object, scale = null) {
  const { handleObjectMoving, updateLayoutChanges, updateGroup } = getShowPageScope();

  if (object.id.startsWith(ID_GROUP_MASK)) {
    updateLayoutChanges(object.canvas.instance_id, 'bk_img_manualResizeGroupsCoefficient', JSON.stringify(scale));
    return;
  }

  if (object.get('type') === 'group') {
    updateGroup(object);
    return;
  }

  handleObjectMoving({ target: object });
}

export const ALIGN_CONTENT = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
}

export function addPointByOrigin({
  toDimension,
  fromDimension,
  scale,
  alignContent
}) {
  let x = toDimension.width - fromDimension.width * scale;
  let y = toDimension.height - fromDimension.height * scale;

  const HANDLERS_ORIGIN = {
    [ALIGN_CONTENT.START]: (value) => 0,
    [ALIGN_CONTENT.CENTER]: (value) => value / 2,
    [ALIGN_CONTENT.END]: (value) => value
  }
  
  return {
    x: HANDLERS_ORIGIN[alignContent.originX](x),
    y: HANDLERS_ORIGIN[alignContent.originY](y),
  }
}

export function replaceSizeIntoTitleOfTemplate(title, width, height) {
  return title.replace(/[\d]{2,4}\s*[x]\s*[\d]{2,4}/g, `${width}x${height}`);
}
