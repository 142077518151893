export default {
  namespaced: true,

  state: {
    selectedTheme: {},
  },

  getters: {
    selectedTheme: state => _.isEmpty(state.selectedTheme)
      ? null
      : state.selectedTheme,
  },

  mutations: {
    SET_SELECTED_THEME (state, payload) {
      state.selectedTheme = payload
    },
  },

  actions: {},
}
