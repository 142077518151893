<template>
  <div class="file-input">
    <b-form-file
      ref="svgFileInput"
      class="mb-2"
      :accept="accept"
      :placeholder="filePlaceholder"
      @input="onFileSelected"
    />
    <b-button
      v-if="selectedFile"
      variant="light"
      class="clear-input-btn"
      @click="$bvModal.show(`modal-warning-${suffix}`)"
    >
      <b-icon
        icon="x-circle"
        class="icon-clear-input"
      />
    </b-button>

    <b-modal
      :id="`modal-warning-${suffix}`"
      hide-backdrop
      content-class="shadow"
      title=""
      @ok="clearCanvas"
    >
      <p class="my-2">
        Your changes will be lost! Are you sure you want to clear the workspace?
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SvgFileInput',

  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    placeholder: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    selectedFile: null,
  }),
  
  computed: {
    filePlaceholder() {
      return this.selectedFile ? this.selectedFile.name : this.placeholder;
    }
  },

  methods: {
    onFileSelected(file) {
      if (!file) return
      
      this.selectedFile = file;
      this.$emit('on-file-selected', file);
    },
    
    clearCanvas() {
      this.$refs.svgFileInput.reset();
      this.selectedFile = null;
      this.$emit('clear-canvas')
    },
    
    setFileName(file) {
      this.selectedFile = file;
    }
  }
};
</script>

<style scoped>
.file-input {
  position: relative;

  .clear-input-btn {
    position: absolute;
    border: none;
    padding: 0;
    right: 90px;
    top: 9px;
    z-index: 1;

    &:hover {
      background-color: transparent !important;
      border: none;
    }

    &:disabled {
      border: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }
}
</style>