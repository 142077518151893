<template>
  <b-modal 
    :id="MODAL_ID" 
    title="Rename"
    ok-title="Save"
    :ok-disabled="isSaveBtnDisabled"
    @ok="handleSave"
  >
    <b-form-input v-model="name" />
  </b-modal>
</template>

<script>
import { renameJobEventBroker, EVENTS } from "@frontend/group/modules/product-description/event-broker";

const MODAL_ID = 'rename-job-modal';

export default {
  name: 'RenameJobModal',
  
  data: () => ({
    MODAL_ID,
    item: null,
    name: '',
  }),
  
  computed: {
    isSaveBtnDisabled() {
      return this.name === this.item?.name;
    }
  },
  
  mounted() {
    renameJobEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },

  methods: {
    init(dto) {
      this.item = dto;
      this.name = dto.name;
      this.$bvModal.show(MODAL_ID);
    },

    async handleSave() {
      await axios.put(`/banner/groups/product_description_jobs/${this.item.id}`, {
        ...this.item,
        name: this.name
      }); 
      
      renameJobEventBroker.fire(EVENTS.SAVE, { name: this.name, id: this.item.id });
    },
    
    handleCancel() {
      this.$bvModal.hide(MODAL_ID);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
