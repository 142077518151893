import { generateKeyPhrasesRegex } from '@frontend/store/psd-layers/features/auto-map/utils/generate-keyphrases-regex';
import { matchKeyPhrase } from '@frontend/store/psd-layers/features/auto-map/utils/match-keyphrase';

const DEFAULT_MATCH_OPTIONS = {
  includeNumber: false
};

const extractNumber = (match) => {
  return match && match[2] ? parseInt(match[2], 10) : null;
}

export const match = ({ targetName, sourceName, keyPhrases, options = DEFAULT_MATCH_OPTIONS }) => {
  if (!targetName || !sourceName) {
    return false;
  }

  const normalizedSourceName = sourceName.toLowerCase().trim();
  const normalizedTargetName = targetName.toLowerCase().trim();

  if (normalizedSourceName === normalizedTargetName) {
    return true;
  }

  const sourceKeyPhrase = matchKeyPhrase(normalizedSourceName, keyPhrases);
  const targetKeyPhrase = matchKeyPhrase(normalizedTargetName, keyPhrases);

  const sourceMatch = normalizedSourceName.match(generateKeyPhrasesRegex(sourceKeyPhrase, options));
  const targetMatch = normalizedTargetName.match(generateKeyPhrasesRegex(targetKeyPhrase, options));

  if (!sourceMatch || !targetMatch) {
    return false;
  }

  if (options.includeNumber) {
    const sourceNumber = extractNumber(sourceMatch);
    const targetNumber = extractNumber(targetMatch);

    if (sourceNumber === null) {
      return targetNumber === 1;
    }

    if (targetNumber === null) {
      return sourceNumber === 1;
    }

    return sourceNumber === targetNumber;
  }

  return true;
}
