var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: { hover: true },
        },
      ],
      staticClass: "fill-dd",
      class: { active: _vm.isPaintBucketOn },
      attrs: {
        text: "",
        variant: "outline-primary",
        disabled: !_vm.isProductInstanceHasObjects,
        size: "sm",
        "no-caret": "",
        title: "Fill image color",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [_c("b-icon", { attrs: { icon: "paint-bucket" } })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("label", { staticClass: "ml-4 mb-0" }, [_vm._v("Intensity")]),
      _vm._v(" "),
      _c(
        "b-input-group",
        { staticClass: "n-input mr-3", attrs: { append: "%" } },
        [
          _c("b-form-input", {
            attrs: { type: "number", value: _vm.fillIntensity },
            on: { input: _vm.changeFillIntensity },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-group range-wrapper" }, [
        _c("input", {
          attrs: { type: "range", min: "0", max: "50", step: "1" },
          domProps: { value: _vm.fillIntensity },
          on: { input: _vm.changeFillIntensity },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectType("none")
            },
          },
        },
        [_vm._v("\n    None\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectType("replace_color")
            },
          },
        },
        [_vm._v("\n    Replace Color\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectType("replace_gradient")
            },
          },
        },
        [_vm._v("\n    Replace Gradient\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }