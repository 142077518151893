<template>
  <modal
    name="svg-layers-modal"
    draggable=".drag-area"
    class="wrapper"
    height="auto"
    :shift-x="0.82"
    :shift-y="0.5"
  >
    <div class="drag-area">
      <span class="title">Layers</span>
      <b-button
        variant="light"
        class="close-btn"
        @click="close"
      >
        <b-icon
          icon="x-lg"
          class="close-icon"
        />
      </b-button>
    </div>

    <div class="content-area">
      <div
        class="group-list"
      >
        <ul>
          <li
            v-for="(gTag, index) in innerGTags"
            :key="index"
          >
            <div
              :class="{ 'item': isRemovedTag(index), 'item selected': !isRemovedTag(index) }"
              @click="removeGTag(index)"
            >
              {{ getTagDisplayName(gTag, index) }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="control-area">
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-primary mr-2"
            @click="close"
          >
            Apply
          </button>
          <button
            class="btn btn-secondary"
            @click="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const SVG_DATA_IMAGE = 'data:image/svg+xml;base64,'
const SVG_IMAGE = 'image/svg+xml'

export default {
  name: 'SvgLayersModal',

  data: () => ({
    svgInstance: null,
    svgCanvasInstance: null,
    removedGTags: [],
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getSvgInstance',
      'getSvgCanvasInstance',
      'isInteractiveMode',
    ]),

    innerGTags() {
      if (this.svgCanvasInstance?.initialState) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(this.svgCanvasInstance.initialState, SVG_IMAGE);

        return this.getElements(svgDoc, 'g');
      }

      return []
    },

    svgXML() {
      return this.svgCanvasInstance?.initialState ? this.svgCanvasInstance.initialState : null;
    },

    modifiedSvgText() {
      if (!this.svgXML) return;

      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(this.svgXML, SVG_IMAGE);
      const elements = this.getElements(svgDoc, 'g');

      this.removedGTags.forEach(indexToRemove => {
        const gElemToRemove = elements[indexToRemove];

        if (gElemToRemove) {
          gElemToRemove.parentNode.removeChild(gElemToRemove);
        }
      });

      const serializer = new XMLSerializer();

      return serializer.serializeToString(svgDoc);
    },
  },

  methods: {
    init() {
      this.svgInstance = this.getSvgInstance;
      this.svgCanvasInstance = this.getSvgCanvasInstance;
    },

    show() {
      this.init();
      this.removedGTags = this.svgCanvasInstance.removedGTagsIndexes;
      this.$modal.show('svg-layers-modal');
    },

    close() {
      this.$modal.hide('svg-layers-modal');
      this.svgCanvasInstance.removedGTagsIndexes = this.removedGTags;
    },

    apply() {
      this.svgCanvasInstance.modifiedSvgXml = unescape(encodeURIComponent(this.modifiedSvgText));
      this.svgCanvasInstance.modifiedSvgUrl = `${SVG_DATA_IMAGE}${btoa(this.svgCanvasInstance.modifiedSvgXml)}`;
      this.svgCanvasInstance.removedGTagsIndexes = this.removedGTags;

      if (this.isInteractiveMode) {
        return this.svgCanvasInstance.loadSvgFromLayers(this.svgCanvasInstance.modifiedSvgXml);
      }

      this.svgCanvasInstance.pasteImageFromUrl(this.svgCanvasInstance.modifiedSvgUrl);
    },

    removeGTag(index) {
      const indexOfRemoved = this.removedGTags.indexOf(index);

      if (indexOfRemoved === -1) {
        this.removedGTags.push(index);
      } else {
        this.removedGTags.splice(indexOfRemoved, 1);
      }

      this.apply();
    },

    isRemovedTag(index) {
      return this.removedGTags.includes(index);
    },

    getTagDisplayName(gTag, index) {
      return gTag.getAttribute('data-name') ? gTag.getAttribute('data-name') : `group ${index + 1}`;
    },

    getElements(svgDocDOMParserInstance, tag) {
      const { children: svgChildren } = svgDocDOMParserInstance.documentElement;
      const gElements = Array.from(svgChildren).filter(({ tagName }) => tagName === tag);

      return gElements.length > 1 ? gElements : gElements.length ? Array.from(gElements[0].children) : [];
    },
  },
};
</script>

<style scoped>
.wrapper {
  ::v-deep .vm--modal {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0 4px 20px;
    background-color: #f6f6f6;
  }

  .drag-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    position: relative;
    padding: 16px 16px;

    .title {
      font-size: 20px;
    }

    .close-btn {
      position: absolute;
      border: none;
      right: 8px;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        border: none;

        .close-icon {
          color: black;
        }
      }

      .close-icon {
        color: #7f7f7f;
        font-size: 15px;

        &:hover {
          color: black;
        }
      }
    }
  }

  .content-area {
    padding: 0 16px 16px 16px;

    .color-input {
      padding: 0;
      height: 32px;
    }
  }

  .control-area {
    padding: 0 16px 16px 16px;
  }

  .group-list {
    top: 120px;
    right: 30px;
    max-height: 310px;
    min-width: 140px;
    overflow-y: auto;
    background-color: #f6f6f6;

    ul {
      list-style-type: none;
      margin: 5px 0 5px 0;
      padding: 0;

      li {
        cursor: pointer;
        user-select: none;

        .item {
          padding: 0 10px;
          line-height: 30px;

          &.selected {
            background-color: #209c86;
            color: white;
          }
        }
      }
    }
  }
}
</style>

<style>
.wrapper {
  .vm--overlay {
    background: transparent !important;
  }
}
</style>