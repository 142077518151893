var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-10" },
      [
        _vm._l(_vm.selectedLayouts, function (selectedLayout) {
          return _c("input", {
            key: selectedLayout.id,
            attrs: { type: "hidden", name: "layouts[]" },
            domProps: { value: selectedLayout.id },
          })
        }),
        _vm._v(" "),
        _c("Multiselect", {
          attrs: {
            options: _vm.layoutsOfCustomer,
            loading: _vm.isLoading,
            "hide-selected": false,
            "clear-on-select": false,
            "close-on-select": false,
            searchable: "",
            placeholder: "Select layouts",
            label: "name",
            "track-by": "id",
            multiple: "",
          },
          on: { "search-change": _vm.getCustomerLayouts },
          model: {
            value: _vm.selectedLayouts,
            callback: function ($$v) {
              _vm.selectedLayouts = $$v
            },
            expression: "selectedLayouts",
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("label", [_vm._v("Layouts")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }