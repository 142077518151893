var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onSearchKeyBlur,
          expression: "onSearchKeyBlur",
        },
      ],
      staticClass: "product-images-input",
    },
    [
      _c("b-form-input", {
        attrs: {
          id: "multipleValues",
          readonly: "",
          placeholder: "Product images",
          autocomplete: "off",
        },
        on: { focus: _vm.expandDropdown },
        model: {
          value: _vm.searchInputValue,
          callback: function ($$v) {
            _vm.searchInputValue = $$v
          },
          expression: "searchInputValue",
        },
      }),
      _vm._v(" "),
      _vm.searchInputValue
        ? _c(
            "b-button",
            {
              staticClass: "clear-product-input-btn",
              attrs: { variant: "light" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.show("modal-warning-product-image")
                },
              },
            },
            [
              _c("b-icon", {
                staticClass: "icon-clear-input",
                attrs: { icon: "x-circle" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dropdownIsExpanded
        ? _c(
            "div",
            { attrs: { id: "file_ids_dropdown" } },
            [_c("file-browser-product-selector")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-warning-product-image",
            "hide-backdrop": "",
            "content-class": "shadow",
            title: "",
          },
          on: { ok: _vm.clearInputValue },
        },
        [
          _c("p", { staticClass: "my-2" }, [
            _vm._v(
              "\n      Your changes will be lost! Are you sure you want to clear the workspace?\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }