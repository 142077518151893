import axios from "axios";
import {deepCopy} from "../../utils";
import MediaService from './media';
import {EVENTS as PSD_LAYERS_EVENT, EVENTS, psdLayersEventBroker} from "../../group/modules/psd-layers/event-broker";
import { getFilePath } from '../helpers';
import BackgroundImage from "@/includes/parse_psd_data/psd-types/background-image";
import {recursiveWalker} from "@/includes/parse_psd_data";
import Base from "@/includes/parse_psd_data/psd-types/base";
import {PARSED_FIELD_TYPES, PSD_LAYERS_DATA_TYPES} from "@/includes/parse_psd_data/constants";
import {extractLayersFromTree} from "@frontend/group/modules/psd-layers/helpers";
import {FIELD_TYPE_PRODUCT_IMAGE} from "@frontend/constants/type-fields-of-template";
export class ApplyStrategy {
    static FLOWS = {
        SAVE_TO_FILE_BROWSER: 'SAVE_TO_FILE_BROWSER',
        ADD_TO_TEMPLATE: 'ADD_TO_TEMPLATE',
        CREATE_TEMPLATE_FROM_ADMIN: 'CREATE_TEMPLATE_FROM_ADMIN',
        UPDATE_PREVIEW_TEMPLATE: 'UPDATE_PREVIEW_TEMPLATE',
    }
    // TODO should be a structure to select specific action
    actions = [];
    state;

    constructor(module) {
        this.state = deepCopy(module.state);
        this.state.layersByTemplate = module.getters.layers;

        this.FLOW_HANDLERS = {
            [ApplyStrategy.FLOWS.ADD_TO_TEMPLATE]: this.addToTemplateFlow.bind(this),
            [ApplyStrategy.FLOWS.SAVE_TO_FILE_BROWSER]: this.saveToFileBrowser.bind(this),
            [ApplyStrategy.FLOWS.CREATE_TEMPLATE_FROM_ADMIN]: this.createTemplateFormAdmin.bind(this),
            [ApplyStrategy.FLOWS.UPDATE_PREVIEW_TEMPLATE]: this.updatePreviewTemplateFlow.bind(this)
        }
    }

    get selectedLayers() {
        return extractLayersFromTree(this.state.templates)
    }

    add(action) {
        this.actions.push(action);
    }

    all() {
        return new Promise(resolve => {
            Promise.all(this.actions).then(() => {
                // TODO find close action and attache to
                if (this.actions.length) this.actions[0].then(resolve)
            })
        })
    }

    build(flow) {

        if (!this.FLOW_HANDLERS[flow]) {
            throw new Error('unhandled flow');
        }

        this.FLOW_HANDLERS[flow]();

        return this;
    }

    addToTemplateFlow() {
        const closeAction = new Promise(resolve => {
            const closeListener = () => {
                resolve();
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE, closeListener);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
            }
            const closeAndReloadListener = () => {
                resolve(true);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE, closeListener);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
            }

            psdLayersEventBroker.on(PSD_LAYERS_EVENT.CLOSE, closeListener);
            psdLayersEventBroker.on(PSD_LAYERS_EVENT.CLOSE_AND_RELOAD, closeAndReloadListener);
        });

        this.add(closeAction);

        this.savePSD();

        psdLayersEventBroker.fire(EVENTS.FILL_TEMPLATE, {
            templates: this.state.templates.filter(template => template.visible),
            layers: this.state.isUseProductImagesFromPSD ? this.getLayersWithUniqueProductImagesLayerNames() : this.selectedLayers,
            isTreatMultipleSpaces: this.state.isTreatMultipleSpacesAsSingle,
            originalFileName: this.state.originalFileName,
            isReplace: this.state.isReplaceExistingProduct,
            isRemovePlaceholderText: this.state.isRemovePlaceholderText,
            isUseSourceTextSettings: this.state.isUseSourceTextSettings
        })
    }

    updatePreviewTemplateFlow() {
        const updatedPreviewTemplateAction = new Promise(resolve => {
            const updatedPreviewTemplateListener = (event) => {
                resolve(event);
                psdLayersEventBroker.off(PSD_LAYERS_EVENT.UPDATED_PREVIEW_TEMPLATE, updatedPreviewTemplateListener);
            }

            psdLayersEventBroker.on(PSD_LAYERS_EVENT.UPDATED_PREVIEW_TEMPLATE, updatedPreviewTemplateListener);
        });

        this.add(updatedPreviewTemplateAction);
        
        psdLayersEventBroker.fire(EVENTS.UPDATE_PREVIEW_TEMPLATE, {
            templates: this.state.selectedTemplates,
            layers: this.selectedLayers,
            isTreatMultipleSpaces: this.state.isTreatMultipleSpacesAsSingle,
            originalFileName: this.state.originalFileName,
        });
    }

    collectPsdDataToStore() {
        return {
            entireImageThumbnail: this.state.entireImageThumbnail,
            layers: this.state.layers,
            parsePsdData: this.state.parsedPsdModel
        }
    }

    saveToFileBrowser() {
        const folderContent = this.selectedLayers
            .filter(layer => layer.type === PARSED_FIELD_TYPES.BACKGROUND_IMAGE)
            .map((layer, index) => ({
                path: layer.src,
                name: [index, layer.data.name].join('---'),
            }))

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.SAVE_PSD_ASSETS_TO_FOLDER, {
            folderContent,
            parentId: +this.state.filename.split('/')[1],
            folderName: this.state.originalFileName.split('.')[0],
        })

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE)
    }

    createTemplateFormAdmin() {
        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CREATE_TEMPLATE_ADMIN, {
            layers: this.selectedLayers,
            name: this.state.originalFileName.split('.')[0],
            width: this.state.parsedPsdModel.width,
            height: this.state.parsedPsdModel.height,
        })

        psdLayersEventBroker.fire(PSD_LAYERS_EVENT.CLOSE)
    }

    getLayersWithUniqueProductImagesLayerNames() {
        return this.selectedLayers.map(layer => {
            if (
              (this.state.isImportIntoTemplate && layer.field && layer.field.type !== FIELD_TYPE_PRODUCT_IMAGE)
              || (!this.state.isImportIntoTemplate && layer.fieldType !== FIELD_TYPE_PRODUCT_IMAGE)
            ) {
                return layer;
            }

            const uniqueId = Number([...Array(10)].map(() => Math.floor(Math.random() * 10)).join(''));

            return {
                ...layer,
                data: {
                    ...layer.data,
                    data: {
                        ...layer.data.data,
                        name: `${uniqueId}-${layer.data.data.name}`,
                    },
                    name: `${uniqueId}-${layer.data.name}`,
                }
            }
        });
    }

    savePSD() {
        if (!this.state.isSavePsd) {
            return;
        }

        const savePSDAction = PSDLayerService.savePSD({
            filename: this.state.filename,
            originName: this.state.originalFileName,
            psdData: this.collectPsdDataToStore(),
        })

        this.add(savePSDAction);
    }
}

const PSDLayerService = {
    savePSD(payload) {
        return axios
            .post('/file/save-psd-to-file-browser', payload)
    },
    saveIndividualAssets(payload) {
        return MediaService
            .addImageToThemeByItsLocation(payload)
            .then(() => {
                psdLayersEventBroker.fire(
                    EVENTS.SAVED_INDIVIDUAL_ASSETS,
                    payload
                )
            })
    },
    updatePSD(payload) {
        return axios
            .post('/file/update-psd-file-in-browser', payload);
    },
    uploadUsersLogo(payload) {
        return axios.post('/file/upload_users_logo', payload);
    },
    mergeNode(payload) {
      return axios.post('/file/merge_psd_node', payload)
    }
}

export default PSDLayerService
