var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c(
        "b-form-checkbox",
        {
          staticClass: "mr-1",
          on: {
            change: function ($event) {
              return _vm.onChange("applyToAll", _vm.isApplyToAll)
            },
          },
          model: {
            value: _vm.isApplyToAll,
            callback: function ($$v) {
              _vm.isApplyToAll = $$v
            },
            expression: "isApplyToAll",
          },
        },
        [_vm._v("\n    Apply to\n  ")]
      ),
      _vm._v(" "),
      _c("SelectTemplateDropdown", {
        attrs: {
          "local-storage-key":
            _vm.LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }