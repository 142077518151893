var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "editable-text-element" }, [
    !_vm.isEditable
      ? _c(
          "div",
          {
            staticClass: "editable-text-element__text-display",
            on: { click: _vm.startEditing },
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(_vm.renderValue ? _vm.renderValue(_vm.text) : _vm.text)
              ),
            ]),
          ]
        )
      : _c("div", { staticClass: "editable-text-element__edit-mode" }, [
          _c("div", { staticClass: "editable-text-element__input-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editedText,
                  expression: "editedText",
                },
              ],
              ref: "editInput",
              staticClass: "editable-text-element__input",
              attrs: { type: "text" },
              domProps: { value: _vm.editedText },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.saveEdit.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.resetEdit.apply(null, arguments)
                  },
                ],
                blur: _vm.saveEdit,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.editedText = $event.target.value
                },
              },
            }),
          ]),
        ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editable-text-element__error-container" },
      _vm._l(_vm.errors, function (error) {
        return _c(
          "span",
          { key: error.id, staticClass: "editable-text-element__error" },
          [_vm._v("\n      " + _vm._s(error.title) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }