import { parseJsonData } from "../../helpers";
import {
  ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID,
  ADDITIONAL_CIRCLE_PREFIX_ELEMENT_ID,
  ADDITIONAL_CIRCLE_PREFIX_ID,
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ELEMENT_ID,
  ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
  ADDITIONAL_RECTANGLE_PREFIX_ELEMENT_ID,
  ADDITIONAL_RECTANGLE_PREFIX_ID,
  ADDITIONAL_TEXT_PREFIX_ELEMENT_ID,
  ADDITIONAL_TEXT_PREFIX_ID,
  BACKGROUND_THEME_IMAGE_MULTIPLE_PREFIX_ID,
  BACKGROUND_THEME_IMAGE_PREFIX_ID,
  CIRCLE_PREFIX_ID, IMAGE_FROM_BK_PREFIX_ID,
  IMAGE_PREFIX_ID,
  MULTI_LINE_PREFIX_ID,
  RECTANGLE_PREFIX_ID, SHAPE_PREFIX_ID, STATIC_IMAGE_PREFIX_ID,
  STATIC_TEXT_PREFIX_ID,
  TEXT_PREFIX_ID, UPLOAD_BACKGROUND_IMAGE_PREFIX_ID, UPLOAD_IMAGE_PREFIX_ID
} from "../../../constants/templates-field-prefixes";
import {CIRCLE} from "../../../lib/definitions/circle-wipe";

export function calculateTimeByTotalDuration(nodes, totalDuration = 3) {
  const nodesIsPlay = nodes.filter(node => node.isPlay);
  const nodesIsNotPlay = nodes.filter(node => !node.isPlay);
  const duration = totalDuration / nodesIsPlay.length;

  nodesIsPlay.forEach((node, index) => {
    node.startTime = +((duration * index).toFixed(2));
    node.endTime = +((duration * (index + 1)).toFixed(2));
  })

  nodesIsNotPlay.forEach((node) => {
    node.startTime = 0;
    node.endTime = 0;
  })

  return nodes;
}

export function getAnimationData(canvas, animationData) {
  const parseAnimation = parseJsonData(animationData, {});
  let objects = canvas.getObjects();
  const supportObjects = [
    IMAGE_PREFIX_ID,
    TEXT_PREFIX_ID,
    MULTI_LINE_PREFIX_ID,
    STATIC_TEXT_PREFIX_ID,
    STATIC_IMAGE_PREFIX_ID,
    UPLOAD_BACKGROUND_IMAGE_PREFIX_ID,
    IMAGE_FROM_BK_PREFIX_ID,
    UPLOAD_IMAGE_PREFIX_ID,
    BACKGROUND_THEME_IMAGE_PREFIX_ID,
    BACKGROUND_THEME_IMAGE_MULTIPLE_PREFIX_ID,
    ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ID,
    ADDITIONAL_TEXT_PREFIX_ELEMENT_ID,
    ADDITIONAL_TEXT_PREFIX_ID,
    ADDITIONAL_MULTI_LINE_TEXT_FIELD_PREFIX_ELEMENT_ID,
    ADDITIONAL_RECTANGLE_PREFIX_ELEMENT_ID,
    ADDITIONAL_RECTANGLE_PREFIX_ID,
    RECTANGLE_PREFIX_ID,
    SHAPE_PREFIX_ID,
    CIRCLE_PREFIX_ID,
    ADDITIONAL_CIRCLE_PREFIX_ID,
    ADDITIONAL_CIRCLE_PREFIX_ELEMENT_ID,
    ADDITIONAL_BACKGROUND_THEME_IMAGE_PREFIX_ID,
  ];

  objects = objects.filter(obj => supportObjects.find(id => obj.id.startsWith(id)));
  objects = objects.filter(obj => obj.type !== 'image' || obj.getSrc());

  let nodes = objects.map((obj) => {
    const saveNode = parseAnimation?.nodes?.find(node => node.objectId === obj.id) || {};

    return {
      objectId: obj.id,
      name: obj?.templateField?.name || '',
      src: obj.getSrc ? obj.getSrc() : '',
      text: obj.text,
      isPlay: !!Object.keys(saveNode).length,
      startTime: 0,
      endTime: 0,
      animations: [],
      ...saveNode
    }
  })

  nodes = nodes.sort((a,b) => {
    if (a.objectId < b.objectId)
      return -1;
    if (a.objectId > b.objectId)
      return 1;
    return 0;
  });

  return {
    ...parseAnimation,
    isNotInitAnimation: !Object.keys(parseAnimation).length,
    nodes
  };
}

export function getDefaultAnimationData(canvas) {
  const data = getAnimationData(canvas);

  calculateTimeByTotalDuration(data.nodes);

  return data;
}