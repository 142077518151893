<template>
  <div ref="pickrContainer">
    <div ref="pickr" />
  </div>
</template>

<script>
import Pickr from '@simonwep/pickr';
import ThemesContainer from '@/banner_template/themes/ThemesContainer';
import { rgbToHex } from '@frontend/services/helpers';

export default {
  props: {
    value: {
      type: String,
      default: '#000000',
    },
    themes: {
      type: ThemesContainer,
      default: () => new ThemesContainer([])
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom-middle',
    },
    opacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickr: null,
    }
  },
  watch: {
    value (val) {
      if (this.pickr && this.pickr.getColor().toHEXA().toString() !== val) {
        setTimeout(() => {
          this.setColor(val)
        }, 50);
      }
    },
    disabled() {
      if (this.disabled) {
        this.pickr.disable();
      } else {
        this.pickr.enable();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initPicker();
    })
  },
  methods: {
    setColor(color) {
      if (!this.pickr) {
        return
      }
      this.pickr.setColor(color);
      this.pickr.applyColor();
    },
    onColorChange(color, source, instance) {
      instance.applyColor();
      this.$emit('input', color.toHEXA().toString());
    },
    onChangeTheme(event) {
      const themeId = +event.target.value;

      while (this.pickr.removeSwatch(0)) {};

      const theme = this.themes.getThemeById(themeId);

      if (theme) {
        theme.colors.forEach(color => {
          this.pickr.addSwatch(color)
        });
      }
    },
    onClickEyeDropper(event) {
      event.preventDefault();

      new EyeDropper()
        .open()
        .then(({ sRGBHex }) => {

          const rexRGBA = /^(rgb|rgba)\(/;

          if (rexRGBA.test(sRGBHex)) {
            const [red, blue, green] = sRGBHex
              .replace(rexRGBA,'')
              .replace(/\)$/,'')
              .replace(/\s/g,'')
              .split(',');
            
            this.setColor(rgbToHex(+red, +blue, +green));
          } else {
            this.setColor(sRGBHex);
          }
          
        }).catch((error) => {
          console.error(error);
        });

      $(event.target).blur();
    },
    initPicker() {
       this.pickr = new Pickr({
        el: this.$refs.pickr,
        container: this.$refs.pickrContainer,
        theme: 'nano',
        swatches: this.themes.getFirstTheme()?.colors || [],
        position: this.position,
        components: {
          preview: true,
          hue: true,
          opacity: this.opacity,
          interaction: {
            hex: true,
            rgba: true,
            hsla: true,
            input: true,
          },
        },
        default: this.value
      })

      this.pickr.on('change', this.onColorChange.bind(this));

      const rootDomPicker = this.pickr.getRoot();

      // ===== start init theme selector =======
      if (this.themes.themes.length) {
        const $themeSelect = $(`<select class="form-control"></select>`);

        this.themes.themes.forEach((theme) => {
          $themeSelect.append(`<option value="${theme.id}">${theme.name}</option>`);
        });

        $themeSelect.on('change', this.onChangeTheme.bind(this));

        $(rootDomPicker.swatches)
          .before($('<div class="pt-2 px-2"></div>')
          .append($themeSelect));
      }
      // ===== end init theme selector =========

      // ===== start init EyeDropper ===========
      if (window.EyeDropper) {
        const $eyedropper = $(`<a href="#"><i class="c-icon cil-eyedropper"></i></a>`);
        $eyedropper.on('click', this.onClickEyeDropper.bind(this));

        $('.pcr-interaction', rootDomPicker.app).append($eyedropper);
      }
      // ===== end init EyeDropper =============
    }
  }
}
</script>