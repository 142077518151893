var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "svg-messages-wrapper" }, [
    _c("span", [_vm._v(_vm._s(_vm.displayMessage()))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }