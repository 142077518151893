var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "wrapper",
      attrs: {
        name: "svg-layers-modal",
        draggable: ".drag-area",
        height: "auto",
        "shift-x": 0.82,
        "shift-y": 0.5,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drag-area" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("Layers")]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "close-btn",
              attrs: { variant: "light" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon", {
                staticClass: "close-icon",
                attrs: { icon: "x-lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-area" }, [
        _c("div", { staticClass: "group-list" }, [
          _c(
            "ul",
            _vm._l(_vm.innerGTags, function (gTag, index) {
              return _c("li", { key: index }, [
                _c(
                  "div",
                  {
                    class: {
                      item: _vm.isRemovedTag(index),
                      "item selected": !_vm.isRemovedTag(index),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeGTag(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getTagDisplayName(gTag, index)) +
                        "\n          "
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control-area" }, [
        _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-end justify-content-end" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  on: { click: _vm.close },
                },
                [_vm._v("\n          Apply\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-secondary", on: { click: _vm.close } },
                [_vm._v("\n          Close\n        ")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }