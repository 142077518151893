import Template from '@/banner_template/Template';
import { updateObject } from '../../helpers';
import {
  setDataForExistFields
} from "@frontend/group/modules/copy-and-resize/handlers/resize-proportional-template-handler/set-data-for-exist-fields";
import { FIELD_TYPE_MULTI_LINE_TEXT } from '@frontend/constants/type-fields-of-template';
import {
  handleObjectsIntersections
} from '@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/handle-objects-intersections';
import {
  getObjectScaleFactor
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/scale-functinonality";
import {
  interpolateVerticalView
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/interpolate-vertical-view";
import {
  COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT,
  MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING,
  PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING,
  SHOULD_CLOSED_TO_THE_BOTTOM
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/constants";
import {applyPaddings} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/padding";


const MAX_ITERATIONS_FOR_INTERSECTION = 20;

const inlinePositions = objects => JSON.stringify(objects.map(o => o.getPointByOrigin('left', 'top')))


const sortObjectsByXCoordinate = (objects, origins) => {
  return objects.sort((a, b) => {
    const aPoints = a.getPointByOrigin(...origins);
    const bPoints = b.getPointByOrigin(...origins);

    if (aPoints.x < bPoints.x) return -1;
    if (aPoints.x > bPoints.x) return 1;

    return 0;
  });
}

export const resizeLikeGridHandler = ({
  isCopyObject,
  dimension,
  mainTemplate,
  canvas,
  isUseDestinationTemplateFields,
  objects,
  templateSettings,
}) => {
  const origins = ['left', 'top'];
  const template = new Template(dimension?.template || {});
  const rsW = dimension.width / canvas.width;
  const rsH = dimension.height / canvas.height;

  const allObjects = isUseDestinationTemplateFields
    ? setDataForExistFields({
      objects,
      template,
      mainTemplate,
      isCopyObject,
      templateSettings
    })
    : objects;
  const sortedObjects = sortObjectsByXCoordinate(allObjects, origins);

  const resizedObjects = [];

  for (const _object of sortedObjects) {
    const object = isCopyObject ? _.cloneDeep(_object) : _object;
    const originalPoints = object.getPointByOrigin(...origins);
    const scale = getObjectScaleFactor({ object, dimension, canvas });

    let scaleX = object.scaleX * scale;
    let scaleY = object.scaleY * scale;

    const originalOffsetXPercent = originalPoints.x / canvas.width;
    const originalOffsetYPercent = originalPoints.y / canvas.height;

    let newOffsetX = dimension.width * originalOffsetXPercent;
    let newOffsetY = dimension.height * originalOffsetYPercent;

    // Adjust newOffsetX if the element is going beyond the right edge
    if (
        (Math.round(newOffsetX + object.width * scaleX) > dimension.width) &&
        (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT || (Math.round(originalPoints.x + object.getScaledWidth()) <= canvas.width))
    ) {
        newOffsetX = dimension.width - object.width * scaleX;
    }

    if (
        (newOffsetY < 0) 
        && (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT || originalPoints.y >= 0)
    ) {
        newOffsetY = dimension.height - object.height * scaleY;
    }

    // If element's offset X is less than 0, scale it down to fit within dimension.width
    if (newOffsetX < 0 && originalPoints.x >= 0) {
      const maxAllowedWidth = dimension.width;
      const currentWidth = object.width * scaleX;

      if (currentWidth > maxAllowedWidth) {
        if (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT) {
          object.width = maxAllowedWidth / scaleX;
        } else {
          const downscaleFactor = maxAllowedWidth / currentWidth;
          scaleX *= downscaleFactor;
          scaleY *= downscaleFactor;
        }

        newOffsetX = 0;
      }
    }

    if (
      (newOffsetY + object.height * scaleY > dimension.height) &&
      (originalPoints.y + object.getScaledHeight() <= canvas.height)
    ) {
      const excessHeight = (newOffsetY + object.height * scaleY) - dimension.height;

      if (newOffsetY - excessHeight < 0) {
        newOffsetY = 0;
        const desiredHeight = dimension.height - newOffsetY;
        const downscaleFactor = desiredHeight / (object.height * scaleY);

        scaleY *= downscaleFactor;
        scaleX *= downscaleFactor;
      } else {
        newOffsetY -= excessHeight;
      }
    }

    if (
      object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT
      && SHOULD_CLOSED_TO_THE_BOTTOM.some(inclusion => object?.templateField.name.toLowerCase().includes(inclusion))
    ) {
      newOffsetY = dimension.height - object.height * scaleY
    }

    // text lines reducing
    if (
      object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT
      && object.height * scaleY / dimension.height > PERCENTS_OF_HEIGHT_TO_ENABLE_TEXT_LINES_REDUCING
      && object.textLines.length > MINIMUM_OF_TEXT_TO_ENABLE_TEXT_LINES_REDUCING
    ) {

      const lastWidth = object.measureLine([object._textLines.length - 1]).width
      const sLastWidth = object.measureLine([object._textLines.length - 2]).width
      object._textLines = object._textLines.reduceRight((acc, current, i) => {
        if ((object._textLines.length - 2) === i) {
          acc[0] = [...current, ' ', ...acc[0]]
        } else {
          acc.push(current)
        }
        return acc
      }, []).reverse()

      object.width = (lastWidth + sLastWidth) * COEFFICIENT_TO_APPLY_AFTER_TEXT_LINES_CONCAT
    }

    object.set({
      scaleX,
      scaleY,
    });

    object.setPositionByOrigin({
      x: newOffsetX,
      y: newOffsetY
    }, ...origins);

    object.setCoords();

    if (object.templateField?.type === FIELD_TYPE_MULTI_LINE_TEXT) {
      object.initDimensions();
    }

    updateObject(object, { x: rsW, y: rsH });
    resizedObjects.push(object);
  }
  
  const objectsToCheckIntersections = resizedObjects.filter(_object => !!_object.templateField);

  interpolateVerticalView({ objects: objectsToCheckIntersections, dimension, rsW, rsH })

  let prevPoses = undefined
  let i = 0
  do {
    i++
    prevPoses = inlinePositions(objectsToCheckIntersections)
    handleObjectsIntersections({ objects: objectsToCheckIntersections, dimension, rsW, rsH });
  } while (prevPoses !== inlinePositions(objectsToCheckIntersections) && i < MAX_ITERATIONS_FOR_INTERSECTION)

  if (i === MAX_ITERATIONS_FOR_INTERSECTION) {
    console.error('Intersections max iteration reached')
  }

  applyPaddings({ objects: objectsToCheckIntersections, dimension, rsW, rsH, canvas })
}
