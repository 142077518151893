var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          attrs: { size: "sm", variant: "outline-success" },
          on: { click: _vm.addFilter },
        },
        [_vm._v("\n    Add Filter\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "max-height": "300px", "overflow-y": "auto" } },
        _vm._l(_vm.filters, function (input, filterIndex) {
          return _c(
            "div",
            { key: filterIndex },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("hr", { staticClass: "dropdown-divider w-100" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mx-1" }),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      staticClass: "py-0 px-1 text-nowrap",
                      attrs: { size: "sm", variant: "outline-danger" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFilter(filterIndex)
                        },
                      },
                    },
                    [_vm._v("\n          Delete Filter\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Opacity" } },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function () {
                                      return [
                                        _c("b-input", {
                                          attrs: {
                                            min: 0,
                                            step: "0.01",
                                            max: 1,
                                            type: "number",
                                          },
                                          on: { input: _vm.onChange },
                                          model: {
                                            value: input.opacity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                input,
                                                "opacity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "input.opacity",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  min: 0,
                                  step: "0.01",
                                  max: 1,
                                  type: "range",
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: input.opacity,
                                  callback: function ($$v) {
                                    _vm.$set(input, "opacity", _vm._n($$v))
                                  },
                                  expression: "input.opacity",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Angle" } },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function () {
                                      return [
                                        _c("b-input", {
                                          attrs: {
                                            min: -360,
                                            step: "1",
                                            max: 360,
                                            type: "number",
                                          },
                                          on: { input: _vm.onChange },
                                          model: {
                                            value: input.angle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                input,
                                                "angle",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "input.angle",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  min: -360,
                                  step: "1",
                                  max: 360,
                                  type: "range",
                                },
                                on: { input: _vm.onChange },
                                model: {
                                  value: input.angle,
                                  callback: function ($$v) {
                                    _vm.$set(input, "angle", _vm._n($$v))
                                  },
                                  expression: "input.angle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("label", [_vm._v("Type Gradient")]),
                      _vm._v(" "),
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: `gradient-type-${filterIndex}`,
                            value: _vm.GRADIENT_TYPES.LINEAR,
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value: input.type,
                            callback: function ($$v) {
                              _vm.$set(input, "type", $$v)
                            },
                            expression: "input.type",
                          },
                        },
                        [_vm._v("\n            Linear\n          ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-3" }),
                      _vm._v(" "),
                      _c(
                        "b-form-radio",
                        {
                          attrs: {
                            name: `gradient-type-${filterIndex}`,
                            value: _vm.GRADIENT_TYPES.RADIAL,
                          },
                          on: { input: _vm.onChange },
                          model: {
                            value: input.type,
                            callback: function ($$v) {
                              _vm.$set(input, "type", $$v)
                            },
                            expression: "input.type",
                          },
                        },
                        [_vm._v("\n            Radial\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  input.type === _vm.GRADIENT_TYPES.RADIAL
                    ? [
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Left" } },
                              [
                                _c("b-input", {
                                  attrs: { step: "1", type: "number" },
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: input.left,
                                    callback: function ($$v) {
                                      _vm.$set(input, "left", _vm._n($$v))
                                    },
                                    expression: "input.left",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Top" } },
                              [
                                _c("b-input", {
                                  attrs: { step: "1", type: "number" },
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: input.top,
                                    callback: function ($$v) {
                                      _vm.$set(input, "top", _vm._n($$v))
                                    },
                                    expression: "input.top",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "3" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Radius" } },
                              [
                                _c("b-input", {
                                  attrs: { step: "1", type: "number" },
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: input.radius,
                                    callback: function ($$v) {
                                      _vm.$set(input, "radius", _vm._n($$v))
                                    },
                                    expression: "input.radius",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c("div", [_vm._v("Colors")]),
                                        _vm._v(" "),
                                        _c(
                                          "b-btn",
                                          {
                                            staticClass: "py-0 px-1",
                                            attrs: {
                                              size: "sm",
                                              variant: "outline-success",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addColor(filterIndex)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-plus",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm._l(input.colors, function (color, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex mb-1" },
                              [
                                _c("ColorPicker", {
                                  attrs: { position: `top-middle` },
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: color.color,
                                    callback: function ($$v) {
                                      _vm.$set(color, "color", $$v)
                                    },
                                    expression: "color.color",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-input-group",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("b-input", {
                                                attrs: {
                                                  min: 0,
                                                  step: "0.01",
                                                  max: 1,
                                                  type: "number",
                                                },
                                                on: { input: _vm.onChange },
                                                model: {
                                                  value: color.offset,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      color,
                                                      "offset",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "color.offset",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        min: 0,
                                        step: "0.01",
                                        max: 1,
                                        type: "range",
                                      },
                                      on: { input: _vm.onChange },
                                      model: {
                                        value: color.offset,
                                        callback: function ($$v) {
                                          _vm.$set(color, "offset", _vm._n($$v))
                                        },
                                        expression: "color.offset",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mx-1" }),
                                _vm._v(" "),
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "py-0 px-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteColor(
                                          filterIndex,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fas fa-trash" })]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }