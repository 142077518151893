var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "wrapper",
      attrs: {
        name: "svg-extract-modal",
        draggable: ".drag-area",
        height: "auto",
        "shift-x": 0.5,
        "shift-y": 0.5,
        "click-to-close": false,
        width: "1200",
      },
    },
    [
      _c(
        "div",
        { staticClass: "drag-area" },
        [
          _c(
            "b-button",
            {
              staticClass: "close-btn",
              attrs: { variant: "light" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon", {
                staticClass: "close-icon",
                attrs: { icon: "x-lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-area" },
        _vm._l(_vm.images, function (image, index) {
          return _c(
            "div",
            {
              key: index,
              class: {
                "content-image selected": image.isSelected,
                "content-image": !image.isSelected,
              },
              on: {
                click: function ($event) {
                  return _vm.selectImage(index)
                },
              },
            },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(image.title)),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "content-image",
                attrs: { src: image.src, crossorigin: "anonymous" },
              }),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "control-area" }, [
        _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-end justify-content-end" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  on: { click: _vm.apply },
                },
                [_vm._v("\n          Apply\n        ")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }