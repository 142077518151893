import { FIELD_TYPE_MULTI_LINE_TEXT, FIELD_TYPE_TEXT } from '@frontend/constants/type-fields-of-template';
import { FIELD_TYPE_RECTANGLE, FIELD_TYPE_SMART_OBJECT } from '@frontend/constants/type-fields-of-template';
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import { updateObject } from '../../helpers';
import { FIELD_TYPE_TEXT_OPTIONS } from '../../../../../constants/type-fields-of-template';

const getShowPageScope = () => ShowPageScopeCollector.get([
  'updateLayoutChanges',
  'getSettingsDataInstance'
]);

const origins = ['left', 'top'];

const setDataForNotExistFieldTextHandler = ({ object }) => {
  const instanceId = object.canvas.instance_id;
  const elementId = object.element_id;
  const { updateLayoutChanges } = getShowPageScope();

  updateLayoutChanges(instanceId, elementId, '');
}

const setDataForExistFieldTextHandler = ({ object, findMappedField }) => {
  object.set({
    width: findMappedField.getWidth(),
    fontSize: findMappedField.options.fontSize,
    fontFamily: findMappedField.options.font,
  });

  object.setPositionByOrigin({
    x: findMappedField.getLeft(),
    y: findMappedField.getTop()
  }, ...origins);
}

const setDataForExistFieldRectangleHandler = ({ object, template, mainTemplate, findMappedField }) => {
  const scaleX = findMappedField.getWidth() / object.getScaledWidth();
  const scaleY = findMappedField.getHeight() / object.getScaledHeight();
  const radius = findMappedField.getRadius();
  const templateField = object.templateField;

  object.set({
    scaleX,
    scaleY,
    rx: radius,
    ry: radius,
    fill: findMappedField.getFill(),
    stroke: findMappedField.getStrokeColor(),
    strokeWidth: findMappedField.getStrokeWidth()
  });

  const mainSmartObject = mainTemplate.getFieldsByType(FIELD_TYPE_SMART_OBJECT)
    .find(smartObject => smartObject.getGroupName() === templateField.getGroupName());
  const smartObject = template.getFieldsByType(FIELD_TYPE_SMART_OBJECT)
    .find(smartObject => smartObject.getGroupName() === findMappedField.getGroupName());

  let x = findMappedField.getLeft();
  let y = findMappedField.getTop();

  if (smartObject && mainSmartObject) {
    x = x + templateField.getLeft() + mainSmartObject.getLeft();
    y = y + templateField.getTop() + mainSmartObject.getTop();
  }

  object.setPositionByOrigin({ x, y }, ...origins);
}

const setDataForExistFieldSmartObjectHandler = ({ object, findMappedField, templateSettings }) => {
  const instanceId = object.canvas.instance_id;
  const { updateLayoutChanges, getSettingsDataInstance } = getShowPageScope();

  const settingsTemplateData = getSettingsDataInstance(templateSettings?.settings_template);
  settingsTemplateData.addField({
    'id': object.templateField.id,
    'X': findMappedField.getLeft(),
    'Y': findMappedField.getTop(),
    'Width': findMappedField.getWidth(),
    'Height': findMappedField.getHeight(),
  });

  updateLayoutChanges(instanceId, 'settings_template', settingsTemplateData.toJSON())
}

const MAP_EXIST_FIELD_HANDLER = {
  [FIELD_TYPE_TEXT]: setDataForExistFieldTextHandler,
  [FIELD_TYPE_MULTI_LINE_TEXT]: setDataForExistFieldTextHandler,
  [FIELD_TYPE_TEXT_OPTIONS]: setDataForExistFieldTextHandler,
  [FIELD_TYPE_RECTANGLE]: setDataForExistFieldRectangleHandler,
  [FIELD_TYPE_SMART_OBJECT]: setDataForExistFieldSmartObjectHandler,
}

const MAP_NOT_EXIST_FIELD_HANDLER = {
  [FIELD_TYPE_TEXT]: setDataForNotExistFieldTextHandler,
  [FIELD_TYPE_MULTI_LINE_TEXT]: setDataForNotExistFieldTextHandler,
  [FIELD_TYPE_TEXT_OPTIONS]: setDataForNotExistFieldTextHandler,
}

const setDataForNotExistFields = (data) => {
  const { object } = data;
  const handler = MAP_NOT_EXIST_FIELD_HANDLER[object?.templateField?.type];
      
  if (handler) handler(data);
}

export const setDataForExistFields = ({ allObjects, template, mainTemplate, isCopyObject, templateSettings }) => {      
  const _allObjects = [];

  const fieldsText = template.getFieldsByTypes([
    FIELD_TYPE_TEXT,
    FIELD_TYPE_MULTI_LINE_TEXT,
    FIELD_TYPE_TEXT_OPTIONS,
    FIELD_TYPE_RECTANGLE,
    FIELD_TYPE_SMART_OBJECT
  ]);

  for (const _object of allObjects) {
    const object = isCopyObject ? _.cloneDeep(_object) : _object;
    const templateField = object?.templateField;
    const findMappedField = fieldsText.find(field => field.name === templateField?.name);

    if (!findMappedField) {
      setDataForNotExistFields({object: _object });
      _allObjects.push(_object);
      continue;
    }

    MAP_EXIST_FIELD_HANDLER[findMappedField.getType()]({
      object,
      findMappedField,
      template,
      mainTemplate,
      templateSettings
    });

    object.setCoords();

    updateObject(object);
  }

  return _allObjects;
}
