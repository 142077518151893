import { fabric } from "fabric";

fabric.Image.filters.Gradient = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

    type: 'Gradient',

    gradient: null,

    alpha: 1,

    /**
     * Apply the Blend operation to a Uint8ClampedArray representing the pixels of an image.
     *
     * @param {Object} options
     * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
     */
    applyTo2d: function(options) {
        var imageData = options.imageData,
            data = imageData.data, iLen = data.length,
            tr, tg, tb, ta,
            r, g, b, a,
            alpha1 = 1 - this.alpha;

        const imageWidth = imageData.width;
        const imageHeight = imageData.height;
        const fakeCanvas = document.createElement('canvas');

        fakeCanvas.setAttribute('width', imageWidth);
        fakeCanvas.setAttribute('height', imageHeight);

        const fhc = new fabric.Canvas(fakeCanvas);
        fhc.setZoom(1 / window.devicePixelRatio);
        const rect = new fabric.Rect({
            left: 0,
            top: 0,
            strokeWidth: 0,
            width: imageWidth * window.devicePixelRatio,
            height: imageHeight * window.devicePixelRatio
        });
        
        rect.set('fill', this.gradient || 'transparent');
        fhc.add(rect);
        fhc.renderAll();

        var ifhcContext = fhc.getContext('2d');
        var fhcImageData = ifhcContext.getImageData(0, 0, fhc.width, fhc.height);
        var source = fhcImageData.data;

        for (var i = 0; i < iLen; i += 4) {

            tr = source[i];
            tg = source[i + 1];
            tb = source[i + 2];
            ta = source[i + 3] / 255;

            r = data[i];
            g = data[i + 1];
            b = data[i + 2];
            a = data[i + 3] / 255;

            if (a === 0) continue;
            
            const alpha = ta * this.alpha;

            const outAlpha = alpha + a * (1 - alpha);

            if (outAlpha > 0) {
                data[i] = (tr * alpha + r * a * (1 - alpha)) / outAlpha;
                data[i + 1] = (tg * alpha + g * a * (1 - alpha)) / outAlpha;
                data[i + 2] = (tb * alpha + b * a * (1 - alpha)) / outAlpha;
                data[i + 3] = outAlpha * 255;
            } else {
                data[i] = 0;
                data[i + 1] = 0;
                data[i + 2] = 0;
                data[i + 3] = 0;
            }
        }
    },

});

export const applyGradientFilter = (object) => {
    const oldFilterBackend = fabric.filterBackend;
    fabric.filterBackend = new fabric.Canvas2dFilterBackend();

    object.applyFilters();
    fabric.filterBackend = oldFilterBackend;
}