export function getReplacedTabs(namingValueTabs, replaceTabs = []) {
    
    return namingValueTabs.reduce((acc, tab) => {
        const indexReplaceTab = replaceTabs.findIndex(replaceTab => tab.tabId === replaceTab.tabId);

        if (tab.hasVariable && indexReplaceTab !== -1) {
    
            acc.push(replaceTabs[indexReplaceTab]);

            replaceTabs.splice(indexReplaceTab, 1);

        } else {
            acc.push(tab);
        }

        return acc;
    }, []);
}