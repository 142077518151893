var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-input-group",
    { attrs: { size: "sm" } },
    [
      _c(
        "div",
        [
          _c("Multiselect", {
            attrs: {
              label: "text",
              "track-by": "value",
              options: _vm.colorsWithEmpty,
              searchable: false,
              "show-labels": false,
            },
            scopedSlots: _vm._u([
              {
                key: "singleLabel",
                fn: function ({ option: { text, value } }) {
                  return [
                    _c("span", {
                      staticClass: "color-square mr-1",
                      style: `background: ${_vm.getStyleBackgroundColor(
                        value
                      )}`,
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(text))]),
                  ]
                },
              },
              {
                key: "placeholder",
                fn: function () {
                  return [
                    _vm.modelValue
                      ? _c("span", {
                          staticClass: "color-square mr-1",
                          style: `background: ${_vm.getStyleBackgroundColor(
                            _vm.modelValue
                          )}`,
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.modelValue))]),
                  ]
                },
                proxy: true,
              },
              {
                key: "option",
                fn: function ({ option: { text, value } }) {
                  return [
                    _c("span", {
                      staticClass: "color-square mr-1",
                      style: `background: ${_vm.getStyleBackgroundColor(
                        value
                      )}`,
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(text))]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedColor,
              callback: function ($$v) {
                _vm.selectedColor = $$v
              },
              expression: "selectedColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-input-group-append",
        [
          _c(
            "b-button",
            { ref: "colorPickerBtn" },
            [_c("b-icon", { attrs: { icon: "brush" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        { attrs: { target: _vm.popoverTarget, triggers: "focus" } },
        [
          _c("Compact", {
            attrs: { palette: _vm.palette },
            model: {
              value: _vm.hexColor,
              callback: function ($$v) {
                _vm.hexColor = $$v
              },
              expression: "hexColor",
            },
          }),
          _vm._v(" "),
          _vm.customColor
            ? _c(
                "div",
                [
                  _c("Chrome", {
                    model: {
                      value: _vm.hexColor,
                      callback: function ($$v) {
                        _vm.hexColor = $$v
                      },
                      expression: "hexColor",
                    },
                  }),
                  _vm._v(" "),
                  _vm.selectedTheme && _vm.selectedTheme.name !== "Custom"
                    ? _c(
                        "b-button",
                        {
                          ref: "saveBtn",
                          attrs: { size: "sm", disabled: _vm.colorExists },
                          on: { click: _vm.saveColor },
                        },
                        [_vm._v("\n        Save color to theme\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }