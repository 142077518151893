var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "upload-modal",
        visible: _vm.visible,
        "ok-disabled": !_vm.files.length,
        title: "Upload files",
        "ok-title": "Upload",
        size: "xl",
        centered: "",
      },
      on: {
        ok: _vm.ok,
        hide: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("Vue2Dropzone", {
        ref: "fileInput",
        attrs: { id: "uploadDropzone", options: _vm.dropzoneOptions },
        on: {
          "vdropzone-file-added": _vm.setFiles,
          "vdropzone-removed-file": _vm.setFiles,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }