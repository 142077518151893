<template>
  <div 
    class="c-item-overlay"
    :class="{ folder_overlay: item.type == 'folder' }"
  >
    <div
      v-if="isAvailableEditAction && item.type != 'folder' "
      class="c-item-overlay__item edit-item"
      @click="editItem"
    >
      <i class="fa fa-edit" />
    </div>
    <div
      class="c-item-overlay__item delete-item"
      @click="deleteItem"
    >
      <svg
        width="20px"
        height="20px"
      >
        <use href="/img/icons/sprite.svg#file-circle-close" />
      </svg>
    </div>
    <div
      v-if="isAvailableEditAction && item.type == 'folder' "
      class="c-item-overlay__item edit-item"
      @click="editItem"
    >
      <i class="fa fa-edit" />
    </div>
  </div>
</template>


<script>
import { createNamespacedHelpers } from 'vuex'
import {MUTATIONS} from "../../../../../store/file-browser";
import {createModal} from "../../modals/action-creater";
import {MODALS, TABS} from "../../constants";
import {FILE_TYPE} from "../../../../../constants/file-image-types";

const { mapMutations } = createNamespacedHelpers('fileBrowser')

export default {
  name: "ItemOverlay",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isAvailableEditAction() {
      if (this.$route.params.tab === TABS.PRODUCTS){
        return this.item && this.item.type === FILE_TYPE.FOLDER;
      }
      return true;
    }
  },
  methods: {
    ...mapMutations({
      'setModal': MUTATIONS.SET_MODAL
    }),
    deleteItem() {
      this.setModal(createModal(MODALS.DELETE_ITEM, this.item))
    },
    editItem() {

      let modal = MODALS.TAGS_EDITOR;

      switch (this.$route.params.tab) {
        case TABS.FILES:
        modal = MODALS.RENAME;
            break;
        case TABS.PRODUCTS:
            if (this.item.type === FILE_TYPE.FOLDER )
              modal = MODALS.RENAME;
        break;
      }

      this.setModal(createModal(
        modal,
        this.item
      ))
    }
  }
}
</script>

<style scoped lang="scss">
  .c-item-overlay {
    padding: 2px;
    display: flex;

    &__item {
      padding-right: 3px;
      cursor: pointer;

      .fa-edit {
        margin-top: 5px;
        color: #747474;
      }
    }
  }

  .c-item-overlay.folder_overlay {
    padding: 2px;
    display: block;
    
    .edit-item {
      padding-left: 2px;
    }

  }
</style>