export const percentageToInteger = (integer) => Math.round(integer) / 100;
export const integerToPercentage = (integer) => Math.round(integer * 100);

export const transformObjectDataToPercent = (objectData) => {
    for (const key in objectData) {
      objectData[key] = integerToPercentage(objectData[key]);
    }

    return objectData;
}

export const transformObjectDataToInteger = (objectData) => {
    for (const key in objectData) {
      objectData[key] = percentageToInteger(objectData[key]);
    }

    return objectData;
}