import Theme from "./Theme";

export default class ThemesContainer {

    themes = [];

    constructor(themes = []) {
        themes = Array.isArray(themes)
          ? themes
          : Object.keys(themes).map(key => themes[key]);
        
        this.themes = themes.map((theme) => {
            return new Theme(theme);
        })
    }

    getThemeById(id) {
        return this.themes.find(theme => theme.id == id);
    }

    getFirstTheme() {
        return this.themes[0];
    }
}