import {fabric} from "fabric";

export const resolveImageInstance = src => new Promise((r) => {
    new fabric.Image.fromURL(src, img => {
        r(img)
    })
})

/**
 * see https://jsfiddle.net/r043v/Z7Zg7/
 */
function angle2rect(angle, sx, sy){
  const halfWidth = sx / 2;
  const halfHeight = sy / 2;
  const angleRad = (angle) * (Math.PI / 180) + Math.atan2(sy, sx);
  const dx = Math.cos(angleRad);
  const dy = Math.sin(angleRad);

  const tx1 = (-halfWidth) / dx;
  const tx2 = (halfWidth) / dx;
  const ty1 = (-halfHeight) / dy;
  const ty2 = (halfHeight) / dy;

  const tMinX = Math.min(tx1, tx2);
  const tMaxX = Math.max(tx1, tx2);
  const tMinY = Math.min(ty1, ty2);
  const tMaxY = Math.max(ty1, ty2);

  const tMin = Math.max(tMinX, tMinY);
  const tMax = Math.min(tMaxX, tMaxY);

  if (tMin <= tMax && tMax > 0) {
    const t = tMin > 0 ? tMin : tMax;
    const x = Math.floor(t * dx);
    const y = Math.floor(t * dy);
    return { x: x + sx / 2, y: y + sy /2 };
  }
}

export const setAngleGradientForRect = (angle, width, height) => {
    var odx = 0,
        ody = 0,
        gradient;
    
    gradient = {
        end: angle2rect(angle, width, height)
    };
    
    gradient.start = {
        x: width  - gradient.end.x,
        y: height - gradient.end.y
    }

    const data = {
        x1: gradient.start.x - odx,
        y1: gradient.start.y - ody,
        x2: gradient.end.x   - odx,
        y2: gradient.end.y   - ody,
    }

    return data;
}
