<template>
  <b-btn
    class="a-button-outline"
    variant="outline-primary"
    v-bind="$attrs"
    :disabled="!!$attrs.loading"
    v-on="$listeners"
  >
    <i
      v-if="!!$attrs.loading"
      class="fa fa-spinner fa-spin"
    />
    <slot />
  </b-btn>
</template>

<script>
export default {
  name: "ButtonOutline"
}
</script>

<style scoped lang="scss">
  .a-button-outline {
    color: #007964;
    border-color: #007964;
    border-radius: 8px;

    &:hover:not(:disabled), &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
      background-color: #007964;
      color: white;
    }

    &:focus:not(:disabled), &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem rgb(0, 121, 100, 0.5);
      border-color: #209c86;
    }
  }
</style>
