var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-top-bar" },
    [
      _c(
        "div",
        {
          staticClass:
            "c-top-bar__search_container d-flex flex-grow-1 align-items-center",
        },
        [
          _c("div", { staticClass: "c-top-bar__search" }, [
            _c(
              "svg",
              {
                staticClass: "c-top-bar__search--icon",
                attrs: { width: "13", height: "13" },
              },
              [
                _c("use", {
                  attrs: { href: "/img/icons/sprite.svg#search-big" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInput,
                  expression: "searchInput",
                },
              ],
              staticClass: "c-top-bar__search--input",
              attrs: { type: "text", placeholder: "Search" },
              domProps: { value: _vm.searchInput },
              on: {
                keyup: _vm.debouncedSearchHandler,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchInput = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ToolBar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }