var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "a-button-outline",
          attrs: { variant: "outline-primary", disabled: !!_vm.$attrs.loading },
        },
        "b-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !!_vm.$attrs.loading
        ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }