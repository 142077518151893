var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    _vm._g(
      _vm._b(
        {
          staticClass: "dropdown-counter",
          scopedSlots: _vm._u(
            [
              {
                key: "button-content",
                fn: function () {
                  return [_vm._t("default")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "b-dropdown",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.title ? _c("label", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
          _vm._v(" "),
          _c(
            "b-input-group",
            [
              _c(
                "b-input-group-prepend",
                [
                  _c("b-btn", { on: { click: _vm.decrease } }, [
                    _c("i", { staticClass: "cil-minus" }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-form-input", {
                attrs: {
                  value: _vm.value,
                  type: "number",
                  step: _vm.step,
                  min: _vm.min,
                  max: _vm.max,
                },
                on: { input: _vm.onInput },
              }),
              _vm._v(" "),
              _c(
                "b-input-group-append",
                [
                  _c("b-btn", { on: { click: _vm.increase } }, [
                    _c("i", { staticClass: "cil-plus" }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }