<template>
  <div
    class="c-item-overlay"
  >
    <div
      class="c-item-overlay__item delete-item"
      @click="decreaseCount"
    >
      <b-icon
        icon="dash-circle-fill"
        font-scale="1.3"
        style="color: #479382; stroke: #fff; stroke-width: 1.5px"
      />
    </div>
    <div
      class="c-item-overlay__item count"
    >
      <span class="count-text">
        {{ count }}
      </span>
    </div>
    <div
      class="c-item-overlay__item add-item"
      @click="addItem"
    >
      <b-icon
        icon="plus-circle-fill"
        font-scale="1.3"
        style="color: #479382; stroke: #fff; stroke-width: 1.5px"
      />
    </div>
  </div>
</template>


<script>
import { createNamespacedHelpers } from 'vuex'

const { mapMutations, mapActions } = createNamespacedHelpers('fileBrowserProductSelector')

export default {
  name: "ItemOverlay",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      count: this.item.count
    };
  },
  watch:{
    item(newVal) {
      if (this.count !== newVal.count) {
        this.count = newVal.count;
      }
    }
  },
  methods: {
    ...mapActions([
      'pickItem',
      'deleteItem'
    ]),
    addItem(e) {
      e.stopPropagation();
      this.pickItem({
        ...this.item,
        count: ++this.count
      });
    },
    decreaseCount(e) {
      e.stopPropagation();
      if (this.count > 0) {
        this.deleteItem({
          ...this.item,
          count: --this.count
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .c-item-overlay {
    padding: 2px;
    display: flex;
    align-items: end;

    &__item {
      padding-right: 3px;
      cursor: pointer;

      .fa-edit {
        margin-top: 5px;
        color: #747474;
      }
    }
  }

  .c-item-overlay.folder_overlay {
    padding: 2px;
    display: block;
  }

  .c-item-overlay__item.count {
    font-weight: 900;
    font-size: 18px;
    padding: 5px;
    background: #479382;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 27px;
    border: 1px solid white;
    
    & .count-text {
      text-shadow: 1px 1px 1px #9a9a9a;
    }
  }
</style>
