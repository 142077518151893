var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "folder-list-container" },
    [
      _c("BreadcrumbItem", {
        attrs: { breadcrumbs: _vm.breadcrumbs },
        on: { goTo: _vm.goToFolder },
      }),
      _vm._v(" "),
      _vm.items.length || _vm.isListLoading
        ? _c(
            "div",
            { staticClass: "folders-grid" },
            [
              !_vm.isListLoading
                ? _vm._l(_vm.items, function (item) {
                    return _c("GridItem", {
                      key: item.id,
                      attrs: {
                        item: item,
                        "is-selected": _vm.isSelected(item),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSelectedItem(item)
                        },
                        dblclick: _vm.dblHandler,
                      },
                    })
                  })
                : _vm._l(_vm.pageSize, function (n) {
                    return _c(
                      "div",
                      {
                        key: n,
                        staticClass: "w-100 h-100",
                        staticStyle: { "min-height": "167px" },
                      },
                      [
                        _c("b-skeleton", {
                          attrs: { width: "100%", height: "88%" },
                        }),
                        _vm._v(" "),
                        _c("b-skeleton"),
                      ],
                      1
                    )
                  }),
            ],
            2
          )
        : _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v("\n    Folder is empty\n  "),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center" },
        [
          _c("b-pagination", {
            attrs: {
              "per-page": _vm.pageSize,
              "total-rows": _vm.totalCount,
              disabled: _vm.isListLoading,
            },
            on: { change: _vm.onChangePage },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }