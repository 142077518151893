import templateAPI from '../../services/api/template'

export default {
  namespaced: true,

  state: {
    templates: [],
  },

  getters: {
    templates: state => state.templates,
  },

  mutations: {
    SET_TEMPLATES (state, templates) {
      state.templates = templates
    },

    DELETE_TEMPLATE (state, payload) {
      const index = _.findIndex(state.templates, ['id', payload.id])
      if (index !== -1) {
        state.templates.splice(index, 1)
      }
    },

    UPDATE_TEMPLATE_TAGS (state, { id, tag }) {
      const template = state.templates.find(template => template.id === id)
      if (!template) {
        return
      }
      template.tags.push(tag)
    },

    REMOVE_TEMPLATE_TAG (state, { id, tag }) {
      const template = state.templates.find(template => template.id === id)
      if (!template) {
        return
      }
      const index = _.findIndex(template.tags, ['id', tag.id])
      if (index !== -1) {
        template.tags.splice(index, 1)
      }
    },
  },

  actions: {
    async getTemplates ({ commit }, payload) {
      const response = await templateAPI.getTemplates(payload)
      commit('SET_TEMPLATES', response.data.templates)
    },

    async deleteTemplate ({ commit }, payload) {
      await templateAPI.deleteTemplate(payload.id)
      commit('DELETE_TEMPLATE', payload)
    },

    async attachTagToTemplate ({ commit }, { id, tag }) {
      const { data } = await templateAPI.attachTagToTemplate(id, tag)
      commit('UPDATE_TEMPLATE_TAGS', { id, tag: data })
    },

    async detachTagFromTemplate ({ commit }, { id, tag }) {
      const { data } = await templateAPI.detachTagFromTemplate(id, tag)
      commit('REMOVE_TEMPLATE_TAG', { id, tag: data })
    },

    async updateThumbnails({ commit }, { id, thumbnails }) {
      await templateAPI.updateThumbnails(id, thumbnails)
    },
  },
}
