<template>
  <div class="editable-text-element">
    <div
      v-if="!isEditable"
      class="editable-text-element__text-display"
      @click="startEditing"
    >
      <span>{{ renderValue ? renderValue(text) : text }}</span>
    </div>
    <div
      v-else
      class="editable-text-element__edit-mode"
    >
      <div class="editable-text-element__input-container">
        <input
          ref="editInput"
          v-model="editedText"
          type="text"
          class="editable-text-element__input"
          @keydown.enter="saveEdit"
          @keydown.esc="resetEdit"
          @blur="saveEdit"
        >
      </div>
    </div>
    <div class="editable-text-element__error-container">
      <span
        v-for="error in errors"
        :key="error.id"
        class="editable-text-element__error"
      >
        {{ error.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EditableTextElement',
  props: {
    defaultValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    renderValue: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    text: '',
    editedText: '',
    isEditing: false,
    errors: [],
  }),
  computed: {
    isEditable() {
      return this.isEditing && !this.disabled;
    }
  },
  watch: {
    defaultValue: {
      handler() {
        this.text = this.defaultValue;
      },
      immediate: true,
    },
    editedText: {
      handler() {
        this.checkErrors();
      }
    }
  },
  methods: {
    startEditing() {
      this.isEditing = true;
      this.editedText = this.text;
      this.$nextTick(() => this.$refs.editInput.focus());
    },
    saveEdit() {
      if (this.errors.length > 0) {
        return;
      }

      if (this.text !== this.editedText) {
        this.$emit('change', this.editedText);
      }

      this.isEditing = false;
      this.text = this.editedText;
    },
    resetEdit(event) {
      if (event) {
        event.stopPropagation();
      }

      this.editedText = this.text;
      this.isEditing = false;
    },
    checkErrors() {
      const newErrors = [];

      if (!this.editedText || this.editedText.length === 0) {
        newErrors.push({ id: uuidv4(), title: 'Required' });
      }

      this.errors = newErrors;
    },
  },
};
</script>

<style scoped>
.editable-text-element__text-display {
  cursor: pointer;
  font-size: 18px;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.editable-text-element__text-display:hover {
  background-color: #e2e2e2;
}

.editable-text-element__edit-mode {
  display: flex;
  align-items: center;
}

.editable-text-element__input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.editable-text-element__input {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 100%;
  transition: border-color 0.3s;
}

.editable-text-element__input:focus {
  border-color: #209c86;
}

.editable-text-element__error-container {
  color: red;
}

.editable-text-element__error {
  display: block;
  font-size: 12px;
  margin-top: 4px;
}
</style>
