export default function fileLayoutSvg() {
  return `
  <?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#727272" stroke="none">
<path d="M652 4790 c-163 -24 -296 -161 -323 -331 -6 -40 -9 -378 -7 -904 l3
-840 23 -55 c33 -81 85 -146 152 -189 105 -68 78 -66 870 -66 l715 0 55 23
c117 48 208 146 241 259 21 71 21 1755 0 1826 -33 113 -124 211 -241 259 l-55
23 -690 1 c-379 1 -714 -2 -743 -6z m1408 -330 c20 -20 20 -33 20 -860 0 -827
0 -840 -20 -860 -20 -20 -33 -20 -700 -20 -667 0 -680 0 -700 20 -20 20 -20
33 -20 860 0 827 0 840 20 860 20 20 33 20 700 20 667 0 680 0 700 -20z"/>
<path d="M3052 4790 c-163 -25 -296 -161 -323 -333 -6 -40 -9 -262 -7 -584 3
-510 4 -519 26 -573 47 -117 146 -208 259 -241 71 -21 1435 -21 1506 0 113 33
211 124 259 241 23 55 23 56 23 620 0 551 -1 566 -21 616 -47 114 -123 190
-234 236 l-55 23 -690 1 c-379 1 -714 -2 -743 -6z m1408 -330 c19 -19 20 -33
20 -540 0 -507 -1 -521 -20 -540 -20 -20 -33 -20 -700 -20 -667 0 -680 0 -700
20 -19 19 -20 33 -20 540 0 507 1 521 20 540 20 20 33 20 700 20 667 0 680 0
700 -20z"/>
<path d="M3052 2710 c-163 -24 -296 -161 -323 -331 -6 -40 -9 -378 -7 -904 l3
-840 23 -55 c33 -81 85 -146 152 -189 105 -68 78 -66 870 -66 l715 0 55 23
c117 48 208 146 241 259 21 71 21 1755 0 1826 -33 113 -124 211 -241 259 l-55
23 -690 1 c-379 1 -714 -2 -743 -6z m1408 -330 c20 -20 20 -33 20 -860 0 -827
0 -840 -20 -860 -20 -20 -33 -20 -700 -20 -667 0 -680 0 -700 20 -20 20 -20
33 -20 860 0 827 0 840 20 860 20 20 33 20 700 20 667 0 680 0 700 -20z"/>
<path d="M652 2070 c-163 -25 -296 -161 -323 -333 -6 -40 -9 -262 -7 -584 3
-510 4 -519 26 -573 47 -117 146 -208 259 -241 71 -21 1435 -21 1506 0 113 33
211 124 259 241 23 55 23 56 23 620 0 551 -1 566 -21 616 -47 114 -123 190
-234 236 l-55 23 -690 1 c-379 1 -714 -2 -743 -6z m1408 -330 c19 -19 20 -33
20 -540 0 -507 -1 -521 -20 -540 -20 -20 -33 -20 -700 -20 -667 0 -680 0 -700
20 -19 19 -20 33 -20 540 0 507 1 521 20 540 20 20 33 20 700 20 667 0 680 0
700 -20z"/>
</g>
</svg>
`;
};
