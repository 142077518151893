var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mb-2" }, [
        _vm._v("\n    Product dimensions:\n  "),
      ]),
      _vm._v(" "),
      _c("PositionAndSize", {
        attrs: { h: _vm.height, w: _vm.width, x: _vm.x, y: _vm.y },
        on: { input: _vm.onChange },
      }),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          attrs: { disabled: _vm.isButtonDisabled, variant: "primary" },
          on: { click: _vm.onSave },
        },
        [_vm._v("\n    Save\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        { attrs: { variant: "outline-primary" }, on: { click: _vm.onClose } },
        [_vm._v("\n    Cancel\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }