export default function fileLoadingSvg () {
  return `
  <svg
      id="file-loading-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <g fill="rgb(0,0,0)">
        <path d="m256 109.274c-10.009 0-18.123-8.113-18.123-18.123v-73.028c0-10.01 8.114-18.123 18.123-18.123s18.123 8.113 18.123 18.123v73.029c0 10.009-8.114 18.122-18.123 18.122z"/>
        <path d="m256 512c-10.009 0-18.123-8.113-18.123-18.123v-73.029c0-10.01 8.114-18.123 18.123-18.123s18.123 8.113 18.123 18.123v73.029c0 10.01-8.114 18.123-18.123 18.123z" opacity=".52"/>
        <path d="m345.901 494.362c-7.146 0-13.918-4.255-16.785-11.277l-27.597-67.613c-3.783-9.267.664-19.846 9.93-23.627 9.26-3.787 19.844.662 23.627 9.928l27.597 67.613c3.783 9.267-.664 19.846-9.93 23.627-2.242.917-4.561 1.349-6.842 1.349z" opacity=".58"/>
        <path d="m318.291 121.503c-2.281 0-4.6-.432-6.842-1.349-9.267-3.78-13.713-14.36-9.93-23.627l27.597-67.613c3.782-9.267 14.36-13.726 23.627-9.929 9.267 3.78 13.713 14.36 9.93 23.627l-27.597 67.613c-2.867 7.024-9.639 11.278-16.785 11.278z" opacity=".94"/>
        <path d="m166.098 494.362c-2.281 0-4.6-.432-6.842-1.349-9.267-3.78-13.713-14.36-9.93-23.627l27.597-67.613c3.783-9.267 14.36-13.716 23.627-9.928 9.267 3.78 13.713 14.36 9.93 23.627l-27.597 67.613c-2.867 7.023-9.639 11.277-16.785 11.277z" opacity=".46"/>
        <path d="m35.769 364.021c-7.146 0-13.918-4.251-16.785-11.277-3.783-9.267.663-19.846 9.929-23.627l67.612-27.598c9.266-3.794 19.845.658 23.628 9.928 3.783 9.267-.663 19.846-9.929 23.627l-67.612 27.598c-2.242.917-4.562 1.349-6.843 1.349z" opacity=".36"/>
        <path d="m408.63 211.83c-7.146 0-13.918-4.251-16.785-11.277-3.783-9.267.663-19.846 9.929-23.627l67.612-27.598c9.263-3.791 19.846.658 23.628 9.929 3.783 9.267-.663 19.846-9.929 23.627l-67.612 27.598c-2.242.916-4.562 1.348-6.843 1.348z" opacity=".82"/>
        <path d="m103.369 211.83c-2.281 0-4.6-.432-6.843-1.349l-67.612-27.598c-9.267-3.78-13.712-14.36-9.929-23.627 3.782-9.267 14.358-13.712 23.628-9.929l67.612 27.598c9.267 3.78 13.712 14.36 9.929 23.627-2.867 7.023-9.64 11.278-16.785 11.278z" opacity=".24"/>
        <path d="m476.23 364.021c-2.281 0-4.6-.432-6.843-1.349l-67.612-27.598c-9.267-3.78-13.712-14.36-9.929-23.627 3.783-9.26 14.359-13.709 23.628-9.928l67.612 27.598c9.267 3.78 13.712 14.36 9.929 23.627-2.867 7.022-9.64 11.277-16.785 11.277z" opacity=".7"/>
        <path d="m372.565 157.557c-4.638 0-9.275-1.77-12.814-5.309-7.077-7.076-7.078-18.551-.001-25.627l51.638-51.639c7.077-7.079 18.552-7.079 25.629 0 7.077 7.076 7.078 18.551.001 25.627l-51.638 51.639c-3.538 3.539-8.177 5.309-12.815 5.309z" opacity=".88"/>
        <path d="m87.796 442.327c-4.638 0-9.275-1.77-12.814-5.309-7.077-7.076-7.078-18.551-.001-25.627l51.638-51.639c7.077-7.079 18.552-7.079 25.629 0 7.077 7.076 7.078 18.551.001 25.627l-51.638 51.639c-3.539 3.539-8.178 5.309-12.815 5.309z" opacity=".4"/>
        <path d="m139.434 157.557c-4.639 0-9.275-1.77-12.815-5.309l-51.638-51.639c-7.077-7.076-7.077-18.551.001-25.627 7.076-7.076 18.551-7.076 25.629 0l51.638 51.639c7.077 7.076 7.077 18.551-.001 25.627-3.538 3.539-8.177 5.309-12.814 5.309z" opacity=".18"/>
        <path d="m424.203 442.327c-4.639 0-9.275-1.77-12.815-5.309l-51.638-51.639c-7.077-7.076-7.076-18.551.001-25.627s18.552-7.076 25.629 0l51.638 51.639c7.077 7.076 7.077 18.551-.001 25.627-3.537 3.539-8.176 5.309-12.814 5.309z" opacity=".64"/>
        <path d="m91.15 274.123h-73.026c-10.009 0-18.123-8.113-18.123-18.123s8.114-18.123 18.123-18.123h73.026c10.009 0 18.123 8.113 18.123 18.123s-8.114 18.123-18.123 18.123z" opacity=".3"/>
        <path d="m493.876 274.123h-73.028c-10.009 0-18.123-8.113-18.123-18.123s8.114-18.123 18.123-18.123h73.028c10.009 0 18.123 8.113 18.123 18.123s-8.113 18.123-18.123 18.123z" opacity=".76"/>
      </g>
  </svg>
`
};
