import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";
import {FILE_TYPE} from "./constants/file-image-types";
import fileDocSvg from "./templates/file_image/file-doc-svg";
import filePdfSvg from "./templates/file_image/file-pdf-svg";
import filePsdSvg from "./templates/file_image/file-psd-svg";
import fileXlsSvg from "./templates/file_image/file-xls-svg";
import fileZipSvg from "./templates/file_image/file-zip-svg";
import fileTiffSvg from "./templates/file_image/file-tiff";
import fileSvg from "./templates/file_image/file-svg";
import fileMp4Svg from "./templates/file_image/file-mp4-svg";
import fileMp3Svg from "./templates/file_image/file-mp3-svg";
import fileAviSvg from "./templates/file_image/file-avi-svg";
import fileMovSvg from "./templates/file_image/file-mov-svg";
import filePptSvg from "./templates/file_image/file-ppt-svg";
import filePptxSvg from "./templates/file_image/file-pptx-svg";
import fileLoadingSvg from "./templates/file_image/file-loading-svg";
import fileAnySvg from "./templates/file_image/file-any-svg";
import fileGifSvg from "./templates/file_image/file-gif-svg";
import fileWebmSvg from "./templates/file_image/file-webm-svg";
import fileLink from "./templates/file_image/file-link";
import folderSvg from "./templates/file_image/folder-svg";
import folderUpSvg from "./templates/file_image/folder-up-svg";
import fileAiSvg from './templates/file_image/file-ai-svg'
import fileEpsSvg from './templates/file_image/file-eps-svg'
import fileLayoutSvg from "@frontend/templates/file_image/file-layout";

const eyeDroppers = []

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const deepCopy = (a) => {
  if (!a) return a;
  return JSON.parse(JSON.stringify(a));
};

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidHexColor (color) {
  return /^#[0-9a-fA-F]{6}$/.test(color)
}

export function drawFileSvg(element) {
  switch (element.type) {
    case FILE_TYPE.FOLDER:
      if (element.name !== undefined && element.name == '...'){
        return folderUpSvg();
      }else{
        return folderSvg();
      }
    case FILE_TYPE.DOC:
      return fileDocSvg();
    case FILE_TYPE.PDF:
      if (!element.thumbnail_path) {
        return filePdfSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.XLS:
      return fileXlsSvg();
    case FILE_TYPE.ZIP:
      return fileZipSvg();
    case FILE_TYPE.PSD:
      if (!element.thumbnail_path) {
        return filePsdSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.SVG:
      if (!element.thumbnail_path) {
        return fileSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.TIFF:
      if (!element.thumbnail_path) {
        return fileTiffSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.MP4:
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileVideoPreview(element);
    case FILE_TYPE.MP3:
      return fileMp3Svg()
    case FILE_TYPE.AVI:
      return fileAviSvg()
    case FILE_TYPE.MOV:
      if (!element.thumbnail_path) {
        return fileMovSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.PPT:
      return filePptSvg();
    case FILE_TYPE.PPTX:
      return filePptxSvg();
    case FILE_TYPE.GIF:
      return fileGifSvg()
    case FILE_TYPE.WEBM:
      return fileWebmSvg()
    case FILE_TYPE.IMAGE:
      if (element.image_type === FILE_TYPE.GIF) {
        return fileGifSvg();
      }
      if (element.is_processing) {
        return fileLoadingSvg();
      }
      return drawFileImageThumbnail(element);
    case FILE_TYPE.LINK:
      return fileLink();
    case FILE_TYPE.AI:
      return fileAiSvg()
    case FILE_TYPE.EPS:
      return fileEpsSvg()
    case FILE_TYPE.LAYOUT:
      return fileLayoutSvg()
    default:
      return fileAnySvg();
  }
}

function drawFileVideoPreview(element) {
    let return_draw = '';
    if (element.thumbnail_path)
      return_draw = `<img alt="image" src="${(element.thumbnail_path ? element.thumbnail_path : element.path) + '&is_thumbnail=1'}" loading="lazy"/>`;
    else
      return_draw = fileMp4Svg();

      return_draw +=  `<div class="video-container" data-video-src="${element.path}"></div>`;
    return return_draw
  ;
}


function drawFileImageThumbnail(element) {
  return "<img alt='image' src='" + (element.thumbnail_path || element.url || element.path) + "&is_thumbnail=1' loading='lazy'/>";
}

export function initColorPicker() {
  $(`.color-picker-container .pickr`).each((index, el) => {
    const $colorInput = $(el).siblings(".color-picker-input").first();
    const elementId = $colorInput.attr("id");
    const pickr = new Pickr({
      el,
      container: "body",
      theme: "nano",
      swatches: [],
      components: {
        preview: true,
        hue: true,
        opacity: true,
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          input: true,
        },
      },
      default: $colorInput.val(),
    }).on("changestop", (source, instance) => {
      instance.applyColor();
      let color = pickr.getColor();
      const hexColor = color.toHEXA().toString();
      let inputId = '';
      if (elementId.includes('_fill')) {
        inputId = elementId.replace('_fill_color', '_fill_color_hex');
      }else {
        inputId = elementId.replace('_color', '_color_hex');
        $(`div.dropdown #dropdownMenuButton_${inputId.replace('_color_hex' ,'')} .glyphicon-text-color`).css({
          color: hexColor
        })
        $(`#${inputId}`).trigger('change');
      }
      document.getElementById(inputId).value = hexColor;
      $colorInput.val(hexColor).trigger('change');

    });

    $colorInput.on("change", async function (event) {
      try {
        const currentValue = pickr.getColor().toRGBA.toString();
        const body = { color: currentValue };

        await axios.put("/user/color", body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (e) {
        return false;
      }
    });

    const root = pickr.getRoot()
    root.interaction.options.forEach(option => {
      option.defaultValue = option.defaultValue.substring(0, 3)
    })

    eyeDroppers.push($(
      '<a href="#"><i class="c-icon cil-eyedropper"></i></a>',
    ).on('click', function (e) {
      e.preventDefault()
      new EyeDropper()
        .open()
        .then(result => pickr.setColor(result.sRGBHex))

      $(this).blur();
    }));

    $(document).on('keyup', function (e) {
      if (e.keyCode === 13) {
        pickr.hide();
      }
    })
  });

  $('.pcr-interaction').each((index, el) => {
    $(el).append(eyeDroppers[index])
  })
}

export function getFileNameFromURL(url) {
  let full_url = url;
  const url_ary = url.split('?');
  if (url_ary && url_ary.length > 1) {
    full_url = url_ary[0];
  }
  const full_url_ary = full_url.split('/');
  let file_name = '';
  let file_extension = '';
  if (full_url_ary && full_url_ary.length > 0) {
    file_name = full_url_ary[full_url_ary.length - 1];
  }
  if (file_name && file_name.indexOf('.') > -1) {
    const file_name_ary = file_name.split('.');
    file_extension = file_name_ary[file_name_ary.length - 1];
    file_name = file_name.replace('.' + file_extension, '');
  }
  if (!file_name && full_url_ary.length >= 3) {
    file_name = full_url_ary[2];
  }
  const fileName = file_name ? file_name : new Date().getTime();
  return fileName;
}

export function fetchFileData(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        let full_url = url;
        const url_ary = url.split('?');
        if (url_ary && url_ary.length > 1) {
          full_url = url_ary[0];
        }
        const full_url_ary = full_url.split('/');
        let file_name = '';
        let file_extension = '';
        if (full_url_ary && full_url_ary.length > 0) {
          file_name = full_url_ary[full_url_ary.length - 1];
        }
        if (file_name && file_name.indexOf('.') > -1) {
          const file_name_ary = file_name.split('.');
          file_extension = file_name_ary[file_name_ary.length - 1];
          file_name = file_name.replace('.' + file_extension, '');
        }
        if (!file_extension) {
          file_extension = blob.type.split('/')[1]
        }
        const fileName = (file_name ? file_name : new Date().getTime()) + '.' + file_extension;
        const file = new File([blob], fileName, { type: blob.type, path: url });
        resolve(file);
      })
      .catch(err => {
        toastr.error('There are some errors to get file content. Try again with proper file url.');
        reject(err);
      })
  })
}

export function copyTextToClipboard(text) {
  var textArea = document.createElement( "textarea" );
  textArea.value = text;
  document.body.appendChild( textArea );
  textArea.select();

  try {
    var successful = document.execCommand( 'copy' );
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy',err);
  }
  document.body.removeChild( textArea );
}
export function getOrderForAddField(fields) {
  let order = 2000;
  for (let field of fields) {

    if (typeof field.order !== 'number') {
      continue
    }
    if (field.order < order) {
      order = field.order;
    }
  }
  return order - 1;
}
export function getArrayByFileIds(fileIds) {
  let filedIdsArray = [];
  
  if (fileIds.includes(' ')) {
    filedIdsArray = fileIds.split(' ');
  }
  if (fileIds.includes(',')) {
    filedIdsArray = fileIds.split(',')
  }
  if (!fileIds.includes(' ') && !fileIds.includes(',')) {
    filedIdsArray = [fileIds];
  }
  return filedIdsArray
}
export function getFileIdByProductObjectId(fileIds, objectId) {
  const fileIndex = objectId.split('_').reverse()[0];
  let filedIdsArray = getArrayByFileIds(fileIds);
  return filedIdsArray.at(fileIndex);
}

export function isURL(str) {
  // Check if the string is a URL (absolute or relative)
  const pattern = new RegExp('^(https?:\\/\\/)?(\\/[^\\s]*)?$');
  return !!pattern.test(str);
}

export function setURLParameters(url, params) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);

  // Add or update parameters
  for (const [key, value] of Object.entries(params)) {
    urlObj.searchParams.set(key, value);
  }

  // Return the updated URL
  return isAbsoluteURL ? urlObj.href : urlObj.pathname + urlObj.search;
}

export function deleteURLParameters(url, params) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);

  // Delete specified parameters
  for (const param of params) {
    urlObj.searchParams.delete(param);
  }

  // Return the updated URL
  return isAbsoluteURL ? urlObj.href : urlObj.pathname + urlObj.search;
}

export function getURLParametersByKey(url, paramKey) {
  // Check if the URL is absolute or relative
  const isAbsoluteURL = url.startsWith('http://') || url.startsWith('https://');
  const baseURL = isAbsoluteURL ? url : window.location.origin + url;

  // Create a URL object to work with parameters
  const urlObj = new URL(baseURL);
 
  return urlObj.searchParams.get(paramKey);
}
