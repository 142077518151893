import {Layer} from "@/includes/parse_psd_data/psd-types/layer";

export default class Image extends Layer {

    src;

    isFlipV = false

    isFlipH = false

    constructor(data) {
        super(data);

        this.src = data.publicPath;
    }
}