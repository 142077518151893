var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: _vm.MODAL_ID,
        title: "Mask Settings",
        size: "lg",
        "hide-backdrop": "",
        "ok-title": "Save",
        centered: "",
        "ignore-enforce-focus-selector": ".swal2-container",
      },
      on: { ok: _vm.onSave },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "d-flex justify-content-center" }, [
            _c("canvas", {
              ref: "maskPreview",
              attrs: { width: "480", height: "270" },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-form-row",
                [
                  _c("label", [_vm._v("Type Mask")]),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      attrs: { value: _vm.typeMask },
                      on: { change: _vm.onChangeTypeMask },
                    },
                    _vm._l(_vm.typeMaskOptions, function (option) {
                      return _c(
                        "b-form-select-option",
                        { key: option.value, attrs: { value: option.value } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(option.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "w-100",
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [_vm._v("\n          Shadow Presets\n        ")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "presets-select-wrapper" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          options: _vm.shadowPresets,
                          label: "name",
                          "track-by": "id",
                          "allow-empty": false,
                          "deselect-label": "Selected",
                        },
                        model: {
                          value: _vm.selectedPreset,
                          callback: function ($$v) {
                            _vm.selectedPreset = $$v
                          },
                          expression: "selectedPreset",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "d-inline-block",
                          attrs: { tabindex: "0", title: "Apply" },
                        },
                        [
                          _vm.selectedPreset.id
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "button",
                                    variant: "primary",
                                    disabled: _vm.isApplyBtnDisabled,
                                  },
                                  on: { click: _vm.applyPreset },
                                },
                                [_c("b-icon", { attrs: { icon: "check-lg" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true },
                            },
                          ],
                          attrs: {
                            title: _vm.selectedPreset.id ? `Edit` : "Save",
                            type: "button",
                            variant: "outline-primary",
                          },
                          on: { click: _vm.saveShadowPreset },
                        },
                        [
                          !_vm.selectedPreset.id
                            ? _c("b-icon", { attrs: { icon: "save-fill" } })
                            : _c("b-icon", { attrs: { icon: "pencil-fill" } }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.selectedPreset.id
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                title: "Delete",
                                type: "button",
                                variant: "outline-danger",
                              },
                              on: { click: _vm.deletePreset },
                            },
                            [_c("b-icon", { attrs: { icon: "bucket-fill" } })],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.typeMask !== _vm.TYPE_MASK.NONE
        ? _c(_vm.getInputsView, {
            tag: "component",
            attrs: { "object-data": _vm.objectData },
            on: { input: _vm.onChangeObjectData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }