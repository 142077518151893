<template>
  <div>
    <b-modal
      id="simple-table-builder"
      title="Table Builder"
      centered
      size="xl"
      ok-title="Save"
      @ok="onSave"
    >
      <div
        v-if="table && table.rows.length"
        class="mb-3"
      >
        <b-button-group>
          <b-button @click="addRow">
            Add Row
          </b-button>
          <b-button @click="addColumn">
            Add Column
          </b-button>
          <b-button @click="removeRow">
            Remove Row
          </b-button>
          <b-button @click="removeColumn">
            Remove Column
          </b-button>
          <DropdownCounter
            :value="getStyleValue('font-size', 0)"
            :min="1"
            :step="1"
            @input="(value) => onChangeStyleOfTable('font-size', value, 'px')"
          >
            <i class="cil-text-size" />
          </DropdownCounter>
          <DropdownCounter
            :value="getStyleValue('border-width', 0)"
            :min="0"
            :step="1"
            @input="(value) => onChangeStyleOfTable('border-width', value, 'px', true)"
          >
            <i class="cil-border-style" />
          </DropdownCounter>
          <b-button>
            <ColorPicker
              :value="getStyleValue('color', '#000000')"
              @input="(value) => onChangeStyleOfTable('color', value)"
            />
          </b-button>
        </b-button-group>
        
        <b-form-row
          v-if="isSelectedCell"
        >
          <b-col cols="3">
            <div>
              <label
                class="mb-0 mt-1"
                for="cell-value"
              >
                Value
              </label>
              <b-input
                id="cell-value"
                :value="selectedCell.value"
                type="text"
                class="form-control"
                @input="(value) => selectedCell.value = value"
              />
            </div>
          </b-col>

          <b-col cols="1">
            <div>
              <label
                class="mb-0 mt-1"
                for="cell-value"
              >
                Colspan
              </label>
              <b-input
                id="cell-value"
                :value="selectedCell.colspan"
                type="number"
                class="form-control"
                @input="(value) => selectedCell.colspan = value"
              />
            </div>
          </b-col>
          <b-col cols="0">
            <b-button
              class="btn btn-secondary field-btn"
              @click="onCancel"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col cols="0">
            <b-button
              class="btn btn-success field-btn"
              @click="onApply"
            >
              Apply
            </b-button>
          </b-col>
        </b-form-row>
      </div>

      <div
        v-if="table && table.rows.length"
        ref="tableContainer"
        class="table-builder-container"
      >
        <table
          ref="table"
          :style="getStyleOfTable"
        >
          <thead>
            <tr>
              <th
                v-for="column in table.headers"
                :key="column.id"
                :class="{'selected-cell': selectedCellId === column.id}"
                :style="column.stringStyles"
                :colspan="column.colspan"
                @click="onSelectCell(column)"
              >
                {{ column.value }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in table.rows"
              :key="row.id"
              :style="row.stringStyles"
            >
              <td
                v-for="cell in row.cells"
                :key="cell.id"
                :class="{'selected-cell': selectedCellId === cell.id}"
                :style="cell.stringStyles"
                :colspan="cell.colspan"
                @click="onSelectCell(cell)"
              >
                {{ cell.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="d-flex justify-content-center"
      >
        <b-dropdown text="Create Table">
          <div class="p-2">
            <div class="d-flex">
              <b-form-group>
                <label>Columns</label>
                <b-form-input
                  v-model.number="createTable.columns"
                  type="number"
                  size="sm"
                />
              </b-form-group>
              <div class="mx-2" />
              <b-form-group>
                <label>Rows</label>
                <b-form-input
                  v-model.number="createTable.rows"
                  type="number"
                  size="sm"
                />
              </b-form-group>
            </div>

            <b-button
              variant="primary"
              size="sm"
              class="w-100"
              @click="onCreateTable"
            >
              Create
            </b-button>
          </div>
        </b-dropdown>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { simpleTableBuilderEventBroker, EVENTS } from '@frontend/group/modules/simple-table-builder/event-broker';
import DropdownCounter from '../../../common/atoms/DropdownCounter.vue';
import ColorPicker from '../EditorControl/components/ColorPicker.vue';

let tableInstance;

export default {
  name: 'SimpleTableBuilderModal',
  components: {
    DropdownCounter,
    ColorPicker
  },
  data() {
    return {
      $element: null,
      table: null,
      params: null,
      tableData: null,
      createTable: {
        columns: 10,
        rows: 10
      },
      selectedCell: {
        id: null,
        value: 'value',
        colspan: 1,
      },
      selectedCellId: null,
      isSelectedCell: false,
    };
  },
  computed: {
    getStyleOfTable() {
      if (!this.table) return {};

      const rules = this.table.styles.rules;

      return {
        ...rules,
      };
    }
  },
  mounted() {
    simpleTableBuilderEventBroker.on(EVENTS.INIT, this.init.bind(this));
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    init({ $element, table, styles, params, tableData }) {
      this.clearData();

      this.$element = $element;
      this.params = params;
      this.tableData = tableData;
      tableInstance = table;
      this.table = tableInstance.toData();

      this.$bvModal.show('simple-table-builder');
    },
    clearData() {
      this.$element = null;
      this.table = null;
      this.params = null;
      this.createTable = {
        columns: 10,
        rows: 10
      };
      this.selectedCellId = null;
    },
    onCreateTable() {
      const columns = this.createTable.columns;
      const rows = this.createTable.rows;

      tableInstance.createTableGrid(columns, rows);

      this.table = tableInstance.toData();
    },
    addRow() {
      tableInstance.addNewRow();

      this.table = tableInstance.toData();
    },
    addColumn() {
      tableInstance.addNewColumn();

      this.table = tableInstance.toData();
    },
    removeRow() {
      tableInstance.removeNewRow();
      this.table = tableInstance.toData();
    },
    removeColumn() {
      tableInstance.removeNewColumn();
      this.table = tableInstance.toData();
    },
    onSelectCell({ id, value, colspan }) {
      this.isSelectedCell = true;
      this.selectedCell = { id, value, colspan };
      this.selectedCellId = id;
    },
    onApply() {
      tableInstance.changeCellValue(this.selectedCell.id, this.selectedCell);
      this.table = tableInstance.toData();
    },
    onKeyDown(e) {
      switch (e.keyCode) {
        // Enter key
        case 13:
        case 36:
        case 76:
          this.onApply();
          break;
      }
    },
    onCancel() {
      this.isSelectedCell = false;
      this.selectedCellId = null;
    },
    getStyleValue(key, defaultValue) {
      const value = this.table.styles.rules[key];
      const number = parseInt(value);

      return value === undefined ? defaultValue : Number.isNaN(number) ? value : number;
    },
    onChangeStyleOfTable(key, value, measure = '', hasChangeForCells = false) {
      tableInstance.styles.setRules({
        [key]: `${value}${measure}`,
      });

      if (hasChangeForCells) {
        tableInstance.setCellsRules({
          [key]: `${value}${measure}`,
        });
      }

      this.table = tableInstance.toData();
    },
    onEnderValue(value, cellId) {
      tableInstance.changeCellValue(cellId, value);
    },
    onSave() {
      simpleTableBuilderEventBroker.fire(EVENTS.SAVE, {
        $element: this.$element,
        tableInstance,
        styles: this.styles,
        params: this.params,
        tableData: this.tableData
      });
    },
  }
};
</script>

<style lang="scss">

.table-builder-container {
  overflow: auto;
  min-height: 500px;
  max-height: 500px;

  .content-editor {
    cursor: text;
    width: 100%;
    height: 100%;
  }
}

.selected-cell {
  background-color: rgba(40, 167, 69, 0.10) !important;
}

.field-btn {
  margin-top: 27px;
}

</style>