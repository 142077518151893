<template>
  <b-dropdown
    :text="text"
    variant="primary"
    class="w-100 select-name-dropdown"
    :disabled="disabled || !getNamingValuesOfTab.length && !searchNamingValueOfTab"
  >
    <b-dropdown-header id="dropdown-header-label">
      <b-form-input v-model="searchNamingValueOfTab" />
    </b-dropdown-header>

    <template v-if="getNamingValuesOfTab.length">
      <b-dropdown-item-button
        v-for="name in getNamingValuesOfTab"
        :key="name.id"
        class="text-truncate"
        @click="$emit('onSelectName', name)"
      >
        {{ name.name + (name.name !== name._value ? ` - (${name._value})` : '') }}
      </b-dropdown-item-button>
    </template>

    <b-dropdown-item-button
      v-else
      disabled
    >
      List is empty
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import { getVariableValue } from '../../../../group/modules/naming-table-variables/helpers';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    namingDesignTab: {
      type: Object,
      default: null,
    },
    namingValuesOfTab: {
      type: Array,
      default: () => [],
    },
    hasChooseVariables: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      searchNamingValueOfTab: ''
    }
  },
  computed: {
    getNamingValuesOfTab() {
      const variables = [];
      const filteredName = this.namingValuesOfTab.filter((name) => {
        return !this.searchNamingValueOfTab ||
          name
              .name
              .toLowerCase()
              .indexOf(this.searchNamingValueOfTab.toLowerCase()) !== -1 
      })

      if (this.hasChooseVariables && this.namingDesignTab) {
        variables.push({
          id: +new Date,
          name: 'Default value',
          hasVariable: true,
          _value: getVariableValue(this.namingDesignTab.name)
        });
      }

      return [
        ...variables,
        ...filteredName
      ]; 
    },
  },
  watch: {
    namingDesignTab() {
      this.searchNamingValueOfTab = '';
    }
  }
}
</script>

<style lang="scss">
.select-name-dropdown {
  .dropdown-menu {
    max-height: 400px;
    max-width: 500px;
    overflow-y: auto;
  }
}
</style>