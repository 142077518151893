import { match } from './match';
import { FIELD_TYPE_PRODUCT_IMAGE } from '@frontend/constants/type-fields-of-template';
import { PRODUCT_IMAGE_KEYPHRASES } from '@frontend/store/psd-layers/features/auto-map/constants/keyphrases';

const PRODUCT_IMAGE_ACTIVATOR_REGEX = /product/i;

const matcher = ({ sourceName }) => match({
  fieldType: FIELD_TYPE_PRODUCT_IMAGE,
  sourceName,
  keyPhrases: PRODUCT_IMAGE_KEYPHRASES,
  options: {
    includeNumber: true
  }
});

export const productImageMatcher = {
  match: matcher,
  activator: (sourceName) => !!sourceName.match(PRODUCT_IMAGE_ACTIVATOR_REGEX),
}