var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: { hover: true },
        },
      ],
      staticClass: "brushes-dd",
      attrs: {
        text: "",
        variant: "outline-primary",
        size: "sm",
        "no-caret": "",
        title: "Brushes",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [_c("b-icon", { attrs: { icon: "brush" } })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-input-group",
        { staticClass: "n-input mr-3", attrs: { append: "px" } },
        [
          _c("b-form-input", {
            attrs: { type: "number", value: _vm.brushSize },
            on: { input: _vm.changeBrushSize },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-group range-wrapper" }, [
        _c("input", {
          attrs: { type: "range", min: "0", max: "100", step: "1" },
          domProps: { value: _vm.brushSize },
          on: { input: _vm.changeBrushSize },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectBrush("None")
            },
          },
        },
        [_vm._v("\n    None\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectBrush("PencilBrush")
            },
          },
        },
        [_vm._v("\n    Pencil\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectBrush("CircleBrush")
            },
          },
        },
        [_vm._v("\n    Circle\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.selectBrush("SprayBrush")
            },
          },
        },
        [_vm._v("\n    Spray\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }