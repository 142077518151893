export class Context {
    contexts = [];

    add(item) {
        this.contexts.push(item)
    }

    get(instance) {
        return this.contexts.find(item => item instanceof instance);
    }
}