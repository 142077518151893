import {
  ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
  H_LINE_ID, HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
  V_LINE_ID, VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS
} from "@/common/preview/positioning-lines";
import { ShowPageScopeCollector } from '@frontend/group/modules/scope-collectors/show-page-collector';
import { deepCopy } from "@frontend/utils";
import { copyAndResizeEventBroker, EVENTS } from './event-broker';
import { resizeTemplateHandler } from "./handlers/resize-template-handler";
import { replaceSizeIntoTitleOfTemplate } from "./helpers";
import { FIELD_TYPE_BACKGROND_THEME_IMAGE } from "@frontend/constants/type-fields-of-template";
import { DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES } from '@frontend/group/modules/additional-fields'

const NOT_PROCESSED_OBJECTS = [
  'pixel_background',
  H_LINE_ID,
  V_LINE_ID,
  ALIGN_TOP_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_BOTTOM_BETWEEN_OBJECTS_LINE_ID,
  ALIGN_LEFT_BETWEEN_OBJECTS_LINE_ID,
  VERTICAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
  HORIZONTAL_LINE_OF_INTERSECT_IN_CENTER_OF_OBJECTS,
  ALIGN_RIGHT_BETWEEN_OBJECTS_LINE_ID,
];

const MODAL_TITLES = {
  COPY_AND_RESIZE: 'Copy and Resize Template',
  RESIZE: 'Resize Template'
}

const getShowPageScope = () => ShowPageScopeCollector.get([
  'previews',
  'layoutChanges',
  'getChangesLayoutById',
  'saveLayout',
  'updateLayoutChanges',
  'handleObjectMoving',
  'onClickDuplicateTemplateButton',
]);

let currentPreview = null,
  originalCopyHref = null,
  copyAndResizeButtonContext = null;

export function changeNameOfTemplate(width, height, _preview = currentPreview) {
  const $templateNameCanvas = $(_preview.canvas.upperCanvasEl)
    .closest('.template-item')
    .find('.template-name-canvas');

  $templateNameCanvas.text(
    replaceSizeIntoTitleOfTemplate(
      $templateNameCanvas.text(),
      width,
      height
    )
  );
}

export function changeTemplateCanvasElement(newWidth, newHeight, _preview = currentPreview) {
  const upperCanvasEl = _preview.canvas.upperCanvasEl;
  _preview.canvas.setDimensions({
    width: newWidth,
    height: newHeight
  });
  const templateItem = $(upperCanvasEl.closest(".template-item"));
  const width = $(upperCanvasEl).closest('.grid-stack-row').width();
  const resizeFactor = Math.min(newWidth / newHeight, newHeight / newWidth)
  let cWidth = width;
  let cHeight = resizeFactor * width;

  if (width > newWidth) {
    cHeight = newHeight
    cWidth = newWidth
  }

  const styles = { width: cWidth, height: cHeight };
  const selectors = [
    '.canvas-container:first',
    '.lower-canvas:first',
    '.upper-canvas:first'
  ];

  templateItem.find('.grid-stack-item').css('height', cHeight);
  selectors.forEach(selector => {
    templateItem.find(selector).css(styles);
  });
}

async function copyResizedTemplate(data) {
  const { onClickDuplicateTemplateButton } = getShowPageScope();
  await onClickDuplicateTemplateButton.bind(copyAndResizeButtonContext)(JSON.stringify(data));
}

copyAndResizeEventBroker.on(EVENTS.SAVED, async (state) => {
  const { saveLayout, layoutChanges, getChangesLayoutById } = getShowPageScope();
  const { isMultiSizes, selectedSizes } = state;

  if (isMultiSizes && layoutChanges.length) {
    await saveLayout(false);
  }

  const resizedData = [];

  if (!isMultiSizes) {
    const { width, height } = selectedSizes[0];
    resizeTemplateHandler({
      isCopyObject: false,
      dimension: selectedSizes[0],
      state,
      preview: currentPreview
    });

    changeNameOfTemplate(width, height);
    changeTemplateCanvasElement(width, height);

    copyAndResizeEventBroker.fire(EVENTS.CLOSED);

    return;
  }

  for (let dimension of selectedSizes) {
    resizeTemplateHandler({
      isCopyObject: true,
      dimension,
      state,
      preview: currentPreview
    });

    resizedData.push(deepCopy(getChangesLayoutById(currentPreview.id)));
  }

  if (originalCopyHref) {
    await copyResizedTemplate(resizedData);
  }

  copyAndResizeEventBroker.fire(EVENTS.CLOSED);
});

export const getObjectsForOrder = (objects) => objects
  .filter(object => !NOT_PROCESSED_OBJECTS.includes(object.id))
  .map(object => {
    if (object.get('type') === 'group') {
      const objects = object.getObjects();
      return {
        name: 'Group',
        isGroup: true,
        target: object,
        visible: false,
        objects: getObjectsForOrder(objects),
      }
    }

    return {
      name: object?.templateField?.name || object?.name,
      target: object,
      objects: [],
      id: object.id,
    }
  });

const handlerButton = (instanceId, withCopingTemplate) => {
  const { previews } = getShowPageScope();
  const { COPY_AND_RESIZE, RESIZE} = MODAL_TITLES;
  
  const preview = previews.find(preview => preview.id === instanceId);
  currentPreview = preview;
  
  const objects = getObjectsForOrder(preview.canvas.getObjects());
  
  const fields = objects.map(object => {
    let thumbnail = null;
    
    if (object.target._element) {
      const url = new URL(object.target._element.currentSrc);
      url.searchParams.set('lightweight', '');
      url.searchParams.set('scale_width', '30');

      thumbnail = url;
    }
    
    return {
      id: object.target.id,
      name: object.name,
      type: object.target.templateField?.type || FIELD_TYPE_BACKGROND_THEME_IMAGE,
      thumbnail: thumbnail || DEFAULT_BACKGROUND_THEME_IMAGE_FIELD_PROPERTIES.additional_background_images,
    }
  })
  
  copyAndResizeEventBroker.fire(EVENTS.INIT, {
    title: withCopingTemplate ? COPY_AND_RESIZE : RESIZE,
    isMultiSizes: withCopingTemplate,
    fields,
    canvasSize: {
      width: preview.canvas.width,
      height: preview.canvas.height,
    },
    preview,
    objects: objects,
  });
}
  
export function initCopyAndResizeHandler($row) {
  const $button = $row.find('.resize-template');

  $button.on('click', function () {
    const { id, withCopingTemplate } = $(this).data();
    originalCopyHref = withCopingTemplate ? $(this)
      .closest('.settings-dropdown')
      .find('.duplicate-template')
      .attr('href') : null;
    copyAndResizeButtonContext = this;

    handlerButton(id, withCopingTemplate);
  });
}
