export class ScopeCollector {

    vars = {}

    set(object) {
        Object.keys(object).forEach(key => this.vars[key] = object[key])
    }

    get(name) {
        if (Array.isArray(name)) {
            return name.reduce(
                (acc, name) => !this.vars[name] ? acc : {...acc, [name]: this.vars[name] }, {}
            );
        } 

        return this.vars[name];
    }
}