var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "outline-fiels-modal",
        title: "Outline Fields",
        "ok-title": _vm.isLoading ? "Saving..." : "Save",
        "ok-disabled": _vm.isLoading,
      },
      on: { ok: _vm.onSave },
    },
    _vm._l(_vm.fields, function (field, index) {
      return _c(
        "b-row",
        { key: field.id, class: { "mt-2": index > 0 } },
        [
          _c("b-col", { staticClass: "d-flex align-contents-center" }, [
            _c(
              "div",
              { staticClass: "mr-2" },
              [
                _c("label", [_vm._v(_vm._s(field.name))]),
                _vm._v(" "),
                _c("b-form-input", {
                  model: {
                    value: field.productId,
                    callback: function ($$v) {
                      _vm.$set(field, "productId", $$v)
                    },
                    expression: "field.productId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("label", [_vm._v(_vm._s(field.name) + " Color")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-contents-center" },
                [
                  _c("b-form-input", {
                    model: {
                      value: field.color,
                      callback: function ($$v) {
                        _vm.$set(field, "color", $$v)
                      },
                      expression: "field.color",
                    },
                  }),
                  _vm._v(" "),
                  _c("ColorPicker", {
                    staticClass: "ml-2",
                    model: {
                      value: field.color,
                      callback: function ($$v) {
                        _vm.$set(field, "color", $$v)
                      },
                      expression: "field.color",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }