var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "select-way-import-from-psd",
        title: "Import PSD",
        centered: "",
        size: "lg",
      },
      on: { ok: _vm.handleOkBtn },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel, ok }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between w-100 align-items-center",
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isSamplePsdExist
                        ? [
                            _c("b-icon", {
                              attrs: { id: "info-icon", icon: "info-circle" },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "info-icon" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "info-icon-tooltip" },
                                  [
                                    _vm._v(
                                      "\n              When editing your PSD file, "
                                    ),
                                    _c("br"),
                                    _vm._v("please use layer naming as shown "),
                                    _c("br"),
                                    _vm._v(
                                      "in the sample PSD file, such as:\n              "
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "info-icon-tooltip__list",
                                      },
                                      [
                                        _c("li", [_vm._v("Headline 1")]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v("Headline 2 (optional)"),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [
                                          _vm._v("Subhead 1 (optional)"),
                                        ]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Image Area")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 1")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 2")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Product Image 3")]),
                                        _vm._v(" "),
                                        _c("li", [_vm._v("Logo")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "download-link",
                                attrs: { href: _vm.samplePsdUrl, download: "" },
                              },
                              [
                                _vm._v(
                                  "\n            Download sample PSD\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-button", { on: { click: cancel } }, [
                        _vm._v("\n          Cancel\n        "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mx-1" }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.hasDisabledOkButton,
                          },
                          on: { click: ok },
                        },
                        [_vm._v("\n          OK\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("b-form-radio-group", {
        attrs: { name: "select-strategy", options: _vm.options },
        model: {
          value: _vm.selectedStrategy,
          callback: function ($$v) {
            _vm.selectedStrategy = $$v
          },
          expression: "selectedStrategy",
        },
      }),
      _vm._v(" "),
      _vm.selectedStrategy === _vm.IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY
        ? _c("SelectTemplateBanner", {
            staticClass: "mt-3",
            attrs: { customers: _vm.customers },
            model: {
              value: _vm.selectedTemplates,
              callback: function ($$v) {
                _vm.selectedTemplates = $$v
              },
              expression: "selectedTemplates",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }