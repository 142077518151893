export default {
  extractInfo(formData, endpoint) {
    //temporary endpoint since the task is still in progress, related to POR-5620 and POR-5532
    return axios.post(
      `https://testing.rapidads.io:8085/pdf/extract/${endpoint}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  },

  convertPdfToSvg(formData) {
    return axios.post('https://testing.rapidads.io:8085/pdf/convert-to-svg',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },

  createClippingTiff(formData) {
    return axios.post(`https://testing.rapidads.io:8085/pdf/create-tiff-with-clipping-path`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },

  createProductClippingTiff(formData) {
    return axios.post(`https://testing.rapidads.io:8085/pdf/create-product-tiff-with-clipping-path`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },

  optimizeSVGO(file) {
    const formData = new FormData();
    formData.append('file', file);
    
    return axios.post('/svg/optimize-svg',
      formData,
      {
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },
  
  saveToFileBrowser(formData) {
    return axios.post(`/svg/save-to-file-browser`, formData);
  },

  downloadImage(format, formData) {
    return axios.post(`/svg/convert-image?format=${format}`, formData);
  },

  checkOnProductImage(formData) {
    return axios.post(`/svg/check-on-product-image`, formData,
      {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }
}