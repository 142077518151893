import { EventBroker } from "../../../lib/utils/event-broker";

export const EVENTS = {
    INIT: 'INIT',
    SHOW: 'SHOW',
    CLOSE: 'CLOSE',
    EXPORT: 'EXPORT',
    FINISH: 'FINISH',
}

export const exportTemplatesEventBroker = new EventBroker();