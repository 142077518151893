export class FileFormURL {

    url;

    name;

    constructor(url, name) {
        this.url = url;
        this.name = name;
    }
}