import {EventBroker} from "../../../lib/utils/event-broker";

export const BACKGROUND_THEME_COLOR_FIELD_TYPE = 'Background Theme Color'

const openButtonClickHandler = (instance, preview, previews) => {
    backgroundThemeHandlerEventBroker.fire(INIT_BACKGROUND_THEME_COLOR_MODAL, { instance, preview, previews })
}

export const initBackgroundThemeColor = (template, instance, previews) => {
    const backgroundThemeColorButton = template.find('.background-theme-color')

    const backgroundFields = instance.template.fields.find(({ type }) => type === BACKGROUND_THEME_COLOR_FIELD_TYPE)

    if (!backgroundFields) {
        backgroundThemeColorButton.remove();
    } else {
        backgroundThemeColorButton.on('click', () =>
            openButtonClickHandler(
                instance,
                previews.find(preview => preview.id === instance.instance_id),
                previews.filter(preview => preview.id !== instance.instance_id)
            )
        );
    }
}

export const backgroundThemeHandlerEventBroker = new EventBroker();

export const INIT_BACKGROUND_THEME_COLOR_MODAL = 'init-background-theme-color-modal';
export const CHANGE_BACKGROUND_THEME_COLOR = 'change-background-theme-color';
