import axios from 'axios'

export default {
  getAvailablePermissions () {
    return axios.get(`/banner/layout_folder/available-permissions`)
  },
  getFolderPermissionsData (folderId) {
    return axios.get(`/banner/layout_folders/${folderId}/permissions-data`)
  },
  getFolders (params = {}) {
    return axios.get('/banner/layout_folder_list', { params })
  },
  getBreadcrumbs (folderId) {
    return axios.get('/banner/layout_folder_breadcrumbs', { params: {folderId} });
  },
  syncFolderPermissions(folderId, userIds, userGroupIds) {
    return axios.post(`/banner/layout_folders/${folderId}/sync-folder-permissions`, { userIds, userGroupIds });
  },
  createFolder(parent_id, name) {
    return axios.post('/banner/layout_folder_create', { parent_id, name });
  },
  checkFolderName (name) {
    return axios.get('/banner/layout_folder_check_name', { params: { name } })
  },
  deleteFolder(folderId) {
    return axios.delete('/banner/layout_folder_delete', { data: { folderId } });
  },
  editFolder(folderId, folderName) {
    return axios.put('/banner/layout_folder_edit', { folderId, folderName });
  },
  linkToFolder(folderId, layoutIds, customerIds, folderName) {
    return axios.post('/banner/layout_link_to_folder', { folderId, layoutIds, customerIds, folderName });
  },
  moveLayoutsToFolder(folderId, layoutIds) {
    return axios.post('/banner/layout_move_to_folder', { folderId, layoutIds });
  },
  moveFoldersToFolder(folderId, folderIdsToMove) {
    return axios.post('/banner/folders_move_to_folder', { folderId, folderIdsToMove });
  },
  copyToFolders(folders, layoutIds, customerIds) {
    return axios.post('/banner/layout_copy_to_folders', { folders, layoutIds, customerIds });
  },
  getFolderContent(folderId) {
    return axios.get('/banner/layout_folder_content', {
      params: {
        folderId
      }
    });
  },
  deleteFolderLayout(folderId, layoutId) {
    return axios.delete('/banner/layout_folder_delete_layout', { data: { folderId, layoutId } });
  },
  deleteFolderLayouts(folderId, layoutIds) {
    return axios.delete('/banner/layout_folder_delete_layouts', { data: { folderId, layoutIds } });
  },
  archiveFolderItem(layout) {
    const { customer_id, id } = layout
    
    return axios.patch(`/banner/${customer_id}/group/${id}/archive_layout_from_folder`, { data: { layout } });
  },
  archiveLayout(customer_id, layout_id) {
    return axios.patch(`/banner/${customer_id}/group/${layout_id}/archive_layout_from_folder`);
  },
  getFolder (folderId) {
    return axios.get(`/banner/layout_folders/${folderId}`);
  },
}
