import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    productDescriptionJobs: [],
    prompts: [],
    promptsOriginal: [],
  }),

  getters: {
    productDescriptionJobs: state => state.productDescriptionJobs,
    prompts: state => state.prompts,
    promptsOriginal: state => state.promptsOriginal,
    promptIndexById: state => id => _.findIndex(state.prompts, ['id', id]),
  },

  mutations: {
    SET_PRODUCT_DESCRIPTION_JOBS (state, payload) {
      state.productDescriptionJobs = payload
    },
    SET_PROMPTS (state, payload) {
      state.prompts = payload
      state.promptsOriginal = _.cloneDeep(payload)
    },
    SET_PROMPT (state, payload) {
      const index = _.findIndex(state.prompts, ['id', payload.id])
      Vue.set(state.prompts, index, payload)
    },
    SET_PROMPT_ORIGINAL (state, payload) {
      const index = _.findIndex(state.promptsOriginal, ['id', payload.id])
      Vue.set(state.promptsOriginal, index, payload)
    },
  },

  actions: {
    async fetchProductDescriptionJobs ({ commit }) {
      const { data: { data } } = await axios.get(`/banner/groups/index_gpt_completion_jobs`, {
        params: {
          type: 0,
          per_page: 20,
        },
      })
      commit('SET_PRODUCT_DESCRIPTION_JOBS', data)
    },
    async fetchPrompts ({ commit }, jobId) {
      const { data } = await axios.get(`/banner/groups/product_description_jobs/${jobId}/prompts`, {
        params: {
          per_page: 0,
        },
      })
      commit('SET_PROMPTS', data)
    },
    async updatePrompt ({ commit }, payload) {
      const { data } = await axios.put(`/banner/groups/product_description_jobs/${payload.product_description_job_id}/prompts/${payload.id}`, payload)
      commit('SET_PROMPT_ORIGINAL', _.cloneDeep(data))
      commit('SET_PROMPT', data)
    },
  },
}
