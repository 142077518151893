var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modal-body d-flex justify-content-center align-items-center",
      },
      [_c("i", { staticClass: "fas fa-spinner fa-spin fa-3x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }