import { resizeWithAspectRatio } from "@frontend/services/helpers";

export class GeneratePreviewImage {

    canvas = null;

    constructor(preview) {
        this.canvas = preview instanceof HTMLCanvasElement
            ? preview
            : preview.canvas.toCanvasElement();
    }

    resize(desiredWidth, desiredHeight) {
        // Determine final dimensions without upscaling
        let finalWidth = this.canvas.width
        let finalHeight = this.canvas.height

        // Check if scaling down is needed
        if (desiredWidth < finalWidth || desiredHeight < finalHeight) {
            const scale = Math.min(desiredWidth / finalWidth, desiredHeight / finalHeight)
            finalWidth *= scale
            finalHeight *= scale
        }

        const thumbnailSize = resizeWithAspectRatio(
            this.canvas.width,
            this.canvas.height,
            finalWidth,
            finalHeight
        );

        const backgroundCanvas = document.createElement('canvas');
        backgroundCanvas.width = thumbnailSize.width;
        backgroundCanvas.height = thumbnailSize.height;

        const backgroundCanvasCtx = backgroundCanvas.getContext('2d');
        backgroundCanvasCtx.fillStyle = 'white';
        backgroundCanvasCtx.fillRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);

        backgroundCanvasCtx.drawImage(this.canvas, 0, 0, backgroundCanvas.width, backgroundCanvas.height);

        this.canvas = backgroundCanvas;

        return this;
    }

    url(type = 'image/png') {
        return this.canvas.toDataURL(type);
    }

    file(name, type = 'image/png') {
        return new Promise((resolve, reject) => {
            this.canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], name, {type});
                    resolve(file);
                } else {
                    reject(new Error('Failed to create blob from canvas'));
                }
            }, type);
        });
    }
}
