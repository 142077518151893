<template>
  <modal
    name="svg-image-type-modal"
    draggable=".drag-area"
    class="wrapper"
    height="auto"
    :shift-x="0.5"
    :shift-y="0.5"
    :click-to-close="false"
  >
    <div class="drag-area">
      <b-button
        variant="light"
        class="close-btn"
        @click="close"
      >
        <b-icon
          icon="x-lg"
          class="close-icon"
        />
      </b-button>
    </div>

    <div class="content-area">
      Select how the image will be uploaded
    </div>

    <div class="control-area">
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-primary mr-2"
            @click="apply(asBackground)"
          >
            As background
          </button>
          <button
            class="btn btn-primary"
            @click="apply(asImage)"
          >
            As image
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { TYPE_IMAGE, TYPE_IMAGE_BG } from '@frontend/components/modules/svg-editor/constants';

export default {
  name: 'SvgImageTypeModal',

  data: () => ({
    asBackground: TYPE_IMAGE_BG,
    asImage: TYPE_IMAGE,
    file: null,
    typeImage: TYPE_IMAGE_BG,
    fbProps: null
  }),

  methods: {
    init(file, typeImage, fbProps) {
      Object.assign(this, { file, typeImage, fbProps });
    },

    show(file, typeImage, fbProps) {
      this.init(file, typeImage, fbProps);
      this.$modal.show('svg-image-type-modal');
    },

    close() {
      this.$emit('upload-file', this.file, this.typeImage, this.fbProps);
      this.$modal.hide('svg-image-type-modal');
    },

    apply(uploadType) {
      this.typeImage = uploadType;
      this.$emit('upload-file', this.file, this.typeImage, this.fbProps);
      this.$modal.hide('svg-image-type-modal');
    },
  },
};
</script>

<style scoped>
.wrapper {
  ::v-deep .vm--modal {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 6px;
    box-shadow: rgba(208, 208, 208, 0.45) 0 4px 20px;
    background-color: #f6f6f6;
  }

  .drag-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    position: relative;
    padding: 16px 16px;

    .title {
      font-size: 20px;
    }

    .close-btn {
      position: absolute;
      border: none;
      right: 8px;
      background-color: transparent;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        border: none;

        .close-icon {
          color: black;
        }
      }

      .close-icon {
        color: #7f7f7f;
        font-size: 15px;

        &:hover {
          color: black;
        }
      }
    }
  }

  .content-area {
    padding: 0 16px 16px 16px;

    .color-input {
      padding: 0;
      height: 32px;
    }
  }

  .control-area {
    padding: 0 16px 16px 16px;
  }
}
</style>

<style>
.wrapper {
  .vm--overlay {
    background: transparent !important;
  }
}
</style>