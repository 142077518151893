var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      attrs: { variant: "outline-light", disabled: _vm.disabled },
      on: { hide: _vm.onHide },
      scopedSlots: _vm._u(
        [
          {
            key: "button-content",
            fn: function () {
              return [_vm._t("default")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c("h6", { staticClass: "dropdown-header" }, [
        _vm._v("\n    Line Height\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.options, function (option) {
        return _c(
          "button",
          {
            key: option.value,
            staticClass: "dropdown-item",
            class: { active: option.value === _vm.value },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.onChange(option.value)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(option.text) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mx-3 mt-2" },
        [
          _c("b-form-input", {
            attrs: {
              size: "sm",
              type: "number",
              min: "0.01",
              step: "0.01",
              max: "2",
            },
            model: {
              value: _vm.lineHeight,
              callback: function ($$v) {
                _vm.lineHeight = _vm._n($$v)
              },
              expression: "lineHeight",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }