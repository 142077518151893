import {parseStyleToText} from "../../../../common/preview/parse-style-to-text";
import {CUSTOM_RECT_TYPE} from "../../../constants/custom-fabric-types";
import { KEY_HTML } from '@frontend/constants/templates-field-prefixes';

const aliasType = {
    'textbox': 'text',
    [CUSTOM_RECT_TYPE]: 'rectangle',
    'rect': 'rectangle',
    'circle': 'ellipse',
    'ellipse': 'ellipse',
    'image': 'image'
}

const serializerBuilder = options => object => {
    const { maskSettings } = options;
    const position = object.getPointByOrigin('left', 'top');
    const mask = maskSettings.find(mask => mask.id === object.id.replace(/_/g, '')) || {};
    let fill = object.fill;

    if (object.fill instanceof fabric.Gradient) {
        fill = object.fill.colorStops.map(({ color }) => color);
    }

    return {
        ...object.toJSON(),
        ...(object.type === 'textbox' ? {
            text: parseStyleToText(object.textLines, object.styles),
            lineHeight: Math.max(...object.__lineHeights)
        } : {}),
        ...(object.type === CUSTOM_RECT_TYPE ? {
            rounding: object.rx || 0,
        } : {}),
        id: object.id,
        type: aliasType[object.type] || object.type,
        width: object.getScaledWidth(),
        height: object.getScaledHeight(),
        left: position.x,
        top: position.y,
        color: object.fontColor,
        cornerSize: object.rx,
        fontName: window.fontFamilyManager.getFontByIndex(object.fontFamily)?.name,
        corners: object.corners,
        order: object.order,
        fill,
        mask,
        link: object?.templateField?.getLink?.() || object.link || '',
        ...(object.id.startsWith(KEY_HTML) ? { src: object.toDataURL() } : {}),
    };
}

export const linesFilter = object => Object.keys(aliasType).some(l => l === object.type)

// extra serializers override props created from the simple one
export const serializerFactory = (options, extraSerializers = []) => objects => {
    const serializer = serializerBuilder(options)

    return objects
        .filter(linesFilter)
        .map(object => {
            const results = extraSerializers.map(extraSerializer => extraSerializer(object));

            results.unshift(serializer(object))

            return results.reduce((acc, current) => Object.assign(acc, current), {})
        })
}

