var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "actions-checkbox mt-3" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 d-flex align-content-center" },
        [
          _c("span", { staticClass: "mr-1" }, [_vm._v("Apply to")]),
          _vm._v(" "),
          _vm.target.canvas
            ? _c("SelectTemplateDropdown", {
                attrs: {
                  "current-instance-id": _vm.target.canvas.instance_id,
                  "local-storage-key":
                    _vm.LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mx-1" }, [_vm._v("\n        :\n      ")]),
          _vm._v(" "),
          _c("SelectPropertiesDropdown", {
            attrs: {
              "is-color-selector": !!_vm.options.colorSelector,
              "is-font-selector": !!_vm.options.fontSelector,
              "is-alignment-selector": !!_vm.options.alignmentSelector,
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.selectedPropertyKeys,
              callback: function ($$v) {
                _vm.selectedPropertyKeys = $$v
              },
              expression: "selectedPropertyKeys",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }