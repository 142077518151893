const STORAGE_KEY = 'store';

const StoreSaver = (store) => {
    window.Store = {
        save() {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(store._modules.root.state))
        },
        restore() {
            const saved = localStorage.getItem(STORAGE_KEY)
            if (!saved) {
                throw new Error('There is no saved store in the Storage. Could you use "store()" function first.')
            }

            try {
                store.replaceState(JSON.parse(saved))
            } catch (error) {
                localStorage.removeItem(STORAGE_KEY)
                throw new Error('Store abandoned.')
            }
        }
    }
}



export default StoreSaver;