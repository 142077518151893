import { ShapeTemplateField } from "./ShapeTemplateField";

export class RectangleTemplateField extends ShapeTemplateField {

    constructor(fieldData) {
        super(fieldData);
    }

    getFill() {
      return this.options.option3 || '#000000';
    }

    getStrokeColor() {
      return this.options.option1 || '#000000';
    }

    getRadius() {
        let radius = this.options.option4;

        if (!radius) return 0;

        return +(String(radius).split(',')[0]) || 0;
    }
}
