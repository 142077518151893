var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "wrapper",
      attrs: {
        name: "svg-text-modal",
        draggable: ".drag-area",
        height: "auto",
        "shift-x": 0.5,
        "shift-y": 0.5,
        "click-to-close": false,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drag-area" },
        [
          _c("span", { staticClass: "title" }, [_vm._v("Text settings")]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "close-btn",
              attrs: { variant: "light" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon", {
                staticClass: "close-icon",
                attrs: { icon: "x-lg" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-area" },
        [
          _c(
            "b-form-row",
            [
              _c("b-col", { attrs: { cols: "5" } }, [
                _c(
                  "div",
                  { staticClass: "text-editor-modal__settings-field" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "my-0 mr-1 ml-6",
                        attrs: { for: "font-family" },
                      },
                      [_vm._v("\n            Font Family\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        staticClass: "form-control form-control-sm",
                        attrs: { id: "font-family" },
                        domProps: { value: _vm.fontFamily },
                        on: { input: _vm.setFontFamily },
                      },
                      _vm._l(_vm.fontsList, function (currentFont, index) {
                        return _c(
                          "option",
                          {
                            key:
                              (currentFont.index || currentFont.created_at) +
                              index,
                            style: { "font-family": currentFont.index },
                            domProps: {
                              value: currentFont.index || currentFont,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(currentFont.name || currentFont.title) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "5" } }, [
                _c(
                  "div",
                  { staticClass: "text-editor-modal__settings-field" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "my-0 mr-1 ml-6",
                        attrs: { for: "font-size" },
                      },
                      [_vm._v("\n            Size\n          ")]
                    ),
                    _vm._v(" "),
                    _c("b-input", {
                      staticClass: "form-control form-control-sm",
                      attrs: {
                        id: "font-size",
                        value: _vm.fontSize,
                        type: "number",
                      },
                      on: { input: _vm.setFontSize },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "my-0 mr-1 ml-6",
                      attrs: { for: "font-color" },
                    },
                    [_vm._v("\n          Color\n        ")]
                  ),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "color-input",
                    attrs: {
                      id: "font-color",
                      type: "color",
                      value: _vm.fontColor,
                    },
                    on: { input: _vm.setColor },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "control-area" }, [
        _c("div", { staticClass: "d-flex justify-content-end w-100" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-end justify-content-end" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  on: {
                    click: _vm.apply,
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.apply.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n          Apply\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-secondary", on: { click: _vm.close } },
                [_vm._v("\n          Close\n        ")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }