import { render, staticRenderFns } from "./DeleteDownloadSettingsModal.vue?vue&type=template&id=74b59590"
import script from "./DeleteDownloadSettingsModal.vue?vue&type=script&lang=js"
export * from "./DeleteDownloadSettingsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_diego/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74b59590')) {
      api.createRecord('74b59590', component.options)
    } else {
      api.reload('74b59590', component.options)
    }
    module.hot.accept("./DeleteDownloadSettingsModal.vue?vue&type=template&id=74b59590", function () {
      api.rerender('74b59590', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/layouts-page/modals/DeleteDownloadSettingsModal.vue"
export default component.exports