var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      !_vm.productDescriptionJobId
        ? _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Job", "label-cols": "auto" } },
                [
                  _c("Multiselect", {
                    attrs: {
                      label: "name",
                      "track-by": "id",
                      options: _vm.productDescriptionJobs,
                      "allow-empty": false,
                      "deselect-label": "",
                    },
                    model: {
                      value: _vm.selectedJob,
                      callback: function ($$v) {
                        _vm.selectedJob = $$v
                      },
                      expression: "selectedJob",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedProductDescriptionJobId
        ? [
            _c("b-col", { attrs: { cols: "12" } }, [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        variant: "outline-primary",
                        size: "sm",
                        href: `/banner/groups/product_description_jobs/${_vm.selectedProductDescriptionJobId}/analytics?keyword_data`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showSuccessMessage(
                            "Keyword data download started"
                          )
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "download" } }),
                      _vm._v("\n          Download Keyword data\n        "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        variant: "outline-primary",
                        size: "sm",
                        href: `/banner/groups/product_description_jobs/${_vm.selectedProductDescriptionJobId}/analytics?search_volume_results`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showSuccessMessage(
                            "Search volume results download started"
                          )
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "download" } }),
                      _vm._v("\n          Search volume results\n        "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "h-100 d-flex align-items-center mr-auto" },
                    [
                      _c("b-icon", {
                        staticClass: "h4 mb-0",
                        attrs: {
                          id: "search-volume-info",
                          icon: "exclamation-circle",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            target: "search-volume-info",
                            triggers: "hover",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "white-space": "nowrap" } },
                            [
                              _vm._v(
                                '\n              In the search volume graph, "original" is calculated by multiplying the number of occurrences of a keyword in the original copy by the search'
                              ),
                              _c("br"),
                              _vm._v(
                                'volume data for that keyword. "Generated" uses the same logic, but the number of occurrences comes from the generated copy.\n            '
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-form-radio-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showChart,
                        expression: "showChart",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: {
                      options: ["Summary", "Used", "Unused"],
                      size: "sm",
                      "button-variant": "outline-primary",
                      buttons: "",
                    },
                    on: { input: _vm.setKeywordUsageView },
                    model: {
                      value: _vm.keywordUsageView,
                      callback: function ($$v) {
                        _vm.keywordUsageView = $$v
                      },
                      expression: "keywordUsageView",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-radio-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showChart,
                        expression: "!showChart",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: {
                      options: [
                        "Original",
                        "Generated",
                        "Summary",
                        "Search Volume",
                      ],
                      size: "sm",
                      "button-variant": "outline-primary",
                      buttons: "",
                    },
                    on: { input: _vm.setMostUsedKeywordsView },
                    model: {
                      value: _vm.mostUsedKeywordsView,
                      callback: function ($$v) {
                        _vm.mostUsedKeywordsView = $$v
                      },
                      expression: "mostUsedKeywordsView",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-button-group",
                    { attrs: { size: "sm" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.toggleChart },
                        },
                        [_c("b-icon", { attrs: { icon: "chevron-left" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.toggleChart },
                        },
                        [_c("b-icon", { attrs: { icon: "chevron-right" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mx-5" }, [
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showChart && !_vm.showSearchVolumeChart,
                      expression: "showChart && !showSearchVolumeChart",
                    },
                  ],
                  ref: "keywordUsage",
                }),
                _vm._v(" "),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showChart && !_vm.showSearchVolumeChart,
                      expression: "!showChart && !showSearchVolumeChart",
                    },
                  ],
                  ref: "mostUsedKeywords",
                }),
                _vm._v(" "),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSearchVolumeChart,
                      expression: "showSearchVolumeChart",
                    },
                  ],
                  ref: "searchVolumeResults",
                }),
              ]),
            ]),
            _vm._v(" "),
            false
              ? _c("b-col", { attrs: { cols: "4" } }, [
                  _c("canvas", { ref: "optimizedKeywordData" }),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.keywords
        ? _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-2 float-right",
                  attrs: {
                    variant: "outline-primary",
                    size: "sm",
                    href: `/banner/groups/product_description_jobs/${_vm.selectedProductDescriptionJobId}/analytics?csv`,
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "download" } }),
                  _vm._v("\n      Download CSV\n    "),
                ],
                1
              ),
              _vm._v(" "),
              _c("VueWordCloud", {
                staticStyle: { width: "100%", height: "500px" },
                attrs: {
                  "font-size-ratio": 5,
                  words: _vm.keywords,
                  rotation: 0,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }