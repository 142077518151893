import {EVENTS as PSD_LAYERS_EVENT, psdLayersEventBroker} from "../frontend/group/modules/psd-layers/event-broker";
import {ApplyStrategy} from "../frontend/services/api/psd-layers";
import {FileFormURL} from "@/includes/file-form-url";

const PSD_FILE_TYPE = 'image/vnd.adobe.photoshop';

export const PSD_LAYERS_CALLBACK_DTO_TYPES = {
    EXIT: 'EXIT'
}

const previewState = {
    callback: null,
};


function preparePayloadForSavingToTheme() {
    const saveImagesInThemePayload = {};
    const $themeInput = $('#theme');
    if ($themeInput.length && window.template_data) {
        saveImagesInThemePayload['themeId'] = $themeInput ? $themeInput.val() : 0;
        saveImagesInThemePayload['customerId'] = template_data.customer_id;
        saveImagesInThemePayload['templateId'] = template_data.id;
    }

    return saveImagesInThemePayload;
}

function closeModal() {
    $('#psd-preview').modal('hide');

    if (previewState.callback) {
        previewState.callback(false, {
            type: PSD_LAYERS_CALLBACK_DTO_TYPES.EXIT
        })
    }
}

function cancelButtonHandler() {
    if (previewState.callback) {
        previewState.callback(true);
    }

    previewState.callback = undefined;
    $('#psd-preview').modal('hide');
}


export function isPSDFileType(file) {
    return file.type === PSD_FILE_TYPE
        || file.name.split('.').pop().toLowerCase() === 'psb'
        || file.name.split('.').pop().toLowerCase() === 'psd';
}

export function addEventListenerToInput(element, listener) {
    const inputElement = $(element);
    const EVENTS = [`change.fileinput_${inputElement.attr('id')}`]

    for (let eventName of EVENTS) {
        inputElement.off(eventName, listener);
        inputElement.on(eventName, listener);
    }
}


window.initDummy = () => {
    initPsdPreviewer(new FileFormURL('user-folder/55/user-files/1725291890.psd', 'test'))
}

export function initPsdPreviewer(file, callback, _options = {}) {
    const options = {
        flow: ApplyStrategy.FLOWS.ADD_TO_TEMPLATE,
        isMultipleTemplateSelectionType: false,
        ..._options
    }

    previewState.callback = callback;

    $('#psd-preview').modal();

    const saveImagesInThemePayload = preparePayloadForSavingToTheme()

    psdLayersEventBroker.fire(PSD_LAYERS_EVENT.INIT, {
        file,
        themeFields: saveImagesInThemePayload,
        ...options
    });
    psdLayersEventBroker.on(PSD_LAYERS_EVENT.CANCEL, cancelButtonHandler, true)
    psdLayersEventBroker.on(PSD_LAYERS_EVENT.CLOSE, closeModal, true)
    psdLayersEventBroker.on(PSD_LAYERS_EVENT.IMAGE_MADE, data => {
        if (callback) {
            callback(undefined, data);
        }

        $('#psd-preview').modal('hide');
    }, true)
}
