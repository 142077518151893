export default function filePptxSvg() {
  return `
      <svg
      id="file-pptx-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 548.291 548.291">
          <g id="file-pptx-svg">
              <path id="path0"
                    d="M222.581 262.339c-5.504 0-9.229.538-11.176 1.061v35.292c2.312.541 5.145.715 9.046.715 14.374 0 23.237-7.276 23.237-19.519 0-10.984-7.637-17.549-21.107-17.549z"
                    stroke="none" fill="#747474" fill-rule="evenodd"></path>
              <path id="path1"
                    d="M486.201 196.116h-13.166V132.59c0-.399-.062-.795-.115-1.2-.021-2.522-.825-5-2.552-6.96L364.657 3.677c-.033-.034-.064-.044-.085-.075-.63-.704-1.364-1.292-2.143-1.796a11.29 11.29 0 0 0-2.823-1.312c-.2-.052-.379-.134-.577-.188A10.793 10.793 0 0 0 356.562 0H96.757C84.894 0 75.256 9.649 75.256 21.502v174.613H62.092c-16.971 0-30.732 13.756-30.732 30.73v159.81c0 16.966 13.761 30.736 30.732 30.736h13.164V526.79c0 11.854 9.638 21.501 21.501 21.501h354.776c11.853 0 21.501-9.647 21.501-21.501V417.392H486.2c16.966 0 30.729-13.764 30.729-30.731V226.854c.001-16.982-13.762-30.738-30.728-30.738zM96.757 21.502h249.054v110.006c0 5.94 4.817 10.751 10.751 10.751h94.972v53.861H96.757V21.502zm181.882 244.042v-22.711h91.863v22.711h-32.63v96.822h-27.128v-96.822h-32.105zm-8.187 13.651c0 11.696-3.89 21.622-10.987 28.363-9.228 8.692-22.887 12.604-38.837 12.604-3.556 0-6.75-.179-9.228-.54v42.74h-26.78V244.429c8.336-1.417 20.045-2.488 36.536-2.488 16.68 0 28.555 3.194 36.544 9.575 7.626 6.036 12.752 15.968 12.752 27.679zM81.578 362.362V244.429c8.336-1.417 20.034-2.488 36.526-2.488 16.675 0 28.549 3.194 36.536 9.575 7.627 6.037 12.766 15.968 12.766 27.669 0 11.696-3.903 21.627-10.998 28.368-9.229 8.692-22.876 12.598-38.842 12.598-3.536 0-6.746-.179-9.229-.535v42.74H81.578v.006zm369.956 158.6H96.757v-103.57h354.776v103.57zm-.493-158.6-10.824-21.638c-4.425-8.336-7.276-14.541-10.646-21.454h-.346c-2.478 6.913-5.503 13.118-9.219 21.454l-9.937 21.638h-30.852l34.573-60.488-33.329-59.041h31.028l10.458 21.819c3.555 7.268 6.215 13.122 9.05 19.861h.356c2.835-7.633 5.145-12.953 8.159-19.861l10.109-21.819h30.867l-33.702 58.347 35.466 61.182h-31.211z"
                    stroke="none" fill="#787474" fill-rule="evenodd"></path>
              <path id="path2"
                    d="M140.627 279.888c0-10.989-7.628-17.554-21.109-17.554-5.487 0-9.231.538-11.181 1.061v35.297c2.315.535 5.145.715 9.053.715 14.373 0 23.237-7.272 23.237-19.519z"
                    stroke="none" fill="#787474" fill-rule="evenodd"></path>
          </g>
    </svg>
`;
};