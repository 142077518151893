var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.getNodes, function (node, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-row mb-2 align-items-center" },
          [
            _c(
              "div",
              { staticClass: "col d-flex align-items-center" },
              [
                _c("b-form-checkbox", {
                  on: { input: _vm.onChange },
                  model: {
                    value: node.isPlay,
                    callback: function ($$v) {
                      _vm.$set(node, "isPlay", $$v)
                    },
                    expression: "node.isPlay",
                  },
                }),
                _vm._v(" "),
                node.src
                  ? _c("img", {
                      staticClass: "mx-2 image-field-preview",
                      attrs: { src: node.src, width: "30", height: "30" },
                    })
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "container-text-running mx-2",
                        attrs: { title: node.text || "<Empty>" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "d-block",
                            attrs: { width: "30", height: "20" },
                          },
                          [
                            _c("use", {
                              attrs: { href: "/img/icons/sprite.svg#eye" },
                            }),
                          ]
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c("span", { staticClass: "name-container" }, [
                  _vm._v(_vm._s(node.name)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-3 d-flex align-items-center" },
              [
                _c("b-form-input", {
                  staticClass: "form-control noscroll",
                  attrs: { type: "number" },
                  on: { input: _vm.onChange },
                  model: {
                    value: node.startTime,
                    callback: function ($$v) {
                      _vm.$set(node, "startTime", _vm._n($$v))
                    },
                    expression: "node.startTime",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-3 d-flex align-items-center" },
              [
                _c("b-form-input", {
                  staticClass: "form-control noscroll",
                  attrs: { type: "number" },
                  on: { input: _vm.onChange },
                  model: {
                    value: node.endTime,
                    callback: function ($$v) {
                      _vm.$set(node, "endTime", _vm._n($$v))
                    },
                    expression: "node.endTime",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: `animation-options-${index}`,
                    expression: "`animation-options-${index}`",
                  },
                ],
                staticClass: "position-relative",
                attrs: { size: "sm" },
              },
              [
                _c("b-icon", { attrs: { icon: "chevron-down" } }),
                _vm._v(" "),
                _c("b-icon", { attrs: { icon: "chevron-up" } }),
                _vm._v(" "),
                _c(
                  "b-badge",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: node.animations.length,
                        expression: "node.animations.length",
                      },
                    ],
                    staticClass: "animation-options-count",
                    attrs: { variant: "primary" },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(node.animations.length) + "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                staticClass: "col-12 card mt-2 mb-0",
                attrs: { id: `animation-options-${index}` },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-content-center",
                  },
                  [
                    _c("div", { staticClass: "pt-1" }, [
                      _vm._v("\n          Animation Options\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn p-0 add-new-animation-option",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onAddNewAnimation(node, index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-plus" })]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "form-row m-0" }, [
                    _c("label", { staticClass: "col" }, [_vm._v("Type")]),
                    _vm._v(" "),
                    _c("label", { staticClass: "col" }, [_vm._v("Direction")]),
                    _vm._v(" "),
                    _c("label", { staticClass: "col" }, [_vm._v("Start")]),
                    _vm._v(" "),
                    _c("label", { staticClass: "col" }, [_vm._v("End")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-1" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "container-options" },
                    _vm._l(
                      node.animations,
                      function (animation, indexAnimation) {
                        return _c(
                          "div",
                          {
                            key: indexAnimation,
                            staticClass: "form-row m-0 mb-1",
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: animation.type,
                                      expression: "animation.type",
                                    },
                                  ],
                                  staticClass: "form-control pr-0",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          animation,
                                          "type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.onChange,
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "fade" } }, [
                                    _vm._v(
                                      "\n                  Fade\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "wipe" } }, [
                                    _vm._v(
                                      "\n                  Wipe\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: animation.direction,
                                      expression: "animation.direction",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          animation,
                                          "direction",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.onChange,
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "in" } }, [
                                    _vm._v(
                                      "\n                  In\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "out" } }, [
                                    _vm._v(
                                      "\n                  Out\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: animation.startTime,
                                    expression: "animation.startTime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number" },
                                domProps: { value: animation.startTime },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        animation,
                                        "startTime",
                                        $event.target.value
                                      )
                                    },
                                    _vm.onChange,
                                  ],
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: animation.endTime,
                                    expression: "animation.endTime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number" },
                                domProps: { value: animation.endTime },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        animation,
                                        "endTime",
                                        $event.target.value
                                      )
                                    },
                                    _vm.onChange,
                                  ],
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-1 d-flex align-content-center justify-content-center p-0",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn p-0 delete-option",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDeleteAnimation(
                                          node,
                                          indexAnimation
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fas fa-trash" })]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "mb-2",
          attrs: { label: "Total duration", "label-cols": "5" },
        },
        [
          _c("b-form-input", {
            attrs: { type: "number" },
            model: {
              value: _vm.totalDuration,
              callback: function ($$v) {
                _vm.totalDuration = $$v
              },
              expression: "totalDuration",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Number of loops", "label-cols": "5" } },
        [
          _c("b-form-input", {
            attrs: { type: "number" },
            model: {
              value: _vm.numberOfLoops,
              callback: function ($$v) {
                _vm.numberOfLoops = $$v
              },
              expression: "numberOfLoops",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "w-100 mb-2",
          attrs: { variant: "primary" },
          on: { click: _vm.onPreview },
        },
        [_vm._v("\n    Preview\n  ")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-muted" }, [
        _vm._v("\n    Animations are supported for GIF and HTML formats.\n  "),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "col" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [_c("label", [_vm._v("Start")])]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [_c("label", [_vm._v("End")])]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }