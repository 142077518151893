import {TARGET_TYPE_MAP} from "./constants";

export const resolveTargetByType = type => {
    for (const target in TARGET_TYPE_MAP) {
        if (TARGET_TYPE_MAP[target].includes(type)) {
            return target;
        }
    }

    return undefined;
}

export const collisionEditBox = text => text.replace(new RegExp('[ \\n]', 'g'), '')