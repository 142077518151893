<template>
  <b-form-group v-if="max">
    <template #label>
      <span>{{ label }}</span>
      <b-badge
        :variant="state === null ? 'success' : 'danger'"
        class="float-right"
      >
        {{ count }}/{{ max }}
      </b-badge>
    </template>
    <slot :state="state" />
  </b-form-group>
  <b-form-group
    v-else
    :label="label"
  >
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: 'FormGroupCharLimit',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    state () {
      return this.count <= this.max && null
    },
    count () {
      return this.value.replace(/<[^>]*>/g, '').length
    },
  },
}
</script>

<style scoped>

</style>
