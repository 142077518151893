import { match } from './match';
import { IMAGE_AREA_KEYPHRASES } from '@frontend/store/psd-layers/features/auto-map/constants/keyphrases';

const IMAGE_AREA_ACTIVATOR_REGEX = /image area/i;

const matcher = ({ targetName, sourceName }) => match({
  targetName,
  sourceName,
  keyPhrases: IMAGE_AREA_KEYPHRASES,
});

export const imageAreaMatcher = {
  match: matcher,
  activator: (targetName) => !!targetName.match(IMAGE_AREA_ACTIVATOR_REGEX)
}