var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      attrs: {
        variant: "outline-light",
        "menu-class": "p-4",
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "button-content",
            fn: function () {
              return [_vm._t("default")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _c("label", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "b-input-group",
            { staticClass: "mb-3" },
            [
              _c(
                "b-input-group-prepend",
                [
                  _c("b-input-group-text", { on: { click: _vm.decrease } }, [
                    _c("i", { staticClass: "glyphicon glyphicon-minus" }),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-form-input", {
                staticStyle: { width: "94px" },
                attrs: { value: _vm.value, type: "number", step: _vm.step },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-input-group-append",
                [
                  _c("b-input-group-text", { on: { click: _vm.increase } }, [
                    _c("i", { staticClass: "glyphicon glyphicon-plus" }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }