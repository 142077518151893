import { resizeTemplateHandler } from '@frontend/group/modules/copy-and-resize/handlers/resize-template-handler';
import { changeNameOfTemplate, changeTemplateCanvasElement } from '@frontend/group/modules/copy-and-resize';
import {getTypeDimension} from "@frontend/group/modules/copy-and-resize/helpers";

export const createCanvasResizeControl = (canvasEl) => {
  const resizeControl = document.createElement('div');
  resizeControl.style.width = "16px";
  resizeControl.style.height = "16px";
  resizeControl.style.position = "absolute";
  resizeControl.style.bottom = "0px";
  resizeControl.style.right = "0px";
  resizeControl.style.backgroundColor = "black";
  resizeControl.style.cursor = "se-resize";
  resizeControl.style.border = "1px solid white";

  resizeControl.addEventListener('mousedown', e => {
    e.preventDefault();

    const onMouseMove = e => {
      const preview = window.previews.find(_preview => _preview.canvas === canvasEl);

      if (!preview) {
        onMouseUp();
        return;
      }
      
      const newWidth = Math.round(e.clientX - canvasEl.wrapperEl.getBoundingClientRect().left - (canvasEl.wrapperEl.getBoundingClientRect().width - canvasEl.width));
      const newHeight = Math.round(e.clientY - canvasEl.wrapperEl.getBoundingClientRect().top  - (canvasEl.wrapperEl.getBoundingClientRect().height - canvasEl.height));

      resizeTemplateHandler({
        dimension: {
          width: newWidth,
          height: newHeight,
          type: getTypeDimension(newWidth, newHeight, { width: canvasEl.width, height: canvasEl.height })
        },
        state: {},
        preview
      });
      changeNameOfTemplate(newWidth, newHeight, preview);
      changeTemplateCanvasElement(newWidth, newHeight, preview);
    }
    
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  });

  canvasEl.wrapperEl.appendChild(resizeControl)
}
