var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-input" },
    [
      _c("b-form-file", {
        ref: "svgFileInput",
        staticClass: "mb-2",
        attrs: { accept: _vm.accept, placeholder: _vm.filePlaceholder },
        on: { input: _vm.onFileSelected },
      }),
      _vm._v(" "),
      _vm.selectedFile
        ? _c(
            "b-button",
            {
              staticClass: "clear-input-btn",
              attrs: { variant: "light" },
              on: {
                click: function ($event) {
                  return _vm.$bvModal.show(`modal-warning-${_vm.suffix}`)
                },
              },
            },
            [
              _c("b-icon", {
                staticClass: "icon-clear-input",
                attrs: { icon: "x-circle" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: `modal-warning-${_vm.suffix}`,
            "hide-backdrop": "",
            "content-class": "shadow",
            title: "",
          },
          on: { ok: _vm.clearCanvas },
        },
        [
          _c("p", { staticClass: "my-2" }, [
            _vm._v(
              "\n      Your changes will be lost! Are you sure you want to clear the workspace?\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }