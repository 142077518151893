<template>
  <b-dropdown
    v-b-tooltip.hover
    text=""
    variant="outline-primary"
    class="shapes-dd"
    size="sm"
    no-caret
    title="Shapes"
  >
    <template #button-content>
      <svg
        class="b-icon bi"
        width="1em"
        height="1em"
        fill="currentColor"
      >
        <use href="/img/icons/sprite.svg#shapes-svg" />
      </svg>
    </template>

    <b-form-checkbox
      v-model="isBorder"
      class="border-wrapper"
      :value="true"
      :unchecked-value="false"
    >
      Border
    </b-form-checkbox>

    <div
      v-show="isBorder"
    >
      <b-input-group
        class="n-input mr-3"
        append="px"
      >
        <b-form-input
          type="number"
          :value="borderSize"
          @input="changeBorderSize"
        />
      </b-input-group>

      <div class="input-group range-wrapper">
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          :value="borderSize"
          @input="changeBorderSize"
        >
      </div>
    </div>

    <b-dropdown-item
      @click="clearShape"
    >
      <b-icon
        class="mr-2 transparent-icon"
        icon="circle-fill"
      />
      None
    </b-dropdown-item>
    
    <b-dropdown-item
      v-for="shape in shapes"
      :key="shape.name"
      @click="selectShape(shape.name)"
    >
      <b-icon
        :class="shape.class"
        :icon="shape.icon"
      />
      {{ shape.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShapesDD',

  props: {
    canvas: {
      type: Object,
      default: null,
    }
  },

  data: () => ({
    shapes: [
      { name: 'Circle', icon: 'circle-fill', class: 'mr-2' },
      { name: 'Ellipse', icon: 'circle-fill', class: 'ellipse-icon' },
      { name: 'Rect', icon: 'square-fill', class: 'mr-2' },
      { name: 'Triangle', icon: 'triangle-fill', class: 'mr-2' },
    ],
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'borderSize',
      'isBorderShape'
    ]),

    isBorder: {
      get() {
        return this.isBorderShape;
      },
      set(value) {
        this.setIsBorderShape(value);
      }
    },
  },

  methods: {
    ...mapActions('svgEditor', [
      'setBorderSize',
      'setIsBorderShape'
    ]),

    selectShape(shape) {
      this.canvas.initShapeDrawing(shape);
    },

    clearShape() {
      this.canvas.clearShape();
    },

    changeBorderSize(event) {
      this.setBorderSize(Number(event.target ? event.target.value : event));
    },
  },
};
</script>

<style lang="scss" scoped>
.shapes-dd {

  .ellipse-icon {
    font-size: 110% !important;
    margin: 0 10px 0 1px;
    transform: scaleX(1.3);
  }

  .transparent-icon {
    color: transparent;
  }

  .range-wrapper {
    padding: 10px 6px 10px 24px;
  }

  .n-input {
    padding: 5px 23px 5px 24px;
    width: 100%;
  }
  
  .border-wrapper {
    margin: 0 0 0 24px;
    
    label {
      line-height: 25px;
      padding-left: 7px;
    }
  }
}
</style>