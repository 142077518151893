var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-body d-flex justify-content-between" },
    [
      _c(
        "div",
        { staticClass: "w-100" },
        [
          !_vm.isError
            ? _c(
                "b-progress",
                { staticClass: "w-100", attrs: { max: 1 } },
                [
                  _c("b-progress-bar", {
                    attrs: {
                      animated: "",
                      value: _vm.progress,
                      label: `${(_vm.progress * 100).toFixed(0)}%`,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-alert", { attrs: { variant: "danger", show: _vm.isError } }, [
            _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-100 d-flex justify-content-end" },
        [
          _c("b-button", { on: { click: _vm.cancel } }, [
            _vm._v("\n      Cancel\n    "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }