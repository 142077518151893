var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "a-color-picker" },
    [
      _c(
        "b-button",
        {
          ref: "colorPickerBtn",
          staticClass: "rounded color-input-btn",
          attrs: { variant: "light" },
        },
        [_c("div", { staticClass: "a-color-picker__preview" })]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        { attrs: { target: _vm.popoverTarget, triggers: "focus" } },
        [
          _c("Compact", {
            attrs: { value: _vm.value, palette: _vm.PALETTE },
            on: { input: _vm.onChange },
          }),
          _vm._v(" "),
          _c("Chrome", {
            attrs: { value: _vm.value },
            on: { input: _vm.onChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }