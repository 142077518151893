var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MultipleSelect",
    _vm._g(
      _vm._b(
        {
          staticClass: "multple-select-jquery form-control",
          attrs: { multiple: "multiple" },
        },
        "MultipleSelect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }