var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Left %")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.left,
              expression: "input.left",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.input.left },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "left", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Top %")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.top,
              expression: "input.top",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.input.top },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "top", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "formControlRange" } }, [
          _vm._v("Width %"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.right,
              expression: "input.right",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.input.right },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "right", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "formControlRange" } }, [
          _vm._v("Height %"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.input.bottom,
              expression: "input.bottom",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.input.bottom },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.input, "bottom", _vm._n($event.target.value))
              },
              _vm.onInput,
            ],
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }