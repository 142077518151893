export const matchesBoundingBox = ({
    nodeCoordinates,
    boundingBoxes,
    coordinatesTolerance = 150,
    sizeTolerance = 400,
  }) => {
    const matches = boundingBoxes.find(boundingBox => {
      const boxCoordinates = {
        x: boundingBox.boundingBox.x,
        y: boundingBox.boundingBox.y,
        w: boundingBox.boundingBox.w,
        h: boundingBox.boundingBox.h,
      };

      return (
        (nodeCoordinates.x >= boxCoordinates.x - coordinatesTolerance && nodeCoordinates.x <= boxCoordinates.x + coordinatesTolerance)
        && (nodeCoordinates.y >= boxCoordinates.y - coordinatesTolerance && nodeCoordinates.y <= boxCoordinates.y + coordinatesTolerance)
        && (nodeCoordinates.w >= boxCoordinates.w - sizeTolerance && nodeCoordinates.w <= boxCoordinates.w + sizeTolerance)
        && (nodeCoordinates.h >= boxCoordinates.h - sizeTolerance && nodeCoordinates.h <= boxCoordinates.h + sizeTolerance)
      );
    });

    return {
        matches: !!matches,
        boundingBoxes: !!matches
            ? boundingBoxes.filter(boundingBox => boundingBox.id !== matches.id)
            : boundingBoxes,
    };
  };