<template>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Left %</label>
        <input
          v-model.number="input.left"
          type="number"
          class="form-control"
          @input="onInput"
        >
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Right %</label>
        <input
          v-model.number="input.right"
          type="number"
          class="form-control"
          @input="onInput"
        >
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="formControlRange">Top %</label>
        <input
          v-model.number="input.top"
          type="number"
          class="form-control"
          @input="onInput"
        >
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="formControlRange">Bottom %</label>
        <input
          v-model.number="input.bottom"
          type="number"
          class="form-control"
          @input="onInput"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_MASK, DEFAULT_OBJECT_MASKS } from '@frontend/group/modules/mask-settings';
import { transformObjectDataToPercent, transformObjectDataToInteger } from '@frontend/group/modules/mask-settings/helpers';

export default {
  props: {
    objectData: {
        type: Object,
        default: () => transformObjectDataToPercent(
          DEFAULT_OBJECT_MASKS()[TYPE_MASK.ELLIPSE]
        )
    }
  },
  data() {
    return {
      input: {
        ...transformObjectDataToPercent(
          DEFAULT_OBJECT_MASKS()[TYPE_MASK.ELLIPSE]
        )
      }
    }
  },
  watch: {
    objectData() {
      this.input = transformObjectDataToPercent({ ...this.objectData });
    }
  },
  methods: {
    onInput() {
      this.$emit('input', {
        typeMask: TYPE_MASK.ELLIPSE,
        objectData: transformObjectDataToInteger({ ...this.input })
      });
    }
  }
}
</script>