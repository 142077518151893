var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "folderTreeSelect", staticClass: "folder-tree-select" },
    [
      _c("b-form-input", {
        attrs: { placeholder: _vm.placeholder },
        on: { click: _vm.handleOpenDropdown, input: _vm.handleSearchFolder },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
      _vm._v(" "),
      _c("b-icon", {
        staticClass: "folder-tree-select__triangle-icon",
        class: { "folder-tree-select__triangle-icon--opened": _vm.isVisible },
        attrs: { icon: "triangle-fill" },
      }),
      _vm._v(" "),
      _vm.selectedFolder
        ? _c("b-icon", {
            staticClass: "folder-tree-select__remove-icon",
            attrs: { icon: "x" },
            on: { click: _vm.handleRemove },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "folder-tree-select__dropdown",
          class: { "folder-tree-select__dropdown--opened": _vm.isVisible },
        },
        [
          _vm.folders.length
            ? _vm._l(_vm.folders, function (folder) {
                return _c("FolderTreeSelectItem", {
                  key: folder.id,
                  attrs: { item: folder, selected: _vm.selectedFolder },
                  on: { change: _vm.handleFolderChange },
                })
              })
            : [
                _c("div", { staticClass: "px-2" }, [
                  _vm._v("\n        No folders found.\n      "),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }