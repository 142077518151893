<template>
  <b-modal
    id="shadow-group-name-modal"
    :title="title"
    centered
    :ok-title="submitBtnTitle"
    @ok="$emit('submit', inputValue)"
    @show="init"
  >
    <b-form-input
      v-model="inputValue"
      :placeholder="placeholder"
    />
  </b-modal>
</template>

<script>
export default {
  name: 'ShadowGroupNameModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    submitBtnTitle: {
      type: String,
      default: 'Ok'
    }
  },
  data: () => ({
    inputValue: ''
  }),
  methods: {
    init() {
      this.inputValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
