<template>
  <b-input-group
    size="sm"
  >
    <div>
      <Multiselect
        v-model="selectedColor"
        label="text"
        track-by="value"
        :options="colorsWithEmpty"
        :searchable="false"
        :show-labels="false"
      >
        <template #singleLabel="{option: { text, value }}">
          <span
            class="color-square mr-1"
            :style="`background: ${getStyleBackgroundColor(value)}`"
          />
          <span>{{ text }}</span>
        </template>
        <template #placeholder>
          <span
            v-if="modelValue"
            class="color-square mr-1"
            :style="`background: ${getStyleBackgroundColor(modelValue)}`"
          />
          <span>{{ modelValue }}</span>
        </template>
        <template #option="{ option: { text, value } }">
          <span
            class="color-square mr-1"
            :style="`background: ${getStyleBackgroundColor(value)}`"
          />
          <span>{{ text }}</span>
        </template>
      </Multiselect>
    </div>
    <b-input-group-append>
      <b-button
        ref="colorPickerBtn"
      >
        <b-icon icon="brush" />
      </b-button>
    </b-input-group-append>
    <b-popover
      :target="popoverTarget"
      triggers="focus"
    >
      <Compact
        v-model="hexColor"
        :palette="palette"
      />
      <div v-if="customColor">
        <Chrome
          v-model="hexColor"
        />
        <b-button
          v-if="selectedTheme && selectedTheme.name !== 'Custom'"
          ref="saveBtn"
          size="sm"
          :disabled="colorExists"
          @click="saveColor"
        >
          Save color to theme
        </b-button>
      </div>
    </b-popover>
  </b-input-group>
</template>

<script>
import { Chrome, Compact } from 'vue-color'
import { mapGetters } from 'vuex'
import { colorNameToHex, getInfoColorTheme } from '../services/helpers'
import Multiselect from 'vue-multiselect'
import { BACKGROUND_COLOR_TYPES } from '../../backend/constants/theme-background-color-types';

export default {
  name: 'ColorPicker',

  components: {
    Chrome,
    Compact,
    Multiselect,
  },

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('theme', [
      'selectedTheme',
    ]),

    colorsWithEmpty() {
      return [
        { text: '', value: '' },
        ...this.colors.map(({ text, value }) => ({
          value,
          text: text || value
        }))
      ]
    },

    hexColor: {
      get () {
        return this.modelValue
      },
      set (val) {
        const color = val.hex?.toLowerCase() ?? val
        this.$emit('change', color)
      },
    },

    selectedColor: {
      get () {
        return _.find(this.colorsWithEmpty, ['value', this.hexColor])
      },
      set ({ value }) {
        this.hexColor = value
      },
    },

    palette () {
      return _.map(this.colorsWithEmpty, 'value')
    },

    customColor () {
      return !this.selectedTheme || (this.selectedTheme?.is_font_color_selector || this.selectedTheme.name === 'Custom')
    },

    colorExists () {
      return this.palette.includes(this.hexColor)
    },
  },

  created () {
    this.color = (this.modelValue ? colorNameToHex(this.modelValue) : null) || this.palette[0] || ''
  },

  methods: {
    async saveColor () {
      this.$refs.saveBtn.disabled = true
      try {
        const { data } = await axios.put(`video/themes/${this.selectedTheme.id}/add-font-color`, {
          color: this.modelValue,
        })
        this.selectedTheme.font_colors = data
        this.$emit('theme-changed')
      } catch (e) {
        toastr.error(e)
      } finally {
        this.$refs.saveBtn.disabled = false
      }
    },

    popoverTarget () {
      return this.$refs.colorPickerBtn
    },
    getStyleBackgroundColor(value) {
      const { type, color1, color2 } = getInfoColorTheme(value);

      switch(type) {
        case BACKGROUND_COLOR_TYPES.SOLID:
          return color1;
        case BACKGROUND_COLOR_TYPES.ANIMATION:
        case BACKGROUND_COLOR_TYPES.GRADIENT:
          return `linear-gradient(90deg, ${color1} 0%, ${color2} 100%);`;
        default:
          return value
      }
    }
  },
}
</script>

<style scoped lang="scss">
.vc-compact {
  width: 225px;
}

.multiselect {
  min-height: calc(1.5em + 0.5rem + 2px);
  min-width: 104px;
  font-size: 0.875rem;

  ::v-deep &__select {
    height: calc(1.5em + 0.5rem + 2px);
    width: 20px;
    padding-right: unset;
  }

  ::v-deep &__tags {
    min-height: calc(1.5em + 0.5rem + 2px);
    max-height: calc(1.5em + 0.5rem + 5px);
    padding: 0.25rem 12px 0.25rem 7px;
  }

  ::v-deep &__single {
    font-size: 0.875rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  ::v-deep &__option {
    display: flex;
    align-items: center;
    padding: 6px;
    min-height: calc(1.5em + 0.5rem + 2px);
  }

  ::v-deep &__placeholder {
    display: flex;
    align-items: center;
  }
}

.color-square {
  display: inline-block;
  width: 15px;
  height: 15px;
}
</style>
