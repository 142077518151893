import { render, staticRenderFns } from "./GridItem.vue?vue&type=template&id=be0cf444&scoped=true"
import script from "./GridItem.vue?vue&type=script&lang=js"
export * from "./GridItem.vue?vue&type=script&lang=js"
import style0 from "./GridItem.vue?vue&type=style&index=0&id=be0cf444&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be0cf444",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/appdata/www/radzs_diego/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be0cf444')) {
      api.createRecord('be0cf444', component.options)
    } else {
      api.reload('be0cf444', component.options)
    }
    module.hot.accept("./GridItem.vue?vue&type=template&id=be0cf444&scoped=true", function () {
      api.rerender('be0cf444', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/modules/file-browser-product-selector/browser/GridItem.vue"
export default component.exports