<template>
  <b-modal
    :id="MODAL_ID"
    ref="modal"
    title="Mask Settings"
    size="lg"
    hide-backdrop
    ok-title="Save"
    centered
    ignore-enforce-focus-selector=".swal2-container"
    @ok="onSave"
  >
    <b-row>
      <b-col class="d-flex justify-content-center">
        <canvas
          ref="maskPreview"
          width="480"
          height="270"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-row>
          <label>Type Mask</label>
          <b-form-select
            :value="typeMask"
            @change="onChangeTypeMask"
          >
            <b-form-select-option
              v-for="option in typeMaskOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.title }}
            </b-form-select-option>
          </b-form-select>
        </b-form-row>
      </b-col>
      <b-col cols="6">
        <b-form-group class="w-100">
          <template
            #label
          >
            Shadow Presets
          </template>
          <div class="presets-select-wrapper">
            <Multiselect
              v-model="selectedPreset"
              :options="shadowPresets"
              label="name"
              track-by="id"
              :allow-empty="false"
              deselect-label="Selected"
            />
            <span
              v-b-tooltip.hover
              class="d-inline-block"
              tabindex="0"
              title="Apply"
            >
              <b-button
                v-if="selectedPreset.id"
                type="button"
                variant="primary"
                :disabled="isApplyBtnDisabled"
                @click="applyPreset"
              >
                <b-icon icon="check-lg" />
              </b-button>
            </span>
            <b-button
              v-b-tooltip.hover
              :title="selectedPreset.id ? `Edit`: 'Save'"
              type="button"
              variant="outline-primary"
              @click="saveShadowPreset"
            >
              <b-icon
                v-if="!selectedPreset.id"
                icon="save-fill"
              />
              <b-icon
                v-else
                icon="pencil-fill"
              />
            </b-button>
            <b-button
              v-if="selectedPreset.id"
              v-b-tooltip.hover
              title="Delete"
              type="button"
              variant="outline-danger"
              @click="deletePreset"
            >
              <b-icon icon="bucket-fill" />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    
    
    <component
      :is="getInputsView"
      v-if="typeMask !== TYPE_MASK.NONE"
      :object-data="objectData"
      @input="onChangeObjectData"
    />
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { maskSettingsEventBroker, EVENTS } from '@frontend/group/modules/mask-settings/event-broker';
import {
  TYPE_MASK,
  initPreview,
  setTypeMask,
  prepareTypeMask,
  DEFAULT_OBJECT_MASKS
} from '@frontend/group/modules/mask-settings';
import InputsCircleTypeMask from './mask-settings-components/InputsCircleTypeMask.vue';
import InputsEllipseTypeMask from './mask-settings-components/InputsEllipseTypeMask.vue';
import InputsRectangleTypeMask from './mask-settings-components/InputsRectangleTypeMask.vue';
import InputsShadowTypeMask from './mask-settings-components/InputsShadowTypeMask.vue';
import shadowPresetApi from "@frontend/services/api/shadow-preset";

const MODAL_ID = 'mask-settings-modal';

const INPUTS_VIEW_MAP = {
  [TYPE_MASK.CIRCLE]: InputsCircleTypeMask,
  [TYPE_MASK.ELLIPSE]: InputsEllipseTypeMask,
  [TYPE_MASK.RECTANGLE]: InputsRectangleTypeMask,
  [TYPE_MASK.SHADOW_FILTER]: InputsShadowTypeMask,
}

const CREATE_NEW_PRESET_OPTION = {
  name: 'Create New',
  id: ''
};

export default {
  components: {
    InputsCircleTypeMask,
    InputsEllipseTypeMask,
    InputsRectangleTypeMask,
    InputsShadowTypeMask,
    Multiselect
  },
  data() {
    return {
      MODAL_ID,
      TYPE_MASK,
      typeMask: TYPE_MASK.NONE,
      typeMaskOptions: [
        {
          value: TYPE_MASK.NONE,
          title: 'None'
        },
        {
          value: TYPE_MASK.CIRCLE,
          title: 'Circle'
        },
        {
          value: TYPE_MASK.ELLIPSE,
          title: 'Eclipse'
        },
        {
          value: TYPE_MASK.RECTANGLE,
          title: 'Square'
        },
        {
          value: TYPE_MASK.SHADOW_FILTER,
          title: 'Shadow Filter'
        },
      ],
      objectMasks: null,
      objectData: null,
      previouslySelectedPreset: null,
      selectedPreset: CREATE_NEW_PRESET_OPTION,
      shadowPresets: []
    };
  },
  computed: {
    getInputsView() {
      return INPUTS_VIEW_MAP[this.typeMask];
    },
    isApplyBtnDisabled: {
      get() {
        return this.previouslySelectedPreset === this.selectedPreset;
      },
      set(selectedPresetId) {
        this.previouslySelectedPreset = selectedPresetId;
      }
    }
  },
  async mounted() {
    maskSettingsEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },
  methods: {
    async initPresetList() {
      const presetsResponse = await shadowPresetApi.getAll({type: this.typeMask});
      this.shadowPresets = [
        CREATE_NEW_PRESET_OPTION,
        ...presetsResponse.data
      ];
      
      this.setPreviouslySelectedPreset(this.objectData);
    },
    setPreviouslySelectedPreset(objectData) {
      if (!objectData) {
        return this.selectedPreset = CREATE_NEW_PRESET_OPTION;
      }
      
      this.previouslySelectedPreset = this.shadowPresets.find(({ settings }) => JSON.stringify(objectData) === settings);
      
      if (this.previouslySelectedPreset) {
        this.selectedPreset = this.previouslySelectedPreset;
        
        return;
      }
      
      this.selectedPreset = CREATE_NEW_PRESET_OPTION;
    },
    async init({instanceId, object, typeMask, objectData}) {
      this.$bvModal.show(MODAL_ID);

      this.objectMasks = DEFAULT_OBJECT_MASKS();
      this.instanceId = instanceId;
      this.typeMask = prepareTypeMask(typeMask);
      await this.initPresetList();
      if (objectData) {
        this.objectMasks[this.typeMask] = objectData;
      }

      this.$nextTick(() => {
        this.object = object;
        this.objectData = objectData;
        this.setPreviouslySelectedPreset(objectData)
        this.initPreview(object);
        this.onChangeTypeMask(this.typeMask);
        this.initDraggable();
      });

    },
    initDraggable() {
      const $element = $(`#${MODAL_ID}`);

      $element.draggable({
        handle: '.modal-header',
      });
    },
    initPreview(object) {
      const canvasElement = this.$refs.maskPreview;
      initPreview(canvasElement, object);
    },
    onChangeTypeMask(typeMask) {
      this.objectData = this.objectMasks[typeMask] || null;
      // this.setPreviouslySelectedPreset(this.objectData);
      setTypeMask(typeMask, this.objectData);
      this.typeMask = typeMask;
      this.initPresetList();
    },
    onChangeObjectData(data) {
      this.objectMasks[data.typeMask] = data.objectData;
      this.objectData = this.objectMasks[this.typeMask] || null;
      // this.selectedPreset = '';
      setTypeMask(this.typeMask, this.objectData);
    },
    onSave() {
      maskSettingsEventBroker.fire(EVENTS.SAVE, {
        id: this.object.id,
        instanceId: this.instanceId,
        typeMask: this.typeMask,
        objectData: this.objectData,
      });
    },
    applyPreset() {
      this.onChangeObjectData({
        typeMask: this.typeMask,
        objectData: JSON.parse(this.shadowPresets.find(({id}) => this.selectedPreset.id && id === this.selectedPreset.id).settings)
      })
      
      this.isApplyBtnDisabled = this.selectedPreset;
      
      toastr.success("Shadow preset applied");
    },
    async saveShadowPreset() {
      const selectedPreset = this.shadowPresets.find(preset => this.selectedPreset.id && preset.id === this.selectedPreset.id);
      const swalData = await Swal.fire({
        title: selectedPreset ? 'Edit Shadow Preset' : `Save Shadow Preset`,
        html: `<div class="d-flex flex-column">
          <div>
            <input type="text" id="preset-name" class="form-control" placeholder="name" value="${selectedPreset ? selectedPreset.name : ''}">
          </div>
          <div class="form-check ${!selectedPreset ? 'd-none' : ''}">
            <input type="checkbox" class="form-check-input" id="update-only-name">
            <label for="update-only-name" class="form-check-label">update name only</label>
          </div>
        </div>`,
        inputValue: selectedPreset ? selectedPreset.name : '',
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,

        preConfirm: () => {
          const name = document.getElementById('preset-name').value
          const updateOnlyName = document.getElementById('update-only-name').checked
          return {name, updateOnlyName};
        },
      });
      if (!swalData.isConfirmed) return;
      const data = {
        name: swalData.value.name,
        settings: JSON.stringify(this.objectData),
        type: this.typeMask
      };
      data.settings = JSON.stringify(this.objectData)
      if (swalData.value.updateOnlyName) {
        delete data.settings;
      }
      if (selectedPreset) {
        await shadowPresetApi.update(this.selectedPreset.id, data);
        Object.assign(this.shadowPresets.find(({id}) => id === this.selectedPreset.id), data);
        toastr.success("Shadow preset updated");
      } else {
        const {data: preset} = await shadowPresetApi.create(data);
        this.shadowPresets.push(preset);
        this.selectedPreset = preset;
        this.isApplyBtnDisabled = this.selectedPreset;
        toastr.success("Shadow preset saved");
      }
    },
    async deletePreset() {
      await shadowPresetApi.delete(this.selectedPreset.id);
      this.shadowPresets.splice(this.shadowPresets.findIndex(({id}) => id === this.selectedPreset.id), 1);
      this.selectedPreset = CREATE_NEW_PRESET_OPTION;
    }
  }
}
</script>

<style lang="scss">
#mask-settings-modal {
  max-width: 670px
}

.presets-select-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .multiselect {
    max-width: 230px;
  }
}
</style>
