<template>
  <b-modal
    id="svg-preview-modal"
    title="Preview"
    size="xl"
    centered
  >
    <div class="content">
      <div v-html="modifiedSvgText" />

      <div
        v-if="isInnerGTags()"
        class="group-list"
      >
        <ul>
          <li
            v-for="(gTag, index) in innerGTags"
            :key="index"
          >
            <div
              :class="{ 'item': isRemovedTag(index), 'item selected': !isRemovedTag(index) }"
              @click="removeGTag(index)"
            >
              {{ getTagDisplayName(gTag, index) }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-end justify-content-end">
          <button
            class="btn btn-secondary mr-2"
            @click="hide"
          >
            Close
          </button>
          <button
            class="btn btn-primary mr-2"
            @click="onSaveLHS"
          >
            Close & Update Layers
          </button>
          <button
            class="btn btn-primary mr-5"
            @click="onSave"
          >
            Save
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
const SVG_DATA_IMAGE = 'data:image/svg+xml;base64,';

export default {
  name: 'SvgPreviewEditor',

  props: {
    svgText: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    removedGTags: [],
  }),

  computed: {
    ...mapGetters('svgEditor', [
      'getProductCanvasInstance',
      'getSvgCanvasInstance',
      'isInteractiveMode',
    ]),
    
    innerGTags() {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(this.svgText, 'image/svg+xml');
      
      return this.getElements(svgDoc, 'g');
    },

    modifiedSvgText() {
      if (!this.svgText) return;

      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(this.svgText, 'image/svg+xml');
      const elements = this.getElements(svgDoc, 'g');

      this.removedGTags.forEach(indexToRemove => {
        const gElemToRemove = elements[indexToRemove];

        if (gElemToRemove) {
          gElemToRemove.parentNode.removeChild(gElemToRemove);
        }
      });

      const serializer = new XMLSerializer();

      return serializer.serializeToString(svgDoc);
    },
  },

  methods: {
    getElements(svgDocDOMParserInstance, tag) {
      const { children: svgChildren } = svgDocDOMParserInstance.documentElement;
      const gElements = Array.from(svgChildren).filter(({ tagName }) => tagName === tag);

      return gElements.length > 1 ? gElements : gElements.length ? Array.from(gElements[0].children) : [];
    },
    
    show() {
      this.$bvModal.show('svg-preview-modal');
      this.removedGTags = [];
    },

    hide() {
      this.$bvModal.hide('svg-preview-modal');
    },

    isInnerGTags() {
      return this.innerGTags.length > 0;
    },

    getTagDisplayName(gTag, index) {
      return gTag.getAttribute('data-name') ? gTag.getAttribute('data-name') : `group ${index + 1}`;
    },

    removeGTag(index) {
      const indexOfRemoved = this.removedGTags.indexOf(index);

      if (indexOfRemoved === -1) {
        this.removedGTags.push(index);
      } else {
        this.removedGTags.splice(indexOfRemoved, 1);
      }
    },

    isRemovedTag(index) {
      return this.removedGTags.includes(index);
    },

    onSaveLHS() {
      const svgCanvasInstance = this.getSvgCanvasInstance;
      svgCanvasInstance.modifiedSvgXml = unescape(encodeURIComponent(this.modifiedSvgText));
      svgCanvasInstance.modifiedSvgUrl = `${SVG_DATA_IMAGE}${btoa(svgCanvasInstance.modifiedSvgXml)}`;
      svgCanvasInstance.removedGTagsIndexes = this.removedGTags;

      if (this.isInteractiveMode) {
        svgCanvasInstance.loadSvgFromLayers(svgCanvasInstance.modifiedSvgXml);
      } else {
        svgCanvasInstance.pasteImageFromUrl(svgCanvasInstance.modifiedSvgUrl);
      }
      
      this.hide();
    },

    onSave() {
      const ProductCanvas = this.getProductCanvasInstance;
      const svgData = unescape(encodeURIComponent(this.modifiedSvgText));
      const imgSrc = `${SVG_DATA_IMAGE}${btoa(svgData)}`;
      ProductCanvas.pasteImageFromUrl(imgSrc);
      this.hide();
    },
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .modal-dialog {
    max-width: 100%;

    .modal-body {
      display: flex;
      justify-content: center;
      overflow-y: auto;
    }
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .group-list {
    position: fixed;
    top: 120px;
    right: 30px;
    max-height: 310px;
    min-width: 140px;
    overflow-y: auto;
    background-color: #f6f6f6;
    border-radius: 9px;

    ul {
      list-style-type: none;
      margin: 5px 0 5px 0;
      padding: 0;

      li {
        cursor: pointer;
        user-select: none;

        .item {
          padding: 0 10px;
          line-height: 30px;

          &.selected {
            background-color: #209c86;
            color: white;
          }
        }
      }
    }
  }
}
</style>
