
export const COLOR_TYPE = 'Color';
export const LINEAR_GRADIENT_COLOR_TYPE = 'Linear';
export const RADIAL_GRADIENT_COLOR_TYPE = 'Radial';
export const BEHIND_DISPLAY_MODE = 'behind';
export const IN_FRONT_DISPLAY_MODE = 'in front';
export const LEFT_LINEAR_GRADIENT_DIRECTION = 'left',
  RIGHT_LINEAR_GRADIENT_DIRECTION ='right',
  TOP_LINEAR_GRADIENT_DIRECTION ='top',
  BOTTOM_LINEAR_GRADIENT_DIRECTION = 'bottom';
export const DEFAULT_COLOR_STOPS = [
  { offset: 0, color: '#FF0000' },
  { offset: 1, color: '#0000FF'}
]
export const DEFAULT_COLOR = '#000000'