<template>
  <b-button
    v-b-tooltip.hover
    variant="outline-primary"
    :title="target.locked ? 'Unlock' : 'Lock'"
    @click="toggle"
  >
    <b-icon :icon="target.locked ? 'lock-fill' : 'unlock'" />
  </b-button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LockToggle',

  computed: {
    ...mapGetters('layout', [
      'target',
    ]),
  },

  methods: {
    toggle () {
      this.target.set('locked', !this.target.locked)
      this.target.canvas.fire('locked:changed', this.target)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
