<template>
  <div class="folder-list-container">
    <BreadcrumbItem
      :breadcrumbs="breadcrumbs"
      @goTo="goToFolder"
    />

    <div
      v-if="items.length || isListLoading"
      class="folders-grid"
    >
      <template v-if="!isListLoading">
        <GridItem
          v-for="item in items"
          :key="item.id"
          :item="item"
          :is-selected="isSelected(item)"
          @click="onSelectedItem(item)"
          @dblclick="dblHandler"
        />
      </template>

      <template v-else>
        <div
          v-for="n in pageSize"
          :key="n"
          class="w-100 h-100"
          style="min-height: 167px;"
        >
          <b-skeleton
            width="100%"
            height="88%"
          />
          <b-skeleton />
        </div>
      </template>
    </div>
    <b-alert
      v-else
      show
      variant="warning"
    >
      Folder is empty
    </b-alert>
    
    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="totalCount"
        :disabled="isListLoading"
        @change="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import GridItem from './browser/GridItem.vue';
import { TABS } from "./constants";
import { TYPE } from "../../../constants/file-image-types";
import fetchListDTO, {fetchListDataBasedActionCreator} from "./dto/fetch-list-dto";
import BreadcrumbItem from "../../common/atoms/BreadcrumbItem.vue";
import {IMAGE_TYPE_PRODUCT} from "@frontend/constants/file-upload-images-types";

const {
  mapGetters: mapGettersFileBrowser,
  mapActions: mapActionsFileBrowser,
  mapMutations: mapMutationsFileBrowser,
} = createNamespacedHelpers('fileBrowser');

const {
  mapGetters: mapGettersFileUploadImages
} = createNamespacedHelpers('fileUploadImages');

export default {
  components: {BreadcrumbItem, GridItem },
  props: {
    isOnlyFolder: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedItems: [],
      pageSize: 12,
      breadcrumbs: [],
      currentFolderId: null,
    }
  },
  computed: {
    ...mapGettersFileBrowser([
      'items',
      'totalCount',
      'isListLoading',
    ]),
    ...mapGettersFileUploadImages([
      'getSelectedImageType',
      'getContentType',
      'getTab',
    ]),
    isSelected() {
      const selectedIds = this.selectedItems.map(({ id }) => id);

      return item => selectedIds.includes(item.id);
    }
  },
  mounted() {
    this.setPageSize(this.pageSize);
    this.fetchFolderList();
  },
  methods: {
    ...mapActionsFileBrowser(['fetchList', 'setShowOnlyFolders']),
    ...mapMutationsFileBrowser({
      setPageSize: 'SET_PAGE_SIZE'
    }),
    fetchFolderList() {
      this.setShowOnlyFolders(this.getSelectedImageType === IMAGE_TYPE_PRODUCT)
      this.fetchList(fetchListDataBasedActionCreator(
        this.getTab,
        this.currentFolderId ? this.currentFolderId.toString() : null,
        this.currentPage,
        '',
        this.getContentType,
      ));
    },
    async dblHandler(item) {
      this.currentPage = 1;
      this.setSelectedItems();
      this.currentFolderId = item.id;

      if (item.is_root === true) {
        this.breadcrumbs = [];
      }

      if (item.is_root === false) {
        this.breadcrumbs.pop();
      }

      if (item.created_at) {
        this.breadcrumbs.push({
          id: item.id,
          text: item.name,
        });
      }

      await this.fetchFolderList();
    },
    async goToFolder(folderId, breadcrumbIndex) {
      breadcrumbIndex = folderId?.index;
      folderId = folderId?.id;
      if (this.currentFolderId === folderId) return;

      this.currentPage = 1;
      this.setSelectedItems();
      this.currentFolderId = folderId;
      this.breadcrumbs.splice(breadcrumbIndex);

      await this.fetchFolderList();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchFolderList();
    },
    setSelectedItems(selectedItems = []) {
      this.selectedItems = selectedItems;

      this.$emit('onSelectedItems', selectedItems);
    },
    onSelectedItem(item) {
      if (!item.created_at) return;

      const isSelected = this.isSelected(item);

      if (isSelected) {
        this.selectedItems = this.selectedItems.filter(selected => selected.id !== item.id);
      } else {
        if (this.isMultiple) {
          this.selectedItems.push(item);
        } else {
          this.selectedItems = [item];
        }
      }

      this.setSelectedItems(this.selectedItems);
    }
  }
}
</script>

<style lang="scss">
.folder-list-container {
  .breadcrumb {
    background: unset;
    border-radius: unset;
    border: unset;
  }

  .folders-grid {
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    margin-bottom: 19px;
    grid-template-columns: repeat(4, 1fr) !important;  
  }
}
</style>
