import { rgbToHex } from "../../../frontend/services/helpers";
import Base from "./base";
import {FIELD_TYPES} from "../../../frontend/constants/field-types";
import {Layer} from "@/includes/parse_psd_data/psd-types/layer";
function removeBreakSymbol(text) {
    return text.replaceAll(/[\n\r]/g, '');
}

function removeMultiSpaces(text) {
    // TODO implement this
    return text
}

export default class Text extends Layer {

    text = '';

    angle = 0;

    tracking = 0;

    fontSize = 40;

    fontFamily;

    alignment = 'left';

    color = [0, 0, 0, 0];

    constructor(field) {
        super(field);

        this.text = _.get(field, 'text.value', this.text);
        this.fontSize = _.get(field, 'text.font.size', this.fontSize);
        this.color = _.get(field, 'text.font.color', this.color);
        this.fontFamily = _.get(field, 'text.font.names[0]');
        this.alignment = _.get(field, 'text.font.alignment[0]', this.alignment);
        this.angle = _.get(field, 'text.angle', this.angle);
        this.tracking = _.get(field, 'text.font.tracking', this.tracking);
    }

    getOriginText() { // TODO remove it
        return _.get(this.data, 'text.value', this.text);
    }

    getHEXColor() {
        const [red, green, blue] = this.color;

        return rgbToHex(red, green, blue);
    }

    getText(isTreatMultipleSpaces = false) {
        return isTreatMultipleSpaces ? removeMultiSpaces(this.text) : this.text;
    }

    getTextWithoutMultiLine(isTreatMultipleSpaces) {
        return removeBreakSymbol(this.getText(isTreatMultipleSpaces));
    }

    getFontFamilies() {
        return _.get(this.data, 'text.font.names') || [];
    }

    getFontSize() {
        return this.fontSize;
    }
}