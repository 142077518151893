import {fabric} from "fabric";
import {
  BOTTOM_LINEAR_GRADIENT_DIRECTION,
  LEFT_LINEAR_GRADIENT_DIRECTION, LINEAR_GRADIENT_COLOR_TYPE, RADIAL_GRADIENT_COLOR_TYPE,
  RIGHT_LINEAR_GRADIENT_DIRECTION,
  TOP_LINEAR_GRADIENT_DIRECTION
} from "@frontend/constants/oval-shadows";
export function initOvalShadowBehind(shadows, image, ctx) {
  for (let shadowOptions of shadows) {
    initOvalShadow(image, ctx, shadowOptions);
  }
}
export function initOvalShadowInFront(shadows, image, ctx) {
  for (let shadowOptions of shadows) {
    initOvalShadow(image, ctx, shadowOptions);
  }
}
export function initOvalShadow(image, ctx, options = {
  width: 0.8,
  height: 0.15,
  opacity: 0.5,
  offsetX: 0.1,
  offsetY: 0.9,
  blur: 30,
}) {
  const ellipse = getEllipseBasedOnImage(image, options);
  ctx.save();
  ctx.filter = `blur(${options.blur}px)`;
  ctx.drawImage(
    ellipse,
    (-image.width / 2) + image.width  * options.offsetX,
    (-image.height / 2) + image.height  * options.offsetY
  );
  ctx.restore();
}

function fillShape(ellipse, fillType, linearDirection, colorStops) {
  const LINEAR_GRADIENT_DIRECTION_SETTINGS = {
    [LEFT_LINEAR_GRADIENT_DIRECTION]: {x1: 0, y1: 0, x2: ellipse.width, y2: 0},
    [RIGHT_LINEAR_GRADIENT_DIRECTION]: {x1: ellipse.width, y1: 0, x2: 0, y2: 0},
    [TOP_LINEAR_GRADIENT_DIRECTION]: {x1: 0, y1: 0, x2: 0, y2: ellipse.height},
    [BOTTOM_LINEAR_GRADIENT_DIRECTION]: {x1: 0, y1: ellipse.height, x2: 0, y2: 0},
  }
  
  switch (fillType) {
    case LINEAR_GRADIENT_COLOR_TYPE: {
      const gradientData = {
        type: 'linear',
        gradientUnits: 'pixels', // or 'percentage'
        coords: LINEAR_GRADIENT_DIRECTION_SETTINGS[linearDirection],
        colorStops: colorStops
      }
      ellipse.set('fill', new fabric.Gradient(gradientData));
      break;
    }
    case RADIAL_GRADIENT_COLOR_TYPE: {
      const gradientData = {
        type: 'radial',
        gradientUnits: 'pixels', // or 'percentage'
        coords: {
          x1: ellipse.width / 2,
          y1: ellipse.height / 2,
          x2: ellipse.width / 2,
          y2: ellipse.height / 2,
          r1: 10, // inner circle radius
          r2: (ellipse.width / 2), // outer circle radius
        },
        colorStops: colorStops
      }
      ellipse.set('fill', new fabric.Gradient(gradientData));
      break;
    }
    default:
      break;
  }
}

function getEllipseBasedOnImage(
  image, 
  {width, height, opacity, color, fillType, linearDirection, colorStops}
) {
  
  const ellipse = new fabric.Ellipse({
    left: 0,
    top: 0,
    rx: image.width / 2   * width, // Horizontal radius
    ry: image.height / 2  * height,  // Vertical radius
    fill: color,
    opacity: opacity,
  })
  fillShape(ellipse, fillType, linearDirection, colorStops);

  return ellipse.toCanvasElement();
}