import BaseElement from "./BaseElement";

export default class TableColumn extends BaseElement {

  title;

  constructor(title) {
    super()

    this.styles.setRules({
      'border-width': '1px',
      'border-style': 'solid',
      'padding-top': '2px',
      'padding-bottom': '2px',
      'padding-left': '5px',
      'padding-right': '5px'
    });

    this.title = title;
  }

  setTitle(title) {
    this.title = title;
  }

  toHTML() {
    return `<th style="${this.styles.getStyle()}">${this.title}</th>`;
  }

  toData() {
    return {
      id: this.id,
      stringStyles: this.styles.getStyle(),
      styles: this.styles,
      title: this.title,
    }
  }
}