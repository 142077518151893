var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          staticClass: "prevent-unselect",
          attrs: {
            shadow: "",
            "z-index": "1040",
            title: _vm.selectedInstance?.template.name || "No selected item",
            width: _vm.width,
            right: _vm.right,
          },
          on: {
            change: function ($event) {
              return _vm.$emit("toggle")
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _vm.selectedInstance
            ? _c(
                "b-tabs",
                {
                  staticClass: "no-gutters",
                  attrs: {
                    "nav-wrapper-class": "layout-sidebar-nav-wrapper",
                    "content-class": "px-3 py-2",
                    small: "",
                    pills: "",
                    vertical: "",
                    end: "",
                    "nav-class": _vm.target ? "" : "no-target",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "tabs-end",
                        fn: function () {
                          return [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    value: `${
                                      _vm.right ? "Left" : "Right"
                                    } side`,
                                    expression:
                                      "`${right ? 'Left' : 'Right'} side`",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "my-auto ml-auto mr-1 d-none",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.right = !_vm.right
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: `arrow-bar-${
                                      _vm.right ? "left" : "right"
                                    }`,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2941356894
                  ),
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: { title: "Settings", icon: "sliders" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1875473405
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm.target
                        ? [
                            _vm.typeEditorControl === "TEXT"
                              ? _c("TextEditorControl", {
                                  attrs: {
                                    id: "text-editor-control-settings",
                                    target: _vm.target,
                                    options: _vm.options,
                                    themes: _vm.themes,
                                    "field-type": _vm.fieldType,
                                  },
                                })
                              : _vm.typeEditorControl === "SHAPE"
                              ? _c("ShapeEditorControl", {
                                  attrs: {
                                    target: _vm.target,
                                    themes: _vm.themes,
                                  },
                                })
                              : _vm.typeEditorControl === "IMAGE"
                              ? _c("ImageEditorControl", {
                                  attrs: {
                                    target: _vm.target,
                                    options: _vm.options,
                                    themes: _vm.themes,
                                  },
                                  on: {
                                    toggle: function ($event) {
                                      return _vm.$emit("toggle")
                                    },
                                  },
                                })
                              : _vm.typeEditorControl === "PRODUCT_DIMENSION"
                              ? _c("ProductDimensionEditorControl", {
                                  attrs: {
                                    options: _vm.options,
                                    target: _vm.target,
                                  },
                                })
                              : _vm.typeEditorControl === "GROUP"
                              ? _c("GroupEditorControl", {
                                  attrs: {
                                    options: _vm.options,
                                    target: _vm.target,
                                  },
                                })
                              : _vm.typeEditorControl === "SMART_OBJECT"
                              ? _c("SmartObjectEditorControl", {
                                  attrs: {
                                    options: _vm.options,
                                    preview: _vm.target.preview,
                                    "smart-objects-list":
                                      _vm.target.smartObjectsList,
                                    target: _vm.target,
                                  },
                                })
                              : _vm.typeEditorControl === "NEW_SMART_OBJECT"
                              ? _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Create a new smart object",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: { placeholder: "name" },
                                      model: {
                                        value: _vm.newSmartObjectName,
                                        callback: function ($$v) {
                                          _vm.newSmartObjectName = $$v
                                        },
                                        expression: "newSmartObjectName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          disabled: !_vm.newSmartObjectName,
                                        },
                                        on: { click: _vm.createSmartObject },
                                      },
                                      [
                                        _vm._v(
                                          "\n              create\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: {
                                    title: "Properties",
                                    icon: "gear-wide-connected",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        382685246
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("PropertiesModule", {
                        attrs: {
                          target: _vm.target,
                          options: _vm.modules.properties,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: { title: "Shadows", icon: "back" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3128943184
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("ShadowsModule", { attrs: { target: _vm.target } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: {
                                    title: "Animations",
                                    icon: "collection-play",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2598209684
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("AnimationsModule", {
                        attrs: {
                          target: _vm.target,
                          options: _vm.modules.animation,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { "title-item-class": "instance-related" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: { title: "Fields", icon: "diagram3" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3907081081
                      ),
                    },
                    [_vm._v(" "), _c("FieldsModule")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { "title-item-class": "instance-related" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: { title: "Layers", icon: "layers" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        278230574
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("LayersModule", {
                        ref: "layersModule",
                        attrs: { "is-tab-active": _vm.tabIndex === 5 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.backgroundColorRectangle
                    ? _c(
                        "b-tab",
                        {
                          attrs: { "title-item-class": "instance-related" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "TabTitle",
                                      {
                                        attrs: {
                                          title: "Background Color Editor",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-iconstack",
                                          { staticClass: "overflow-hidden" },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                stacked: "",
                                                icon: "square",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("b-icon", {
                                              attrs: {
                                                stacked: "",
                                                scale: "1.5",
                                                rotate: "-45",
                                                icon: "justify",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2509172747
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("BackgroundColorEditor", {
                            attrs: {
                              height: _vm.backgroundColorRectangle.height,
                              target: _vm.backgroundColorRectangle,
                              width: _vm.backgroundColorRectangle.width,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { "title-item-class": "instance-related" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("TabTitle", {
                                  attrs: { title: "Content", icon: "images" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        318483027
                      ),
                    },
                    [_vm._v(" "), _c("ContentModule")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }