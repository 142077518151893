import {FILE_TYPE} from "../../../constants/file-image-types";

export const SVG_IMPORT_TARGET = 'svg-target';
export const SVG_PDF_IMPORT_TARGET = 'svg-pdf-target';
export const PRODUCT_IMPORT_TARGET = 'product-target';
export const PRODUCT_TIFF_IMPORT_TARGET = 'product-tiff-target';

export const TYPE_IMAGE_BG = 'BG_IMAGE';
export const TYPE_IMAGE = 'IMAGE';

export const IMPORT_TARGET = {
    SVG: SVG_IMPORT_TARGET,
    SVG_PDF: SVG_PDF_IMPORT_TARGET,
    PRODUCT: PRODUCT_IMPORT_TARGET,
    PRODUCT_TIFF: PRODUCT_TIFF_IMPORT_TARGET,
}

export const TARGET_TYPE_MAP = {
    [IMPORT_TARGET.SVG]: [FILE_TYPE.SVG],
    [IMPORT_TARGET.SVG_PDF]: [FILE_TYPE.PDF],
    [IMPORT_TARGET.PRODUCT]: [FILE_TYPE.IMAGE],
    [IMPORT_TARGET.PRODUCT_TIFF]: [FILE_TYPE.TIFF],
}

export const SVG_EDITOR_SUPPORTED_TYPES = (() => Object.keys(TARGET_TYPE_MAP).reduce((acc, current) => acc.concat(TARGET_TYPE_MAP[current]), []))()