<template>
  <b-modal
    id="select-way-import-from-psd"
    title="Import PSD"
    centered
    size="lg"
    @ok="handleOkBtn"
  >
    <b-form-radio-group
      v-model="selectedStrategy"
      name="select-strategy"
      :options="options"
    />
    <SelectTemplateBanner
      v-if="selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY"
      v-model="selectedTemplates"
      class="mt-3"
      :customers="customers"
    />

    <template #modal-footer="{ cancel, ok }">
      <div class="d-flex justify-content-between w-100 align-items-center">
        <div>
          <template v-if="isSamplePsdExist">
            <b-icon
              id="info-icon"
              icon="info-circle"
            />
            <b-tooltip target="info-icon">
              <div class="info-icon-tooltip">
                When editing your PSD file, <br>please use layer naming as shown <br>in the sample PSD file, such as:
                <ul class="info-icon-tooltip__list">
                  <li>Headline 1</li>
                  <li>Headline 2 (optional)</li>
                  <li>Subhead 1 (optional)</li>
                  <li>Image Area</li>
                  <li>Product Image 1</li>
                  <li>Product Image 2</li>
                  <li>Product Image 3</li>
                  <li>Logo</li>
                </ul>
              </div>
            </b-tooltip>
            <a
              class="download-link"
              :href="samplePsdUrl"
              download
            >
              Download sample PSD
            </a>
          </template>
        </div>

        <div class="d-flex">
          <b-button
            @click="cancel"
          >
            Cancel
          </b-button>
          <div class="mx-1" />
          <b-button
            variant="primary"
            :disabled="hasDisabledOkButton"
            @click="ok"
          >
            OK
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { importPsdEventBroker, EVENTS } from '@frontend/group/modules/import-template-from-psd/event-broker';
import SelectTemplateBanner from '@frontend/components/common/atoms/SelectTemplateBanner.vue';
import {
  IMPORT_AND_CREATE_STRATEGY,
  IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY
} from '@frontend/group/modules/import-template-from-psd/import-from-psd-new-template';
import { createNamespacedHelpers } from 'vuex';
import { MUTATIONS } from '@frontend/store/psd-layers';
const { mapMutations } = createNamespacedHelpers('psdLayers');
import SAMPLE_PSD_API from '@frontend/services/api/sample-psd';

export default {
  name: 'SelectWayImportFromPsd',
  components: { SelectTemplateBanner },
  props: {
    customers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      IMPORT_AND_CREATE_STRATEGY,
      IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY,
      selectedStrategy: IMPORT_AND_CREATE_STRATEGY,
      selectedTemplates: {
        customer: null,
        templates: []
      },
      options: [
        { text: 'Create new template', value: IMPORT_AND_CREATE_STRATEGY },
        { text: 'Use existing templates', value: IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY },
      ],
      settings: {
        newTemplate: null
      },
      samplePsdUrl: `${window.location.origin}/share?file=sample-psd-file/sample.psd`,
      isSamplePsdExist: false
    }
  },
  computed: {
    hasDisabledOkButton() {
      return this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY ? !this.selectedTemplates.templates.length : false
    }
  },
  mounted() {
    importPsdEventBroker.on(EVENTS.SELECT_WAY_IMPORT_FROM_PSD.INIT, this.init.bind(this));
  },
  methods: {
    ...mapMutations({
      setIsImportIntoTemplate: MUTATIONS.SET_IS_IMPORT_INTO_TEMPLATE
    }),
    async init({ settings } = {}) {
      if (settings) {
        this.settings = settings;
      }

      this.selectedStrategy = IMPORT_AND_CREATE_STRATEGY;
      this.selectedTemplates = {
        customer: null,
        templates: []
      }
      
      this.isSamplePsdExist = await SAMPLE_PSD_API.checkIfSamplePsdExist(this.samplePsdUrl);
      
      this.$bvModal.show('select-way-import-from-psd');
    },
    handleOkBtn() {
      if (this.settings.newTemplate) {
        return this.handleCreateNewTemplate();
      }

      this.setIsImportIntoTemplate(this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY);
      
      importPsdEventBroker.fire(EVENTS.SELECT_WAY_IMPORT_FROM_PSD.SAVE, {
        selectedSratagy: this.selectedStrategy,
        selectedTemplates: this.selectedTemplates,
      });
    },
    async handleCreateNewTemplate() {
      const newTemplate = this.settings.newTemplate;
      const isImportIntoExistsTemplate = this.selectedStrategy === IMPORT_INTO_EXISTS_TEMPLATE_STRATEGY;

      if (!newTemplate.preselectedPsdFile) {
        console.error('No preselected PSD found.');
        return;
      }

      if (isImportIntoExistsTemplate && this.selectedTemplates.length === 0) {
        console.error('No templates found.');
        return;
      }

      this.isLoading = true;

      const { data } = await axios.post('/banner/0/group/create-layout-from-psd', { file: newTemplate.preselectedPsdFile });

      const query = {
        file_id: data.file_id,
        folder_id: data.folder_id,
        ...(isImportIntoExistsTemplate && {
          templates_ids: this.selectedTemplates.templates.map(selectedTemplate => selectedTemplate.id)
        })
      };
      const querystring = new URLSearchParams(query).toString();

      window.location.href = `/banner/${data.customer_id}/group/${data.layout_id}/show?${querystring}`;
    }
  }
}
</script>

<style lang="scss">
  .download-link {
    color: #3c4b64;
    text-decoration: underline;
  }
  
  .info-icon-tooltip {
    text-align: left;
    
    &__list {
      list-style: none;
      padding-left: 10px;
    }
  }
</style>
