import {updateObject} from "@frontend/group/modules/copy-and-resize/helpers";
import {
  PADDING,
  PADDING_TOP_KEY
} from "@frontend/group/modules/copy-and-resize/handlers/resize-like-grid-handler/constants";

const LARGEST_PERCENT = 0.2

const isTheNextRighterThenPrevious = objects => {
  let prev = undefined
  for (const object of objects) {
    if (!prev) {
      prev = object;
      continue
    }

    if (prev.left > object.left && prev.left + prev.getScaledWidth() > object.left + object.getScaledWidth()) {
      return false
    }
  }

  return true;
}


const checkIntersections = ({width, height, left, top}, objects) => objects.find(_object => {

  const _objectPosition =  _object.getPointByOrigin('left', 'top')
  const leftSideCond = left > _objectPosition.x && left < (_objectPosition.x + _object.getScaledWidth())
  const rightSideCond = left + width > _objectPosition.x && left + width < _objectPosition.x + _object.getScaledWidth()
  const topSideCond = top > _objectPosition.y && top < _objectPosition.y + _object.getScaledHeight()
  const bottomSideCond = top + height > _objectPosition.y && top + height < _objectPosition.y + _object.getScaledHeight()

  return leftSideCond && (topSideCond || bottomSideCond) || rightSideCond && (topSideCond || bottomSideCond)
})

export const interpolateVerticalView = ({ objects, dimension, rsW, rsH }) => {

  const templateArea = (dimension.width * dimension.height)

  const largestObjects = objects.filter(object =>
    object.getScaledHeight() * object.getScaledWidth() / templateArea
    >= LARGEST_PERCENT
  )

  if (!largestObjects.length || !isTheNextRighterThenPrevious(largestObjects) || dimension.width > dimension.height) {
    return;
  }

  const totalHeight = largestObjects.reduce((acc, current) => acc + current.getScaledHeight(), 0)

  if (totalHeight > dimension.height) {
    //TODO reduce scale
  }

  const padding = PADDING[PADDING_TOP_KEY] * dimension.height

  let prevBottomPosition = padding;

  largestObjects.forEach(object => {
    const leftSpace = dimension.width - object.getScaledWidth()

    object.setPositionByOrigin({
      x: leftSpace / 2,
      y: prevBottomPosition // TODO apply padding
    }, 'left', 'top')
    prevBottomPosition = prevBottomPosition + object.getScaledHeight() + padding

    updateObject(object, { x: rsW, y: rsH });
  })

  const smallObjects = objects.filter(object => !largestObjects.includes(object))
  const readyObjects = [...largestObjects]

  smallObjects.forEach(object => {
    const objectPosition = object.getPointByOrigin('left', 'top')
    const objectDataForChecking = {
      width: object.getScaledWidth(),
      height: object.getScaledHeight(),
      left: objectPosition.x,
      top: objectPosition.y,
    }
    const isOverlapping = checkIntersections(objectDataForChecking, readyObjects)

    if (!isOverlapping) {
      readyObjects.push(object)
      return;
    }


    topLoop: for (let top = 0; top < dimension.height - object.getScaledHeight(); top++) {
      for (let left = 0; left < dimension.width - object.getScaledWidth(); left++) {
        if (checkIntersections({
          ...objectDataForChecking,
          left,
          top,
        }, readyObjects)) {
          continue;
        }

        object.setPositionByOrigin({
          x: left,
          y: top,
        }, 'left', 'top')
        updateObject(object, { x: rsW, y: rsH });
        readyObjects.push(object)
        break topLoop;
      }
    }

  })
}