export default {
  data: () => ({
    truncateTextThreshold: 20,
  }),
  methods: {
    truncateFieldName(fieldName) {
      if (this.isNotTruncated(fieldName)) {
        return fieldName;
      }

      return `${fieldName.slice(0, 9).trim()}...${fieldName.slice(-9).trim()}`;
    },
    isNotTruncated (text) {
      return text.length < this.truncateTextThreshold
    },
  }
}
