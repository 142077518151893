<template>
  <div>
    <b-modal
      v-model="open"
      title="Layouts in catalog"
      hide-footer
    >
      <b-tab
        title="Catalogs"
        active
      >
        <b-list-group>
          <p
            v-if="layouts.length === 0"
            class="d-flex justify-content-center"
          >
            No items
          </p>
          <b-list-group-item
            v-for="layout in layouts"
            :key="layout.id"
            class="d-flex justify-content-between catalog-item"
          >
            <div>
              #{{ layout.id }} {{ layout.name }}
            </div>
            <div class="catalog-item__buttons d-flex align-self-center">
              <b-button
                id="remove-layout"
                v-b-tooltip.noninteractive.left
                class="btn-sm"
                variant="outline-danger"
                title="Remove layout from catalog"
                @click.stop="deleteLayout(layout)"
              >
                <i class="bi bi-trash" />
              </b-button>
              <b-button
                v-b-tooltip.noninteractive.left
                class="btn-sm ml-1"
                variant="outline-info"
                title="Open layout in new tab"
                @click.stop="openInNewWindow(layout)"
              >
                <b-icon icon="arrow-right-circle" />
              </b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
        <div class="d-flex justify-content-center mt-2">
          <b-pagination
            v-model="layoutsCurrentPage"
            :total-rows="layoutsCount"
            :per-page="layoutsPerPage"
            @change="getPage"
          />
        </div>
      </b-tab>
    </b-modal>
  </div>
</template>

<script>
import {catalogManagementEventBroker} from "@frontend/group/modules/catalog-management/event-broker";
import CatalogService from '@/frontend/services/api/catalogs'

export default {
  name: 'CatalogLayoutsListsModal',
  data: () => ({
    open: false,
    layouts: [],
    catalogID: undefined,
    layoutsCurrentPage: undefined,
    layoutsCount: undefined,
    layoutsPerPage: undefined,
  }),
  mounted() {
    catalogManagementEventBroker.on('catalogLayoutsListsModal', this.init.bind(this));
  },
  methods: {
    init(catalog) {
      this.catalogID = catalog.id;
      this.open = true;
      this.getLayoutsList(this.catalogID)
    },
    async getPage(page) {
      await this.getLayoutsList(this.catalogID, page);
    },
    async deleteLayout(layout) {
      console.log(layout);
      await CatalogService.unpinLayoutFromCatalog(this.catalogID, layout.id);
      await this.getLayoutsList(this.catalogID, this.layoutsCurrentPage);
      toastr.success('Layout is detached')
    },
    async getLayoutsList(id, page = 1) {
      try {
        const {data} = await CatalogService.getLayouts(id, page);
        this.layouts = data.data;
        this.layoutsPerPage = data.per_page;
        this.layoutsCurrentPage = data.current_page;
        this.layoutsCount = data.total;

      } catch (e) {
        toastr.error('Something went wrong');
      }
    },
    openInNewWindow(layout) {
      let link = '/banner/' + layout.customer_id + '/group/' + layout.id + '/show';
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">

</style>