import TemplateField from "./TemplateField";

const OPTION_CROP = 'crop'
const OPTION_FILL = 'fill'

export default class BackgroundThemeImageMultipleField extends TemplateField {

    constructor(fieldData) {
        super(fieldData)
    }

    isFill() {
        return this.options.option1 === OPTION_FILL
    }

    isCrop() {
        return this.options.option5 === OPTION_CROP
    }
}