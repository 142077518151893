import mediaAPI from '../../services/api/media';
import { getFolderAudioInfo } from '../../services/helpers'

export default {
  namespaced: true,

  state: {
    audio: [],
    tags: [],
    companyAudio: {
      current_page: 1,
      total: 0,
      per_page: 0,
      data: [],
    },
  },

  getters: {
    audio: state => state.audio,
    tags: state => state.tags,
    companyAudio: state => state.companyAudio,
    companyAudioList: state => state.companyAudio.data,
  },

  mutations: {
    FETCH_AUDIO (state, payload) {
      state.audio = payload
    },
    FETCH_TAGS (state, payload) {
      state.tags = payload
    },
    FETCH_COMPANY_AUDIO (state, payload) {
      state.companyAudio = payload
    },
    ADD_COMPANY_AUDIO (state, payload) {
      if (state.companyAudio.data.length + payload.files.length <= state.companyAudio.per_page) {
        state.companyAudio.data.push(...payload.files)
      }
      state.companyAudio.total += payload.files.length
    },
    DELETE_COMPANY_AUDIO (state, payload) {
      const index = _.findIndex(state.companyAudio.data, ['id', payload])
      if (index !== -1) {
        state.companyAudio.data.splice(index, 1)
        state.companyAudio.total--
      }
    },
    SET_COMPANY_AUDIO_PAGE (state, payload) {
      state.companyAudio.current_page = payload
    },
  },

  actions: {
    async fetchAudio ({ commit }, payload) {
      const { data } = await axios.post('/video/audio', payload)
      commit('FETCH_AUDIO', data)
    },
    async fetchTags ({ commit }) {
      const { data } = await axios.get('/video/media/tags', {
        params: {
          name: 'audio',
        },
      })
      commit('FETCH_TAGS', data)
    },
    async fetchCompanyAudio ({ commit, state }) {
      const payload = {
        page: state.companyAudio.current_page,
      }
      const { data } = await mediaAPI.getLibraryAudioFiles(payload)
      const infoList = await getFolderAudioInfo(data.data)
      commit('FETCH_COMPANY_AUDIO', { ...data, data: infoList })
    },
    async uploadCompanyAudio ({ commit }, { formData, config }) {
      const { data } = await mediaAPI.uploadLibraryAudioFiles(formData, config)
      commit('ADD_COMPANY_AUDIO', data)
    },
    async deleteCompanyAudio ({ commit }, payload) {
      await mediaAPI.deleteLibraryAudioFile(payload)
      commit('DELETE_COMPANY_AUDIO', payload)
    },
    async changeCompanyAudioPage ({ commit, dispatch }, payload) {
      commit('SET_COMPANY_AUDIO_PAGE', payload)
      dispatch('fetchCompanyAudio')
    },
    async fetchFolderAudio({ commit }, payload) {
      return await mediaAPI.getFolderAudioFiles(payload);
    },
    async deleteFolderAudio({ commit }, payload) {
      return await mediaAPI.deleteFolderAudioFiles(payload);
    },
  },
}
