<template>
  <div
    class="c-grid-view-item"
  >
    <div
      class="c-grid-view-item__preview"
      @click="$emit('click')"
      @dblclick="$emit('dblclick')"
    >
      <div
        class="c-grid-view-item__preview--item"
      >
        <i class="fas fa-plus-circle add-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GridItem",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('fileBrowserProductSelector', ['mode']),
  },
  methods:{},
}
</script>

<style scoped lang="scss">
  .c-grid-view-item {
    width: 140px;
    height: fit-content;
    user-select: none;

    &__preview {
      float: inherit;
      border: 1px solid #1c7964;
      background: white;
      box-sizing: border-box;
      box-shadow: 0 4px 4px #f8f8f8;
      border-radius: 10px;
      position: relative;
      transition: background-color ease-in-out 0.4s;
      overflow: hidden;

      &--item {
        border: 4px solid transparent;
        height: 90px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-icon {
          color: #6c757dd1;
          font-size: 35px;
        }
        
        &:hover {
          border: 4px solid #95d9cc;
          cursor: pointer;
          background-color: #f9f9f9;
        }

        &.active {
          border: 4px solid #209c86;
        }
      }

      &.active, &:hover {
        border: 1px solid transparent;
      }
    }

    .tooltip {
      position: absolute;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
</style>
