<template>
  <b-dropdown
    class="dropdown-counter"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #button-content>
      <slot />
    </template>
    <div>
      <label v-if="title">{{ title }}</label>

      <b-input-group>
        <b-input-group-prepend>
          <b-btn @click="decrease">
            <i class="cil-minus" />
          </b-btn>
        </b-input-group-prepend>

        <b-form-input
          :value="value"
          type="number"
          :step="step"
          :min="min"
          :max="max"
          @input="onInput"
        />

        <b-input-group-append>
          <b-btn @click="increase">
            <i class="cil-plus" />
          </b-btn>
        </b-input-group-append>
      </b-input-group>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    }
  },

  methods: {
    getValue(value) {
      let _value = +value || 0;

      return this.min > _value ? this.min : (this.max < _value ? this.max : _value);
    },
    onInput(value) {
      let _value = this.getValue(value);

      this.$emit('input', _value)
    },
    decrease() {
      this.$emit('input', this.getValue(+this.value - this.step));
    },
    increase() {
      this.$emit('input', this.getValue(+this.value + this.step));
    }
  }
}
</script>

<style lang="scss">
.dropdown-counter {
  .dropdown-menu {
    min-width: 165px;
    padding: 5px;
  }
}
</style>