var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Job", "label-cols": "auto" } },
            [
              _c("Multiselect", {
                attrs: {
                  label: "name",
                  "track-by": "id",
                  options: _vm.productDescriptionJobs,
                  "allow-empty": false,
                  "deselect-label": "",
                },
                model: {
                  value: _vm.selectedJob,
                  callback: function ($$v) {
                    _vm.selectedJob = $$v
                  },
                  expression: "selectedJob",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-end mb-3",
            },
            [
              _c("div", { staticClass: "find-mark-container mb-0" }),
              _vm._v(" "),
              _vm.anySaveBtnVisible
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "sm" },
                      on: { click: _vm.saveAll },
                    },
                    [_vm._v("\n        Save all\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedProductDescriptionJobId
        ? [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c("b-table", {
                  staticStyle: { "max-height": "calc(100vh - 490px)" },
                  attrs: {
                    "sticky-header": "",
                    fields: [
                      { key: "source_data", label: "Original Copy" },
                      { key: "result", label: "Generated Copy" },
                      { key: "actions", label: "", thStyle: "width: 0" },
                    ],
                    items: _vm.prompts,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(source_data)",
                        fn: function (data) {
                          return [
                            _c("div", {
                              staticClass: "text-muted",
                              staticStyle: { "white-space": "break-spaces" },
                              domProps: {
                                textContent: _vm._s(
                                  data.item.variables.source_data
                                ),
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(result)",
                        fn: function (data) {
                          return [
                            _c("contenteditable", {
                              staticClass: "generated-copy-text",
                              staticStyle: { "white-space": "break-spaces" },
                              attrs: {
                                value: data.item.result,
                                tag: "div",
                                contenteditable: true,
                                "no-h-t-m-l": false,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChangeResult($event, data.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (data) {
                          return [
                            !_vm.isEqualResult(data.item.id)
                              ? [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.left",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                          },
                                        },
                                      ],
                                      staticClass: "mb-2 mr-2",
                                      attrs: {
                                        variant: "primary",
                                        size: "sm",
                                        title: "Save",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(data.item)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "check-lg" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.left",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                          },
                                        },
                                      ],
                                      staticClass: "mb-2 mr-2",
                                      attrs: {
                                        variant: "danger",
                                        size: "sm",
                                        title: "Cancel",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancel(data.item)
                                        },
                                      },
                                    },
                                    [_c("b-icon", { attrs: { icon: "x-lg" } })],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showUpdateCopyToOriginal(
                                      data.item.id
                                    ),
                                    expression:
                                      "showUpdateCopyToOriginal(data.item.id)",
                                  },
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.left",
                                    modifiers: { hover: true, left: true },
                                  },
                                ],
                                staticClass: "mb-2 mr-2",
                                attrs: {
                                  variant: "outline-danger",
                                  size: "sm",
                                  title: "Update copy to original",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateCopyToOriginal(data.item)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "arrow90deg-right" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1021216326
                  ),
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }