import { parseJsonData } from "../../../services/helpers";

export const LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT = 'setected-templates-for-text';
export const LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE = 'setected-templates-for-image';
export const LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_GLOBAL = 'selected-templates-global-update';

function putToLocalStorage(localStorageKey, selectedTemplates) {
    localStorage.setItem(localStorageKey, JSON.stringify(selectedTemplates));
}

function hasSelectedTemplatesKey(localStorageKey) {
    return !!localStorage.getItem(localStorageKey);
}

function hasSelectedTemplates(localStorageKey, instanceId) {
    switch (localStorageKey) {
        case LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT:
            return hasSelectedTemplatesText(instanceId)
        case LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE:
            return hasSelectedTemplatesImage(instanceId)
        case LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_GLOBAL:
            return hasSelectedTemplatesGlobalUpdate(instanceId)
        default:
            throw new Error('provided local storage key not supported: ' + localStorageKey)
    }
}

function hasSelectedTemplatesText(instanceId) {
    return getSelectedTempaltes(LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT).includes(instanceId);
}

function hasSelectedTemplatesImage(instanceId) {
    return getSelectedTempaltes(LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE).includes(instanceId);
}

function hasSelectedTemplatesGlobalUpdate(instanceId) {
  return getSelectedTempaltes(LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_GLOBAL).includes(instanceId);
}

function getSelectedTempaltes(localStorageKey) {
    return parseJsonData(localStorage.getItem(localStorageKey), []);
}

function getSelectedTempaltesText() {
    return getSelectedTempaltes(LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_TEXT);
}

function getSelectedTempaltesImage() {
    return getSelectedTempaltes(LOCAL_STORAGE_KEY_SELECTED_TEMPLATES_FOR_IMAGE);
}

function addSelectedTemplate(instanceId, localStorageKey) {
    const selectedTemplates = getSelectedTempaltes(localStorageKey);

    if (!selectedTemplates.find(_instanceId => _instanceId === instanceId)) {
        selectedTemplates.push(instanceId);
    }

    putToLocalStorage(localStorageKey, selectedTemplates);
}

function removeSelectedTemplate(instanceId, localStorageKey) {
    const selectedTemplates = getSelectedTempaltes(localStorageKey);

    const _selectedTemplates = selectedTemplates.filter(_instanceId => _instanceId !== instanceId);

    putToLocalStorage(localStorageKey, _selectedTemplates);
}

function toggleSelectedTemplate(instanceId, localStorageKey) {
    const selectedTemplates = getSelectedTempaltes(localStorageKey);

    if (!selectedTemplates.find(_instanceId => _instanceId === instanceId)) {
        addSelectedTemplate(instanceId, localStorageKey);
    } else {
        removeSelectedTemplate(instanceId, localStorageKey);
    }
}

export default {
    hasSelectedTemplates,
    hasSelectedTemplatesKey,
    hasSelectedTemplatesText,
    hasSelectedTemplatesImage,
    hasSelectedTemplatesGlobalUpdate,
    getSelectedTempaltes,
    getSelectedTempaltesText,
    getSelectedTempaltesImage,
    addSelectedTemplate,
    removeSelectedTemplate,
    toggleSelectedTemplate
};