var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "naming-table-variables",
        title: "Naming Table Variables",
        "ok-only": "",
      },
      on: { ok: _vm.saveName },
    },
    [
      _c("NamingTableInput", {
        attrs: {
          "selected-names-from-tabs": _vm.selectedNamesFromTabs,
          "naming-design-tabs": _vm.getNamingDesignTabs,
          "has-show-custom-name-input": false,
        },
        on: { onSelectedNamesFromTabs: _vm.onSelectedNamesFromTabs },
        model: {
          value: _vm.namingTable,
          callback: function ($$v) {
            _vm.namingTable = $$v
          },
          expression: "namingTable",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }