<template>
  <b-btn
    class="a-button-primary"
    variant="primary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-btn>
</template>

<script>
export default {
  name: "ButtonPrimary"
}
</script>

<style scoped lang="scss">
.a-button-primary {
  color: white;
  background-color: #007964;
  border-color: #007964;
  border-radius: 8px;

  &:hover, &:not(:disabled):not(.disabled):active {
    background-color: #006050;
    color: white;
  }

  &:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgb(0, 121, 100, 0.5);
    border-color: #209c86;
  }
}
</style>