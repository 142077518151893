export default {
    createLayout(customerId, payload) {
        return axios.post(`/banner/${customerId}/group/store_ajax`, payload)
    },
    saveAsTemplate(data) {
        return axios.post('/banner/save_as_template', data);
    },
    updateLayout(customerId, layoutId, data) {
        return axios.post(`/banner/${customerId}/group/${layoutId}/update_ajax`, data);
    },
    copyAndTranslate(url, data) {
        return axios.post(url, data);
    },
    async copy(gridLayoutId) {
      const requestData = await axios.get(`/banner/0/group/${gridLayoutId}/copy?api_request=true`);
      return requestData.data;
    },
    getTranslationForFields(layoutPartPath, instance, data) {
        const instanceId = instance.data.instance_id ?? instance.id;
        const url = `${layoutPartPath}template/${instanceId}/${instance.data.template_id}/get_translation_for_fields`;

        return axios.get(url, {
          params: data
        });
    },
    translate(layoutPartPath, instance, data) {
        const instanceId = instance.data.instance_id ?? instance.id;
        const url = `${layoutPartPath}template/${instanceId}/${instance.data.template_id}/translate_template`;

        return axios.post(url, data);
    },
    updateTemplate(data) {
        return axios.post('/banner/update_existing_template', data);
    },

    getPreviews(customerId, layoutId) {
      return axios.get(`/banner/${customerId}/group/${layoutId}/preview`);
    },
    getLayoutsCustomer(customerId, data = {}) {
      return axios.get(`/banner/${customerId}/group/get-layouts-customer`, data);
    },
    getLayoutTemplates(layoutId, params = {}) {
        return axios.get(`/banner/groups/${layoutId}/templates`, { params: params });
    },
    downloadLayoutPdfFromProof(proof, data) {
        return axios.get(`/banner/${proof}/download_layout_pdf`, data);
    },
    toggleFavorite (layoutId) {
        return axios.post(`/banner/groups/${layoutId}/toggle-favorite`)
    },
}

